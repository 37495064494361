import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
// components
import ButtonDefault from '@atoms/buttons/button';
// icons
import IconWrong from '@assets/icons/modals/wrong';
// style
import './style.scss';

interface RemoveLcCompanyModalType {
  component: any;
  action?: any;
}

const RemoveLcCompanyModal: FC<RemoveLcCompanyModalType> = ({
  component: Component,
  action,
}: RemoveLcCompanyModalType) => {
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);

  const { t } = useTranslation();

  const onCancel = () => {
    setVisible(false);
  };

  const call = () => {
    setLoader(true);
    action(onCancel);
  };

  return (
    <>
      <>
        <Component
          onClick={() => {
            setVisible(true);
          }}
        />
        <Modal
          className="modal-confirm"
          open={visible}
          width={504}
          footer={null}
          closable={true}
          bodyStyle={{ padding: '64px' }}
          centered
          onCancel={onCancel}
        >
          <div className="modal-confirm__header">
            <IconWrong />
          </div>
          <div className="modal-confirm__body">
            <div className="modal-confirm__title">{t('Удаление компании')}</div>
            <div className="modal-confirm__desc">{ReactHtmlParser(t('Вы уверены, что хотите удалить компанию?'))}</div>
            <div className="modal-confirm__btns">
              <ButtonDefault action={call} name={t('Удалить')} />
              <ButtonDefault action={onCancel} name={t('Отменить')} classes={'btn-gray'} />
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default RemoveLcCompanyModal;
