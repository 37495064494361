import { IconAwait, IconCompanies, IconReject, IconVerification } from '@assets/icons/statsInfo';
import { stateType } from '@types';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// styles
import './style.scss';

const StatsInfo: FC = () => {
  const {
    info: {
      verifier: { infoLists },
    },
  } = useSelector((state: stateType) => state.user);
  const { t } = useTranslation();

  const { allCompanies, companiesActive, companiesReject, companiesUnderConsideration } = infoLists;
  return (
    <div className="stats-info-block">
      <div className="stats-info-block__box">
        <div className="stats-info-block__box-body">
          <div className="stats-info-block__box-title">{t('Компании')}</div>
          <div className="stats-info-block__box-counts">
            <div className="stats-info-block__box-count">{allCompanies}</div>
          </div>
        </div>
        <div className="stats-info-block__box-icon stats-info-block__box-icon-companies">
          <IconCompanies />
        </div>
      </div>
      <div className="stats-info-block__box">
        <div className="stats-info-block__box-body">
          <div className="stats-info-block__box-title">{t('Ожидают проверки')}</div>
          <div className="stats-info-block__box-counts">
            <div className="stats-info-block__box-count">{companiesUnderConsideration}</div>
          </div>
        </div>
        <div className="stats-info-block__box-icon stats-info-block__box-icon-await">
          <IconAwait />
        </div>
      </div>
      <div className="stats-info-block__box">
        <div className="stats-info-block__box-body">
          <div className="stats-info-block__box-title">{t('Верифицированы')}</div>
          <div className="stats-info-block__box-counts">
            <div className="stats-info-block__box-count">{companiesActive}</div>
          </div>
        </div>
        <div className="stats-info-block__box-icon stats-info-block__box-icon-verification">
          <IconVerification />
        </div>
      </div>
      <div className="stats-info-block__box">
        <div className="stats-info-block__box-body">
          <div className="stats-info-block__box-title">{t('Отклонены')}</div>
          <div className="stats-info-block__box-counts">
            <div className="stats-info-block__box-count">{companiesReject}</div>
          </div>
        </div>
        <div className={classNames('stats-info-block__box-icon', 'stats-info-block__box-icon-reject')}>
          <IconReject />
        </div>
      </div>
    </div>
  );
};

export default StatsInfo;
