// types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { menuLists, menuItemType } from '@tools/menu';
/*
 *
 * Menu reducer
 *
 */
const initialState = {
  active: null,
  list: [] as Array<menuItemType>,
};

export type menuInitStateType = typeof initialState;

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuList: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setActive: (state, action: PayloadAction<any>) => {
      state.active = action.payload;
    },
  },
});

export default menu.reducer;
export const { setMenuList, setActive }: any = menu.actions;
