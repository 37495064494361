import React from 'react';
import { useTranslation } from 'react-i18next';
// styles
import './style.scss';

const AppBenefits: React.FC = () => {
  const benefits = [
    'Автоматизация HR',
    'Интеграция с job-порталами',
    'Оценка персонала',
    'Синхронизация с Outlook',
    'Безопасность данных',
    'Парсинг резюме',
    'Мультиязычность',
    'Конструктор отчетности',
  ];

  const { t } = useTranslation();
  return (
    <div className="benefits">
      <ul className="benefits__list">
        {benefits.map((benefit, ind) => (
          <li key={ind} className="benefits__item">
            <div className="benefits__icon" />
            {t(benefit)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AppBenefits;
