import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
// components
import ButtonDefault from '@atoms/buttons/button';
// icons
import IconWrong from '@assets/icons/modals/wrong';
// style
import './style.scss';

interface RemoveEmailModalType {
  component: any;
  action?: any;
}

const RemoveSocialModal: FC<RemoveEmailModalType> = ({ component: Component, action }: RemoveEmailModalType) => {
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  const onCancel = () => {
    setVisible(false);
  };

  const call = () => {
    action && action();
    setVisible(false);
  };

  return (
    <>
      <Component
        onClick={() => {
          setVisible(true);
        }}
      />
      <Modal
        className="modal-confirm"
        open={visible}
        width={504}
        footer={null}
        closable={true}
        bodyStyle={{ padding: '64px' }}
        centered
        onCancel={onCancel}
      >
        <div className="modal-confirm__header">
          <IconWrong />
        </div>
        <div className="modal-confirm__body">
          <div className="modal-confirm__title">{t('Удаление мессенджера')}</div>
          <div className="modal-confirm__desc">{t('Вы уверены, что хотите удалить Мессенджер?')}</div>
          <div className="modal-confirm__btns">
            <ButtonDefault action={call} name={t('Удалить')} />
            <ButtonDefault action={onCancel} name={t('Отменить')} classes={'btn-gray'} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RemoveSocialModal;
