import { AxiosResponse } from 'axios'
// routers
import { get, post } from '@api/api_helper'

export const api = {
	addNewDepartment: async (request: any): Promise<AxiosResponse> => {
		const { token, is_new_address, data } = request
		try {
			return await post(
				`/add_new_department/?token=${token}&is_new_address=${is_new_address}&data=${JSON.stringify(
					data
				)}`
			)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	getDepartments: async (token: string): Promise<AxiosResponse> => {
		try {
			return await get(`/get_list_departments/?token=${token}`)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	getDepartmentInfo: async (request: any): Promise<AxiosResponse> => {
		const { token, idItem, type } = request
		try {
			return await post(
				`/get_info_department/?token=${token}&id_tl_lists=${idItem}&type=${type}`
			)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	checkAllDepartments: async (token: string): Promise<AxiosResponse> => {
		try {
			return await post(`/check_departments_adresses/?token=${token}`)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},
}

/*
   // отримання списка відділів
   get_list_departments: `${domain}/get_list_departments/`,

   // отримання інформації про відділ
   get_info_department: `${domain}/get_info_department/`,

   // створення нового відділа
   add_new_department: `${domain}/add_new_department/`,

   // перевірка відділів (навність адресів)
   check_departments_adresses: `${domain}/check_departments_adresses/`
  */
