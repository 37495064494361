import React, { useEffect } from 'react';
// components
// import HomeTemplate from '@templates/homeTemplate';

const StaffAssessment: React.FC = () => {
  useEffect(() => {
    console.log('StaffAssessment PAGE');
  }, []);

  return <>StaffAssessment</>;
};

export default StaffAssessment;
