import React, { FC, useMemo } from 'react';
import i18n from '@src/i18n';
import { Checkbox } from 'antd';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import md5 from 'md5';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// components
import InputDefault from '@atoms/inputs';
import ButtonDefault from '@atoms/buttons/button';
// actions
import { appPage, setStatus } from '@redux/reducers/app';
import { login, setSessionAuth } from '@redux/reducers/auth';
// helps
import { loginSchema } from '@tools/shemas';
import usePasswordHook from '@tools/hooks/forms/usePasswordHook';
// types
import { stateType } from '@types';
// icons
import IconEye from '@assets/icons/inputs/eye';
import IconEyeClose from '@assets/icons/inputs/eyeClose';
// styles
import './style.scss';

interface FormData {
  email: string;
  password: string;
}

const Login: FC = () => {
  const { loading } = useSelector((state: stateType) => state.auth);
  const schema = useMemo(() => loginSchema(), [i18n.language]);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setSession = (e: any) => {
    dispatch(setSessionAuth(e.target.checked));
  };

  const onSubmit = async (data: FormData) => {
    const { email, password } = data;

    let key;
    let request;
    Number(email) ? (key = 'phone') : (key = 'email');

    if (key === 'email') {
      request = {
        [key]: email,
        password: md5(password),
      };
    }

    if (key === 'phone') {
      request = {
        [key]: email,
        password: md5(password),
      };
    }

    dispatch(
      login({
        request,
        callback: () => {
          dispatch(setStatus(''));
          navigate('/');
        },
      }),
    );
  };

  const { passwordType: passwordType, changePasswordType: changePasswordType } = usePasswordHook();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form auth-form">
      <div className="form__body">
        <div className="form__container">
          <InputDefault
            name={'email'}
            label={t('E-mail или телефон')}
            placeholder={t('Адрес эл. почты или телефон')}
            error={errors.email?.message}
            register={register}
            control={control}
          />
        </div>
        <div className="form__container">
          <InputDefault
            name={'password'}
            label={t('Пароль')}
            classes={'margin-0'}
            placeholder={t('Укажите ваш пароль')}
            error={errors.password?.message}
            register={register}
            control={control}
            type={passwordType}
          />
          <div className="form__container-icons" onClick={changePasswordType}>
            {passwordType === 'input' ? <IconEye /> : <IconEyeClose />}
          </div>
        </div>
        <div className="form__container-footer">
          <Checkbox className="form__container-checkbox" onChange={setSession}>
            {t('Запомнить')}
          </Checkbox>
          <Link to="/forgot" onClick={() => appPage('forgot')}>
            {t('Забыли пароль?')}
          </Link>
        </div>
      </div>

      <div className="form__container">
        <ButtonDefault name={t('Войти в аккаунт')} type={'submit'} loading={loading} disabled={loading} />
      </div>
    </form>
  );
};

export default Login;
