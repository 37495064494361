import React, { FC } from 'react';
import { Button, Col, Form, message, Row } from 'antd';
import { checkFormValues } from '@tools/helpers';

import { func } from 'prop-types';
import { useSelector } from 'react-redux';

import { rules } from '@tools/messages';
import { useAddItemToDropDownList } from '@tools/hooks/useAddItemToDropDownList';
import { stateType } from '@types';
import AppText from '@atoms/text/AppText';
import AppSelect from '@molecules/app/select';

const FormAddProgram: FC<any> = ({ changeVisible, onSubmitForm }: any) => {
  const [form] = Form.useForm();
  const addProgram = useAddItemToDropDownList();
  const { dropDownList } = useSelector((state: stateType) => ({ dropDownList: state.app.options }));
  const onFinish = () => {
    const values = checkFormValues(form.getFieldsValue());
    onSubmitForm(values);
    form.resetFields();
    changeVisible();
  };
  const handleOnAddProgram = async () => {
    /*
      try {
      await addProgram('', name);
    } catch (err) {
      console.error(err);
      message.error(err.message);
      throw err;
    }
   */
  };
  return (
    <Form style={{ height: '100%' }} name="add-program" layout="vertical" size="large" onFinish={onFinish} form={form}>
      <Row style={{ height: '100%' }}>
        <Col span={24}>
          <AppText
            text="Владение программами"
            style={{
              color: '#20272E',
              fontSize: 18,
              fontWeight: 700,
              marginBottom: 24,
              lineHeight: 1.5715,
              display: 'block',
            }}
          />
          <Form.Item
            className="form__container"
            label="Программа"
            name="name"
            rules={[{ required: true, message: rules.required }]}
          >
            <AppSelect
              options={dropDownList?.s_programs}
              placeholder="Напишите или выберите из списка"
              isHaveFooter
              onSubmit={handleOnAddProgram}
            />
          </Form.Item>
          <Form.Item
            className="form__container"
            label="Уровень владения"
            name="levels"
            rules={[{ required: true, message: rules.required }]}
          >
            <AppSelect options={dropDownList?.s_program_level} placeholder="Выберите из списка" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Row justify="end" align="bottom" style={{ height: '100%' }}>
            <Col>
              <Button type="primary" shape="round" htmlType="submit">
                Сохранить
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default FormAddProgram;
