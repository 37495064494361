import { useEffect, useState } from 'react';

const useCalculatePageSize = () => {
    const [pageSize, setPageSize] = useState(0);

    useEffect(() => {
        const calculatePageSize = () => {
            const windowHeight = window.innerHeight;
            const tableHeaderHeight = 64; // Height of the table header in pixels
            const paginationHeight = 64; // Height of the pagination component in pixels

            // Calculate the remaining height for the table content
            const availableHeight = windowHeight - tableHeaderHeight - paginationHeight;

            // Calculate the number of rows that can fit in the available height
            const rowHeight = 55; // Assuming each row has a fixed height of 55 pixels
            const calculatedPageSize = Math.floor(availableHeight / rowHeight);

            setPageSize(calculatedPageSize);
        };

        // Initial calculation on component mount
        calculatePageSize();

        // Recalculate on window resize
        window.addEventListener('resize', calculatePageSize);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', calculatePageSize);
        };
    }, []);

    return pageSize;
};

export default useCalculatePageSize;
