import React, { FC, useEffect } from 'react';

import { Modal } from 'antd';

import __ from 'lodash';
import CloseModalIcon from '@assets/icons/CloseModalIcon';
import PositionFunctionalityModalHeader from './PositionFunctionalityModalHeader';
import PositionFunctionalityModalBody from './PositionFunctionalityModalBody';
import PositionFunctionalityModalFooter from './PositionFunctionalityModalFooter';

const PositionFunctionalityModal: FC<any> = (props: any) => {
  const {
    visible,
    changeVisible,
    userSelectedList,
    changeUserSelectedList,
    positionsId,
    setPositionId,
    positionData,
    addItem,
    removeItem,
    title,
  } = props;
  const { checkedList, setCheckedList, setAllOptions } = positionData;

  function handleOnSubmitFunctionality() {
    changeUserSelectedList(__.sortBy(checkedList, 'name').filter((el) => el.selected));
    changeVisible(!visible);
  }

  useEffect(() => {
    if (visible) {
      setAllOptions((prev: any) => prev.map((p: any) => ({ ...p, selected: false })));
      setCheckedList(userSelectedList);
    }
  }, [visible]);

  return (
    <Modal
      className="functionality-modal"
      visible={visible}
      footer={null}
      bodyStyle={{ padding: 0 }}
      closable
      centered
      closeIcon={<CloseModalIcon onClick={() => changeVisible(!visible)} />}
    >
      <PositionFunctionalityModalHeader positionData={positionData} />

      <PositionFunctionalityModalBody
        title={title}
        positionsId={positionsId}
        setPositionId={setPositionId}
        positionData={positionData}
        addItem={addItem}
        removeItem={removeItem}
      />

      <PositionFunctionalityModalFooter onClick={() => handleOnSubmitFunctionality()} />
    </Modal>
  );
};

export default PositionFunctionalityModal;
