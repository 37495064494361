import React, { FC } from 'react';
import Icon from '@ant-design/icons';
// styles
import './style.scss';

interface AppMessageType {
  img: any;
  active?: boolean;
}
const AppMessage: FC<AppMessageType> = ({ img, active }: AppMessageType) => {
  return (
    <div className="message">
      {img && <Icon component={img} />}
      {active && <span className="message__span"></span>}
    </div>
  );
};

export default AppMessage;
