import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// actions
import { appPage, getOptions } from '@redux/reducers/app';
// components
import FormTemplate from '@templates/formTemplate';
import FormName from '@atoms/text/formName';
import FormUserRegistration from '@organisms/forms/userRegistration';
// types
import { stateType } from '@types';

const UserRegistration: FC = () => {
  const { page } = useSelector((state: stateType) => state.app);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(appPage('user-registration'));
    dispatch(
      getOptions({
        request: {
          source: ['gender'],
        },
      }),
    );
  }, []);
  return (
    <>
      <Helmet title={page} />
      <FormTemplate page={page}>
        <FormName label={t('Профиль пользователя')} />
        <FormUserRegistration />
      </FormTemplate>
    </>
  );
};

export default UserRegistration;
