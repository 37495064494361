import React from 'react';

function MyCompanyIcon() {
   return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd"
               d="M8.92031 2.93247C8.3617 2.93247 7.88646 3.32066 7.75306 3.84386H12.2386C12.1052 3.32066 11.63 2.93247 11.0714 2.93247H8.92031ZM13.5059 3.84386H15.1568C16.9076 3.84386 18.3334 5.28689 18.3334 7.05903C18.3334 7.05903 18.2833 7.80924 18.2667 8.85396C18.265 8.93666 18.225 9.01767 18.1591 9.06662C17.7581 9.36282 17.3912 9.60754 17.3579 9.62442C15.9738 10.5527 14.3655 11.2058 12.6522 11.5307C12.5405 11.5527 12.4304 11.4945 12.3737 11.3949C11.8935 10.5628 10.9964 10.021 9.99585 10.021C9.00202 10.021 8.09657 10.5569 7.60216 11.3898C7.54463 11.4877 7.43624 11.5442 7.32535 11.5231C5.62617 11.1974 4.01786 10.5451 2.64217 9.63286L1.84177 9.0759C1.77507 9.03371 1.73339 8.95776 1.73339 8.87337C1.70837 8.44299 1.66669 7.05903 1.66669 7.05903C1.66669 5.28689 3.0924 3.84386 4.84328 3.84386H6.48576C6.64418 2.62024 7.66969 1.66666 8.92031 1.66666H11.0714C12.322 1.66666 13.3475 2.62024 13.5059 3.84386ZM18.0499 10.6793L18.0165 10.6962C16.3324 11.827 14.3063 12.5781 12.1803 12.8903C11.8801 12.9325 11.58 12.7384 11.4966 12.4346C11.3132 11.7426 10.7212 11.2869 10.0125 11.2869H10.0042H9.98752C9.27883 11.2869 8.68687 11.7426 8.50344 12.4346C8.42006 12.7384 8.11992 12.9325 7.81977 12.8903C5.6937 12.5781 3.66769 11.827 1.98351 10.6962C1.97518 10.6878 1.8918 10.6372 1.8251 10.6793C1.75006 10.7215 1.75006 10.8228 1.75006 10.8228L1.80843 15.1266C1.80843 16.8987 3.2258 18.3333 4.97668 18.3333H15.015C16.7659 18.3333 18.1833 16.8987 18.1833 15.1266L18.25 10.8228C18.25 10.8228 18.25 10.7215 18.1749 10.6793C18.1333 10.654 18.0832 10.6625 18.0499 10.6793ZM10.6212 14.2152C10.6212 14.5696 10.346 14.8481 9.99585 14.8481C9.65401 14.8481 9.37054 14.5696 9.37054 14.2152V13.1266C9.37054 12.7806 9.65401 12.4937 9.99585 12.4937C10.346 12.4937 10.6212 12.7806 10.6212 13.1266V14.2152Z"
               fill="currentColor"/>
      </svg>
   );
}

export default MyCompanyIcon;