/* eslint-disable react/no-unescaped-entities */
import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Col, Form, message, Row } from 'antd';
// components
import DeputyForm from './DeputyForm';
import FormAddButton from '@atoms/buttons/navAddButton';
import AppDescriptions from '@molecules/app/description';
// actions
import { addCompanyDeputy } from '@redux/reducers/company';
import { setStatus, setSuccess } from '@redux/reducers/app';
// tools
import { checkFormValues } from '@tools/helpers';
// types
import { stateType } from '@types';

import { messages } from '@tools/messages';
// images
import bgAside from '@image/bg-aside.svg';
import { useNavigate } from 'react-router-dom';

//import {addCompanyDeputy} from "@redux/actions/deputy-actions";

const ErrorContent: FC<string> = ({ email }: any) => {
  return (
    <span>
      Ошибка, пользователь
      {<span style={{ fontWeight: 600 }}>"{email}"</span>}, уже существует
    </span>
  );
};

const FormAddDeputy: FC<any> = (props: any) => {
  const { isSuccess, changeSuccess, changeVisible, otherDeputy, onRemoveDeputy, onOpenDeputy } = props;
  const [form] = Form.useForm();

  const { token } = useSelector((state: stateType) => state.auth);
  const { loading } = useSelector((state: stateType) => state.company);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = () => {
    form.submit();
    setTimeout(async () => {
      const values = form.getFieldsValue();
      const email = otherDeputy.find((item: any) => item.email === values.email)?.email;
      if (email) {
        form.setFields([{ name: 'email', errors: ['Заместитель с таким адресом уже добавлен!'] }]);
        return;
      }

      const errorsLength = form.getFieldsError().filter(({ errors }) => errors.length).length;
      if (errorsLength) {
        return;
      }

      const checkValues = checkFormValues(values);
      const deputy = [...otherDeputy, checkValues];

      dispatch(
        addCompanyDeputy({
          token: token,
          data: deputy,
          callback: () => {
            setSuccess({
              title: messages.deputyRegistration,
              message: messages.goToHome,
              img: bgAside,
              successFn: () => {
                dispatch(setStatus('main'));
                navigate('/');
              },
            });
          },
        }),
      );
      /*
      try {
        const json = await addCompanyDeputy(deputy);
        if (json.ok) {
          setIsLoading(false);
          changeSuccess(!isSuccess);
        } else if (json.error) {
          const emails = Object.keys(json?.data);
          emails.forEach((email) => {
            message.error({ content: React.createElement(ErrorContent, { email }), duration: 5 });
          });
          setIsLoading(false);
        } else {
          throw new Error('Ошибка создания заместителя');
        }
      } catch (err) {
        setIsLoading(false);
        message.error(err.message);
      }
      */
    }, 0);
  };
  return (
    <>
      <DeputyForm form={form} isSubmit={false} />
      <Row wrap justify="end" gutter={[24, 24]}>
        <Col span={24}>
          <FormAddButton onClick={() => changeVisible()} extraClasses="_card" text="Добавить еще заместителя" />
        </Col>
        {!!otherDeputy.length && (
          <Col span={24}>
            <Row wrap gutter={[24, 24]}>
              {otherDeputy.map(({ id, last_name, first_name, middle_name, tel, email }: any) => (
                <Col key={id} span={24}>
                  <AppDescriptions
                    title={{ last_name, first_name, middle_name }}
                    descriptions={{ email, tel }}
                    onRemove={() => onRemoveDeputy(id)}
                    onOpen={() => onOpenDeputy(id)}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        )}
        <Col>
          <Button type="primary" shape="round" loading={loading} onClick={() => onFinish()}>
            Сохранить и продолжить
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default FormAddDeputy;
