import React, { useEffect } from 'react';

// components
// import HomeTemplate from '@templates/homeTemplate';

const Performens: React.FC = () => {
  useEffect(() => {
    console.log('Performens PAGE');
  }, []);

  return <>Performens</>;
};

export default Performens;
