import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
// components
import AddTasksModal from '@molecules/addTasks';
import AddCandidatesModal from '@organisms/modals/addCandidates';
import FormAddButton, { MainNavButton } from '@atoms/buttons/navAddButton';
import InfoByVerifyModal from '@organisms/modals/InfoByVerifyModal';
// tools
import { oldCompanyStatus } from '@tools/constants';
// icons
import BigPlusIcon from '@assets/icons/home/BigPlusIcon';
// styles
import './style.scss';
import AddCompanyModal from '@organisms/modals/mainNavigation/addCompany';
import AddVacancyModal from '@organisms/modals/mainNavigation/addVacancy';
import AddMeetingModal from '@organisms/modals/mainNavigation/addMeeting';
import AddTaskModal from '@organisms/modals/mainNavigation/addTask';

const NavigationContainer: FC = () => {
  const navigate = useNavigate();

  const renderController = () => {
    /*
              <Row gutter={[5, 5]}>
            <Col span={8}>
              <FormAddButton
                text="В процессе"
                icon={<BigPlusIcon fill={'#84df88'} />}
                containerStyle={{ backgroundColor: 'rgb(182, 255, 189)' }}
                onClick={() => console.log('dispatch(setVerificationInProgress())')}
              />
            </Col>
            <Col span={8}>
              <InfoByVerifyModal
                status={companyStatus.SUCCESS}
                btnText="Успех"
                icon={<BigPlusIcon fill={'#FFC864'} />}
                containerStyle={{ backgroundColor: 'rgb(255, 238, 191)' }}
                component={FormAddButton}
              />
            </Col>
            <Col span={8}>
              <InfoByVerifyModal
                status={companyStatus.FAILED}
                btnText="Не удалось"
                icon={<BigPlusIcon fill={'#FFC864'} />}
                containerStyle={{ backgroundColor: 'rgb(255, 238, 191)' }}
                component={FormAddButton}
              />
            </Col>
          </Row>
    */
  };

  return (
    <div className="nav-container">
      <AddCompanyModal component={MainNavButton} />
      <AddVacancyModal component={MainNavButton} />
      <AddMeetingModal component={MainNavButton} />
      <AddTaskModal component={MainNavButton} />
    </div>
  );
};

export default NavigationContainer;

/*
      <FormAddButton
        text="Добавить вакансию"
        icon={<BigPlusIcon fill={'#FFC864'} />}
        containerStyle={{ backgroundColor: 'rgb(255, 238, 191)' }}
        onClick={() => {
          navigate('/add-vacancy');
          localStorage.setItem('company_step', '1');
        }}
      />
      <FormAddButton
        text="Добавить встречу"
        icon={<BigPlusIcon fill={'#8D8BED'} />}
        containerStyle={{ backgroundColor: 'rgb(219, 218, 255)' }}
        onClick={() => console.log()}
      />
      <AddTasksModal />
*/
