/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react';
// components
import Remainder from '@molecules/remainder';

export const columns = [
  {
    title: '№',
    dataIndex: 'number',
    className: 'app-table__column _vacancies',
  },
  {
    title: 'Вакансии',
    dataIndex: 'name',
    className: 'app-table__column _vacancies',
  },
  {
    title: 'Новые отклики',
    dataIndex: 'feedback',
    className: 'app-table__column _vacancies',
    sorter: (a, b) => a.feedback - b.feedback,
    render: (text, record) => <Remainder text={text} record={record} type="feedback" />,
  },
  {
    title: 'Ожидают звонка',
    dataIndex: 'called',
    className: 'app-table__column _vacancies',
    sorter: (a, b) => a.called - b.called,
    render: (text, record) => <Remainder text={text} record={record} type="called" />,
  },
  {
    title: 'Прошли тестирование',
    dataIndex: 'tested',
    className: 'app-table__column _vacancies',
    sorter: (a, b) => a.tested - b.tested,
    render: (text, record) => <Remainder text={text} record={record} type="tested" />,
  },
  {
    title: 'Ожидают собеседование',
    dataIndex: 'interviewed',
    className: 'app-table__column _vacancies',
    sorter: (a, b) => a.interviewed - b.interviewed,
    render: (text, record) => <Remainder text={text} record={record} type="interviewed" />,
  },
];
