import React, { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import { Pagination } from 'antd';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// components
import DropdownDateFilter from '@molecules/app/dropdwons/dateFilter';
// actions
import { getOptions } from '@redux/reducers/app';
// configs
import { columns } from '@configs/tables/lcCompanies';
// types
import { stateType } from '@types';
// style
import './style.scss';

interface LcVerificationCompaniesTableType {
  headerTitle?: string;
}

const LcVerificationCompaniesTable: FC<LcVerificationCompaniesTableType> = ({
  headerTitle,
}: LcVerificationCompaniesTableType) => {
  const { options } = useSelector((state: stateType) => state.app);
  const { companies, loading }: any = useSelector((state: stateType) => state.lcVerificator);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    !options?.filter_companies &&
      dispatch(
        getOptions({
          request: {
            source: ['filter_companies'],
          },
        }),
      );
  }, []);

  return (
    <>
      <div className="lc-verification-companies-table">
        <HeaderTable headerTitle={headerTitle} />
        <Table
          loading={loading}
          showSorterTooltip={false}
          locale={{
            emptyText: <div className="lc-verification-companies-table__empty">{t('Нет данных')}.</div>,
          }}
          pagination={{
            defaultPageSize: 9,
            pageSize: 9,
            current: pageIndex,
            total: companies?.list?.length,
          }}
          className="lc-verification-companies-table__container"
          size="middle"
          columns={columns}
          rowKey={'id'}
          dataSource={companies?.list ? companies?.list : []}
          sortDirections={['ascend', 'descend']}
        />
      </div>
      {companies?.list?.length > 9 && (
        <Pagination
          className="table-default__pagination"
          defaultPageSize={9}
          pageSize={9}
          current={pageIndex}
          total={companies?.list?.length}
          onChange={(page, size) => {
            setPageIndex(page);
            setPageSize(size);
          }}
        />
      )}
    </>
  );
};

export default LcVerificationCompaniesTable;

const HeaderTable: FC<LcVerificationCompaniesTableType> = ({ headerTitle }: LcVerificationCompaniesTableType) => {
  const { t } = useTranslation();

  return (
    <div className="verification-table-header">
      <div className="verification-table-header__title">{headerTitle && t(headerTitle)}</div>
      <div className="verification-table-header__filters">
        <DropdownDateFilter />
      </div>
    </div>
  );
};
