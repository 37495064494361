import React from 'react';

function StaffTrainingIcon() {
   return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd"
               d="M6.39156 1.666H13.6166C16.4416 1.666 18.3332 3.64933 18.3332 6.59933V13.4085C18.3332 16.3502 16.4416 18.3327 13.6166 18.3327H6.39156C3.56656 18.3327 1.66656 16.3502 1.66656 13.4085V6.59933C1.66656 3.64933 3.56656 1.666 6.39156 1.666ZM9.99156 7.55017C9.5999 7.55017 9.2749 7.22433 9.2749 6.82517C9.2749 6.41683 9.5999 6.09183 10.0082 6.09183C10.4082 6.09183 10.7332 6.41683 10.7332 6.82517C10.7332 7.22433 10.4082 7.55017 9.99156 7.55017ZM10.7249 13.1502C10.7249 13.5502 10.3999 13.8752 9.99156 13.8752C9.59157 13.8752 9.26657 13.5502 9.26657 13.1502V9.46683C9.26657 9.066 9.59157 8.7335 9.99156 8.7335C10.3999 8.7335 10.7249 9.066 10.7249 9.46683V13.1502Z"
               fill="currentColor"/>
      </svg>
   
   
   );
}

export default StaffTrainingIcon;