import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
// actions
import { appPage, setStatus, setSuccess } from '@redux/reducers/app';
import { logoutFromCompany } from '@redux/reducers/company';
// icons
import successIcon from '@image/icons/succes-icon.svg';
import IconClose from '@assets/icons/page/close';
// types
import { stateType } from '@types';
// styles
import './style.scss';
import { setUser } from '@redux/reducers/user';
import { IconAppSuccess } from '@assets/icons/app';
import useLogout from '@tools/hooks/useLogout'

interface SuccessType {
  type?: string;
  action?: string;
}

const Success: FC<SuccessType> = ({ type, action}: SuccessType) => {
  const { info } = useSelector((state: stateType) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useLogout();

  const bodyController = () => {
    switch (type) {
      case 'registration':
        return (
          <>
            <p className="success__message">
              {t('Аккаунт был успешно зарегистрирован')}. <br />
              {t('На ваш E-Mail отправлено письмо с ссылкой для подтверждения')}
            </p>
          </>
        );
      case 'forgot':
        return (
          <>
            <p className="success__message">
              {t('На ваш E-Mail отправлено письмо')} <br />
              {t('с инструкцией по восстановлению пароля')}
            </p>
          </>
        );

      case 'reset':
        return (
          <>
            <p className="success__message">{ReactHtmlParser(t('Новый пароль успешно сохранен'))}</p>

            <div className="success__btns">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  dispatch(appPage('login'));
                  dispatch(setStatus('auth'));
                }}
              >
                {t('Войти')}
              </button>
              <button
                className="btn-default btn-clasic"
                onClick={() => {
                  dispatch(appPage('registration'));
                  dispatch(setStatus('auth'));
                }}
              >
                {t('Регистрация')}
              </button>
            </div>
          </>
        );

      case 'company-create':
        return (
          <>
            <p className="success__message">{ReactHtmlParser(t('Новая Компания успешно создана'))}</p>

            <div className="success__btns">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  //dispatch(appPage('login'));
                  //dispatch(setStatus('auth'));

                  dispatch(setStatus('company-verification'));
                  navigate('/company-create/verification');
                }}
              >
                {t('Пройти Верификацию')}
              </button>
              <button
                className="btn-default btn-clasic"
                onClick={() => {
                  //dispatch(appPage('registration'));
                  //dispatch(setStatus('auth'));

                  if (!info?.company?.id) {
                    dispatch(setStatus('companies-profile'));
                    navigate('/companies');
                    return;
                  }

                  dispatch(
                    logoutFromCompany({
                      request: { company_id: info.company.id },
                      callback: () => {
                        dispatch(setUser({ ...info, company: false }));
                        dispatch(setStatus('companies-profile'));
                        navigate('/companies');
                      },
                    }),
                  );
                }}
              >
                {t('Перейти к Компаниям')}
              </button>
            </div>
          </>
        );

      case 'company-verification':
        return (
          <>
            <p className="success__message">{ReactHtmlParser(t('Успешная верификация компании'))}</p>

            <div className="success__btns">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  //dispatch(appPage('login'));
                  //dispatch(setStatus('auth'));
                  dispatch(setStatus('main'));
                  navigate('/');
                }}
              >
                {t('Перейти на Главную')}
              </button>
              <button
                className="btn-default btn-clasic"
                onClick={() => {
                  //dispatch(appPage('registration'));
                  //dispatch(setStatus('auth'));
                  if (info?.company?.id) {
                    dispatch(
                      logoutFromCompany({
                        request: { company_id: info.company.id },
                        callback: () => {
                          dispatch(setUser({ ...info, company: false }));
                          dispatch(setStatus('company-create'));
                          navigate('/company-create');
                        },
                      }),
                    );
                    return;
                  }
                  dispatch(setStatus('company-create'));
                  navigate('/company-create');
                }}
              >
                {t('Добавить Компанию')}
              </button>
            </div>
          </>
        );
      case 'user-registration':
        return (
          <>
            <p className="success__message">{ReactHtmlParser(t('Данные пользователя сохранены'))}</p>

            <div className="success__btns">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  dispatch(setStatus('company-create'));
                  navigate('/company-create');
                }}
              >
                {t('Добавить компанию')}
              </button>
              <button
                className="btn-default btn-clasic"
                onClick={() => {
                  dispatch(setStatus('companies-profile'));
                  navigate('/companies');
                }}
              >
                {t('Перейти в Профиль Компаний')}
              </button>
            </div>
          </>
        );

      case 'add-user-to-company':
        return (
          <>
            <p className="success__message">{ReactHtmlParser(t('Новый Пользователь Компании успешно назначен'))}</p>

            <div className="success__btns">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  dispatch(setStatus('main'));
                  navigate('/');
                }}
              >
                {t('Главная')}
              </button>
              <button
                className="btn-default btn-clasic"
                onClick={() => {
                  dispatch(setStatus('main'));
                  navigate('/settings');
                }}
              >
                {t('Личный Кабинет')}
              </button>
            </div>
          </>
        );

      case 'add-admin-to-company':
        return (
          <>
            <p className="success__message">{ReactHtmlParser(t('Новый Администратор Компании успешно назначен'))}</p>

            <div className="success__btns">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  dispatch(setStatus('main'));
                  navigate('/');
                }}
              >
                {t('Главная')}
              </button>
              <button
                className="btn-default btn-clasic"
                onClick={() => {
                  dispatch(setStatus('main'));
                  navigate('/settings');
                }}
              >
                {t('Личный Кабинет')}
              </button>
            </div>
          </>
        );

      case 'update-user-to-company':
        return (
          <>
            <p className="success__message">{ReactHtmlParser(t('Права доступа пользователя успешно изменены'))}</p>

            <div className="success__btns">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  dispatch(setStatus('main'));
                  navigate('/');
                }}
              >
                {t('Главная')}
              </button>
              <button
                className="btn-default btn-clasic"
                onClick={() => {
                  dispatch(setStatus('main'));
                  navigate('/settings');
                }}
              >
                {t('Личный Кабинет')}
              </button>
            </div>
          </>
        );

      case 'transfer-company-to-user':
        return (
          <>
            <p className="success__message">{ReactHtmlParser(t('Новый Владедец Компании успешно назначен'))}</p>

            <div className="success__btns">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  dispatch(setStatus('main'));
                  navigate('/');
                }}
              >
                {t('Главная')}
              </button>
              <button
                className="btn-default btn-clasic"
                onClick={() => {
                  dispatch(setStatus('main'));
                  navigate('/settings');
                }}
              >
                {t('Личный Кабинет')}
              </button>
            </div>
          </>
        );

      case 'transfer-company-to-user-logout':
        return (
          <>
            <p className="success__message">{ReactHtmlParser(t('Новый Владедец Компании успешно назначен'))}</p>

            <div className="success__btns">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  dispatch(setStatus('company-create'));
                  navigate('/company-create');
                }}
              >
                {t('Добавить Компанию')}
              </button>
              <button
                className="btn-default btn-clasic"
                onClick={() => {
                  dispatch(setStatus('companies-profile'));
                  navigate('/companies');
                }}
              >
                {t('Мои Компании')}
              </button>
            </div>
          </>
        );

      case 'lc-verification':
        return (
          <>
            <p className="success__message">
              {ReactHtmlParser(t('Новая компания успешно верифицирована и добавлена в базу'))}
            </p>

            <div className="success__btns">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  dispatch(setStatus('verifier'));
                  navigate('/lc-companies');
                }}
              >
                {t('Компании')}
              </button>
            </div>
          </>
        );

      case 'lc-not-verification':
        return (
          <>
            <p className="success__message">{ReactHtmlParser(t('Отказ в верификации отправлен владельцу'))}</p>

            <div className="success__btns">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  dispatch(setStatus('verifier'));
                  navigate('/lc-companies');
                }}
              >
                {t('Компании')}
              </button>
            </div>
          </>
        );

      case 'lc-verification-remove':
        return (
          <>
            <p className="success__message">{ReactHtmlParser(t('Компания успешно удалена'))}</p>

            <div className="success__btns">
              <button
                className="btn-default btn-white"
                onClick={() => {
                  dispatch(setStatus('verifier'));
                  navigate('/lc-companies');
                }}
              >
                {t('Компании')}
              </button>
            </div>
          </>
        );

      default:
        return <></>;
    }
  };

    const handleConfirm = () => {
        switch (action) {
            case 'logout':
                logout()
                 break
            default:
                dispatch(setSuccess({}));
                dispatch(setStatus('auth'));
                dispatch(appPage('login'));
                break;
        }
        dispatch(setSuccess({}));
    }

  return (
    <div className="success">
      <div
        className="success__icon-close"
        onClick={handleConfirm}
      >
        <IconClose />
      </div>
      <div className="success__icon">
        <IconAppSuccess />
      </div>
      <div className="success__body">{bodyController()}</div>
    </div>
  );
};

export default Success;
