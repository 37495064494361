import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import { useTranslation } from 'react-i18next';
import i18n from '@src/i18n';
// components
import InputDefault from '@atoms/inputs';
import GeneratePassword from '@molecules/generatePassword';
import ButtonDefault from '@atoms/buttons/button';
// actions
import { registration } from '@redux/reducers/auth';
// helpers
import usePasswordHook from '@tools/hooks/forms/usePasswordHook';
import { regSchema } from '@tools/shemas';
import { consts } from '@tools/consts';
// types
import { stateType } from '@types';
// icons
import IconEye from '@assets/icons/inputs/eye';
import IconEyeClose from '@assets/icons/inputs/eyeClose';

// style
import './style.scss';

interface FormData {
  email: string;
  password: string;
  password_repeat: string;
}

const Registration: FC = () => {
  const { loading } = useSelector((state: stateType) => state.auth);
  const schema = useMemo(() => regSchema(), [i18n.language]);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: '',
      password: '',
      password_repeat: '',
    },
    resolver: yupResolver(schema),
  });

  const { passwordType: passwordType, changePasswordType: changePasswordType } = usePasswordHook();
  const { passwordType: passwordRepeatType, changePasswordType: changePasswordRepeatType } = usePasswordHook();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = async (data: FormData) => {
    const { email, password } = data;

    const request = {
      email: email,
      password: md5(password),
      ref: consts.domain,
    };

    dispatch(registration({ ...request, callback: () => console.log('ads') }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form reg-form">
      <div className="form__container">
        <InputDefault
          name={'email'}
          label={t('E-mail')}
          placeholder={t('Адрес эл. почты')}
          error={errors.email?.message}
          register={register}
          control={control}
        />
      </div>
      <div className="form__container">
        <InputDefault
          name={'password'}
          label={t('Придумайте пароль')}
          placeholder={t('Укажите ваш пароль')}
          error={errors.password?.message}
          register={register}
          control={control}
          type={passwordType}
        />
        <div className="form__container-icons">
          <GeneratePassword setValue={setValue} />
          <div className="form__container-icon" onClick={changePasswordType}>
            {passwordType === 'input' ? <IconEye /> : <IconEyeClose />}
          </div>
        </div>
      </div>

      <div className="form__container">
        <InputDefault
          name={'password_repeat'}
          label={t('Повторите пароль')}
          placeholder={t('Повторите ваш пароль')}
          error={errors.password_repeat?.message}
          register={register}
          control={control}
          type={passwordRepeatType}
        />
        <div className="form__container-icons">
          <div className="form__container-icon" onClick={changePasswordRepeatType}>
            {passwordRepeatType === 'input' ? <IconEye /> : <IconEyeClose />}
          </div>
        </div>
      </div>

      <div className="form__container">
        <ButtonDefault name={t('Зарегистрироваться')} type={'submit'} loading={loading} disabled={loading} />
      </div>
    </form>
  );
};

export default Registration;
