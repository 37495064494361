/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { message, notification } from 'antd';
import { put, call, select } from 'redux-saga/effects';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
// api
import { api } from '@api/company';
// actions
import { setLoading, setCompany, setCompanies, loginToCompany, setLoadingLogin } from '@redux/reducers/company';
import { setAdrList } from '@redux/reducers/addresses';
import { updateUser } from '@redux/reducers/user';
import NOTIFICATION from './notification';
// tools
import { getAddress } from '@tools/helpers';

export function* sagaCreateCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const { data } = yield call(api.createCompany, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }
    if (data.status) {
      yield put(loginToCompany({ request: { company_id: data.company_data.id } }));
      yield put(updateUser({ company: { ...data.company_data } }));
      callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaUpdateCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const { data } = yield call(api.updateCompany, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      notification.close('update-company');
      return NOTIFICATION.error(data.msg, false, 'update-company');
    }
    if (data.status) {
      yield put(updateUser({ company: { ...data.company_data } }));
      callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaGetCompanies(action) {
  const { payload } = action;
  const { request, callback } = payload;

  yield put(setCompanies([]));
  yield put(setLoading(true));

  try {
    const { data } = yield call(api.getCompanies, request);

    if (!_.isObject(data)) {
      yield put(setCompanies([]));
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      yield put(setCompanies([]));
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      yield put(setCompanies(data.company_list));
      callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaAddCompanyToArchive(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const { data } = yield call(api.addToArchive, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaRecoveryCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const response = yield call(api.recoveryCompany, request);
    const { data } = response;

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      console.log(data);
    }

    callback && callback();
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaLoginToCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;

  yield put(setLoadingLogin(true));

  try {
    const response = yield call(api.loginToCompany, request);
    const { data } = response;

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoadingLogin(false));
  }
}

export function* sagaLogoutFromCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const response = yield call(api.logoutFromCompany, request);
    const { data } = response;

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      yield put(updateUser({ company: false }));
    }

    callback && callback();
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaSetFileToCompany(action) {
  const { payload } = action;
  const { request, dir, callback } = payload;
  const getUserInfo = (state) => state.user.info;
  const info = yield select(getUserInfo);

  try {
    const response = yield call(api.setFileToCompany, request);
    const { data } = response;

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      yield put(updateUser({ company: { ...info.company, fileList: { [dir]: data.files } } }));
      //callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaRemoveFileCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const response = yield call(api.removeFileCompany, request);
    const { data } = response;

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      console.log(data);
    }

    callback && callback();
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaGetCompanyInfo(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const response = yield call(api.getCompanyInfo, request);
    const { data } = response;

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      yield put(setCompany(data.company));
    }

    callback && callback();
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaSetCompanyStatus(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const response = yield call(api.setCompanyStatus, request);
    const { data } = response;

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      console.log(data);
    }

    callback && callback();
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaRemoveCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;
  const { success, error } = callback;

  try {
    const { data } = yield call(api.removeCompany, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      error();
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      callback && success();
    }
  } catch (e) {
    error();
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaSearchUserInCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;
  const { success, error } = callback;

  try {
    const { data } = yield call(api.searchUserInCompany, request);

    if (!_.isObject(data)) {
      notification.close('search-user');
      return NOTIFICATION.error(ReactHtmlParser(data), false, 'search-user');
    }

    if (data.error) {
      error();

      notification.close('search-user');
      return NOTIFICATION.error(data.msg, false, 'search-user');
    }

    if (data.status) {
      notification.close('search-user');
      callback && success(data.users);
    }
  } catch (e) {
    error();
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaAddUserToCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;
  const { success, error } = callback;

  try {
    const { data } = yield call(api.addUserToCompany, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      error();
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      if (callback) {
        yield put(updateUser(data.user_data));
        yield call(success);
      }
    }
  } catch (e) {
    error();
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaUpdateUserToCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;
  const { success, error } = callback;

  try {
    const { data } = yield call(api.updateUserToCompany, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      error();
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      if (callback) {
        yield put(updateUser(data.user_data));
        yield call(success);
      }
    }
  } catch (e) {
    error();
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaRemoveUserFromCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;
  const { success, error } = callback;

  try {
    const { data } = yield call(api.removeUserFromCompany, request);

    if (!_.isObject(data)) {
      error();
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      error();
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      if (callback) {
        yield put(updateUser(data.user_data));
        yield call(success);
      }
    }
  } catch (e) {
    error();
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaTransferCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;
  const { success, error } = callback;

  try {
    const { data } = yield call(api.transferCompany, request);

    if (!_.isObject(data)) {
      error();
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      error();
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      if (callback) {
        yield put(updateUser(data.user_data));
        yield call(success);
      }
    }
  } catch (e) {
    error();
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

/* TODO sagas for old actions and old API */
export function* sagaGetCompany(action) {
  const { payload } = action;
  const { callback } = payload;

  try {
    const { data } = yield call(api.getCompany, payload);
    if (data.ok) {
      yield put(setCompany({ ...data.data, files: data.files }));
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaSetCompanyAdmin(action) {
  const { payload } = action;
  const { callback, key } = payload;

  try {
    const { data } = yield call(api.setCompanyAdmin, payload);
    if (data.ok) {
      yield put(setCompany(data.data));
      callback && callback();
      return;
    }
    message.error({ content: data.msg, key, duration: 4 });
  } catch (e) {
    message.error({ content: e.message, key, duration: 4 });
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaAddCompanyDeputy(action) {
  const { payload } = action;
  const { callback } = payload;
  yield put(setLoading(true));
  // yield put(setStatus('loading'));

  try {
    const { data } = yield call(api.addCompanyDeputy, payload);

    if (data.ok) {
      // yield put(setCompany(data.data));
      callback && callback();
      return;
      // yield put(setCompany())
      // yield put(setStatus('main'));
      // yield put(setToken(localStorage.getItem('token')));
      // yield put(setUser(data.data));
    }

    if (data.error) {
      let messageError = `Ошибка, пользователь ${payload.data[0].email} уже существует`;
      if (data.data[payload.data[0].email].error === 6) {
        message.error({ content: messageError, duration: 5 });
        return;
      }

      messageError = 'Что-то пошло не так';
      message.error({ content: messageError, duration: 5 });
    }
    // message.error({ content: React.createElement(ErrorContent, 'email'), duration: 5 });
  } catch (e) {
    message.error({ content: e.message, duration: 4 });
    throw new Error('Ошибка создания заместителя');

    // localStorage.clear();
    // yield put(setStatus(''));
    //yield put(setError(ERROR_MAP.auth(e)));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaGetAddressesList(action) {
  const { payload } = action;
  const { callback } = payload;
  yield put(setLoading(true));
  // yield put(setStatus('loading'));

  try {
    const { data } = yield call(api.getAddressesList, localStorage.getItem('token'));

    if (data.ok) {
      const addresses = data.data.s_offices?.filter((el) => el).map((address) => getAddress(address));
      console.log(addresses);
      yield put(setAdrList(addresses));
      // yield put(setCompany(data.data));
      callback && callback();
      return;
      // yield put(setCompany())
      // yield put(setStatus('main'));
      // yield put(setToken(localStorage.getItem('token')));
      // yield put(setUser(data.data));
    }

    if (data.error) {
      let messageError = `Ошибка, пользователь ${payload.data[0].email} уже существует`;
      if (data.data[payload.data[0].email].error === 6) {
        message.error({ content: messageError, duration: 5 });
        return;
      }

      messageError = 'Что-то пошло не так';
      message.error({ content: messageError, duration: 5 });
    }
    // message.error({ content: React.createElement(ErrorContent, 'email'), duration: 5 });
  } catch (e) {
    message.error({ content: e.message, duration: 4 });
    throw new Error('Ошибка создания заместителя');

    // localStorage.clear();
    // yield put(setStatus(''));
    //yield put(setError(ERROR_MAP.auth(e)));
  } finally {
    yield put(setLoading(false));
  }
}
