import React from 'react';
import { notification } from 'antd';
import IconCloseBox from '@assets/icons/page/closeBox';

const NOTIFICATION = {
  error: (message, description, key) => {
    notification.open({
      key: key ? key : '',
      className: 'notification notification__error',
      message: message,
      description: description,
      closeIcon: <IconCloseBox />,
      icon: null,
      duration: 10,
    });
  },
  success: (message, description, key) => {
    notification.open({
      key: key ? key : '',
      className: 'notification notification__success',
      message: message,
      description: description,
      closeIcon: <IconCloseBox />,
      icon: null,
      duration: 10,
    });
  },
};

export default NOTIFICATION;
