import React, { useEffect } from 'react';
// components
// import HomeTemplate from '@templates/homeTemplate';

const HrAdministration: React.FC = () => {
  useEffect(() => {
    console.log('HrAdministration PAGE');
  }, []);

  return <>HrAdministration</>;
};

export default HrAdministration;
