import React, { FC, useEffect } from 'react';
import { Layout } from 'antd';
import { useDispatch } from 'react-redux';
// actions
import { appPage } from '@redux/reducers/app';
// components
import AppSider from '@atoms/siders/AppSider';
import AppTitle from '@atoms/text/AppTitle';
import AppText from '@atoms/text/AppText';
import DynamicSteps from '@organisms/dynamicSteps';
// tools
import useProfileSteps from '@tools/hooks/steps/useProfileSteps';

const CompanyRegistrationOther: FC = () => {
  const { profileSteps, currentProfileStep, setCurrentProfileStep } = useProfileSteps();

  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.getItem('company_step') && setCurrentProfileStep(Number(localStorage.getItem('company_step')));
    dispatch(appPage('company-reg'));
  }, []);

  return (
    <Layout>
      <AppSider>
        <AppTitle level={3} title="Компания" style={{ marginBottom: 56 }} />
        <AppText
          text="Заполните и подтвердите информацию о компании, чтобы использовать весь функционал платформы."
          style={{ fontWeight: 500, color: '#EDF2FF', margin: 0, fontSize: 16 }}
        />
      </AppSider>

      <Layout style={{ backgroundColor: '#fff', padding: '48px 24px', overflow: 'auto', height: '100vh' }}>
        <div style={{ maxWidth: 576 }}>
          <DynamicSteps steps={profileSteps} current={currentProfileStep} />
        </div>
      </Layout>
    </Layout>
  );
};

export default CompanyRegistrationOther;
