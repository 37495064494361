import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// actions
import { setActive } from '@redux/reducers/menu';
// types
import { stateType } from '@types';
import { findMenuKey } from '@tools/helpFunctions';
// styles
import './style.scss';
import { Tooltip } from 'antd';

interface MenuType {
  isCollapsed: boolean;
}

const AppMenu: FC<MenuType> = ({ isCollapsed }: MenuType) => {
  const { list, active } = useSelector((state: stateType) => state.menu);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setActive(findMenuKey(pathname)));
  }, []);

  const onClick = (key: number, link: string) => {
    dispatch(setActive(key));
    navigate(`${link}`);
  };

  return (
    <div className={classNames('menu-list', { ['menu-list__is-collapsed']: isCollapsed })}>
      {list.map(({ key, label, link, icon }) => (
        <Tooltip
          key={key}
          trigger={'hover'}
          placement="right"
          title={t(label)}
          className={'default-tooltip'}
          overlayClassName={classNames('default-tooltip-overlay', {
            ['default-tooltip-overlay-hide']: !isCollapsed,
          })}
        >
          <div
            key={key.toString()}
            className={classNames('menu-list__item', {
              ['menu-list__item-active']: `/${pathname.split('/')[1]}` === link ? true : false,
            })}
            onClick={() => onClick(key, link)}
          >
            {icon}
            <div className="menu-list__item-name">{t(label)}</div>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default AppMenu;
