import React, { useEffect } from 'react';
// components
import SettingsContainer from '@containers/settings';

const LcVerificationSettings: React.FC = () => {
  useEffect(() => {
    console.log('LcVerificationSettings PAGE');
  }, []);

  return <SettingsContainer />;
};

export default LcVerificationSettings;
