import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
// types
import { stateType } from '@types';
// style
import './style.scss';

const list = [
  { index: 1, id: 'company-create', name: 'Регистрация', link: '/company-create' },
  { index: 2, id: 'company-verification', name: 'Верификация', link: '/company-create/verification' },
];

const StepList: FC<any> = ({ page }: any) => {
  const [active, setActive] = useState(list[0]);
  const { info } = useSelector((state: stateType) => state.user);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const result = _.find(list, (el) => el.id === page);
    if (result) {
      setActive(result);
      return;
    }
    setActive(list[0]);
  }, [page]);

  const linkAction = ({ index, link }: any) => {
    if (index === 1) {
      return navigate(link);
    }

    if (info?.company?.id && index > 1) {
      return navigate(link);
    }
  };

  return (
    <div className="step-list">
      {list.map((item, index) => {
        return (
          <div
            key={index}
            className={classNames(
              'step-list__item',
              { ['step-list__item-move']: index + 1 <= active?.index },
              { ['step-list__item-active']: item.id === active.id },
            )}
          >
            <div
              className={classNames(
                'step-list__index',
                { ['step-list__index-move']: index + 1 <= active?.index },
                { ['step-list__index-active']: item.id === active.id },
              )}
            >
              {index + 1}
            </div>
            <div
              className={classNames(
                'step-list__name',
                { ['step-list__name-move']: index + 1 <= active?.index },
                { ['step-list__name-active']: item.id === active.id },
              )}
              onClick={() => linkAction(item)}
            >
              {t(item.name)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StepList;
