import React, { FC, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Dropdown } from 'antd'
import classNames from 'classnames'
// icons
import { IconFacebook, IconTelegram, IconWhatsapp } from '@assets/icons/nav/profileHeader'
import { IconArrowDropdown } from '@assets/icons/btn/arrow'
// types
import { stateType } from '@types'
// styles
import '../style.scss'

const DropdownSocialsControlled: FC<any> = ({ value, control, name, rules, options }: any) => {
	const [open, setOpen] = useState<boolean>(false)
	const [id, setId] = useState<any>(1)

	useEffect(() => {
		if (value && value !== id) {
			setId(value)
		}
	}, [value])

	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({
				field: { onChange, onBlur, value, name, ref },
				fieldState: { invalid, isTouched, isDirty },
				formState,
			}) => (
				<Dropdown
					className={'dropdown'}
					overlay={
						<DropdownOverlay
							setOpen={setOpen}
							setId={setId}
							options={options}
							onChange={onChange}
						/>
					}
					trigger={['click']}
					placement='bottomRight'
					open={open}
					onOpenChange={(data: any) => setOpen(data)}
				>
					<div
						className='dropdown__triger'
						onClick={(e) => {
							e.preventDefault()
							setOpen(true)
						}}
					>
						<span className='dropdown__name'>{initIcon(id)}</span>
						<div
							className={classNames(
								'dropdown__icon',
								{ ['animation-dropdown-up']: open },
								{ ['animation-dropdown-back']: !open }
							)}
						>
							<div className='dropdown__icon-arrow'>
								<IconArrowDropdown />
							</div>
						</div>
					</div>
				</Dropdown>
			)}
		/>
	)
}

export default DropdownSocialsControlled

const DropdownOverlay = ({ setOpen, setId, onChange }: any) => {
	const { options } = useSelector((state: stateType) => state.app)
	const action = (id: any) => {
		onChange(id)
		setId(id)
		setOpen(false)
	}

	return (
		<div className='dropdown-overlay-with-icons dropdown-overlay'>
			<div className='dropdown-overlay__list'>
				{options?.messengers &&
					options?.messengers.map(({ id, name }: { id: number; name: string }) => {
						return (
							<div
								key={id}
								className='dropdown-overlay__item'
								onClick={() => action(id)}
							>
								{initIcon(id)} {name}
							</div>
						)
					})}
			</div>
		</div>
	)
}

const initIcon = (type: any) => {
	switch (type) {
		case 1:
			return <IconTelegram />
		case 2:
			return <IconWhatsapp />
		case 3:
			return <IconFacebook />

		default:
			break
	}
}
