import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { createPhoneMask } from '@tools/helpers';
import ReactHtmlParser from 'react-html-parser';
// components
import FormCode from '@organisms/forms/code/confirmPhoneProfile';
// style
import './style.scss';
import {sendSmsOnPhone, updateUser} from '@redux/reducers/user'
import {useDispatch} from 'react-redux'

interface PhoneProfileModalType {
  component?: any;
  action?: any;
  phone?: string;
  open?: any;
  setOpen?: any;
  setModalPhone?: any;
}

const PhoneProfileModal: FC<PhoneProfileModalType> = ({
  component: Component,
  action,
  phone,
  open,
  setOpen,
  setModalPhone,
}: PhoneProfileModalType) => {
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch()
  const { t } = useTranslation();

  useEffect(() => {
    open && setVisible(open);
  }, [open]);

  useEffect(()=>{

      dispatch(
          sendSmsOnPhone({
                request: {
                  phone
                },
              },
      ));

  },[])
  const onCancel = () => {
    setVisible(false);
    setLoader(false);
    setOpen && setOpen(false);
    setModalPhone && setModalPhone(false);
  };

  const call = () => {
    setLoader(true);
    action(onCancel);
  };

  return (
    <>
      {Component && (
        <Component
          onClick={() => {
            setVisible(true);
          }}
        />
      )}
      <Modal
        className="modal-confirm"
        open={visible}
        width={504}
        footer={null}
        closable={true}
        bodyStyle={{ padding: '64px' }}
        centered
        onCancel={onCancel}
      >
        <div className="modal-confirm__body">
          <div className="modal-confirm__title">{t('Подтверждение телефона')}</div>
          <div className="modal-confirm__desc">
            {ReactHtmlParser(
              t('Мы отправили SMS с 6-значным кодом', {
                phone: phone ? createPhoneMask(phone) : '',
              }),
            )}
          </div>
          <FormCode action={call} setVisible={setVisible} loading={loader} />
          <button className="btn-default btn-gray" onClick={onCancel}>
            {t('Отменить')}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default PhoneProfileModal;
