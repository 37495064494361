import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
// actions
import { setStatus } from '@redux/reducers/app';
import { loginToCompany, logoutFromCompany } from '@redux/reducers/company';
import { getUserInfo } from '@redux/reducers/user';
// icons
import IconPlus from '@assets/icons/uploaders/plus';
import { IconArrowDropdown, IconBellDropdown } from '@assets/icons/btn/arrow';
// types
import { stateType } from '@types';
// styles
import '../style.scss';

const DropdownChoiceCompany: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<any>('');

  const { info } = useSelector((state: stateType) => state.user);
  useEffect(() => {
    info.company && setName(info.company.name);
  }, [info?.company?.name]);
  return (
    <Dropdown
      className={'dropdown'}
      overlay={<DropdownOverlay />}
      trigger={['click']}
      open={open}
      onOpenChange={(data: any) => setOpen(data)}
    >
      <div
        className="dropdown__triger"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        <div className="dropdown__prefix-icon">
          <IconBellDropdown />
        </div>
        <span className="dropdown__name">{name}</span>
        <div
          className={classNames(
            'dropdown__icon',
            { ['animation-dropdown-up']: open },
            { ['animation-dropdown-back']: !open },
          )}
        >
          <div className="dropdown__icon-arrow">
            <IconArrowDropdown />
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

export default DropdownChoiceCompany;

const DropdownOverlay = () => {
  const { list } = useSelector((state: stateType) => state.company);
  const { info } = useSelector((state: stateType) => state.user);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const action = (id: number) => {
    dispatch(setStatus('loading'));
    dispatch(
      loginToCompany({
        request: { company_id: id },
        callback: () => {
          dispatch(getUserInfo('7'));
        },
      }),
    );
  };

  const logout = () => {
    dispatch(
      logoutFromCompany({
        request: { company_id: info.company.id },
        callback: () => {
          dispatch(setStatus('companies-profile'));
          navigate('/companies');
        },
      }),
    );
  };

  return (
    <div className="dropdown-overlay">
      <div className="dropdown-overlay__list">
        {list &&
          list.map(({ id, name }: any) => {
            return (
              <div key={id} className="dropdown-overlay__item" onClick={() => action(id)}>
                {name}
              </div>
            );
          })}
      </div>

      <div className="dropdown-overlay__item">
        <div className="dropdown-overlay__add" onClick={logout}>
          <IconPlus />
        </div>
        {t('Компанию')}
      </div>
    </div>
  );
};
