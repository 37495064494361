import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
// actions
import { getCompanies, setFilter } from '@redux/reducers/lcVerificator';
// icons
import { IconFilter } from '@assets/icons/statsInfo';
import { IconArrowDropdown } from '@assets/icons/btn/arrow';
// types
import { stateType } from '@types';
// styles
import '../style.scss';

const DropdownDateFilter: FC = () => {
  const { options } = useSelector((state: stateType) => state.app);
  const { filter } = useSelector((state: stateType) => state.lcVerificator);
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<{ id?: number; name?: string }>({});

  useEffect(() => {
    if (options?.filter_companies) {
      const result = _.find(options?.filter_companies, { id: filter });
      setValue(result);
    }
  }, [filter, options?.filter_companies]);

  return (
    <Dropdown
      className={'dropdown'}
      overlay={<DropdownOverlay setOpen={setOpen} setValue={setValue} />}
      trigger={['click']}
      placement="bottomRight"
      open={open}
      onOpenChange={(data: any) => setOpen(data)}
    >
      <div
        className="dropdown__triger"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        <div className="dropdown__prefix-icon">
          <IconFilter />
        </div>
        <span className="dropdown__name">{value?.name}</span>
        <div
          className={classNames(
            'dropdown__icon',
            { ['animation-dropdown-up']: open },
            { ['animation-dropdown-back']: !open },
          )}
        >
          <div className="dropdown__icon-arrow">
            <IconArrowDropdown />
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

export default DropdownDateFilter;

const DropdownOverlay = ({ setOpen, setValue }: any) => {
  const { page, options } = useSelector((state: stateType) => state.app);
  const dispatch = useDispatch();

  const action = (item: any) => {
    dispatch(setFilter(item.id));
    setValue(item);
    setOpen(false);

    dispatch(
      getCompanies({
        request: {
          type: `companies${page.charAt(0).toUpperCase() + page.slice(1)}`,
          filter: item.id,
        },
      }),
    );
  };

  return (
    <div className="dropdown-overlay">
      <div className="dropdown-overlay__list">
        {options?.filter_companies &&
          options?.filter_companies.map((item: any) => {
            const { id, name } = item;
            return (
              <div key={id} className="dropdown-overlay__item" onClick={() => action(item)}>
                {name}
              </div>
            );
          })}
      </div>
    </div>
  );
};
