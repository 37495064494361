/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react';
// components
import DropdownUsersNav from '@molecules/app/dropdwons/usersNav';
import { Status, StatusCompany, Titlte } from '@molecules/tables/users';
// helps
import MAPPING from '@tools/map';
import { IconFile } from '@assets/icons/uploaders';
import { IconLink } from '@assets/icons/statsInfo';
import Moment from 'react-moment';

/* TODO need parser for avatar */

export const columns = [
  {
    title: <Titlte name={'№'} classes={'lc-verification-companies-table__title'} />,
    dataIndex: 'id',
    className: 'lc-verification-companies-table__column lc-verification-companies-table__column-id',
    render: (text, record, index) => (
      <div className="lc-verification-companies-table__column-container">{index + 1}</div>
    ),
  },
  {
    title: () => <Titlte name={'Сфера деятельность'} classes={'lc-verification-companies-table__title'} />,
    dataIndex: 'activity',
    className: 'lc-verification-companies-table__column lc-verification-companies-table__column-activity w-230',
    render: (text, record) => <div>{record?.activity?.name}</div>,
  },
  {
    title: () => <Titlte name={'Код'} name2={'ЕДРПОУ'} classes={'lc-verification-companies-table__title'} />,
    dataIndex: 'code',
    className: 'lc-verification-companies-table__column w-125',
  },
  {
    title: () => <Titlte name={'Дата'} name2={'создания'} classes={'lc-verification-companies-table__title'} />,
    dataIndex: 'update_date',
    className: 'lc-verification-companies-table__column lc-verification-companies-table__column-date w-125',
    render: (text, record) => (
      <div className="lc-verification-companies-table__column-date-block">
        <span className="table-date">
          <Moment format="MM/DD/YYYY">{record.update_date}</Moment>
        </span>
        <span className="table-time">
          <Moment format="HH:mm:ss">{record.update_date}</Moment>
        </span>
      </div>
    ),
  },
  {
    title: () => <Titlte name={'Статус'} classes={'lc-verification-companies-table__title w-135'} />,
    dataIndex: 'status',
    className: 'lc-verification-companies-table__column lc-verification-companies-table__column-status',
    render: (text, record) => (
      <StatusCompany status={record.status} classes={'lc-verification-companies-table__status'} />
    ),
  },
];
