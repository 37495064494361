import React, { FC } from 'react';
import classNames from 'classnames';
// components
import AsideFormBlock from '@containers/app/aside/form';
// styles
import './style.scss';

interface AsideTemplateType {
  children: React.ReactNode;
  center?: boolean;
  page?: string;
}

const FormTemplate: FC<AsideTemplateType> = ({ children, center, page }: AsideTemplateType) => {
  return (
    <div className="form-template">
      <AsideFormBlock page={page} background />
      <div className={classNames('form-template__body', { 'form-template__body-center': center })}>{children}</div>
    </div>
  );
};

export default FormTemplate;
