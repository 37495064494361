import React, { FC, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from '@src/i18n';
// components
import InputDefault, { InputDatePicker, InputMaskDefault, InputSelectDefault } from '@atoms/inputs';
import ButtonDefault from '@atoms/buttons/button';
import BtnLogout from '@atoms/buttons/logoutButton';
import LogoutFromSystemModal from '@organisms/modals/confirms/logoutFromSystem';
// actions
import { profileCreate, sendSmsOnPhone, updateUser } from '@redux/reducers/user';
import { setStatus, setSuccess } from '@redux/reducers/app';
// helps
import { regUserSchema } from '@tools/shemas';
import useLogout from '@tools/hooks/useLogout';
// types
import { stateType } from '@types';
// styles
import './style.scss';

interface FormData {
  birth_date: any;
  lname: string;
  name: string;
  sname: string;
  gender_id: number;
  phone: string;
}

const FormUserRegistration: FC = () => {
  const { info } = useSelector((state: stateType) => state.user);
  const { options } = useSelector((state: stateType) => state.app);
  const { birth_date, lname, name, sname, gender_id, phone } = info;
  const schema = useMemo(() => regUserSchema(), [i18n.language]);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      birth_date: birth_date ? moment(birth_date, 'YYYY-MM-DD') : '',
      lname: lname ? lname : '',
      name: name ? name : '',
      sname: sname ? sname : '',
      gender_id: gender_id ? gender_id : undefined,
      phone: phone ? phone : '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    phone && setValue('phone', phone);
  }, [info]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { logout } = useLogout();

  const watchPhone = watch('phone');

  const onSubmit = async (data: FormData) => {
    const request = {
      ...data,
      phone: info?.is_confirm_phone ? data.phone : data.phone.replace(/[^\d]/g, '').slice(2),
      birth_date: moment(data.birth_date).format('YYYY-MM-DD'),
    };

    dispatch(
      profileCreate({
        request,
        callback: () => {
          localStorage.setItem('confirm-phone', '');
          dispatch(setStatus('success'));
          dispatch(setSuccess({ type: 'user-registration' }));
          //request.phone && navigate('/confirm-phone');
          //!request.phone && navigate('/');
        },
      }),
    );
  };

  const prepareForConfirmPhone = () => {
    dispatch(
      sendSmsOnPhone({
        request: {
          phone: getValues('phone')
            .replace(/[^\d]/g, '')
            .slice(watchPhone[0] === '+' ? 2 : 0),
        },
        callback: () => {
          dispatch(
            updateUser({
              name: getValues('name'),
              lname: getValues('lname'),
              sname: getValues('sname'),
              birth_date: getValues('birth_date'),
              phone: getValues('phone'),
              gender_id: getValues('gender_id'),
            }),
          );
          localStorage.setItem(
            'confirm-phone',
            `${getValues('phone')
              .replace(/[^\d]/g, '')
              .slice(watchPhone[0] === '+' ? 2 : 0)}`,
          );
          navigate('/confirm-phone');
        },
      }),
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form user-registration-form">
        <div className="form__body">
          <div className="form__container-columns">
            <InputDefault
              name={'lname'}
              label={t('Фамилия')}
              placeholder={t('Фамилия')}
              error={errors.lname?.message}
              register={register}
              control={control}
              requered
            />
            <InputDefault
              name={'name'}
              label={t('Имя')}
              placeholder={t('Имя')}
              error={errors.name?.message}
              register={register}
              control={control}
              requered
            />
            <InputDefault
              name={'sname'}
              label={t('Отчество')}
              placeholder={t('Отчество')}
              error={errors.sname?.message}
              register={register}
              control={control}
            />
          </div>
          <div className="form__container">
            <InputDatePicker
              name={'birth_date'}
              label={t('Дата рождения')}
              placeholder={'10/08/1983'}
              error={errors.birth_date?.message}
              register={register}
              control={control}
              requered
            />
          </div>
          <div className="form__container">
            <InputSelectDefault
              name={'gender_id'}
              optionKey={'gender'}
              label={t('Пол')}
              placeholder={t('Выберите пол')}
              error={errors.gender_id?.message}
              register={register}
              control={control}
              options={options?.gender ? options?.gender : []}
              requered
            />
          </div>
          <div className="form__container">
            <InputMaskDefault
              name={'phone'}
              label={t('Телефон')}
              placeholder={t('Телефон')}
              error={errors.phone?.message}
              register={register}
              control={control}
              disabled={info?.is_confirm_phone ? true : 0}
              requered
            />

            {watchPhone.replace(/[^\d]/g, '').slice(watchPhone[0] === '+' ? 2 : 0).length === 10 && (
              <span className="confirm-phone" onClick={prepareForConfirmPhone}>
                {t('Подтвердить телефон')}
              </span>
            )}
          </div>
          <div className="form__container">
            <InputDefault label={'E-Mail'} placeholder={'email'} defaultValue={info?.email} disabled />
          </div>
        </div>
        <div className="form-footer form-footer_submit">
          <LogoutFromSystemModal
            component={BtnLogout}
            action={() => {
              logout();
            }}
          />
          <ButtonDefault name={t('Далее')} type={'submit'} />
        </div>
      </form>
    </>
  );
};

export default FormUserRegistration;
