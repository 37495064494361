import React, { FC } from 'react'
import { Link } from 'react-router-dom'
// icons
import { IconFacebook, IconTelegram, IconWhatsapp } from '@assets/icons/nav/profileHeader'
// styles
import './style.scss'

interface SocialListType {
	socials?: []
}

const SocialList: FC<SocialListType> = ({ socials }: SocialListType) => {
	const initIcon = (type: any) => {
		switch (type) {
			case 1:
				return <IconTelegram />
			case 2:
				return <IconWhatsapp />
			case 3:
				return <IconFacebook />

			default:
				break
		}
	}

	return (
		<div className='social-list'>
			{socials &&
				socials.map((item: any, index: any) => {
					return (
						<div className='social-list__item' key={index}>
							<Link to={'#'} onClick={() => window.open(item.name, '_blank')}>
								{initIcon(item.type)}
							</Link>
						</div>
					)
				})}
		</div>
	)
}

export default SocialList
