import facebook from '@image/icons/socials/facebook.svg';
import linkedin from '@image/icons/socials/linkedin.svg';
import pinterest from '@image/icons/socials/pinterest.svg';
import twitter from '@image/icons/socials/twitter.svg';
import youtube from '@image/icons/socials/youtube.svg';
import instagram from '@image/icons/socials/instagram.svg';
import telegram from '@image/icons/socials/telegram.svg';
import viber from '@image/icons/socials/viber.svg';
import whatsapp from '@image/icons/socials/whatsapp.svg';

import add_candidate_step_1 from '@image/add-candidate-step-1.svg';
import add_candidate_step_2 from '@image/add-candidate-step-2.svg';
import add_candidate_step_3 from '@image/add-candidate-step-3.svg';

import add_task_step_1 from '@image/add-task-step-1.svg';
import add_task_step_2 from '@image/add-task-step-2.svg';
import add_task_step_3 from '@image/add-task-step-3.svg';
import add_task_step_4 from '@image/add-task-step-4.svg';

export const addCandidateStepsImg = [
  { id: 1, src: add_candidate_step_1 },
  { id: 2, src: add_candidate_step_2 },
  { id: 3, src: add_candidate_step_3 },
];

export const addTaskStepsImg = [
  { id: 1, src: add_task_step_1 },
  { id: 2, src: add_task_step_2 },
  { id: 3, src: add_task_step_3 },
  { id: 3, src: add_task_step_4 },
];

export const socialsLinksImages = [
  {
    id: '1',
    name: 'facebook',
    src: facebook,
  },
  {
    id: '2',
    name: 'instagram',
    src: instagram,
  },
  {
    id: '3',
    name: 'linkedin',
    src: linkedin,
  },
  {
    id: '4',
    name: 'pinterest',
    src: pinterest,
  },
  {
    id: '5',
    name: 'telegram',
    src: telegram,
  },
  {
    id: '6',
    name: 'twitter',
    src: twitter,
  },
  {
    id: '7',
    name: 'viber',
    src: viber,
  },
  {
    id: '8',
    name: 'whatsapp',
    src: whatsapp,
  },
  {
    id: '9',
    name: 'youtube',
    src: youtube,
  },
];
