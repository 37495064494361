import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// icons
import IconLogout from '@assets/icons/btn/logout';
// styles
import './style.scss';

interface BtnLogoutType {
  onClick?: () => void;
  color?: string;
}

const BtnLogout: FC<BtnLogoutType> = ({ color, onClick }: BtnLogoutType) => {
  const { t } = useTranslation();

  return (
    <div className="btn-back" onClick={onClick}>
      <div className="btn-back__icon">
        <IconLogout color={color} />
      </div>
      <div className="btn-back__text">
        <span>{t('Выход')}</span>
      </div>
    </div>
  );
};

export default BtnLogout;
