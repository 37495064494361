import React from 'react';

const SkyContainer = () => {
  return (
    <svg width="348" height="165" viewBox="0 0 348 165" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.68">
        <path
          opacity="0.68"
          d="M2.80901 165C-0.835237 160.897 -0.784621 148.994 2.10041 144.334C4.98543 139.675 11.0592 137.243 16.3737 138.662C13.8936 128.987 15.9182 118.705 18.955 109.133C21.1821 102.041 24.0671 94.9505 29.1286 89.5309C36.8726 81.1229 49.0201 77.7294 60.4083 78.6411C71.7966 79.5528 82.5775 84.2632 92.3967 90.1387C98.926 64.8135 106.214 38.0702 125.094 19.9374C149.186 -3.15912 189.273 -5.84359 217.718 11.5801C246.164 29.0038 262.006 63.8512 259.222 97.1284C269.649 96.0648 280.835 97.6856 289.135 104.068C297.436 110.449 301.941 122.454 297.537 131.976C319.251 127.012 343.495 142.562 348 164.392"
          fill="#A5CDF2"
        />
      </g>
      <g opacity="0.24">
        <path
          opacity="0.24"
          d="M2.80901 165C-0.835237 161.607 -0.784621 151.882 2.10041 148.032C4.98543 144.233 11.0592 142.207 16.3737 143.372C13.8936 135.42 15.9182 126.962 18.955 119.162C21.1821 113.337 24.0671 107.563 29.1286 103.055C36.8726 96.1664 49.0201 93.3806 60.4083 94.0897C71.7966 94.7988 82.5775 98.6989 92.3967 103.511C98.926 82.744 106.214 60.8125 125.094 45.9213C149.186 26.9781 189.273 24.8001 217.718 39.0329C246.164 53.2656 262.006 81.9336 259.222 109.183C269.649 108.322 280.835 109.639 289.135 114.856C297.436 120.073 301.941 129.95 297.537 137.75C319.251 133.648 343.495 146.462 348 164.342"
          fill="#A5CDF2"
        />
      </g>
    </svg>
  );
};

export default SkyContainer;
