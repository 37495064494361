import React from 'react';

function HrAdministrationIcon() {
   return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd"
               d="M7.39814 5.31547C7.39814 6.87102 6.11574 8.13184 4.53241 8.13184C2.95001 8.13184 1.66669 6.87102 1.66669 5.31547C1.66669 3.76082 2.95001 2.5 4.53241 2.5C6.11574 2.5 7.39814 3.76082 7.39814 5.31547ZM17.0778 4.08194C17.7704 4.08194 18.3334 4.63502 18.3334 5.31547C18.3334 5.99682 17.7704 6.54991 17.0778 6.54991H11.5982C10.9047 6.54991 10.3417 5.99682 10.3417 5.31547C10.3417 4.63502 10.9047 4.08194 11.5982 4.08194H17.0778ZM2.92316 13.2983H8.40276C9.09627 13.2983 9.65924 13.8514 9.65924 14.5327C9.65924 15.2132 9.09627 15.7672 8.40276 15.7672H2.92316C2.22965 15.7672 1.66669 15.2132 1.66669 14.5327C1.66669 13.8514 2.22965 13.2983 2.92316 13.2983ZM15.4676 17.3148C17.051 17.3148 18.3334 16.054 18.3334 14.4993C18.3334 12.9438 17.051 11.683 15.4676 11.683C13.8852 11.683 12.6019 12.9438 12.6019 14.4993C12.6019 16.054 13.8852 17.3148 15.4676 17.3148Z"
               fill="currentColor"/>
      </svg>
   );
}

export default HrAdministrationIcon;