import React, { FC } from 'react';
// components
import PageBreadcrumb from '@organisms/breadcrumb';
import HeaderProfile from '@organisms/profile/header';
import TabsProfileContainer from '@containers/settings/tabsProfileContainer';
// icons
import { IconUsersSetting } from '@assets/icons/nav/usersTable';
// styles
import './style.scss';

interface SettingsContainerType {
  children?: React.ReactNode;
}

const SettingsContainer: FC<SettingsContainerType> = ({ children }: SettingsContainerType) => {
  return (
    <div className="settings-container">
      {/*<PageBreadcrumb icon={IconUsersSetting} />*/}
      <HeaderProfile />
      <TabsProfileContainer />
    </div>
  );
};

export default SettingsContainer;
