import React, { useState, useEffect } from 'react';
// components
// import FormSetCompanyOther from '@organisms/forms/setCompany/FormSetCompanyOther';
// import FormSetCompanyStructure from '@organisms/forms/setCompany/FormSetCompanyStructure';
// import FormSetCompanyContacts from '@organisms/forms/setCompany/FormSetCompanyContacts';
// import FormSetCompanyMission from '@organisms/forms/setCompany/FormSetCompanyMission';
// import FormSetCompanyPersonManagement from '@organisms/forms/setCompany/FormSetCompanyPersonManagement';
// old`s
/* TODO need GLOBAL rework step flow`s */
import FormSetCompanyOther from '@organisms/forms/setCompany/old/FormSetCompanyOther';
import FormSetCompanyStructure from '@organisms/forms/setCompany/old/FormSetCompanyStructure';
import FormSetCompanyContacts from '@organisms/forms/setCompany/old/FormSetCompanyContacts';
import FormSetCompanyPersonManagement from '@organisms/forms/setCompany/old/FormSetCompanyPersonManagement';
import FormSetCompanyMission from '@organisms/forms/setCompany/old/FormSetCompanyMission';

function useProfileSteps(settings) {
  const [currentProfileStep, setCurrentProfileStep] = useState(1);

  const profileSteps = [
    {
      id: 1,
      content: <FormSetCompanyOther changeStep={setCurrentProfileStep} step={currentProfileStep} settings={settings} />,
    },
    {
      id: 2,
      content: (
        <FormSetCompanyStructure changeStep={setCurrentProfileStep} step={currentProfileStep} settings={settings} />
      ),
    },
    {
      id: 3,
      content: (
        <FormSetCompanyContacts changeStep={setCurrentProfileStep} step={currentProfileStep} settings={settings} />
      ),
    },
    {
      id: 4,
      content: (
        <FormSetCompanyPersonManagement
          changeStep={setCurrentProfileStep}
          step={currentProfileStep}
          settings={settings}
        />
      ),
    },
    {
      id: 5,
      content: <FormSetCompanyMission changeStep={setCurrentProfileStep} step={currentProfileStep} />,
    },
  ];

  return {
    currentProfileStep,
    setCurrentProfileStep,
    profileSteps,
  };
}

export default useProfileSteps;
