import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
// components
import AsideFormBlock from '@containers/app/aside/form';
import StepList from '@molecules/navigations/companies/stepList';
// actions
import { getOptions } from '@redux/reducers/app';
// styles
import './style.scss';
// types
import { stateType } from '@types';

interface AsideTemplateType {
  children: React.ReactNode;
  center?: boolean;
  classes?: string;
}

const CompaniesCreate: FC<AsideTemplateType> = ({ children, center, classes }: AsideTemplateType) => {
  const { status } = useSelector((state: stateType) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOptions({
        request: {
          source: ['employees', 'activities'],
        },
      }),
    );
  }, []);

  return (
    <div className={classNames('form-template', classes)}>
      <AsideFormBlock page={status}>
        <StepList page={status} />
      </AsideFormBlock>
      <div className={classNames('form-template__body', { 'form-template__body-center': center })}>{children}</div>
    </div>
  );
};

export default CompaniesCreate;
