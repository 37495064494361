import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// actions
import { appPage, setStatus } from '@redux/reducers/app';
import { setLogout } from '@redux/reducers/auth';

const useLogout: any = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(
      setLogout({
        callback: () => {
          dispatch(setStatus('auth'));
          dispatch(appPage('login'));
          navigate('/auth');

          if (sessionStorage.getItem('token') && localStorage.getItem('token')) {
            sessionStorage.removeItem('token');
            localStorage.removeItem('token');
            return;
          }

          if (sessionStorage.getItem('token')) {
            sessionStorage.removeItem('token');
            return;
          }

          if (localStorage.getItem('token')) {
            localStorage.removeItem('token');
            return;
          }
        },
      }),
    );
  };

  return { logout };
};

export default useLogout;
