import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
// components
import UserRegistration from '@pages/user/registration';
import HomeTemplate from '@templates/homeTemplate';
import Success from '@pages/success';
import Loading from './Loading';
// actions
import { setStatus } from '@redux/reducers/app';
import { getUserInfo } from '@redux/reducers/user';
// tools
import { getStoreToken } from '@tools/helpFunctions';
import usePageStatusController from '@tools/hooks/usePageStatusController';
// types
import { stateType } from '@types';

interface PageWrapperType {
  children: React.ReactNode;
  showTemplate?: boolean;
}

const PageWrapper: FC<PageWrapperType> = ({ children, showTemplate }: PageWrapperType) => {
  const { status, success } = useSelector((state: stateType) => state.app);
  const token = getStoreToken();
  const { navigatePageController } = usePageStatusController();
  const dispatch = useDispatch();

  useEffect(() => {
    token && dispatch(getUserInfo());
    !token && dispatch(setStatus('auth'));
  }, []);

  useEffect(() => {
    navigatePageController();
  }, [status]);

  const controller = () => {
    switch (status) {
      case 'success':
        return <Success type={success.type} />;

      case 'loading':
        return <Loading />;

      case 'profile-create':
        return <UserRegistration />;

      case 'main':
        return showTemplate ? <HomeTemplate>{children}</HomeTemplate> : <>{children}</>;
    }
  };
  return <>{controller()}</>;
};

export default PageWrapper;
