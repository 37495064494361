/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { appPage, setStatus, setSuccess } from '@redux/reducers/app';
import { setToken } from '@redux/reducers/auth';
import { setLoading, setUser } from '@redux/reducers/user';
import { put, call, select } from 'redux-saga/effects';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
// api
import { api } from '../../api/user';
// mapping
import NOTIFICATION from './notification';
import { notification } from 'antd';

export function* sagaUserInfo(action) {
  const { payload } = action;
  //const { callback } = payload;
  const getUserInfo = (state) => state.user.info;
  const info = yield select(getUserInfo);
  yield put(setLoading(true));

  if (_.isEmpty(info)) {
    yield put(setStatus('loading'));
  }

  try {
    const { data } = yield call(api.getUserInfo, payload);

    if (!_.isObject(data)) {
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
      yield put(setStatus('auth'));
      notification.close('profile-error');
      return NOTIFICATION.error(ReactHtmlParser(data, false, 'profile-error'));
    }

    if (data.error) {
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
      yield put(setStatus('auth'));
      notification.close('profile-error');

      return NOTIFICATION.error(data.msg, false, 'profile-error');
    }

    if (data.status && data.user_data === null) {
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
      yield put(setStatus('auth'));
      return NOTIFICATION.error('data cant be null');
      // yield put(setStatus('auth'));
    }

    if (data.status) {
      if (!_.isEmpty(data.user_data)) {
        /* TODOD user_data has been token */
        // yield put(setToken(localStorage.getItem('token')));
        yield put(setUser(data.user_data));

        yield call(initStatus, data.user_data);
      }
    }
  } catch (e) {
    console.log(e.message);
    // console.log(e.message);
    /* TODO why removed token and change status */

    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    yield put(setStatus('auth'));

    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaUserProfileCreate(action) {
  const { payload } = action;
  const { request, callback } = payload;
  yield put(setLoading(true));
  //yield put(setStatus('loading'));

  try {
    const { data } = yield call(api.profileCreate, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      yield put(setUser(data.user_data));
      callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
    //localStorage.clear();
    //yield put(setStatus('auth'));
    //yield put(setError(ERROR_MAP.auth(e)));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaUserProfileUpdate(action) {
  const { payload } = action;
  const { request, callback } = payload;
  const { success, error } = callback;
  yield put(setLoading(true));
  //yield put(setStatus('loading'));

  console.log('request', request);
  try {
    const { data } = yield call(api.updateProfile, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      yield put(setUser(data.user_data));
      success && success();
    }
  } catch (e) {
    error && error();
    yield NOTIFICATION.error(ReactHtmlParser(e));
    //localStorage.clear();
    //yield put(setStatus('auth'));
    //yield put(setError(ERROR_MAP.auth(e)));
  } finally {
    error && error();
    yield put(setLoading(false));
  }
}

export function* sagaUserСonfirmPhone(action) {
  const { payload } = action;
  const { request, callback } = payload;
  yield put(setLoading(true));
  //yield put(setStatus('loading'));

  try {
    const { data } = yield call(api.confirmPhone, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
    //yield put(setError(ERROR_MAP.auth(e)));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaUserSendSms(action) {
  const { payload } = action;
  const { request, callback } = payload;
  yield put(setLoading(true));
  //yield put(setStatus('loading'));

  try {
    const { data } = yield call(api.sendSmsOnPhone, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
    //yield put(setError(ERROR_MAP.auth(e)));
  } finally {
    yield put(setLoading(false));
  }
}

function* initStatus(user_data) {
  const { is_confirm_email, is_profile_created, is_verifier, company } = user_data;

  if (is_confirm_email && !is_profile_created) {
    yield put(setStatus('profile-create'));
  }

  if (is_confirm_email && is_profile_created && company && company.status?.id > 1) {
    yield put(setStatus('main'));
  }

  if (is_confirm_email && is_profile_created && company && company.status?.id === 1) {
    yield put(setStatus('company-verification'));
  }

  if (is_confirm_email && is_profile_created && !company) {
    yield put(setStatus('companies-profile'));
  }

  if (is_confirm_email && is_profile_created && !company?.status?.id) {
    yield put(setStatus('companies-profile'));
  }

  if (is_verifier) {
    yield put(setStatus('verifier'));
  }
}

/*export function* sagaUserChangeStep(action) {
  const getUser = (state) => state.user;
  const { info } = yield select(getUser);
  const { payload } = action;
  const { step } = payload;
  //yield put(setLoading(true));
  //yield put(setStatus('loading'));

  try {
    const { data } = yield call(api.changeStep, payload);
    if (data.ok) {
      yield put(setUser({ ...info, step: step }));
      // yield put(setStatus('main'));
      // yield put(setToken(localStorage.getItem('token')));
      // yield put(setUser(data.data));

      console.log(data);
    }
  } catch (e) {
    // localStorage.clear();
    // yield put(setStatus(''));
    //yield put(setError(ERROR_MAP.auth(e)));
  } finally {
    yield put(setLoading(false));
  }
}*/
