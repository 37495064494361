import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import i18n from '@src/i18n';
// components
import InputDefault from '@atoms/inputs';
import ButtonDefault from '@atoms/buttons/button';
import TimeCountdown from '@molecules/timeCountdown';
// helps
import { codeSchema } from '@tools/shemas';
// styles
import './style.scss';

interface FormData {
  code: string;
}

interface FormCodeType {
  action: any;
  loading: boolean;
}

const FormCode: FC<FormCodeType> = ({ action, loading }: FormCodeType) => {
  const schema = useMemo(() => codeSchema(), [i18n.language]);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation();

  const onSubmit = (data: FormData) => {
    action();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form code-form">
        <div className="form__container">
          <InputDefault
            name={'code'}
            label={t('SMS-код')}
            placeholder={t('Укажите код')}
            error={errors.code?.message}
            register={register}
            control={control}
          />
          <div className="form__container-links">
            <TimeCountdown />
          </div>
        </div>
        <div className="form__container">
          <ButtonDefault name={t('Подтвердить')} type={'submit'} loading={loading} disabled={loading} />
        </div>
      </form>
    </>
  );
};

export default FormCode;
