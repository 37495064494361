import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// img
import SkyContainer from '@assets/image/loaders/sky';
import RocketContainer from '@assets/image/loaders/rocket';

const Loading: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="main-page-loader">
      <div className="main-page-loader__body">
        <div className="main-page-loader__tremor">
          <div className="main-page-loader__rocket">
            <RocketContainer />
          </div>
        </div>

        <div className="main-page-loader__sky">
          <SkyContainer />
        </div>
      </div>

      <span className="main-page-loader__text">{t('Загрузка')}</span>
    </div>
  );
};

export default Loading;
