import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
// style
import './style.scss';
import BigPlusIcon from '@assets/icons/home/BigPlusIcon';

interface ModalType {
  component: any;
  action?: any;
}

const AddMeetingModal: FC<ModalType> = ({ component: Component, action }: ModalType) => {
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);

  const { t } = useTranslation();

  const onCancel = () => {
    setVisible(false);
  };

  const call = () => {
    setLoader(true);
    action(onCancel);
  };

  return (
    <>
      <>
        <Component
          text={t('Добавить встречу')}
          icon={<BigPlusIcon />}
          type="add-meeting"
          onClick={() => {
            setVisible(true);
          }}
          disabled
        />
        <Modal
          className="modal-add-meeting"
          open={visible}
          width={464}
          footer={null}
          closable={true}
          bodyStyle={{ padding: '64px' }}
          centered
          onCancel={onCancel}
        >
          FORM MEETING
        </Modal>
      </>
    </>
  );
};

export default AddMeetingModal;
