import React, { FC, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
// components
import LcVerificationCompanyTable from '@organisms/tables/lcVerificationCompanies/company';
import LcVerificationFilesTable from '@organisms/tables/lcVerificationCompanies/files';
import LcMessagesContainer from '../messages';
// styles
import './style.scss';

const { TabPane } = Tabs;

const TabsCompany: FC = () => {
  const [tab, setTab] = useState<any>('history');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { tab_company, id } = useParams();

  useEffect(() => {
    setTab(tab_company);
  }, [tab_company]);

  const onChange = (value: string) => {
    setTab(tab_company);
    navigate(`/lc-companies/company/${id}/${value}`);
  };

  const TabTitle: any = ({ text }: any) => {
    return <div className="company-tabs__title">{t(text)}</div>;
  };

  return (
    <Tabs onChange={onChange} activeKey={tab} centered className={classNames('company-tabs')}>
      <TabPane tab={<TabTitle text={'История компании'} />} key="history">
        <LcVerificationCompanyTable />
      </TabPane>
      <TabPane tab={<TabTitle text={'Файлы'} />} key="files">
        <LcVerificationFilesTable />
      </TabPane>
      <TabPane tab={<TabTitle text={'Комментарии'} />} key="comments">
        <LcMessagesContainer />
      </TabPane>
    </Tabs>
  );
};

export default TabsCompany;
