import React, { FC } from 'react';
// icons
import NavLeft from '@image/icons/calendar/left.svg';
import NavRight from '@image/icons/calendar/right.svg';
// hooks
import useCalendar from '@tools/hooks/useCalendar';
// styles
import './style.scss';

const SmallCalendar: FC = () => {
  const { calendarRows, selectedDate, todayFormatted, daysShort, monthNames, getNextMonth, getPrevMonth } =
    useCalendar();
  const calendarMonth = `${monthNames[selectedDate.getMonth()]} - ${selectedDate.getFullYear()}`;

  const dateClickHandler = (date: any) => {
    console.log(date);
  };

  return (
    <>
      <div className="calendar">
        <div className="calendar__header">
          <div className="calendar__navigation">
            <img className="calendar__arrow" src={NavLeft} alt="left" onClick={getPrevMonth} />
            <p className="calendar__month">{calendarMonth}</p>
            <img className="calendar__arrow" src={NavRight} alt="right" onClick={getNextMonth} />
          </div>
        </div>
        <div className="calendar__container">
          {daysShort.map((day: any) => (
            <span key={day} className="calendar__weeks">
              {day}
            </span>
          ))}
        </div>
        <div className="calendar__body">
          {Object.values(calendarRows).map((cols: any) => {
            return (
              <div className="calendar__row" key={cols[0].date}>
                {cols.map((col: any) =>
                  col.date === todayFormatted ? (
                    <div
                      key={col.date}
                      className={`${col.classes} calendar__day calendar__day-today`}
                      onClick={() => dateClickHandler(col.date)}
                    >
                      <span>{col.value}</span>
                    </div>
                  ) : (
                    <div
                      key={col.date}
                      className={`${col.classes} calendar__day`}
                      onClick={() => dateClickHandler(col.date)}
                    >
                      {col.value}
                    </div>
                  ),
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SmallCalendar;
