import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '@src/i18n';
// components
import InputDefault from '@atoms/inputs';
import GeneratePassword from '@molecules/generatePassword';
import ButtonDefault from '@atoms/buttons/button';
// actions
import { reset } from '@redux/reducers/auth';
import { appPage } from '@redux/reducers/app';
// helpers
import usePasswordHook from '@tools/hooks/forms/usePasswordHook';
import { resetSchema } from '@tools/shemas';
// types
import { stateType } from '@types';
// icons
import IconEye from '@assets/icons/inputs/eye';
import IconEyeClose from '@assets/icons/inputs/eyeClose';
// styles
import './style.scss';

interface FormData {
  password: string;
  password_repeat: string;
}

const FormResetPass: FC = () => {
  const { loading } = useSelector((state: stateType) => state.auth);
  const schema = useMemo(() => resetSchema(), [i18n.language]);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      password: '',
      password_repeat: '',
    },
    resolver: yupResolver(schema),
  });

  const { passwordType: passwordType, changePasswordType: changePasswordType } = usePasswordHook();
  const { passwordType: passwordRepeatType, changePasswordType: changePasswordRepeatType } = usePasswordHook();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = async (data: FormData) => {
    const { password } = data;

    const request = {
      new_password: md5(password),
      reset_password_token: localStorage.getItem('recovery-token'),
    };

    dispatch(reset(request));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form reset-form">
      <div className="form__container">
        <InputDefault
          name={'password'}
          label={t('Придумайте пароль')}
          placeholder={t('Укажите ваш пароль')}
          error={errors.password?.message}
          register={register}
          control={control}
          type={passwordType}
        />
        <div className="form__container-icons">
          <GeneratePassword setValue={setValue} />
          <div className="form__container-icon" onClick={changePasswordType}>
            {passwordType === 'input' ? <IconEye /> : <IconEyeClose />}
          </div>
        </div>
      </div>

      <div className="form__container">
        <InputDefault
          name={'password_repeat'}
          label={t('Повторите пароль')}
          placeholder={t('Повторите ваш пароль')}
          error={errors.password_repeat?.message}
          register={register}
          control={control}
          type={passwordRepeatType}
        />
        <div className="form__container-icons">
          <div className="form__container-icon" onClick={changePasswordRepeatType}>
            {passwordRepeatType === 'input' ? <IconEye /> : <IconEyeClose />}
          </div>
        </div>
      </div>

      <div className="form__container">
        <ButtonDefault name={t('Сохранить')} type={'submit'} loading={loading} disabled={loading} />
        <div className="form__container-links" onClick={() => console.log('dispatch(appPage("forgot"))')}>
          <Link to="/auth" onClick={() => dispatch(appPage('login'))}>
            {t('Вход')}
          </Link>
          <Link to="/auth" onClick={() => dispatch(appPage('registration'))}>
            {t('Регистрация')}
          </Link>
        </div>
      </div>
    </form>
  );
};

export default FormResetPass;
