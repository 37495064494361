import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
// styles
import './style.scss';
import { useDispatch } from 'react-redux';
import { getCompanies } from '@redux/reducers/company';
import { useTranslation } from 'react-i18next';

const CompaniesNavigationList: FC = () => {
  const { sort } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCompanies({ request: { type: sort ? sort : 'my' } }));
  }, [sort]);

  return (
    <div className="sort-companies">
      <div
        className={classNames('sort-companies__item', { ['sort-companies__item-active']: !sort })}
        onClick={() => navigate('/companies')}
      >
        {t('Мои Компании')}
      </div>
      <div
        className={classNames('sort-companies__item', { ['sort-companies__item-active']: sort === 'transfer' })}
        onClick={() => navigate('/companies/transfer')}
      >
        {t('Переданные Компании')}
      </div>
      <div
        className={classNames('sort-companies__item', { ['sort-companies__item-active']: sort === 'archive' })}
        onClick={() => navigate('/companies/archive')}
      >
        {t('Архив Компаний')}
      </div>
    </div>
  );
};

export default CompaniesNavigationList;
