import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'antd'
// components
// style
import './style.scss'
import {useDispatch} from 'react-redux'
import {getOptions} from '@redux/reducers/app'
import {t} from 'i18next'
import AddSocialForm from '@organisms/forms/addSocial'

interface AddSocialModalType {
  component?: any;
  action?: any;
  open?: boolean;
  setOpen?: any;
}

const AddSocialModal: FC<AddSocialModalType> = ({
  component: Component,
  action,
  open,
  setOpen,
}: AddSocialModalType) => {
  const [visible, setVisible] = useState(true);

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    open && setVisible(true);
  }, [open]);

  useEffect(() => {
    if (visible) {
      dispatch(
        getOptions({
          request: {
            source: ['social_media'],
          },
        }),
      );
    }
  }, [visible]);

  const onCancel = () => {
    setVisible(false);
    setOpen(false);
  };

  const call = () => {
    setLoader(true);
    action(onCancel);
  };

  return (
    <>
      {Component && (
        <Component
          onClick={() => {
            setVisible(true);
          }}
        />
      )}
      <Modal
        className="modal-add-social"
        open={visible}
        width={696}
        footer={null}
        closable={true}
        bodyStyle={{ padding: '64px 0' }}
        centered
        onCancel={onCancel}
      >
        <div className="modal-add-social__body">
          <div className="modal-add-social__header">
            <div className="modal-add-social__title">{t('Добавление социальной сети')}</div>
            <div className="modal-add-social__desc">{t('Зайдите в профиль социальной сети и скопируйте URL ')}</div>
          </div>
          <div className="modal-add-social__form">
            <AddSocialForm setVisible={onCancel} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddSocialModal;
