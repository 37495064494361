/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { setStatus, setSuccess } from '@redux/reducers/app';
import { setToken } from '@redux/reducers/auth';
import { setLoading, setCompany, getCompany } from '@redux/reducers/company';
import { put, call, select } from 'redux-saga/effects';
import __ from 'lodash';
// api
import { api } from '@api/department';
// mapping
import { message } from 'antd';
import { checkFormValues, getCurrentValues } from '@tools/helpers';
import { setDePInfo, setDepList } from '@redux/reducers/departments';

export function* sagaCreateDepartment(action) {
  const geOptions = (state) => state.app;
  const { options } = yield select(geOptions);
  const { payload } = action;
  const { values, callback } = payload;

  // yield put(setLoading(true));
  // yield put(setStatus('loading'));

  const formValues = checkFormValues(values);
  formValues.category = getCurrentValues(formValues.category, options.tl_lists);
  const is_new_address = !!formValues.is_new_address;
  const id_tl_list = formValues.category.idItem;
  const request = {
    list: 'tl_lists',
    id_tl_list,
    ...formValues,
  };

  try {
    const { data } = yield call(api.addNewDepartment, {
      token: localStorage.getItem('token'),
      data: request,
      is_new_address: is_new_address,
    });

    if (data.ok) {
      //yield put(setCompany(payload.data));
      callback && callback();
      return;
      //
      // yield put(setStatus('main'));
      // yield put(setToken(localStorage.getItem('token')));
      // yield put(setUser(data.data));
    }
    message.error({ content: data.msg, duration: 4 });
  } catch (e) {
    message.error({ content: e.message, duration: 4 });
    // localStorage.clear();
    // yield put(setStatus(''));
    //yield put(setError(ERROR_MAP.auth(e)));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaGetDepartments(action) {
  const { payload } = action;
  const { callback } = payload;
  // yield put(setLoading(true));
  // yield put(setStatus('loading'));

  try {
    const { data } = yield call(api.getDepartments, localStorage.getItem('token'));

    if (data.ok) {
      const list = data?.data?.tl_lists
        ? [...data.data?.tl_lists].map((item) => ({ name: item, id: __.uniqueId() }))
        : [];
      yield put(setDepList(list));
      //yield put(setCompany(payload.data));
      callback && callback();
      return;
      //
      // yield put(setStatus('main'));
      // yield put(setToken(localStorage.getItem('token')));
      // yield put(setUser(data.data));
    }
    message.error({ content: data.msg, duration: 4 });
  } catch (e) {
    message.error({ content: e.message, duration: 4 });
    // localStorage.clear();
    // yield put(setStatus(''));
    //yield put(setError(ERROR_MAP.auth(e)));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaGetDepartmentInfo(action) {
  const { payload } = action;
  const { idItem, type, callback } = payload;

  // yield put(setLoading(true));
  // yield put(setStatus('loading'));

  try {
    const { data } = yield call(api.getDepartmentInfo, {
      token: localStorage.getItem('token'),
      idItem,
      type,
    });

    if (data.ok) {
      yield put(setDePInfo(data?.data));
      //yield put(setCompany(payload.data));
      callback && callback();
      return;
      //
      // yield put(setStatus('main'));
      // yield put(setToken(localStorage.getItem('token')));
      // yield put(setUser(data.data));
    }
    message.error({ content: data.msg, duration: 4 });
  } catch (e) {
    message.error({ content: e.message, duration: 4 });
    // localStorage.clear();
    // yield put(setStatus(''));
    //yield put(setError(ERROR_MAP.auth(e)));
  } finally {
    yield put(setLoading(false));
  }
}
