import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from '@src/i18n';

// components
import InputDefault from '@atoms/inputs';
import ButtonDefault from '@atoms/buttons/button';
import TimeCountdown from '@molecules/timeCountdown';
import FormFooter from '@atoms/text/formFooter';
// actions
import { forgotByPhone } from '@redux/reducers/auth';
// helps
import { codeSchema } from '@tools/shemas';
// types
import { stateType } from '@types';
// styles
import './style.scss';

interface FormData {
  code: string;
}

const FormCode: FC = () => {
  const { loading } = useSelector((state: stateType) => state.auth);
  const schema = useMemo(() => codeSchema(), [i18n.language]);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = async (data: FormData) => {
    const { code } = data;
    const request = {
      phone: localStorage.getItem('forgot-phone'),
      confirm_phone_code: Number(code),
    };

    dispatch(
      forgotByPhone({
        request,
        callback: () => {
          navigate('/recovery-password');
        },
      }),
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form code-form">
        <div className="form__container">
          <InputDefault
            name={'code'}
            label={t('SMS-код')}
            placeholder={t('Укажите код')}
            error={errors.code?.message}
            register={register}
            control={control}
            type={'number'}
          />
          <div className="form__container-links">
            <TimeCountdown />
          </div>
        </div>
        <div className="form__container">
          <ButtonDefault name={t('Подтвердить')} type={'submit'} loading={loading} disabled={loading} />
        </div>
      </form>
      <FormFooter />
    </>
  );
};

export default FormCode;
