import React, { useEffect } from 'react';
// components
// import HomeTemplate from '@templates/homeTemplate';

const Tasks: React.FC = () => {
  useEffect(() => {
    console.log('Tasks PAGE');
  }, []);

  return <>Tasks</>;
};

export default Tasks;
