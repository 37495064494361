/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
// components
import { StatusCompany, Titlte } from '@molecules/tables/users';
// icons
import { IconFile } from '@assets/icons/uploaders';
import { IconLink } from '@assets/icons/statsInfo';

export const columns = [
  {
    title: <Titlte name={'№'} classes={'lc-verification-companies-table__title'} />,
    dataIndex: 'id',
    className: 'lc-verification-companies-table__column lc-verification-companies-table__column-id',
    render: (text, record) => <div className="lc-verification-companies-table__column-container">{text}</div>,
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: () => <Titlte name={'Компания'} classes={'lc-verification-companies-table__title'} />,
    dataIndex: 'name',
    className: 'lc-verification-companies-table__column lc-verification-companies-table__column-name',
    render: ( text, record) =>
        <div>
          <Link to={`/lc-companies/company/${record.id}`}>
            {text}
          </Link>
        </div>,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: () => <Titlte name={'Сфера деятельность'} classes={'lc-verification-companies-table__title'} />,
    dataIndex: 'activity',
    className: 'lc-verification-companies-table__column lc-verification-companies-table__column-activity',
    render: (text, record) => <div>{record.activity?.name}</div>,
    sorter: (a, b) => a.activity?.id - b.activity?.id,
  },
  {
    title: () => <Titlte name={'Код'} name2={'ЕДРПОУ'} classes={'lc-verification-companies-table__title'} />,
    dataIndex: 'code',
    className: 'lc-verification-companies-table__column lc-verification-companies-table__column-code',
    sorter: (a, b) => a.code - b.code,
  },
  {
    title: () => <Titlte name={'Дата'} name2={'создания'} classes={'lc-verification-companies-table__title'} />,
    dataIndex: 'update_date',
    className: 'lc-verification-companies-table__column lc-verification-companies-table__column-date',
    render: (text, record) => (
      <div className="lc-verification-companies-table__column-date-block">
        <span className="table-date">
          <Moment format="MM/DD/YYYY">{record.update_date}</Moment>
        </span>
        <span className="table-time">
          <Moment format="HH:mm:ss">{record.update_date}</Moment>
        </span>
      </div>
    ),
    sorter: (a, b) => new Date(a.update_date) - new Date(b.update_date),
  },
  {
    title: () => <Titlte name={'Файл'} classes={'lc-verification-companies-table__title'} />,
    className: 'lc-verification-companies-table__column lc-verification-companies-table__column-files',
    render: (text, record) => (
      <div className="lc-verification-companies-table__column-container">
        <Link to={`/lc-companies/company/${record.id}/files`}>
          <IconFile />
        </Link>
      </div>
    ),
  },
  {
    title: () => <Titlte name={'Статус'} classes={'lc-verification-companies-table__title'} />,
    dataIndex: 'status',
    className: 'lc-verification-companies-table__column lc-verification-companies-table__column-status',
    render: (text, record) => (
      <StatusCompany status={record.status} isDel={record.is_del} classes={'lc-verification-companies-table__status'} />
    ),
    sorter: (a, b) => a.status.id - b.status.id,
  },
  {
    title: () => <Titlte name={'Детальнее'} classes={'lc-verification-companies-table__title'} />,
    className: 'lc-verification-companies-table__column lc-verification-companies-table__column-link',
    render: (text, record) => (
      <div className="lc-verification-companies-table__column-container">
        <Link to={`/lc-companies/company/${record.id}`}>
          <IconLink />
        </Link>
      </div>
    ),
  },
];
