import React, { useEffect } from 'react';
// components
import SettingsContainer from '@containers/settings';

const Settings: React.FC = () => {
  useEffect(() => {
    console.log('Settings PAGE');
  }, []);

  return <SettingsContainer />;
};

export default Settings;
