import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
// actions
import { appPage, setStatus } from '@redux/reducers/app';
// components
import FormCompanyRegistration from '@organisms/forms/companies/create';
// types
import { stateType } from '@types';
import { FormHeaderCompanies } from '@atoms/text/formHeader';
import { useTranslation } from 'react-i18next';

const CompanyCreate: FC = () => {
  const { page } = useSelector((state: stateType) => state.app);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setStatus('company-create'));
  }, []);

  return (
    <>
      <Helmet title={page} />
      <FormHeaderCompanies title={t('Регистрация')} iconClosed />
      <FormCompanyRegistration />
    </>
  );
};

export default CompanyCreate;
