import React, { FC } from 'react';
// components
import LcSendMessage from '@atoms/textarea/lcMessage';
import LcMessagesList from '@molecules/lists/lcMessages';
// styles
import './style.scss';

const LcMessagesContainer: FC = () => {
  return (
    <div className="lc-messages-container">
      <LcSendMessage />
      <LcMessagesList />
    </div>
  );
};

export default LcMessagesContainer;
