export const messages = {
  registrationSuccess:
    'Поздравляю Ви успешно зарегистрировались. На Ваш E-Mail отправлено письмо с ссылкой для подтверждения',
  resetPasswdSuccess: 'На ваш E-Mail отправлено письмо с инструкцией по восстановлению пароля.',

  isNotConfirmUser:
    'Для продолжения необходимо активировать свою учетную запись. На Ваш адрес электронной почты было отправлено письмо со ссылкой для подтверждения.',

  bannersText: {
    verifyWait:
      'Добро пожаловать в Ваш профиль. Когда вы пройдете верификацию, вам на почту придет уведомление. Также вы сможете пользоваться всеми функциями платформы!',
    getAccess:
      'Добро пожаловать в Ваш профиль, Вы получили доступ ко всем функциям платформы. Верифицируйте информацию о Компании, чтобы публиковать свои первые вакансии.',
  },

  addVacancyMainTxt: 'Новая вакансия успешно создана и добавлена в базу',
  deputyRegistration:
    'Заместители успешно добавлены в базу. На их E-Mail отправлено письмо с ссылкой для подтверждения',

  goToHome: 'Вы будете перенаправлены на Главную страницу через',
  goToVerify: 'Вы будете перенаправлены на Верификацию компании через: ',
};

export const positionErrorsMsg = {
  FUNCTIONALITY: 'Не выбрано Функционала',
  TARGET: 'Не выбрано Цели',
  RESULT: 'Не выбрано Результата',
  KPI: 'Не выбрано KPI',
  ZUN: 'Не выбрано ЗУН',
};

export const rules = {
  required: 'Поле обязательное',
};
