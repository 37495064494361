import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';
// components
import EditProfile from '@organisms/forms/editProfile';
// style
import './style.scss';
import { useDispatch } from 'react-redux';
import { getOptions } from '@redux/reducers/app';
import { t } from 'i18next';
import ButtonDefault from '@atoms/buttons/button';
import { useSelector } from 'react-redux';
import { stateType } from '@types';
import classNames from 'classnames';
import { profileUpdate } from '@redux/reducers/user';

interface EditProfileModalModalType {
  component: any;
  action?: any;
}

const AddStatusModal: FC<EditProfileModalModalType> = ({ component: Component, action }: EditProfileModalModalType) => {
  const { info } = useSelector((state: stateType) => state.user);
  const { options } = useSelector((state: stateType) => state.app);
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState(null);
  const [loader, setLoader] = useState(false);

  const { office_presence_status } = info;

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      dispatch(
        getOptions({
          request: {
            source: ['office_presence_status'],
          },
        }),
      );
    }
  }, [visible]);

  useEffect(() => {
    setActive(office_presence_status?.id);
  }, [office_presence_status]);

  const onCancel = () => {
    setVisible(false);
  };

  const call = () => {
    setLoader(true);
    action(onCancel);
  };

  const setStatus = () => {
    const request = {
      office_presence_status_id: active,
    };
    dispatch(
      profileUpdate({
        request,
        callback: {
          success: () => {
            setLoader(false);
            onCancel();
          },
          error: () => {
            setLoader(false);
          },
        },
      }),
    );
  };

  return (
    <>
      <>
        <Component
          onClick={() => {
            setVisible(true);
          }}
        />
        <Modal
          className="modal-status"
          open={visible}
          width={504}
          footer={null}
          closable={true}
          bodyStyle={{ padding: '64px' }}
          centered
          onCancel={onCancel}
        >
          <div className="modal-status__container">
            <div className="modal-status__header">
              <div className="modal-status__title">{t('Статус присутствия в офисе')}</div>
              <div className="modal-status__desc">
                {t(
                  'Нужный статус поможет команде понять, насколько оперативно вы можете отвечать на сообщения и когда вы доступны',
                )}
              </div>
            </div>
            <div className="modal-status__body">
              {options?.office_presence_status &&
                options?.office_presence_status.map((item: any) => {
                  const { id, name } = item;
                  return (
                    <div
                      className={classNames(`modal-status__item modal-status__item-${id}`, {
                        [`modal-status__item-active-${active}`]: active === id ? true : false,
                      })}
                      key={id}
                      onClick={() => setActive(id)}
                    >
                      {name}
                    </div>
                  );
                })}
            </div>
            <div className="modal-status__footer">
              <ButtonDefault name={t('Выбрать')} action={setStatus} loading={loader} />
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default AddStatusModal;
