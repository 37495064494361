import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
// actions
import { setStatus } from '@redux/reducers/app';
// components
import ButtonAdd from '@atoms/buttons/add';
import { FormHeaderCompanies } from '@atoms/text/formHeader';
import CompaniesList from '@molecules/lists/companies';
// types
import { stateType } from '@types';
// styles
import './style.scss';

const MainCompaniesContainer: FC = () => {
  const { list, loading } = useSelector((state: stateType) => state.company);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sort } = useParams();

  const initTitle = () => {
    switch (sort) {
      case '':
        return t('Мои Компании');
      case 'transfer':
        return t('Переданные Компании');
      case 'archive':
        return t('Архив Компаний');
      default:
        return t('Мои Компании');
    }
  };

  return (
    <div className="companies-container">
      <FormHeaderCompanies
        title={initTitle()}
        desc={_.isEmpty(list) && sort === 'archive' ? '' : t('Компании')}
        classes={!_.isEmpty(list) && sort === 'archive' ? 'archive' : ''}
      />
      {sort !== 'archive' && (
        <ButtonAdd
          action={() => {
            dispatch(setStatus('company-create'));
            navigate('/company-create');
          }}
        />
      )}
      {_.isEmpty(list) && !loading && sort === 'archive' && (
        <div className="companies-container__empty-text">{t('Компаний в архиве нет')}</div>
      )}
      <CompaniesList />
    </div>
  );
};

export default MainCompaniesContainer;
