import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AutoComplete, Button, Checkbox, Form, Input, message, Select } from 'antd';
// components
import InputMask from 'react-input-mask';
import Text from 'antd/es/typography/Text';
import AppText from '@atoms/text/AppText';
// actions
import { setCompanyAdmin } from '@redux/reducers/company';
import { setUser } from '@redux/reducers/user';
// tools
import { passwordGenerator } from '@tools/helpers';
// icons
import DropDownArrow from '@assets/icons/drop_down_arrow/dropDownArrow.jsx';
// types
import { stateType } from '@types';
// styles
import './style.scss';

interface FormSetAdminType {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

const FormSetAdmin: FC<FormSetAdminType> = ({ setCurrent }: FormSetAdminType) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(true);
  const [newPassword, setNewPassword] = useState<string>('');

  const { info } = useSelector((state: stateType) => state.user);
  const { loading } = useSelector((state: stateType) => state.company);
  const { token } = useSelector((state: stateType) => state.auth);
  const key = 'set-admin';
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onFinish = async () => {
    message.loading({ content: 'Загрузка...', key });
    dispatch(
      setCompanyAdmin({
        data: form.getFieldsValue(),
        key,
        token: token,
        callback: () => {
          message.success({ content: 'Данные об Администраторе успешно сохранены!', key, duration: 4 });
          dispatch(setUser({ ...info, step: '3' }));
        },
      }),
    );
  };

  useEffect(() => {
    if (isAdmin) {
      setIsAdmin(true);
      form.resetFields();
      form.setFields([
        { name: 'email', value: info.email },
        { name: 'tel', value: info.tel },
        { name: 'first_name', value: info.name },
        { name: 'surname', value: info.sname },
        { name: 'middle_name', value: info.lname },
      ]);
    } else {
      setIsAdmin(false);
      const valueAdmin = form.getFieldValue('is_admin');
      form.resetFields();
      form.setFields([{ name: 'is_admin', value: valueAdmin }]);
    }
  }, [isAdmin]);

  const handleOnChangeAdmin = () => {
    const valueAdmin = form.getFieldValue('is_admin');
    valueAdmin === '0' ? setIsAdmin(true) : setIsAdmin(false);
  };

  return (
    <Form
      name="set-company-admin"
      initialValues={{
        is_admin: '0', // Передача прав Администратора
        // 0 - Назначить меня администратором Компании
        // 1 - Назначить другого человека администратором Компании
        first_name: '', // імя
        surname: '', // фамилия
        middle_name: '', // отчество
        tel: '', // Телефон*
        email: '', // E-mail*
        password: '', // Пароль*
        confirm: '', // Подтвердите пароль*
        agreement: true, // Я принимаю Политику конфиденциальности и Условия пользования
      }}
      layout="vertical"
      size="large"
      onFinish={onFinish}
      form={form}
      className="form set-company-admin"
    >
      <AppText
        text="Администратор"
        style={{
          color: '#20272E',
          fontSize: 18,
          fontWeight: 700,
          marginBottom: 24,
          lineHeight: 1.5715,
          display: 'block',
        }}
      />

      <Form.Item
        name="is_admin"
        label="Передача прав Администратора"
        className="form__container"
        rules={[{ required: true, message: 'Пожалуйста, введите Адміністатора!' }]}
      >
        <Select
          listHeight={300}
          placeholder="Количество сотрудников"
          suffixIcon={<DropDownArrow />}
          onChange={handleOnChangeAdmin}
        >
          <Select.Option value="0" style={{ borderBottom: '1px solid #D9DCF0' }}>
            <Text className="form__select_title">Назначить меня администратором Компании</Text>
            <Text className="form__select_disabled">
              Вы сможете самостоятельно верифицировать компанию, добавлять и редактировать информацию, открывать и
              закрывать вакансии и добавлять кандидатов.
            </Text>
          </Select.Option>
          <Select.Option value="1">
            <Text className="form__select_title">Назначить другого человека администратором Компании</Text>
            <Text className="form__select_disabled">
              Вы будете добавлены в Компанию как обычный сотрудник. Ваши полномочия и права доступа сможет определить
              указанный Администратор. Указанный Администратор получит на почту уведомление о назначении.
            </Text>
          </Select.Option>
        </Select>
      </Form.Item>
      <div className="form__row">
        <Form.Item
          className="form__container"
          label="Фамилия"
          name="surname"
          rules={[{ required: true, message: 'Пожалуйста, введите Фамилию!' }]}
        >
          <Input className="form__input" placeholder="Фамилия" />
        </Form.Item>

        <Form.Item
          className="form__container"
          label="Имя"
          name="first_name"
          rules={[{ required: true, message: 'Пожалуйста, введите Имя!' }]}
        >
          <Input className="form__input" placeholder="Имя" />
        </Form.Item>

        <Form.Item className="form__container" label="Отчество" name="middle_name">
          <Input className="form__input" placeholder="Отчество" />
        </Form.Item>
      </div>
      <div className="form__row">
        <Form.Item
          className="form__container"
          label="Телефон"
          name="tel"
          rules={[{ required: true, message: 'Пожалуйста, введите номер телефона!' }]}
        >
          <InputMask placeholder="+380" className="ant-input ant-input-lg form__input" mask="+380 99 99 99 999" />
        </Form.Item>

        <Form.Item
          className="form__container"
          label="E-Mail"
          name="email"
          rules={[
            { type: 'email', message: 'Не валидний адрес!' },
            { required: !isAdmin, message: 'Пожалуйста, введите адрес Email!' },
          ]}
        >
          <Input disabled={isAdmin} className="form__input" placeholder="new_email@gmail.com" />
        </Form.Item>
      </div>

      <div className="form__row">
        <Form.Item
          className="form__container"
          name="password"
          label="Пароль"
          rules={[{ required: !isAdmin, message: 'Пожалуйста, введите пароль!' }]}
        >
          <AutoComplete
            disabled={isAdmin}
            options={[{ value: newPassword, option: '1' }]}
            onFocus={() => newPassword || setNewPassword(passwordGenerator())}
            onSelect={(value: any) =>
              form.setFields([
                { name: 'password', value, errors: [], touched: true },
                { name: 'confirm', value, errors: [], touched: true },
              ])
            }
          >
            <Input.Password disabled={isAdmin} className="form__input" placeholder="Укажите ваш пароль" />
          </AutoComplete>
        </Form.Item>

        <Form.Item
          className="form__container"
          name="confirm"
          label="Подтвердите пароль"
          rules={[
            { required: !isAdmin, message: 'Пожалуйста, введите пароль!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Пароли не совпадают!'));
              },
            }),
          ]}
        >
          <Input.Password disabled={isAdmin} className="form__input" placeholder="Укажите ваш пароль" />
        </Form.Item>
      </div>

      <Form.Item
        className="form__container"
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Следует принять соглашение!')),
          },
        ]}
      >
        <Checkbox className="form__input-checkbox ">
          <span className="_required ">
            Я принимаю <span className="link _bold">Политику конфиденциальности</span> и{' '}
            <span className="link _bold">Условия пользования</span>
          </span>
        </Checkbox>
      </Form.Item>

      <Form.Item className="form__container">
        <div className="form__row _between">
          <Button disabled={loading} type="default" htmlType="button" onClick={() => setCurrent(1)}>
            Назад
          </Button>
          <Button type="primary" shape="round" htmlType="submit" disabled={loading}>
            Сохранить и продолжить
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default FormSetAdmin;
