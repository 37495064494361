import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// actions
import { getUserInfo } from '@redux/reducers/user';
import { loginToCompany } from '@redux/reducers/company';
// icons
import IconSpinner2 from '@assets/icons/btn/spinner2';

import RemoveCompanyModal from '@organisms/modals/confirms/removeCompany';
import RecoveryCompanyModal from '@organisms/modals/confirms/recoveryCompany';
import DropdownCompaniesNav from '@molecules/app/dropdwons/companyNav';
// actions
import { recoveryCompany, removeCompanyFromList } from '@redux/reducers/company';
// icons
import IconDelete from '@assets/icons/btn/delete';
import IconRecovry from '@assets/icons/btn/recovery';
// types
import { CompanyType } from '.';
// styles
import './style.scss';

interface CompanyListItemType {
  item: CompanyType;
  loadingLogin: boolean;
  initID: false | number;
  setInitID: React.Dispatch<React.SetStateAction<false | number>>;
  index: number;
  setSuccess: any;
  info?: any;
}

const CompanyListItem: FC<CompanyListItemType> = ({
  item,
  loadingLogin,
  initID,
  setInitID,
  index,
  setSuccess,
  info,
}: CompanyListItemType) => {
  const [opacity, setOpacity] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { id, name, role, status } = item;

  const { sort } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setOpacity(true);
    }, index * 100);
  }, []);

  const navigations = () => {
    switch (sort) {
      case 'archive':
        return (
          <RecoveryCompanyModal
            component={IconRecovry}
            phone={info?.phone}
            action={(callback: any) => {
              dispatch(
                recoveryCompany({
                  request: { company_id: id },
                  callback: () => {
                    dispatch(removeCompanyFromList(id));
                    callback();
                    setSuccess({
                      title: t('Компания успешно восстановлена!'),
                      desc: t('Компания перемещена в раздел «Мои Компании»'),
                    });
                  },
                }),
              );
            }}
          />
        );
      case 'transfer':
        return (
          <RemoveCompanyModal
            component={IconDelete}
            phone={info?.phone}
            action={(callback: any) => {
              //dispatch(addCompanyToArchive({ request: { company_id: id }, callback: () => callback(false) }));
            }}
          />
        );

      default:
        return <DropdownCompaniesNav item={item} setSuccess={setSuccess} />;
    }
  };

  return (
    <div key={id} className={classNames('companies-list__item', { ['animation-opacity']: opacity })}>
      {loadingLogin && initID === id && (
        <div className="companies-list__item-loader">
          <div className="icon-spinner-left">
            <IconSpinner2 />
          </div>
        </div>
      )}
      <div className="companies-list__name">
        <div
          className="companies-list__title"
          onClick={() => {
            setInitID(id);
            !loadingLogin &&
              dispatch(
                loginToCompany({
                  request: { company_id: id },
                  callback: () => {
                    dispatch(getUserInfo());
                  },
                }),
              );
          }}
        >
          {name}
        </div>
        <div className="companies-list__role">{role.name}</div>
      </div>
      <div className="companies-list__nav">
        <div className={classNames('companies-list__status', `companies-list__status-${status.id}`)}>{status.name}</div>
        {navigations()}
      </div>
    </div>
  );
};

export default CompanyListItem;
