import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import __ from 'lodash';
// components
import AuthenticationAside from '@containers/app/aside';
import DynamicSteps from '@organisms/dynamicSteps';
// tools
import useRegCompanySteps from '@tools/hooks/steps/useRegCompanySteps';
// types
import { stateType } from '@types';

const CompanyRegistration: FC = () => {
  const { info } = useSelector((state: stateType) => state.user);
  const { page } = useSelector((state: stateType) => state.app);

  const { current, setCurrent, steps } = useRegCompanySteps();

  useEffect(() => {
    if (!__.isEmpty(info)) {
      setCurrent(Number(info.step));
    }
  }, [info]);

  return (
    <Layout className="new-company">
      <AuthenticationAside page={page} />

      <Layout className="new-company__layout layout">
        <Content className="new-company__container">
          <DynamicSteps steps={steps} current={current} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default CompanyRegistration;
