import React, { FC } from 'react';

const IconArrowLeft: FC<any> = ({ color }: any) => {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.85853 11.4101C9.09252 11.2839 9.2384 11.0438 9.2384 10.7827V6.71729H17.2657C17.671 6.71729 18 6.39596 18 6.00003C18 5.6041 17.671 5.28276 17.2657 5.28276H9.2384V1.21731C9.2384 0.955274 9.09252 0.71523 8.85853 0.589948C8.62453 0.462753 8.33865 0.471361 8.11248 0.610031L0.342671 5.39274C0.129236 5.52472 0 5.75329 0 6.00003C0 6.24676 0.129236 6.47533 0.342671 6.60731L8.11248 11.39C8.23193 11.4627 8.36802 11.5 8.50411 11.5C8.62551 11.5 8.74789 11.4694 8.85853 11.4101Z"
        fill={color ? color : '#6D7895'}
      />
    </svg>
  );
};

export default IconArrowLeft;

export const IconArrowDropdown: FC = () => {
  return (
    <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.666504 0.666748L3.99984 4.00008L7.33317 0.666748H0.666504Z" fill="none" />
    </svg>
  );
};

export const IconBellDropdown: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9204 4.93256C10.3618 4.93256 9.88652 5.32075 9.75312 5.84395H14.2387C14.1053 5.32075 13.6301 4.93256 13.0715 4.93256H10.9204ZM15.506 5.84395H17.1568C18.9077 5.84395 20.3334 7.28698 20.3334 9.05913C20.3334 9.05913 20.2834 9.80933 20.2667 10.8541C20.265 10.9368 20.225 11.0178 20.1592 11.0667C19.7581 11.3629 19.3913 11.6076 19.3579 11.6245C17.9739 12.5528 16.3656 13.2059 14.6522 13.5308C14.5405 13.5528 14.4305 13.4945 14.3738 13.395C13.8935 12.5629 12.9964 12.0211 11.9959 12.0211C11.0021 12.0211 10.0966 12.557 9.60222 13.3899C9.54469 13.4878 9.4363 13.5443 9.32541 13.5232C7.62623 13.1975 6.01792 12.5452 4.64224 11.633L3.84184 11.076C3.77514 11.0338 3.73345 10.9578 3.73345 10.8735C3.70844 10.4431 3.66675 9.05913 3.66675 9.05913C3.66675 7.28698 5.09246 5.84395 6.84334 5.84395H8.48582C8.64424 4.62033 9.66975 3.66675 10.9204 3.66675H13.0715C14.3221 3.66675 15.3476 4.62033 15.506 5.84395ZM20.0499 12.6794L20.0166 12.6963C18.3324 13.8271 16.3064 14.5782 14.1803 14.8904C13.8802 14.9326 13.58 14.7385 13.4967 14.4347C13.3132 13.7427 12.7213 13.287 12.0126 13.287H12.0043H11.9876C11.2789 13.287 10.6869 13.7427 10.5035 14.4347C10.4201 14.7385 10.12 14.9326 9.81983 14.8904C7.69376 14.5782 5.66775 13.8271 3.98358 12.6963C3.97524 12.6879 3.89186 12.6372 3.82516 12.6794C3.75013 12.7216 3.75013 12.8229 3.75013 12.8229L3.80849 17.1267C3.80849 18.8988 5.22586 20.3334 6.97674 20.3334H17.0151C18.766 20.3334 20.1833 18.8988 20.1833 17.1267L20.25 12.8229C20.25 12.8229 20.25 12.7216 20.175 12.6794C20.1333 12.6541 20.0833 12.6626 20.0499 12.6794ZM12.6212 16.2153C12.6212 16.5697 12.3461 16.8482 11.9959 16.8482C11.6541 16.8482 11.3706 16.5697 11.3706 16.2153V15.1267C11.3706 14.7807 11.6541 14.4938 11.9959 14.4938C12.3461 14.4938 12.6212 14.7807 12.6212 15.1267V16.2153Z"
      />
    </svg>
  );
};

export const IconArrowLeftCircle: FC = () => {
    return (
        <div style={{width:42, height: 42, borderRadius: '55px', display: 'flex', justifyContent: 'center',  alignItems: 'center', background: 'white' }}>
            <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5901 24L15.4101 21.18L6.25009 12L15.4101 2.82L12.5901 1.16775e-06L0.590091 12L12.5901 24Z" fill="#8D8BED"/>
            </svg>
        </div>
    );
};


export const IconArrowRightCircle: FC = () => {
    return (
        <div style={{width:42, height: 42, borderRadius: '55px', display: 'flex', justifyContent: 'center',  alignItems: 'center', background: 'white' }}>
            <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.40997 0L0.589966 2.82L9.74997 12L0.589966 21.18L3.40997 24L15.41 12L3.40997 0Z" fill="#8D8BED"/>
            </svg>
        </div>
    );
};



