import React, { FC } from 'react';
// components
import { LcAppHeader } from '@molecules/app/header';
import Menu from '@organisms/menu';
// styles
import './style.scss';

interface HomeTemplateType {
  children: React.ReactNode;
  showHeader?: boolean;
}

const LcVerificationTemplate: FC<HomeTemplateType> = ({ showHeader, children }: HomeTemplateType) => {
  return (
    <div className="lc-verification-template">
      <div className="lc-verification-template__side">
        <Menu set={'lc-verification'} />
      </div>
      <div className="lc-verification-template__container">
        {showHeader && <LcAppHeader />}
        <div className="lc-verification-template__body">{children}</div>
      </div>
    </div>
  );
};

export default LcVerificationTemplate;
