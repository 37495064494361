import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
// actions
import { appPage, setStatus } from '@redux/reducers/app';
// components
import CompaniesTemplate from '@templates/formTemplate/companiesTemplate';
import MainCompaniesContainer from '@containers/companies/main';
// types
import { stateType } from '@types';

const CompaniesProfile: FC = () => {
  const { status: page } = useSelector((state: stateType) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setStatus('companies-profile'));
  }, []);
  return (
    <>
      <Helmet title={page} />
      <CompaniesTemplate page={page}>
        <MainCompaniesContainer />
      </CompaniesTemplate>
    </>
  );
};

export default CompaniesProfile;
