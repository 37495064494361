// types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/*
 *
 * Addresses reducer
 *
 */
const initialState = {
  loading: false,
  error: null,
  list: null,
  cities: [],
  streets: null,
};

export type addressesInitStateType = typeof initialState;

const addresses = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    getAddressesList: () => {
      return;
    },
    setAdrList: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setCities: (state, action: PayloadAction<any>) => {
      state.cities = action.payload;
    },
    setStreets: (state, action: PayloadAction<any>) => {
      state.streets = action.payload;
    },
  },
});

export default addresses.reducer;
export const { getAddressesList, setAdrList, setCities, setStreets }: any = addresses.actions;
