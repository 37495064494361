import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
// components
import AuthenticationAside from '@containers/app/aside';
import FormResetPass from '@organisms/forms/reset/index';
// actions
import { appPage } from '@redux/reducers/app';
import AsideTemplate from '@templates/asideTemplate';
import FormHeader from '@atoms/text/formHeader';

const RecoveryPass: FC = () => {
  const { page } = useSelector((state: any) => state.app);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('recovery-token')) {
      dispatch(appPage('recovery'));
      return;
    }

    navigate('/');
  }, []);

  return (
    <>
      <Helmet title={page} />
      <AsideTemplate showBenefits center page={page}>
        <FormHeader type={'reset'} />
        <FormResetPass />
      </AsideTemplate>
    </>
  );
};

export default RecoveryPass;
