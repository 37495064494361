import React, { FC } from 'react';
// import { useSelector } from 'react-redux';
// types
// import { stateType } from '@types';

interface RemainderType {
  text: string;
  type: string;
  record: any;
}

const Remainder: FC<RemainderType> = ({ text, record, type }: RemainderType) => {
  // const { remainders } = useSelector((state: stateType) => state.vacancies);
  const remainders: any = [];
  const remainder = remainders.find((r: any) => r.name === record.name);
  const isUpdate = remainder && remainder[type];
  return (
    <div className="app-table__block">
      <span className={`${isUpdate ? 'app-table__text active' : 'app-table__text'}`}>{text}</span>
      {isUpdate && <span className="app-table__remainder">+{remainder[type]}</span>}
    </div>
  );
};

export default Remainder;
