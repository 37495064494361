// types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
/*
 *
 * Company reducer
 *
 */

const initialState = {
  loading: false,
  loadingLogin: false,
  info: {} as any,
  list: [] as any,
};

export type companyInitStateType = typeof initialState;

const company = createSlice({
  name: 'company',
  initialState,
  reducers: {
    getCompanyInfo: () => {
      return;
    },
    createCompany: () => {
      return;
    },
    updateCompany: () => {
      return;
    },
    getCompany: () => {
      return;
    },
    getCompanies: () => {
      return;
    },
    addCompanyToArchive: () => {
      return;
    },
    recoveryCompany: () => {
      return;
    },
    removeCompany: () => {
      return;
    },
    loginToCompany: () => {
      return;
    },
    logoutFromCompany: () => {
      return;
    },
    setFileToCompany: () => {
      return;
    },
    removeFileCompany: () => {
      return;
    },
    setCompanyStatus: () => {
      return;
    },
    searchUserInCompany: () => {
      return;
    },
    addUserToCompany: () => {
      return;
    },
    updateUserToCompany: () => {
      return;
    },
    removeUserFromCompany: () => {
      return;
    },
    transferCompany: () => {
      return;
    },
    setCompanies: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    removeCompanyFromList: (state, action: PayloadAction<any>) => {
      state.list = _.filter(state.list, ({ id }) => id !== action.payload);
    },
    setCompanyAdmin: () => {
      return;
    },
    addCompanyDeputy: () => {
      return;
    },
    setCompany: (state, action: PayloadAction<any>) => {
      state.info = action.payload;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
    setLoadingLogin: (state, action: PayloadAction<any>) => {
      state.loadingLogin = action.payload;
    },
    setFile: () => {
      return;
    },
    removeFile: () => {
      return;
    },
  },
});

export default company.reducer;
export const {
  getCompanyInfo,
  createCompany,
  getCompany,
  getCompanies,
  removeCompanyFromList,
  removeCompany,
  setCompanies,
  recoveryCompany,
  addCompanyToArchive,
  loginToCompany,
  logoutFromCompany,
  setFileToCompany,
  removeFileCompany,
  setCompanyStatus,
  searchUserInCompany,
  addUserToCompany,
  updateUserToCompany,
  removeUserFromCompany,
  transferCompany,
  updateCompany,
  setCompanyAdmin,
  addCompanyDeputy,
  setCompany,
  setLoading,
  setLoadingLogin,
  setFile,
  removeFile,
}: any = company.actions;
