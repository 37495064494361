import React, { FC, useEffect } from 'react';
import { Tabs } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
// components
import LcVerificationCompaniesTable from '@organisms/tables/lcVerificationCompanies';
// actions
import { appPage } from '@redux/reducers/app';
import { getCompanies } from '@redux/reducers/lcVerificator';
// styles
import './style.scss';
import { stateType } from '@types';

const { TabPane } = Tabs;

const TabsCompanies: FC = () => {
  const { page } = useSelector((state: stateType) => state.app);
  const { info } = useSelector((state: stateType) => state.user);
  const { filter, companies }: any = useSelector((state: stateType) => state.lcVerificator);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { tab } = useParams();

  useEffect(() => {
    dispatch(
      getCompanies({
        request: {
          type: 'companiesAll',
          filter: filter,
        },
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(appPage(tab));
    if (!tab) {
      dispatch(appPage('all'));
    }
  }, [tab]);

  const onChange = (value: string) => {
    dispatch(appPage(value));

      console.log(value, 'valll')
    dispatch(
      getCompanies({
        request: {
          type: `companies${value.charAt(0).toUpperCase() + value.slice(1)}`,
          filter: filter,
        },
      }),
    );
    navigate(`/lc-companies/${value}`);
  };

  const statusCompanyController = ({ component: Component }: any) => {
    if (info.company.status.id === 2) {
      return <span className="companies-tabs-empty__text">{t('Нет данных')}.</span>;
    }

    return <Component />;
  };

  const TabTitle: any = ({ text, count }: any) => {
    return (
      <div className="companies-tabs__title">
        {t(text)}
        <div className="companies-tabs__title-counter">{count}</div>
      </div>
    );
  };

  return (
    <Tabs onChange={onChange} activeKey={page ? page : 'all'} centered className={classNames('companies-tabs')}>
      <TabPane tab={<TabTitle text={'Все компании'} count={companies?.counts?.companiesAll} />} key="all">
        <LcVerificationCompaniesTable headerTitle={'Все компании'} />
      </TabPane>
      <TabPane tab={<TabTitle text={'На рассмотрении'} count={companies?.counts?.companiesUnderConsideration}/>} key="UnderConsideration" className="padding-0">
        <LcVerificationCompaniesTable headerTitle={'На рассмотрении'} />
      </TabPane>
      <TabPane tab={<TabTitle text={'Верифицированные'} count={companies?.counts?.companiesActive} />} key="active">
        <LcVerificationCompaniesTable headerTitle={'Верифицированные'} />
      </TabPane>
      <TabPane tab={<TabTitle text={'Отклоненные'} count={companies?.counts?.companiesReject} />} key="reject">
        <LcVerificationCompaniesTable headerTitle={'Отклоненные'} />
      </TabPane>
      <TabPane tab={<TabTitle text={'Удаленные'} count={companies?.counts?.companiesDeleted} />} key="deleted">
        <LcVerificationCompaniesTable headerTitle={'Удаленные'} />
      </TabPane>
    </Tabs>
  );
};

export default TabsCompanies;
