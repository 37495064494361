import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// icons
import IconPlus from '@assets/icons/btn/plus';
// styles
import './style.scss';

interface ButtonAddType {
  action: () => void;
}

const ButtonAdd: FC<ButtonAddType> = ({ action }: ButtonAddType) => {
  const { t } = useTranslation();
  return (
    <div className="btn-add" onClick={() => action()}>
      <div className="btn-add__plus">
        <IconPlus />
      </div>
      <div className="btn-add__text">{t('Добавить компанию')}</div>
    </div>
  );
};

export default ButtonAdd;
