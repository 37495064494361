import React, { FC } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
// components
import DeleteCompanyModal from '@organisms/modals/confirms/deleteCompany';
import RemoveCompanyModal from '@organisms/modals/confirms/removeCompany';
// actions
import { addCompanyToArchive, removeCompany, removeCompanyFromList } from '@redux/reducers/company';
// icons
import IconDelete from '@assets/icons/btn/delete';
import { IconMoveToArchive, IconMoveToRemove } from '@assets/icons/nav/myCompanies';
// types
import { stateType } from '@types';
// styles
import '../style.scss';

const DropdownCompaniesNav: FC<any> = ({ item, setSuccess }: any) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Dropdown
      className={'dropdown'}
      overlay={<DropdownOverlay setOpen={setOpen} setSuccess={setSuccess} item={item} />}
      trigger={['click']}
      open={open}
      onOpenChange={(data: any) => setOpen(data)}
    >
      <div
        className="dropdown__triger"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        <IconDelete />
      </div>
    </Dropdown>
  );
};

export default DropdownCompaniesNav;

const DropdownOverlay: FC<any> = ({ setOpen, setSuccess, item }: any) => {
  const { info } = useSelector((state: stateType) => state.user);
  const { id, status, role } = item;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const ItemArchive = ({ onClick }: any) => {
    const action = () => {
      setOpen(false);
      onClick && onClick();
    };
    return (
      <div className="dropdown-overlay__item" onClick={action}>
        <IconMoveToArchive />
        <span className="dropdown-overlay__text">{t('Переместить в архив')}</span>
      </div>
    );
  };

  const ItemDelete = ({ onClick }: any) => {
    const action = () => {
      setOpen(false);
      onClick && onClick();
    };
    return (
      <div className="dropdown-overlay__item" onClick={action}>
        <IconMoveToRemove />
        <span className="dropdown-overlay__text">{t('Удалить')}</span>
      </div>
    );
  };

  return (
    <div className="dropdown-overlay">
      <RemoveCompanyModal
        component={ItemArchive}
        phone={info.phone}
        action={(callback: any) => {
          dispatch(
            addCompanyToArchive({
              request: { company_id: id },
              callback: () => {
                dispatch(removeCompanyFromList(id));
                callback();
                setSuccess({
                  title: t('Компания успешно перенесена в архив!'),
                  desc: t('Компания перемещена в раздел «Архив Компаний»'),
                });
              },
            }),
          );
        }}
      />
      {(status.id === 1 || status === 3) && role.id === 1 && (
        <DeleteCompanyModal
          component={ItemDelete}
          phone={info.phone}
          action={(callback: any) => {
            dispatch(
              removeCompany({
                request: { company_id: id },
                callback: {
                  success: () => {
                    dispatch(removeCompanyFromList(id));
                    callback();
                    setSuccess({
                      title: t('Компания успешно удаленна'),
                      desc: t('Компания успешно удаленна из базы данных'),
                    });
                  },
                  error: () => {
                    callback();
                  },
                },
              }),
            );
          }}
        />
      )}
    </div>
  );
};
