import React, { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
// helps
import useLogout from '@tools/hooks/useLogout';
// icons
import logo from '@image/logo.svg';
// styles
import '../style.scss';

const DropdwonProfile: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Dropdown
      className={'dropdown'}
      overlay={<DropdownOverlay setOpen={setOpen} />}
      trigger={['click']}
      open={open}
      onOpenChange={(data: any) => setOpen(data)}
    >
      <div
        className="dropdown__triger"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        <img className="header-logo" src={logo} alt="logo" />
      </div>
    </Dropdown>
  );
};

export default DropdwonProfile;

const DropdownOverlay = ({ setOpen }: any) => {
  const { logout } = useLogout();
  const { t } = useTranslation();

  return (
    <div className="dropdown-overlay">
      <div className="dropdown-overlay__item">{t('Личный кабинет')}</div>
      <div className="dropdown-overlay__item">{t('Мои Компании')}</div>
      <div className="dropdown-overlay__item" onClick={() => logout()}>
        {t('Выйти')}
      </div>
    </div>
  );
};
