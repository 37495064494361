import React, { FC } from 'react';

const AppAvatar: FC<any> = ({ onClick, src, name }: any) => {
  return (
    <div className="avatar" onClick={onClick}>
      <img className="avatar__icon" src={src} alt={name} />
    </div>
  );
};

export default AppAvatar;
