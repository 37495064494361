import React from 'react';

const V9 = () => {
  return (
    <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.10001 0.299805C2.90001 0.299805 3.60001 0.999796 3.60001 1.8998C3.60001 2.6998 2.90001 3.3998 2.10001 3.3998C1.20001 3.3998 0.5 2.6998 0.5 1.8998C0.5 0.999796 1.20001 0.299805 2.10001 0.299805Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default V9;
