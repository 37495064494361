import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, message } from 'antd';
// components
import AppText from '@atoms/text/AppText';
import AppSelect from '@molecules/app/select';
// actions
import { createCompany } from '@redux/reducers/company';
import { changeStep } from '@redux/reducers/user';
// types
import { stateType } from '@types';

interface FormSetCompanyMainType {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}
const FormSetCompanyMain: FC<FormSetCompanyMainType> = ({ setCurrent }: FormSetCompanyMainType) => {
  const user = useSelector((state: stateType) => state.user);
  const { options }: any = useSelector((state: stateType) => state.app);
  const { token } = useSelector((state: stateType) => state.auth);
  const { info, loading } = useSelector((state: stateType) => state.company);

  const key = 'set-company';
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    if (info) {
      for (const key in info) {
        form.setFieldValue(key, info[key]);
      }
    }
  }, [info]);

  const onFinish = async () => {
    message.loading({ content: 'Загрузка...', key });

    dispatch(
      createCompany({
        data: form.getFieldsValue(),
        token: token,
        key: key,
        callback: () => {
          dispatch(changeStep({ token: token, step: '2' }));
          setCurrent(2);
          message.success({ content: 'Данные о компании успешно сохранены!', key, duration: 4 });
        },
      }),
    );
  };

  return (
    <Form name="set-company" layout="vertical" size="large" onFinish={onFinish} form={form} className="form">
      <AppText
        text="Общее"
        style={{
          color: '#20272E',
          fontSize: 18,
          fontWeight: 700,
          marginBottom: 24,
          lineHeight: 1.5715,
          display: 'block',
        }}
      />

      <Form.Item
        className="form__container"
        name="companyName"
        label="Название Компании"
        rules={[{ required: false, message: 'Пожалуйста, введите название компании!' }]}
      >
        <Input className="form__input" placeholder="ООО Компания" />
      </Form.Item>
      <Form.Item name="s_workers" label="Количество сотрудников в компании" className="form__container">
        <AppSelect options={options?.s_workers} placeholder="Количество сотрудников" />
      </Form.Item>
      <Form.Item
        className="form__container"
        name="kod"
        label="Код ЕГРПОУ (ЄДРПОУ)/ИНН ФОП"
        rules={[{ required: false, message: 'Пожалуйста, введите Код ЕГРПОУ (ЄДРПОУ)/ИНН ФОП!' }]}
      >
        <Input type="number" className="form__input" placeholder="1234567" />
      </Form.Item>

      <Form.Item className="form__container" name="s_activity" label="Сфера деятельности">
        <AppSelect options={options?.s_activity} placeholder="Сфера деятельности" />
      </Form.Item>

      <Form.Item className="form__container" name="services" label="Услуги / Продукт">
        <Input className="form__input" placeholder="Услуги" />
      </Form.Item>
      <Form.Item className="form__container" name="website" label="Сайт">
        <Input className="form__input" placeholder="https://mysite.com" />
      </Form.Item>

      <Form.Item className="form__container">
        <div className="form__row _right">
          <Button type="primary" shape="round" htmlType="submit" disabled={loading}>
            Сохранить и продолжить
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default FormSetCompanyMain;
