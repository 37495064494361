import React from 'react';

const IconMessage = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.939 3C18.28 3 19.57 3.53 20.519 4.481C21.469 5.43 22 6.71 22 8.05V15.95C22 18.74 19.73 21 16.939 21H7.06C4.269 21 2 18.74 2 15.95V8.05C2 5.26 4.259 3 7.06 3H16.939ZM18.53 9.54009L18.61 9.46009C18.849 9.17009 18.849 8.75009 18.599 8.46009C18.46 8.31109 18.269 8.22009 18.07 8.20009C17.86 8.18909 17.66 8.26009 17.509 8.40009L13 12.0001C12.42 12.4811 11.589 12.4811 11 12.0001L6.49998 8.40009C6.18898 8.17009 5.75898 8.20009 5.49998 8.47009C5.22998 8.74009 5.19998 9.17009 5.42898 9.47009L5.55998 9.60009L10.11 13.1501C10.67 13.5901 11.349 13.8301 12.06 13.8301C12.769 13.8301 13.46 13.5901 14.019 13.1501L18.53 9.54009Z"
      />
    </svg>
  );
};

export default IconMessage;
