/* eslint-disable react/prop-types */
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { message, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Attachment from '@molecules/attachment';
import BtnGoBack from '@atoms/buttons/goBackButton';
import ButtonDefault from '@atoms/buttons/button';
// actions
import { removeFileCompany, setCompanyStatus, setFileToCompany } from '@redux/reducers/company';
import { setStatus, setSuccess } from '@redux/reducers/app';
// tools
import { getFileFormat, getFileName, sizeConvertor } from '@tools/helpers';
// types
import { stateType } from '@types';
// icons
import IconPlus from '@assets/icons/uploaders/plus';
import { IconUploader } from '@assets/icons/uploaders';
import IconXError from '@assets/icons/inputs/error';

interface fileItemType {
  id: number;
  name: string;
  format: string;
  size: string;
  url: string;
  uuid: string;
  status: string;
  progress: number;
}

interface fileType {
  uid: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;

  webkitRelativePath: string;
}

const FormVerifyCompany: FC = () => {
  const [fileList, setFileList] = useState<Array<fileItemType>>([]);
  const [error, setError] = useState<boolean>(false);
  const { info } = useSelector((state: stateType) => state.user);

  const dir = 'edr';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleSubmit } = useForm();

  useEffect(() => {
    if (info?.company?.fileList?.[dir]?.length > 0) {
      setFileList(
        info?.company?.fileList?.[dir].map((item: fileItemType) => {
          return {
            id: item.id,
            name: getFileName(item),
            format: item.format,
            size: sizeConvertor(item.size),
            href: item.url,
            uuid: item.url.split('/')[4],
            status: 'done',
            progress: 100,
          };
        }),
      );
    }
  }, [info?.company?.fileList?.[dir]]);

  const handleOnRemoveFile = (id: any) => {
    dispatch(removeFileCompany({ request: { file_id: id }, callback: () => remove(id) }));
  };

  const remove = (id: number) => {
    const files = fileList.filter((item: any) => item.id !== id);
    setFileList(files);
  };

  const props: any = {
    name: 'some_files',
    accept: '.png,.jpg,.jpeg,.PNG,.JPG,.JPEG,.doc,.docx,.pdf',
    multiple: true,
    showUploadList: false,
    beforeUpload: (file: fileType) => {
      setError(false);
      const format = getFileFormat(file).toLocaleLowerCase();
      const formats = ['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf'];

      if (!formats.includes(format)) {
        message.error(t(`Файл с типом format не допустим!`, { format: format }));
        return false;
      }

      if (Math.floor(file.size / 1024 / 1024) > 10) {
        message.error(t(`Файл не может бить больше 10MB!`));
        return false;
      }

      return file;
    },

    onSuccess(files: any) {
      setFileList((prev: any) =>
        prev.map((item: any) => {
          const itemFullName = `${item.name}.${item.format}`;
          files &&
            files.forEach((file: any) => {
              if (file.name.toLocaleLowerCase() === itemFullName.toLocaleLowerCase()) {
                item.href = file.url;
                item.status = 'done';
                item.progress = 100;
              }
            });
          return item;
        }),
      );
    },
    onError(err: any) {
      console.error(err);
      message.error(`Что-то пошло не так: ${err}`);
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
    },
    customRequest: async (props: any) => {
      const { file, onError, onSuccess, withCredentials } = props;

      const formData = new FormData();
      formData.append('file', file);
      formData.append('company_id', `${info.company.id}`);
      formData.append('type', dir);

      dispatch(
        setFileToCompany({
          request: formData,
          dir: dir,
        }),
      );
    },
  };

  const onSubmit = (data: any) => {
    fileList.length === 0 && setError(true);

    fileList.length > 0 &&
      dispatch(
        setCompanyStatus({
          request: { company_id: info.company.id, status_id: 2 },
          callback: () => {
            dispatch(setStatus('success'));
            dispatch(setSuccess({ type: 'company-verification' }));
          },
        }),
      );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form form-company-registration">
      <div className="form__body">
        <div className="form__container">
          <div className="form__container-title">
            <span>
              {t('Фото / Скан выписки с ЕДРПО')}
              {fileList.length === 0 && <span style={{ color: '#FF776F' }}>*</span>}
            </span>
            {fileList.length > 0 && (
              <Upload.Dragger {...props} name="files" className="uploader-icon-zone">
                <IconPlus />
              </Upload.Dragger>
            )}
          </div>
          {fileList.length === 0 && (
            <>
              <Upload.Dragger
                {...props}
                name="files"
                className={classNames('uploader-zone', { ['uploader-zone-error']: error })}
              >
                <div className="uploader-zone__text">
                  <IconUploader /> <span>{t('Загрузить файл')}</span>
                </div>
              </Upload.Dragger>
              {error && (
                <div className="uploader-zone__error-text">
                  <IconXError />
                  <span>{t('Загрузите, пожалуйста, подтверждающий документ')}</span>
                </div>
              )}
            </>
          )}
        </div>
        <div className="form__container">
          {fileList &&
            fileList.map((file: fileItemType) => (
              <Attachment
                key={file.id}
                file={file}
                isEditable
                isDownload
                showProgress
                onRemove={() => handleOnRemoveFile(file.id)}
              />
            ))}
        </div>
      </div>
      <div className="form-footer form-footer_submit">
        <BtnGoBack
          onClick={() => {
            navigate('/company-create');
          }}
        />
        <ButtonDefault name={t('Далее')} type={'submit'} />
      </div>
    </form>
  );
};

export default FormVerifyCompany;
