import axios, { AxiosResponse } from 'axios'
import { post } from '@api/api_helper'
import {getStoreToken} from '@tools/helpFunctions'

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['Accept'] = 'application/json';

export const api = {
	getOptions: async (data: any): Promise<AxiosResponse> => {
		try {
			return await post(`/options/get_selectList`, data)
		} catch (error: any) {
			throw new Error(error.response.data.error)
		}
	},

	setFile: async (data: any): Promise<AxiosResponse> => {
		return axios({
			url: `https://api.prof.world/v2.0/profile/uploadFile`,
			method: 'POST',
			headers: {
				'Content-Type': 'multipart/form-data',
				Accept: 'application/json',
				userToken: getStoreToken(),
			},
			data: data,
		})
	},
	// setFile: async (data: any): Promise<AxiosResponse> => {
	// 	console.log(data, 'asfasf')
	// 	try {
	// 		return await post(`/profile/uploadFile`, data, {
	// 			headers: {
	// 				'Content-Type': `multipart/form-data`,
	// 			},
	// 		})
	// 	} catch (error: any) {
	// 		throw new Error(error.response.data.error)
	// 	}
	// },
}
