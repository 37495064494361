import React, { FC, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import AppSelect from '@molecules/app/select';

const getNotFoundDescription = (list: any, name: any) => {
  return !list?.length && name?.length < 3 ? 'Введите более 2 символов' : 'Нет данных';
};

const AddressesFields = (props: any) => {
  const {
    formName,
    label,
    disabled,
    required,
    office,
    onClearCity,
    onSelectCity,
    onSearchCity,
    onSelectStreet,
    onClearStreet,
    onSearchStreet,
    onChangeStreetNumber,
  } = props;
  const { city_id, city_name, city_list, street_id, street_name, street_list } = office[formName];

  const [citiesLoading, setCitiesLoading] = useState(false);
  const [streetsLoading, setStreetsLoading] = useState(false);

  const handleOnSearchCity = async (name: any) => {
    setCitiesLoading(true);
    await onSearchCity(formName, name);
    setCitiesLoading(false);
  };
  const handleOnSearchStreet = async (name: any) => {
    setStreetsLoading(true);
    await onSearchStreet(formName, name);
    setStreetsLoading(false);
  };
  return (
    <>
      {label && (
        <div className="form__label_control">
          <p className={`form__label ${required ? 'form__label-required' : ''}`}>{label}</p>
        </div>
      )}

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item name={`${formName}_city`} rules={[{ required: required, message: 'Поле обязательное' }]}>
            <AppSelect
              value={city_name}
              placeholder="Город"
              autoComplete="off"
              disabled={disabled}
              dropdownMatchSelectWidth={350}
              notFoundDescription={getNotFoundDescription(city_list, city_name)}
              onClear={() => onClearCity(formName)}
              onSelect={(option: any) => onSelectCity(formName, option)}
              onSearch={handleOnSearchCity}
              options={city_list}
              isLoading={citiesLoading}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={`${formName}_street`}
            rules={[{ required: city_id && required, message: 'Поле обязательное' }]}
          >
            <AppSelect
              placeholder="Улица"
              autoComplete="off"
              disabled={!city_id}
              dropdownMatchSelectWidth={350}
              notFoundDescription={getNotFoundDescription(street_list, street_name)}
              onClear={() => onClearStreet(formName)}
              onSelect={(option: any) => onSelectStreet(formName, option)}
              onSearch={handleOnSearchStreet}
              options={street_list}
              isLoading={streetsLoading}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={`${formName}_street_number`}
            rules={[{ required: street_id && required, message: 'Поле обязательное' }]}
          >
            <Input
              onChange={(e) => onChangeStreetNumber(formName, e.target.value)}
              autoComplete="off"
              disabled={!street_id}
              className="form__input"
              placeholder="Номер улици"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default AddressesFields;
