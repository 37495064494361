import React, { FC, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// components
import AsideTemplate from '@templates/asideTemplate';
import FormCode from '@organisms/forms/code/confirmPhoneResetPassword';
import FormHeader from '@atoms/text/formHeader';
// actions
import { appPage } from '@redux/reducers/app';
// types
import { stateType } from '@types';

const PageForgotByPhone: FC = () => {
  const { page } = useSelector((state: stateType) => state.app);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('forgot-phone')) {
      dispatch(appPage('forgot-phone'));
      return;
    }

    navigate('/');
  }, []);

  return (
    <>
      <Helmet title={page} />
      <AsideTemplate showBenefits center page={page}>
        <FormHeader type={'forgotByPhone'} data={{ phone: localStorage.getItem('forgot-phone') }} />
        <FormCode />
      </AsideTemplate>
    </>
  );
};

export default PageForgotByPhone;
