import React, { FC } from 'react';
// styles
import './style.scss';

interface FormNameType {
  label: string;
}

const FormName: FC<FormNameType> = ({ label }: FormNameType) => {
  return <div className="form-name">{label}</div>;
};

export default FormName;
