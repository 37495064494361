import { createPhoneMask } from '@tools/helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useFormHeader: any = (type: any, data: any) => {
  const [titles, setTitles] = useState<any>({ title: '', desc: '' });

  console.log(type)
  const { t } = useTranslation();
  useEffect(() => {
    switch (type) {
      case 'forgot':
        setTitles({
          title: t('Забыли пароль?'),
          desc: t('Вам будет отправлена инструкция по восстановлению пароля'),
        });
        break;

      case 'reset':
        setTitles({ title: t('Восстановление пароля'), desc: '' });
        break;

      case 'code':
        setTitles({
          title: t('Подтверждение входа'),
          desc: t('Мы отправили SMS с 6-значным кодом', { phone: '+38 (050) 725 60 09' }),
        });
        break;

      case 'confirmPhone':
        setTitles({
          title: t('Подтверждение телефона'),
          desc: t('Мы отправили SMS с 6-значным кодом', { phone: data?.phone && createPhoneMask(data?.phone) }),
        });
        break;

      case 'forgotByPhone':
        setTitles({
          title: t('Подтверждение телефона'),
          desc: t('Мы отправили SMS с 6-значным кодом', { phone: data?.phone && createPhoneMask(data?.phone) }),
        });
        break;
      case 'resetPasswordByPhone':
        setTitles({
          title: t('Восстановление пароля'),
          desc: t('Мы отправили SMS с 6-значным кодом', { phone: data?.phone && createPhoneMask(data?.phone) }),
        });
        break;

      default:
        setTitles({ title: '', desc: '' });
        break;
    }
  }, [type, t]);

  return { titles };
};

export default useFormHeader;
