import React, { FC } from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

interface AppTitleType {
  title: string;
  level: any;
  style?: React.CSSProperties;
}

const AppTitle: FC<AppTitleType> = ({ title, level, style }: AppTitleType) => {
  return (
    <Title level={level} style={{ color: '#ffffff', fontWeight: 700, ...style }}>
      {title}
    </Title>
  );
};

export default AppTitle;
