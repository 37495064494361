import React from 'react'
import classNames from 'classnames'
import {Select} from 'antd'
import IconDropDownArrow from '@assets/icons/select/dropDownArrow'
import {useTranslation} from 'react-i18next'
import './styles.scss'

const LanguageAndRegion = () => {

    const { t } = useTranslation();

    const languageOptions = [
        {
            value: 'ru',
            label: 'Русский'
        },
        {
            value: 'ua',
            label: 'Українська'
        },
        {
            value: 'en',
            label: 'English'
        }
    ]

    const handlerLanguageChange = (value) => {
        localStorage.setItem('lang', value);
        window.location.reload();
    }

    const defaultLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ru';

    return (
        <div className='languageForm'>
            <div className={'input-default'}>
                <label>
                    {t('Язык')}
                </label>
                <div className="input-default__input">
                    <Select
                        className={'form-select'}
                        popupClassName="form-dropdown"
                        bordered={false}
                        onChange={handlerLanguageChange}
                        style={{ width: '100%', height: '40px' }}
                        defaultValue={defaultLang}
                        options={languageOptions}
                        allowClear={false}
                        suffixIcon={<IconDropDownArrow />}
                    />
                </div>
            </div>
        </div>
    )
}

export default LanguageAndRegion
