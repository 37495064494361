import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// components
import AppSearchInput from '@molecules/app/search';
import AppMessage from '@molecules/app/message';
import DropdownLang from '../dropdwons/lang';
import DropdownChoiceCompany from '../dropdwons/companyChoice';
import DropdwonProfile from '../dropdwons/profile';
// actiosn
import { getCompanies } from '@redux/reducers/company';
// icons
import IconMessage from '@assets/image/icons/header/message';
import IconBell from '@assets/image/icons/header/bell';
// types
// import { stateType } from '@types';
// styles
import './style.scss';
import NotificationsModal from '@organisms/modals/app/notification';
import { stateType } from '@types';
import { useSelector } from 'react-redux';

// import useAppReset from '@tools/hooks/useAppReset';

const AppHeader: FC = () => {
  // const resetApp = useAppReset();
  // const { info } = useSelector((state: stateType) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanies({ request: { type: 'active' } }));
  }, []);

  return (
    <header className="header">
      <AppSearchInput />
      <div className="header__container">
        <DropdownChoiceCompany />
        <AppMessage img={IconBell} active />
        <AppMessage img={IconMessage} active />
        <DropdownLang />
        <DropdwonProfile />
      </div>
    </header>
  );
};

export default AppHeader;

export const LcAppHeader: FC = () => {
  // const resetApp = useAppReset();
  const { info } = useSelector((state: stateType) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanies({ request: { type: 'active' } }));
  }, []);

  return (
    <header className="header">
      <AppSearchInput modal />
      <div className="header__container">
        <NotificationsModal
          component={IconBell}
          active
          list={info?.verifier?.notificationList ? info?.verifier?.notificationList : []}
        />
        <AppMessage img={IconMessage} active />
        <DropdownLang />
        <DropdwonProfile />
      </div>
    </header>
  );
};

/*
      <AppMessage active img={IconBell} />
        <AppMessage active img={IconMessage} />
        <ButtonDefault
          action={() => {
            dispatch(
              logoutFromCompany({
                request: { company_id: info.company.id },
                callback: () => {
                  dispatch(setStatus('companies-profile'));
                  navigate('/companies');
                },
              }),
            );
          }}
          name="Выйти с компании"
        />
*/
