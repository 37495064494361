import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// components
// import HomeTemplate from '@templates/homeTemplate';

const Testing: React.FC = () => {
  useEffect(() => {
    console.log('Testing PAGE');
  }, []);

  return <>Testing</>;
};

export default Testing;
