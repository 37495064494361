import React, { FC } from 'react';
// components
import CounterBirth from '@molecules/counters/birth';
import CounterOnline from '@molecules/counters/online';
// styles
import './style.scss';

const CountersBlock: FC = () => {
  return (
    <div className="counters-block">
      <CounterBirth />
      <CounterOnline />
    </div>
  );
};

export default CountersBlock;
