import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
// configs
import { columns } from '@configs/tables/vacancies';
// style
import './style.scss';

const HomeDataTable: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="table-wrapper">
      <Table
        locale={{
          emptyText: <div className="app-table__empty">{t('Нет данных. Ожидайте подтверждения верификации')}</div>,
        }}
        className="app-table"
        size="middle"
        columns={columns}
        dataSource={[]}
        title={Title}
      />
    </div>
  );
};

export default HomeDataTable;

const Title = () => <span className="app-table__title">Актуальные данные</span>;

const Footer = () => {
  const goToMyCompany = () => {
    console.log('goToMyCompany');
  };

  return (
    <span>
      Нет данных. Заполните информацию о{' '}
      <span onClick={goToMyCompany} className="link">
        Компании
      </span>
    </span>
  );
};
