import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// actions
import { setAppTitles } from '@redux/reducers/app';
import { useSelector } from 'react-redux';
import { stateType } from '@types';

type TypeTitles = {
  title: string;
  subTitle: string;
};

const useAsideHook = (page?: string): TypeTitles => {
  const { appTitles } = useSelector((state: stateType) => state.app);
  const [titles, setTitles] = useState<{ title: string; subTitle: string }>({
    title: appTitles ? appTitles.title : '',
    subTitle: appTitles ? appTitles.subTitle : '',
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppTitles(titles));
  }, [titles]);

  useEffect(() => {
    switch (page) {
      case 'login':
        return setTitles({
          title: t('Войти в аккаунт'),
          subTitle: t('Войти в аккаунт description'),
        });
      case 'registration':
        return setTitles({
          title: t('Регистрация аккаунта'),
          subTitle: t('Регистрация аккаунта description'),
        });
      case 'user-registration':
        return setTitles({
          title: t('Регистрация пользователя'),
          subTitle: t('Регистрация пользователя description'),
        });
      case 'forgot':
        return setTitles({
          title: t('Восстановить пароль'),
          subTitle: t('Восстановить пароль description'),
        });

      case 'recovery':
        return setTitles({
          title: t('Восстановление пароля'),
          subTitle: t('Восстановление пароля description'),
        });

      case 'confirm-phone':
        return setTitles({
          title: t('Подтверждение телефона'),
          subTitle: t('Мы отправили SMS с 6-значным кодом подтверждения:'),
        });

      case 'companies-profile':
        return setTitles({
          title: t('Профиль Компаний'),
          subTitle: t('Профиль Компаний description'),
        });

      case 'company-create':
        return setTitles({
          title: t('Регистрация Компании'),
          subTitle: t('Регистрация Компании description'),
        });

      case 'company-verification':
        return setTitles({
          title: t('Регистрация Компании'),
          subTitle: t('Верифицируйте данные компании о своей Компании'),
        });

      case '404':
        return setTitles({
          title: '404',
          subTitle: 'Страница не найдена Страница не найдена Страница не найдена ...',
        });

      case 'company-registration-step-1':
        return setTitles({
          title: 'Регистрация Компании',
          subTitle: 'Заполните информацию о своей Компании, чтобы начать использовать все преимущества платформы:',
        });

      case 'company-registration-step-2':
        return setTitles({
          title: 'Регистрация Администратора',
          subTitle: 'Заполните информацию о себе, чтобы начать использовать все преимущества платформы:',
        });

      case 'company-confirm':
        return setTitles({
          title: 'Продолжение регистрации',
          subTitle: 'Верифицируйте данные компании, чтобы получить полноценный доступ к платформе:',
        });

      case 'add-deputy':
        return setTitles({
          title: 'Регистрация Заместителя',
          subTitle: 'Назначьте заместителя, чтобы дать ему доступ к функционалу платформы:',
        });

      default:
        return setTitles({
          title: t('Войти в аккаунт'),
          subTitle: t('Войти в аккаунт description'),
        });
    }
  }, [page, t]);
  return titles;
};

export default useAsideHook;
