import React, { FC } from 'react'
import { Dropdown } from 'antd'
import { useTranslation } from 'react-i18next'
import '../style.scss'
import './styles.scss'
import { IconLink } from '@assets/icons/statsInfo'
import { useNavigate } from 'react-router-dom'

const DropdownSearchCompanies: FC<any> = ({
	setModalOpen,
	setDropdownOpen,
	dropdownOpen,
	result: { all = [] } = [],
	searchValue,
}: any) => {
	return (
		<div className='dropdown-search'>
			<Dropdown
				className={'dropdown companies'}
				overlay={
					<DropdownOverlay
						setModalOpen={setModalOpen}
						setDropdownOpen={setDropdownOpen}
						data={all}
						searchValue={searchValue}
					/>
				}
				trigger={['click']}
				open={dropdownOpen}
				onOpenChange={(data: any) => setDropdownOpen(data)}
			>
				<div
					className='dropdown__triger'
					onClick={(e) => {
						e.preventDefault()
						setDropdownOpen(true)
					}}
				/>
			</Dropdown>
		</div>
	)
}

export default DropdownSearchCompanies

const DropdownOverlay = ({ setModalOpen, setDropdownOpen, data = [], searchValue }: any) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const getHighlightedText = (text, highlight) => {
		const parts = text.split(new RegExp(`(${highlight})`, 'i'))
		return parts.map((part, index) => (
			<React.Fragment key={index}>
				{part.toLowerCase() === highlight.toLowerCase() ? (
					<span style={{ color: '#3843ED' }}>{part}</span>
				) : (
					part
				)}
			</React.Fragment>
		))
	}

	let result;

	switch (true) {
		case data.length === 1:
			result = t('результат');
			break;
		case data.length >= 2 && data.length <= 4:
			result = t('результата');
			break;
		case data.length >= 5:
			result = t('результатов');
			break;
		default:
			result = t('результатов');
	}

	return (
		<div className='dropdown-overlay'>
			{data.length > 0 ? (
				<>
					<div className='dropdown-overlay__item' onClick={() => {}}>
						<span className='dropdown-overlay__text'>
							{data.length} {result}
						</span>
					</div>

					<div className='dropdown-overlay__content'>
						{data.map((item) => {
							return (
								<div
									key={item.id}
									className='dropdown-overlay__item'
									onClick={() => navigate(`/lc-companies/company/${item.id}`)}
								>
									<span className='dropdown-overlay__text'>
										{getHighlightedText(item.name, searchValue)}
									</span>
								</div>
							)
						})}
					</div>
				</>
			) : (

				<div className='dropdown-overlay__item'>
					<span className='dropdown-overlay__text'>{t('Нет результатов')}</span>
				</div>
			)}

			<div
				className='dropdown-overlay__item'
				onClick={() => {
					setDropdownOpen(false)
					setTimeout(() => {
						setModalOpen(true)
					}, 100)
				}}
			>
				<span className='dropdown-overlay__text'>{t('Расширенный поиск')}</span>
				<IconLink />
			</div>

		</div>
	)
}
