import React, { FC } from 'react';
// images
import image from '@image/loaders/companies.png';
// styles
import './style.scss';

const SkeletonCompanies: FC = () => {
  return (
    <div className="skeleton-companies">
      <div className="skeleton-companies__mask" />
      <img className="skeleton-companies__img" src={image} alt="skeletonCompanies" />
    </div>
  );
};

export default SkeletonCompanies;
