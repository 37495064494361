import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// components
import InfoBlock from '@molecules/InfoBlock';
// style
import './style.scss';
import { initLangFormMomment } from '@tools/helpFunctions';

interface DeleteCompanyModalType {
  component: any;
  action?: any;
  active?: boolean;
  list?: any;
}

const NotificationsModal: FC<DeleteCompanyModalType> = ({
  component: Component,
  action,
  active,
  list,
}: DeleteCompanyModalType) => {
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);

  const { t } = useTranslation();

  const onCancel = () => {
    setVisible(false);
    setLoader(false);
  };

  const call = () => {
    setLoader(true);
    action(onCancel);
  };

  return (
    <>
      <div className="modal-notification-component">
        <Component
          onClick={() => {
            console.log('ping');
            setVisible(true);
          }}
        />
        {active && <div className="modal-notification-component__active" />}
      </div>

      <Modal
        className="modal-notification"
        open={visible}
        width={505}
        footer={null}
        closable={true}
        bodyStyle={{ padding: '24px' }}
        centered
        onCancel={onCancel}
        maskStyle={{ backgroundColor: 'rgba(220, 222, 252, 0.6)' }}
      >
        <div className="modal-notification__body">
          <div className="modal-notification__title">{t('Уведомления')}</div>
          <div className="modal-notification__list">
            {list &&
              list.map((item: any) => {
                return (
                  <InfoBlock
                    key={item.id}
                    classes={'modal-notification__item'}
                    title={item.name}
                    subtitle={moment(item.update_date)
                      .locale(initLangFormMomment(localStorage.getItem('lang')))
                      .format('DD MMMM YYYY · hh:mm:ss')}
                    isWarning
                    type={'new'}
                  />
                );
              })}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NotificationsModal;
