import React from 'react';

function TestIcon() {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.28582 0.857178C5.83116 0.857178 5.39513 1.03779 5.07364 1.35928C4.75214 1.68077 4.57153 2.11681 4.57153 2.57146V3.71432C4.57153 4.16898 4.75214 4.60501 5.07364 4.9265C5.39513 5.24799 5.83116 5.42861 6.28582 5.42861H9.71439C10.169 5.42861 10.6051 5.24799 10.9266 4.9265C11.2481 4.60501 11.4287 4.16898 11.4287 3.71432V2.57146C11.4287 2.11681 11.2481 1.68077 10.9266 1.35928C10.6051 1.03779 10.169 0.857178 9.71439 0.857178L6.28582 0.857178ZM9.71439 2.00003C9.86594 2.00003 10.0113 2.06024 10.1185 2.1674C10.2256 2.27457 10.2858 2.41991 10.2858 2.57146V3.71432C10.2858 3.86587 10.2256 4.01122 10.1185 4.11838C10.0113 4.22555 9.86594 4.28575 9.71439 4.28575H6.28582C6.13427 4.28575 5.98892 4.22555 5.88176 4.11838C5.77459 4.01122 5.71439 3.86587 5.71439 3.71432V2.57146C5.71439 2.41991 5.77459 2.27457 5.88176 2.1674C5.98892 2.06024 6.13427 2.00003 6.28582 2.00003H9.71439Z"
        fill="currentColor"
      />
      <path
        d="M3.42857 2.57141H2.28571C1.67951 2.57141 1.09812 2.81223 0.66947 3.24088C0.240816 3.66954 0 4.25092 0 4.85713V16.8571C0 17.4633 0.240816 18.0447 0.66947 18.4734C1.09812 18.902 1.67951 19.1428 2.28571 19.1428H13.7143C14.3205 19.1428 14.9019 18.902 15.3305 18.4734C15.7592 18.0447 16 17.4633 16 16.8571V4.85713C16 4.25092 15.7592 3.66954 15.3305 3.24088C14.9019 2.81223 14.3205 2.57141 13.7143 2.57141H12.5714V3.71427C12.5714 4.08947 12.4975 4.461 12.3539 4.80765C12.2104 5.15429 11.9999 5.46926 11.7346 5.73457C11.4693 5.99988 11.1543 6.21034 10.8077 6.35392C10.461 6.49751 10.0895 6.57141 9.71429 6.57141H6.28571C5.52795 6.57141 4.80123 6.27039 4.26541 5.73457C3.72959 5.19875 3.42857 4.47203 3.42857 3.71427V2.57141ZM11.2617 10.976L7.83314 14.4046C7.78006 14.4578 7.717 14.5 7.64758 14.5288C7.57816 14.5576 7.50373 14.5724 7.42857 14.5724C7.35341 14.5724 7.27898 14.5576 7.20956 14.5288C7.14014 14.5 7.07708 14.4578 7.024 14.4046L5.30971 12.6903C5.20242 12.583 5.14214 12.4374 5.14214 12.2857C5.14214 12.134 5.20242 11.9884 5.30971 11.8811C5.41701 11.7738 5.56254 11.7135 5.71429 11.7135C5.86603 11.7135 6.01156 11.7738 6.11886 11.8811L7.42857 13.192L10.4526 10.1668C10.5599 10.0595 10.7054 9.99926 10.8571 9.99926C11.0089 9.99926 11.1544 10.0595 11.2617 10.1668C11.369 10.2741 11.4293 10.4197 11.4293 10.5714C11.4293 10.7232 11.369 10.8687 11.2617 10.976V10.976Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default TestIcon;
