import React, { FC, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
// components
import FormForgotPassword from '@organisms/forms/forgot';
import AsideTemplate from '@templates/asideTemplate';
// types
import { stateType } from '@types';
import FormHeader from '@atoms/text/formHeader';
import { appPage } from '@redux/reducers/app';

const Authentication: FC = () => {
  const { page } = useSelector((state: stateType) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appPage('forgot'));
  }, []);

  return (
    <>
      <Helmet title={page} />
      <AsideTemplate showBenefits center page={page}>
        <FormHeader type={'forgot'} />
        <FormForgotPassword />
      </AsideTemplate>
    </>
  );
};

export default Authentication;
