import React, { FC } from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

interface AppTextType {
  text: string;
  style: React.CSSProperties;
}

const AppText: FC<AppTextType> = ({ text, style }: AppTextType) => {
  return <Text style={{ color: '#ffffff', fontWeight: 400, ...style }}>{text}</Text>;
};

export default AppText;
