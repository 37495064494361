import React, { FC, useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import classNames from 'classnames';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
// tools
import { passwordGenerator } from '@tools/helpers';
// icons
import IconKey from '@assets/icons/inputs/key';
import IconRotate from '@assets/icons/inputs/rotate';
import IconCopy from '@assets/icons/inputs/copy';
// styles
import './style.scss';

interface GeneratePasswordType {
  setValue: UseFormSetValue<any>;
}

const GeneratePassword: FC<GeneratePasswordType> = ({ setValue }: GeneratePasswordType) => {
  const [show, setShow] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    setPassword(passwordGenerator());
  }, []);

  const action = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setValue('password', password, { shouldValidate: true, shouldDirty: true });
    setValue('password_repeat', password, { shouldValidate: true, shouldDirty: true });
    setShow(false);
  };

  const refresh = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setPassword(passwordGenerator());
  };

  return (
    <>
      <div className="form__container-icon" onClick={() => setShow(!show)}>
        <IconKey color={show ? '#4E5AF2' : '#D2D8E8'} />
      </div>
      {show && (
        <div className={classNames('container-dropdown', { ['animation-dropdown']: show })}>
          <div className="container-dropdown_value">
            <span>{password}</span>
            <div className="container-dropdown_icons">
              <IconCopy
                onClick={() => {
                  message.success({ content: t('Пароль скопирован'), duration: 4 });
                  navigator.clipboard.writeText(password);
                }}
              />
              <IconRotate onClick={refresh} />
            </div>
          </div>
          <div className="container-dropdown_label" onClick={action}>
            {t('Применить сгенерированный пароль')}
          </div>
        </div>
      )}
    </>
  );
};

export default GeneratePassword;
