import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { createPhoneMask } from '@tools/helpers';
import ReactHtmlParser from 'react-html-parser';
// components
import FormCode from '@organisms/forms/code/confirmRecoveryCompany';
// style
import './style.scss';

interface DeleteCompanyModalType {
  component: any;
  action?: any;
  phone: string;
}

const DeleteCompanyModal: FC<DeleteCompanyModalType> = ({
  component: Component,
  action,
  phone,
}: DeleteCompanyModalType) => {
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);

  const { t } = useTranslation();

  const onCancel = () => {
    setVisible(false);
    setLoader(false);
  };

  const call = () => {
    setLoader(true);
    action(onCancel);
  };

  return (
    <>
      <Component
        onClick={() => {
          setVisible(true);
        }}
      />
      <Modal
        className="modal-confirm"
        open={visible}
        width={504}
        footer={null}
        closable={true}
        bodyStyle={{ padding: '64px' }}
        centered
        onCancel={onCancel}
      >
        <div className="modal-confirm__body">
          <div className="modal-confirm__title">{t('Подтверждение удаления Компании')}</div>
          <div className="modal-confirm__desc">
            {ReactHtmlParser(t('Мы отправили SMS с 6-значным кодом', { phone: phone ? createPhoneMask(phone) : '' }))}
          </div>
          <FormCode action={call} loading={loader} />
          <button className="btn-default btn-clasic" onClick={onCancel}>
            {t('Отменить')}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteCompanyModal;
