import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
// import { ErrorBoundary } from 'react-error-boundary';
import i18n from '@src/i18n';
// import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
// actions
import { setAppLang } from '@redux/reducers/app';
// Components
import AuthWrapper from './AuthWrapper';
import PageWrapper from './PageWrapper';
import CreateCompanyWrapper from './CreateCompanyWrapper';
import AuthCompanyWrapper from './AuthCompanyWrapper';
import UserRegistrationWrapper from './UserRegistrationWrapper';
import LcVerificationWrapper from './LcVerificationWrapper';
// Pages
import Settings from '@pages/settings';
import Authentication from '@pages/authentication';
import CompanyRegistration from '@pages/companyRegisrtration';
import RecoveryPass from '@pages/authentication/recovery';
import Forgot from '@pages/authentication/forgot';
import PageConfirmPhone from '@pages/authentication/confirmPhone';
import VerifyCompany from '@pages/companyVerify';
import AddDeputy from '@pages/deputy/add';
import AddVacancy from '@pages/vacancy/add';
import CompanyRegistrationOther from '@pages/companyRegisrtration/other';
import UserRegistration from '@pages/user/registration';
import PageForgotByPhone from '@pages/authentication/forgotByPhone';
import CompaniesProfile from '@pages/companies';
import CompanyCreate from '@pages/companies/create';
import CompanyVerification from '@pages/companies/verification';
import Page404 from '@pages/app/404';
import LcVerificationCompanies from '@pages/lcVerification/companies';
import LcVerificationCompany from '@pages/lcVerification/company';
// tools
import { menuLcVerificationLists, menuLists } from '@tools/menu';
// Styles
// import 'antd/dist/antd.min.css';
// import 'antd/dist/antd.less';
import '@assets/main.scss';
import 'moment/locale/uk';
import 'moment/locale/ru';
import ViewFilesModal from '@organisms/modals/viewFile/ViewFilesModal'

// lazy
// const Authentication = React.lazy(() => import('@pages/authentication'));
// const Forgot = React.lazy(() => import('@pages/authentication/forgot'));

const App: FC = () => {
  const dispatch = useDispatch();

  // TODO example connect to ES
  //const url = `http://api.prof.world/v2.0/sse/userOnline/?userToken=${localStorage.getItem('token')}`;
  //const es = new EventSource(url, {});
  //console.log(es);

  useEffect(() => {
    let lang: any = 'ru';

    if (localStorage.getItem('lang')) {
      lang = localStorage.getItem('lang');
      i18n.changeLanguage(lang);
      moment.locale(lang);
      dispatch(setAppLang(lang));
    }
  }, []);

  return (
    <Routes>
      <Route
        path="/auth"
        element={
          <AuthWrapper>
            <Authentication />
          </AuthWrapper>
        }
      />
      <Route
        path="/forgot"
        element={
          <AuthWrapper>
            <Forgot />
          </AuthWrapper>
        }
      />
      <Route
        path="/forgot-by-phone"
        element={
          <AuthWrapper>
            <PageForgotByPhone />
          </AuthWrapper>
        }
      />
      <Route path="/confirm-phone" element={<PageConfirmPhone />} />
      <Route
        path="/recovery-password"
        element={
          <AuthWrapper>
            <RecoveryPass />
          </AuthWrapper>
        }
      />
      <Route
        path="/companies"
        element={
          <AuthCompanyWrapper>
            <CompaniesProfile />
          </AuthCompanyWrapper>
        }
      />
      <Route
        path="/companies/:sort"
        element={
          <AuthCompanyWrapper>
            <CompaniesProfile />
          </AuthCompanyWrapper>
        }
      />
      <Route
        path="/company-create"
        element={
          <CreateCompanyWrapper showTemplate>
            <CompanyCreate />
          </CreateCompanyWrapper>
        }
      />
      <Route
        path="/company-create/verification"
        element={
          <CreateCompanyWrapper showTemplate>
            <CompanyVerification />
          </CreateCompanyWrapper>
        }
      />
      <Route
        path="/company-registration-main"
        element={
          <PageWrapper>
            <CompanyRegistration />
          </PageWrapper>
        }
      />
      <Route
        path="/profile-create"
        element={
          <UserRegistrationWrapper>
            <UserRegistration />
          </UserRegistrationWrapper>
        }
      />
      <Route
        path="/company-verify"
        element={
          <PageWrapper>
            <VerifyCompany />
          </PageWrapper>
        }
      />
      <Route
        path="/add-vacancy"
        element={
          <PageWrapper>
            <AddVacancy />
          </PageWrapper>
        }
      />
      <Route
        path="/add-deputy"
        element={
          <PageWrapper>
            <AddDeputy />
          </PageWrapper>
        }
      />
      <Route
        path="/company-registration"
        element={
          <PageWrapper>
            <CompanyRegistrationOther />
          </PageWrapper>
        }
      />

      {RouterMenuList()}
      {RouterLcVerificationMenuList()}

      <Route
        path={'/settings/:tab'}
        element={
          <PageWrapper showTemplate>
            <Settings />
          </PageWrapper>
        }
      />

      <Route
        path={'/lc-companies'}
        element={
          <LcVerificationWrapper showTemplate showHeader>
            <LcVerificationCompanies />
          </LcVerificationWrapper>
        }
      />

      <Route
        path={'/lc-companies/:tab'}
        element={
          <LcVerificationWrapper showTemplate showHeader>
            <LcVerificationCompanies />
          </LcVerificationWrapper>
        }
      />

      <Route
        path={'/lc-companies/company/:id'}
        element={
          <LcVerificationWrapper showTemplate>
            <LcVerificationCompany />
          </LcVerificationWrapper>
        }
      />

        <Route
            path={'/lc-companies/company/:id/files/view'}
            element={
                <ViewFilesModal/>
            }
        />

      <Route
        path={'/lc-companies/company/:id/:tab_company'}
        element={
          <LcVerificationWrapper showTemplate>
            <LcVerificationCompany />
          </LcVerificationWrapper>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default App;

const RouterMenuList = () => {
  return menuLists.map(({ key, link, component }) => {
    return <Route key={key} path={link} element={<PageWrapper showTemplate>{component}</PageWrapper>} />;
  });
};

const RouterLcVerificationMenuList = () => {
  return menuLcVerificationLists.map(({ key, link, component }) => {
    return (
      <Route
        key={key}
        path={link}
        element={
          <LcVerificationWrapper showTemplate showHeader>
            {component}
          </LcVerificationWrapper>
        }
      />
    );
  });
};
