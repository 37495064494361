import React, { FC } from 'react';

export const IconLcEdit: FC<any> = ({ onClick }: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.52001 2.52986C10.0371 1.91186 10.9667 1.82124 11.5975 2.32782C11.6324 2.35531 12.753 3.22586 12.753 3.22586C13.446 3.64479 13.6613 4.5354 13.2329 5.21506C13.2102 5.25146 6.87463 13.1763 6.87463 13.1763C6.66385 13.4393 6.34389 13.5945 6.00194 13.5982L3.57569 13.6287L3.02902 11.3149C2.95244 10.9895 3.02902 10.6478 3.2398 10.3849L9.52001 2.52986Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.34766 4.00098L11.9825 6.79239"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconLcRemove: FC<any> = ({ onClick }: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5247 3.49535C13.7841 3.49535 14 3.71068 14 3.98468V4.23801C14 4.50534 13.7841 4.72734 13.5247 4.72734H2.4759C2.21591 4.72734 2 4.50534 2 4.23801V3.98468C2 3.71068 2.21591 3.49535 2.4759 3.49535H4.41971C4.81457 3.49535 5.1582 3.21469 5.24703 2.81869L5.34882 2.36403C5.50702 1.7447 6.02766 1.33337 6.62351 1.33337H9.37649C9.96585 1.33337 10.4923 1.7447 10.6447 2.33136L10.7536 2.81803C10.8418 3.21469 11.1854 3.49535 11.5809 3.49535H13.5247ZM12.5372 12.7561C12.7402 10.8648 13.0955 6.37147 13.0955 6.32614C13.1084 6.18881 13.0637 6.05881 12.9749 5.95414C12.8796 5.85614 12.759 5.79814 12.626 5.79814H3.37901C3.24545 5.79814 3.11837 5.85614 3.03019 5.95414C2.94071 6.05881 2.89662 6.18881 2.90311 6.32614C2.9043 6.33447 2.91705 6.49275 2.93836 6.75736C3.03305 7.93286 3.29678 11.2068 3.46719 12.7561C3.58779 13.8974 4.33665 14.6147 5.42137 14.6407C6.25841 14.6601 7.12074 14.6667 8.00252 14.6667C8.83308 14.6667 9.67661 14.6601 10.5396 14.6407C11.6619 14.6214 12.4101 13.9167 12.5372 12.7561Z"
      />
    </svg>
  );
};
