import React, { FC } from 'react';

const IconSpinner2: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.7344 5.9399C16.8883 4.22938 15.5162 2.83514 13.8194 1.9619C12.1347 1.09692 10.214 0.804827 8.34837 1.1299C6.47699 1.45896 4.76247 2.38516 3.46137 3.7699C2.14825 5.16774 1.31218 6.94595 1.07337 8.8489C0.830894 10.7562 1.19623 12.6913 2.11737 14.3789C3.02986 16.0534 4.45583 17.3907 6.18538 18.1939C7.90368 18.9897 9.83473 19.2032 11.6854 18.8019C13.5354 18.4009 15.0524 17.4889 16.3054 16.0469C16.7945 15.5141 17.2053 14.9145 17.5254 14.2659"
        stroke="#DCDEFC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.069 14.1867L18.109 13.2797L19 18.4097"
        stroke="#DCDEFC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconSpinner2;
