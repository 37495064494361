import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// components
// import HomeTemplate from '@templates/homeTemplate';

const Surveys: React.FC = () => {
  useEffect(() => {
    console.log('Surveys PAGE');
  }, []);

  return <>Surveys</>;
};

export default Surveys;
