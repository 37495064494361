import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from '@src/i18n';
// components
import InputDefault from '@atoms/inputs';
import ButtonDefault from '@atoms/buttons/button';
import TimeCountdown from '@molecules/timeCountdown';
// actions
import { profileConfirmPhone, updateUser } from '@redux/reducers/user';
// helps
import { codeSchema } from '@tools/shemas';
// styles
import './style.scss';
import NOTIFICATION from '@redux/sagas/notification'
import {message} from 'antd'

interface FormData {
  code: string;
}

interface FormCode {
  callback?: any;
}

const FormCode: FC<any> = ({ action, loading, setVisible }: any) => {
  const schema = useMemo(() => codeSchema(), [i18n.language]);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = async (data: FormData) => {
    const { code } = data;
    const request = {
      confirm_phone_code: Number(code),
    };

    dispatch(
      profileConfirmPhone({
        request,
        callback: () => {
          dispatch(updateUser({ is_confirm_phone: 1 }));
          setVisible(false)
          message.success({ content: t('Телефон успешно подтверждён'), duration: 4 });
        },
      }),
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form code-form">
        <div className="form__container">
          <InputDefault
            name={'code'}
            label={t('SMS-код')}
            placeholder={t('Укажите код')}
            error={errors.code?.message}
            register={register}
            control={control}
          />
          <div className="form__container-links">
            <TimeCountdown />
          </div>
        </div>
        <div className="form__container">
          <ButtonDefault name={t('Подтвердить')} type={'submit'} loading={loading} disabled={loading} />
        </div>
      </form>
    </>
  );
};

export default FormCode;
