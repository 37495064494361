// import { getDropDownList } from '@redux/actions/dropDownList-actions';
import { message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
// api
import { api } from '@api/api';
import { getOptions } from '@redux/reducers/app';
import { getDropDownList } from '@redux/actions/dropDownList-actions';

export const useAddItemToDropDownList = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  return async (listName, name) => {
    if (listName && name) {
      try {
        await api.addDropDownList({ token, listName, itemName: name });
        await dispatch(getOptions(token));
      } catch (e) {
        console.error(e);
        message.error(e.message);
      }
    } else {
      message.warn('Ошибка!');
    }
  };
};
