import React, {FC, useEffect, useMemo, useRef, useState} from 'react'
import moment from 'moment';
import i18n from '@src/i18n';
import { message, Tooltip } from 'antd';
import { useForm, useFieldArray } from 'react-hook-form';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// components
import InputDefault, {
	InputDatePicker,
	InputDropdownPrefix,
	InputMaskDefault,
	InputSelectDefault,
} from '@atoms/inputs';
import ButtonDefault from '@atoms/buttons/button';
import PhoneProfileModal from '@organisms/modals/confirms/phoneProfile';
import AddSocialModal from '@organisms/modals/settings/addSocial';
import RemoveEmailModal from '@organisms/modals/confirms/removeEmail';
import RemovePhoneModal from '@organisms/modals/confirms/removePhone';
import DropdownSocialsControlled from '@molecules/app/dropdwons/socials';
import RemoveSocialModal from '@organisms/modals/confirms/removeSocial';
import TooltipJobTitleBody from '@molecules/tooltips/jobTitle';
// actions
import { profileUpdate } from '@redux/reducers/user';
// helps
import { editUserSchema } from '@tools/shemas';
// types
import { stateType } from '@types';
// icons
import { IconRemoveInput } from '@assets/icons/modals/addUsers';
import { IconTooltipInfo } from '@assets/icons/nav/profileHeader';
// styles
import './style.scss';
import NOTIFICATION from '@redux/sagas/notification'

interface FormData {
	sex: any;
	email: string;
	birth_date: any;
	lname: string;
	name: string;
	sname: string;
	phone: string;
	gender_id: number;
	socials?: any;
	otherPhones: any;
	otherEmails: any;
	test?: any;
}
const EditProfile: FC<any> = ({ setVisible }: any) => {
	const [loading, setLoading] = useState<any>(false);
	const [openSocialModal, setOpenSocialModal] = useState<any>(false);
	const [openConfirmPhoneModal, setOpenConfirmPhoneModal] = useState<any>(false);

	const { info } = useSelector((state: stateType) => state.user);
	const { options } = useSelector((state: stateType) => state.app);
	const {
		birth_date,
		lname,
		name,
		sname,
		phone,
		email,
		gender_id,
		is_confirm_phone,
		otherEmails,
		otherPhones,
		socials = [],
		is_verifier,
	} = info;

	const schema = useMemo(() => editUserSchema(), [i18n.language]);

	const {
		register,
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
		getValues
	} = useForm<FormData>({
		defaultValues: {
			birth_date: birth_date ? moment(birth_date, 'YYYY-MM-DD') : '',
			lname: lname ? lname : '',
			name: name ? name : '',
			sname: sname ? sname : '',
			phone: phone ? phone : '',
			email: email ? email : '',
			gender_id: gender_id ? gender_id : '',
			otherEmails: otherEmails
				? otherEmails.map((el: any) => {
					return { other_email: el };
				})
				: [],
			otherPhones: otherPhones
				? otherPhones.map((el: any) => {
					return { other_phone: el };
				})
				: [],

      		 socials: socials ? socials : [],

    },
    		resolver: yupResolver(schema),
  });

	const phoneGroup = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'otherPhones', // unique name for your Field Array
	});

	const emailGroup = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'otherEmails', // unique name for your Field Array
	});

	const socialsGroup = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'socials', // unique name for your Field Array
	});

	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		phone && setValue('phone', phone);
		birth_date && setValue('birth_date', moment(birth_date, 'YYYY-MM-DD'));
		lname && setValue('lname', lname);
		name && setValue('name', name);
		sname && setValue('sname', sname);
		email && setValue('email', email);
		gender_id && setValue('gender_id', gender_id);
		socials && setValue('socials', socials);

		if (socials ===  null) {
			socialsGroup.append({ type: 1, name: '' });
		}
	}, [info]);

	const watchPhone = watch('phone');

	const onSubmit = async (data: any) => {
		setLoading(true);
		const request = {
			...data,
			phone: info?.is_confirm_phone
				? data.phone
				: data.phone.length > 10
					? data.phone.replace(/[^\d]/g, '').slice(2)
					: data.phone,
			birth_date: moment(data.birth_date).format('YYYY-MM-DD'),
			otherEmails: data.otherEmails.map((el: any) => {
				return el.other_email;
			}),
			otherPhones: data.otherPhones.map((el: any) => {
				return el.other_phone;
			}),
		};

		dispatch(
			profileUpdate({
				request,
				callback: {
					success: () => {
						setLoading(false);
						setVisible(false);
						NOTIFICATION.success('Данные успешно обновленны');
					},
					error: () => {
						setLoading(false);
					},
				},
			}),
		);
	};

	const ConfirmPhoneComponents = ({ onClick }: any) => {
		return (
			<span className="confirm-phone" onClick={() => setOpenConfirmPhoneModal(true)}>
        {t('Подтвердить')}
      </span>
		);
	};

	const inputRef = useRef(null);


	return (
		<>
			{openSocialModal && <AddSocialModal open={openSocialModal} setOpen={setOpenSocialModal} />}
			{openConfirmPhoneModal && (
				<PhoneProfileModal open={openConfirmPhoneModal} setOpen={setOpenConfirmPhoneModal} phone={getValues('phone')} />
			)}
			<form onSubmit={handleSubmit(onSubmit)} className="form edit-profile-form">
				<div className="edit-profile-form__body">
					<div className="edit-profile-form__container">
						<div className="edit-profile-form__container-label">{t('Пользователь')}</div>
						<div className="edit-profile-form__row">
							<InputDefault
								name={'lname'}
								label={t('Фамилия')}
								placeholder={t('Фамилия')}
								error={errors.lname?.message}
								register={register}
								control={control}
								requered
							/>
							<InputDefault
								name={'name'}
								label={t('Имя')}
								placeholder={t('Имя')}
								error={errors.name?.message}
								register={register}
								control={control}
								requered
							/>
							<InputDefault
								name={'sname'}
								label={t('Отчество')}
								placeholder={t('Отчество')}
								error={errors.sname?.message}
								register={register}
								control={control}
							/>
						</div>
						<div className="edit-profile-form__row">
							<InputDatePicker
								name={'birth_date'}
								label={t('Дата рождения')}
								placeholder={'10/08/1983'}
								error={errors.birth_date?.message}
								register={register}
								control={control}
								requered
							/>
							<InputSelectDefault
								name={'gender_id'}
								optionKey={'gender'}
								label={t('Пол')}
								placeholder={t('Выберите пол')}
								error={errors.gender_id?.message}
								register={register}
								control={control}
								options={options?.gender ? options?.gender : []}
								requered
								allowClear={false}
							/>
							<div className="input-default empty" />
						</div>
						<div className="edit-profile-form__row">
							<div className="edit-profile-form__block">
								<InputMaskDefault
									name={'phone'}
									label={t('Телефон')}
									placeholder={t('Телефон')}
									error={errors.phone?.message}
									register={register}
									control={control}
									disabled={info?.is_confirm_phone ? true : 0}
									plus
									actionPlus={() => {
										phoneGroup.fields.length < 3 && phoneGroup.append({ other_phone: '' });
									}}
								/>

								{watchPhone.replace(/[^\d]/g, '').slice(watchPhone[0] === '+' ? 2 : 0).length === 10 &&
									!is_confirm_phone && <ConfirmPhoneComponents />}
								<div>
									{phoneGroup.fields.map((field, index) => (
										<InputMaskDefault
											key={field.id}
											name={`otherPhones[${index}].other_phone`}
											placeholder={t('Телефон')}
											error={errors.otherPhones}
											register={register}
											control={control}
										>
											<RemovePhoneModal component={IconRemoveInput} action={() => phoneGroup.remove(index)} />
										</InputMaskDefault>
									))}
								</div>
							</div>

							<div className="edit-profile-form__block">
								<InputDefault
									name={'email'}
									label={t('E-mail')}
									placeholder={t('Адрес эл. почты')}
									error={errors.email?.message}
									register={register}
									control={control}
									disabled
									plus
									actionPlus={() => {
										emailGroup.fields.length < 3 && emailGroup.append({ other_email: '' });
									}}
								/>
								{emailGroup.fields.map((field, index) => (
									<InputDefault
										key={field.id}
										name={`otherEmails[${index}].other_email`}
										placeholder={t('Адрес эл. почты')}
										error={errors.otherEmails}
										register={register}
										control={control}
									>
										<RemoveEmailModal
											component={IconRemoveInput}
											action={() => {
												emailGroup.remove(index);
											}}
										/>
									</InputDefault>
								))}
							</div>

							<div className="edit-profile-form__block">
								{socialsGroup.fields.map((field: any, index) => (
									<InputDropdownPrefix
										label={index === 0 ? t('Мессенджер') : false}
										plus={socialsGroup.fields.length <= 2}
										actionPlus={() => {
											socialsGroup.fields.length < 4 && socialsGroup.append({ type: 1, name: '' });
										}}
										key={index}
										index={index}
										classes={'edit-profile-form__social-select'}
										dropdownComponent={DropdownSocialsControlled}
										value={field ? field?.type : 1}
										register={register}
										control={control}
										options={options?.social_media ? _.filter(options?.social_media) : []}
										reference={inputRef}
									>
										{index > 0 && (
											<RemoveSocialModal
												component={IconRemoveInput}
												action={() => {
													socialsGroup.remove(index);
												}}
											/>
										)}
									</InputDropdownPrefix>
								))}
							</div>
						</div>
					</div>

					{
						is_verifier === 1
							?
							<div className="edit-profile-form__container">
								<div className="edit-profile-form__container-label">
									{t('Контрагент')}{' '}
								</div>
								<div className="edit-profile-form__row">
									<InputDefault name={'company'} label={t('Компания')} defaultValue={'ТОВ «ProfWorld»'} disabled />
									<InputDefault name={''} label={t('Должность')} defaultValue={t('Верификатор')} disabled />
									<InputDefault name={''} label={t('Район / Город')} placeholder={t('Район / Город')} disabled />
								</div>
							</div>
							:
							<div className="edit-profile-form__container">
								<div className="edit-profile-form__container-label">
									{t('Контрагент')}{' '}
									<Tooltip
										trigger={'hover'}
										placement="bottom"
										title={<TooltipJobTitleBody />}
										className="default-tooltip"
										overlayClassName={'default-tooltip-overlay default-tooltip-overlay-size-294'}
									>
										<></>
										<IconTooltipInfo />
									</Tooltip>
								</div>
								<div className="edit-profile-form__row">
									<InputDefault name={'company'} label={t('Компания')} defaultValue={info.company?.name} disabled />
									<InputDefault name={'role'} label={t('Роль')} defaultValue={info.company?.user?.role?.name} disabled />
									<div className="input-default empty" />
								</div>
								<div className="edit-profile-form__row">
									<InputDefault
										name={''}
										label={t('Подразделение / Отдел')}
										placeholder={t('Подразделение / Отдел')}
										disabled
									/>
									<InputDefault name={''} label={t('Должность')} placeholder={t('Должность')} disabled />
									<InputDefault name={''} label={t('Район / Город')} placeholder={t('Район / Город')} disabled />
								</div>
							</div>
					}

				</div>

				<div className="edit-profile-form__footer">
          <span className="edit-profile-form__btn-text" onClick={() => setVisible(false)}>
            {t('Отмена')}
          </span>
					<ButtonDefault name={t('Сохранить')} type={'submit'} loading={loading} disabled={loading} />
				</div>
			</form>
		</>
	);
};

export default EditProfile;
