import React, { FC } from 'react';
import { useState } from 'react';
import { Dropdown } from 'antd';
import classNames from 'classnames';
// icons
import { IconArrowDropdown } from '@assets/icons/btn/arrow';
// styles
import '../style.scss';

const DropdownLang: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ru';
  return (
    <Dropdown
      className={'dropdown'}
      overlay={<DropdownOverlay setOpen={setOpen} />}
      trigger={['click']}
      open={open}
      onOpenChange={(data: any) => setOpen(data)}
    >
      <div
        className="dropdown__triger"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        <span className="dropdown__lang-name">{lang}</span>
        <div
          className={classNames(
            'dropdown__icon',
            { ['animation-dropdown-up']: open },
            { ['animation-dropdown-back']: !open },
          )}
        >
          <div className="dropdown__icon-arrow">
            <IconArrowDropdown />
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

export default DropdownLang;

const DropdownOverlay = ({ setOpen }: any) => {
  const action = (lang: string) => {
    setOpen(false);
    localStorage.setItem('lang', lang);
    window.location.reload();
    //window.dispatchEvent(new Event('storage'));
  };

  return (
    <div className="dropdown-overlay">
      <div className="dropdown-overlay__item" onClick={() => action('en')}>
        English
      </div>
      <div className="dropdown-overlay__item" onClick={() => action('ru')}>
        Русский
      </div>
      <div className="dropdown-overlay__item" onClick={() => action('ua')}>
        Українська
      </div>
    </div>
  );
};
