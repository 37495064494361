import {takeEvery} from 'redux-saga/effects'
// sagas
import {sagaGetOptions, sagaSetFile} from './app'
import {
  sagaForgot,
  sagaLogin,
  sagaLogout,
  sagaRegistration,
  sagaReset,
  sagaResetByPhone, sagaResetPasswordInside,
  sagaUpdatePassword,
} from './auth'
import {
  sagaAddCompanyDeputy,
  sagaAddCompanyToArchive,
  sagaAddUserToCompany,
  sagaCreateCompany,
  sagaGetAddressesList,
  sagaGetCompanies,
  sagaGetCompany,
  sagaGetCompanyInfo,
  sagaLoginToCompany,
  sagaLogoutFromCompany,
  sagaRecoveryCompany,
  sagaRemoveCompany,
  sagaRemoveFileCompany,
  sagaRemoveUserFromCompany,
  sagaSearchUserInCompany,
  sagaSetCompanyAdmin,
  sagaSetCompanyStatus,
  sagaSetFileToCompany,
  sagaTransferCompany,
  sagaUpdateCompany,
  sagaUpdateUserToCompany,
} from './company'
import {sagaCreateDepartment, sagaGetDepartmentInfo, sagaGetDepartments} from './departments'
import {
  sagaEditVerificationCompanyMessage,
  sagaFindListVerificationCompanies,
  sagaGetVerificationCompaniesList,
  sagaGetVerificationCompanyInfo,
  sagaRemoveVerificationCompany,
  sagaRemoveVerificationCompanyMessage,
  sagaSearchVerificationCompany,
  sagaSendVerificationCompanyMessage,
  sagaSetVerificationCompany,
} from './lcVerification'
import {
  sagaUserInfo,
  sagaUserProfileCreate,
  sagaUserProfileUpdate,
  sagaUserSendSms,
  sagaUserСonfirmPhone,
} from './user'

export function* sagaWatcher(): Generator {
  // APP
  yield takeEvery('app/getOptions', sagaGetOptions);
  yield takeEvery('app/setFile', sagaSetFile);
  // AUTH
  yield takeEvery('auth/login', sagaLogin);
  yield takeEvery('auth/setLogout', sagaLogout);
  yield takeEvery('auth/registration', sagaRegistration);
  yield takeEvery('auth/updatePassword', sagaUpdatePassword);
  yield takeEvery('auth/reset', sagaReset);
  yield takeEvery('auth/resetPasswordInside', sagaResetPasswordInside);
  yield takeEvery('auth/forgot', sagaForgot);
  yield takeEvery('auth/forgotByPhone', sagaResetByPhone);
  // USER
  yield takeEvery('user/getUserInfo', sagaUserInfo);
  yield takeEvery('user/profileCreate', sagaUserProfileCreate);
  yield takeEvery('user/profileUpdate', sagaUserProfileUpdate);
  yield takeEvery('user/profileConfirmPhone', sagaUserСonfirmPhone);
  yield takeEvery('user/sendSmsOnPhone', sagaUserSendSms);
  // yield takeEvery('user/changeStep', sagaUserChangeStep);
  // COMPANY
  yield takeEvery('company/getCompanyInfo', sagaGetCompanyInfo);
  yield takeEvery('company/createCompany', sagaCreateCompany);
  yield takeEvery('company/updateCompany', sagaUpdateCompany);
  yield takeEvery('company/getCompanies', sagaGetCompanies);
  yield takeEvery('company/addCompanyToArchive', sagaAddCompanyToArchive);
  yield takeEvery('company/recoveryCompany', sagaRecoveryCompany);
  yield takeEvery('company/loginToCompany', sagaLoginToCompany);
  yield takeEvery('company/logoutFromCompany', sagaLogoutFromCompany);
  yield takeEvery('company/setFileToCompany', sagaSetFileToCompany);
  yield takeEvery('company/removeFileCompany', sagaRemoveFileCompany);
  yield takeEvery('company/setCompanyStatus', sagaSetCompanyStatus);
  yield takeEvery('company/removeCompany', sagaRemoveCompany);
  yield takeEvery('company/searchUserInCompany', sagaSearchUserInCompany);
  yield takeEvery('company/addUserToCompany', sagaAddUserToCompany);
  yield takeEvery('company/updateUserToCompany', sagaUpdateUserToCompany);
  yield takeEvery('company/removeUserFromCompany', sagaRemoveUserFromCompany);
  yield takeEvery('company/transferCompany', sagaTransferCompany);

  yield takeEvery('company/getCompany', sagaGetCompany);
  yield takeEvery('company/setCompanyAdmin', sagaSetCompanyAdmin);
  yield takeEvery('company/addCompanyDeputy', sagaAddCompanyDeputy);
  yield takeEvery('addresses/getAddressesList', sagaGetAddressesList);
  // LC Verificator
  yield takeEvery('lcVerificator/getCompanies', sagaGetVerificationCompaniesList);
  yield takeEvery('lcVerificator/getCompaniesAdvanceSearch', sagaFindListVerificationCompanies);
  yield takeEvery('lcVerificator/getCompany', sagaGetVerificationCompanyInfo);
  yield takeEvery('lcVerificator/setVerification', sagaSetVerificationCompany);
  yield takeEvery('lcVerificator/removeCompany', sagaRemoveVerificationCompany);
  yield takeEvery('lcVerificator/searchCompanies', sagaSearchVerificationCompany);

  yield takeEvery('lcVerificator/sendMessage', sagaSendVerificationCompanyMessage);
  yield takeEvery('lcVerificator/editMessage', sagaEditVerificationCompanyMessage);
  yield takeEvery('lcVerificator/removeMessage', sagaRemoveVerificationCompanyMessage);

  // DEPARTMENT
  yield takeEvery('deparments/createDepartment', sagaCreateDepartment);
  yield takeEvery('deparments/getDepartments', sagaGetDepartments);
  yield takeEvery('deparments/getDepartmentInfo', sagaGetDepartmentInfo);
}
