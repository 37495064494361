import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
// icons
import search from '@image/icons/search-icon.svg'
// styles
import './style.scss'
import { useTranslation } from 'react-i18next'
import SearchModal from '@organisms/modals/app/search'
import { searchCompanies } from '@redux/reducers/lcVerificator'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { IconClose } from '@assets/icons/nav/usersTable'
import DropdownSearchCompanies from '@molecules/app/dropdwons/companySearch'

const AppSearchInput: FC<any> = ({ modal }: any) => {
	const [searchValue, setSearchValue] = useState<string>('')
	const [result, setResult] = useState([])
	const [loading, setLoading] = useState(false)
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [modalOpen, setModalOpen] = useState<boolean>(false)
	const { t } = useTranslation()
	const inputReference = useRef<any>(null)

	const dispatch = useDispatch()
	useEffect(() => {
		searchValue && !loading && inputReference.current.focus()
	}, [loading])

	const debounceSearch = useCallback(
		debounce((value: any) => {
			setLoading(true)
			dispatch(
				searchCompanies({
					request: { value: value },
					callback: {
						success: (data: any) => {
							setResult(data)
							setLoading(false)
						},
						error: () => {
							setLoading(false)
						},
					},
				})
			)
			inputReference.current.focus()
		}, 400),
		[]
	)

	const onChange = (e: any) => {
		setSearchValue(e.target.value)

		if (e.target.value.length > 0) {
			debounceSearch(e.target.value)
			setDropdownOpen(true)
		}
	}

	const action = () => {
		setModalOpen(true)
	}

	return (
		<>
			{modal && (
				<SearchModal open={modalOpen} setOpen={setModalOpen} defaultValue={searchValue} />
			)}
			<form className='search-form'>
				<button type='button' className='search-form__button' onClick={action}>
					<img src={search} alt='search' className='search-form__icon' />
				</button>
				<input
					ref={inputReference}
					placeholder={`${t('Поиск')}`}
					value={searchValue}
					onChange={onChange}
					type='search'
					className='search-form__input'
				/>
				{searchValue && <IconClose onClick={() => setSearchValue('')} />}
			</form>
			<DropdownSearchCompanies
				result={result}
				setDropdownOpen={setDropdownOpen}
				dropdownOpen={dropdownOpen}
				setModalOpen={setModalOpen}
				searchValue={searchValue}
			/>
		</>
	)
}

export default AppSearchInput
