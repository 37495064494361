import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
// components
import ButtonDefault from '@atoms/buttons/button';
import { UserLcAvatar } from '@molecules/app/avatar';
import DropdownDefault from '@molecules/app/dropdwons/default';
import SocialList from '@molecules/lists/social';
import RemoveLcCompanyModal from '@organisms/modals/confirms/removeLcCompany';
// icons
import { IconProfileCompany, IconProfileConfirmPhone, IconProfileLocale } from '@assets/icons/nav/profileHeader';
import { IconRemoveUser } from '@assets/icons/nav/usersTable';
// actions
import { setVerification, removeCompany } from '@redux/reducers/lcVerificator';
import { setStatus, setSuccess } from '@redux/reducers/app';
// types
import { stateType } from '@types';
// styles
import './style.scss';
import { companyStatus } from '@tools/constants'
import DeclineVerificationCompany from '@organisms/modals/confirms/declineVerificationCompany'

const { REJECTED, ACTIVE } = companyStatus

const CompanyInfo: FC = () => {
  const { company }: any = useSelector((state: stateType) => state.lcVerificator);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const IsCompanyDelete = company?.info?.company?.is_del === 1
  const isCompanyActiveOrRejected = company?.info?.company?.status?.id === REJECTED || company?.info?.company?.status?.id === ACTIVE

  const RemoveBtn = ({ onClick }: any) => {
    return (
      <div className="btn-remove" onClick={onClick}>
        <IconRemoveUser />
      </div>
    );
  };

  const DeclineBtn = ({ onClick }: any) => {
    return (
        <ButtonDefault
            name={t('Отклонить')}
            classes={isCompanyActiveOrRejected ? '' : 'btn-gray btn-transperent'}
            action={onClick}
            disabled={isCompanyActiveOrRejected}
        />
    );
  };

  const actionVerification = (verificationId: any, nameSuccess: string) => {
    dispatch(
      setVerification({
        request: {
          company_id: id,
          verification: verificationId,
        },
        callback: {
          success: () => {
            dispatch(setStatus('success'));
            dispatch(setSuccess({ type: nameSuccess }));
          },
        },
      }),
    );
  };

  const actionRemoveCompany = () => {
    dispatch(
      removeCompany({
        request: {
          company_id: id,
        },
        callback: {
          success: () => {
            dispatch(setStatus('success'));
            dispatch(setSuccess({ type: 'lc-verification-remove' }));
          },
        },
      }),
    );
  };


  return (
    <div className="company-info-block">
      <div className="company-info-block__body">
        {company?.info &&
          <div className="company-info-block__buttons">
            <ButtonDefault
                name={t('Верифицировать')}
                action={() => actionVerification(1, 'lc-verification')}
                disabled={isCompanyActiveOrRejected }
                classes={'mn'}
            />
            <DeclineVerificationCompany component={DeclineBtn}/>
          </div>
        }

        <div className="company-info-block__tags">
          <div className="company-info-block__label">{t('Компания')}</div>
          <div className="company-info-block__list">
            {company?.info?.company?.name && (
              <div className="company-info-block__tag">{company?.info?.company?.name}</div>
            )}
            {company?.info?.company?.code && (
              <div className="company-info-block__tag">{company?.info?.company?.code}</div>
            )}
            {company?.info?.company?.activity?.name && (
              <div className="company-info-block__tag">{company?.info?.company?.activity?.name}</div>
            )}
            {company?.info?.company?.services && (
              <div className="company-info-block__tag">{company?.info?.company?.services}</div>
            )}
            {company?.info?.company?.employees?.name && (
              <div className="company-info-block__tag">{company?.info?.company?.employees?.name}</div>
            )}
            {company?.info?.company?.website && (
              <div className="company-info-block__tag">{company?.info?.company?.website}</div>
            )}
          </div>
        </div>
        <div className="company-info-block__tags">
          <div className="company-info-block__label">{t('Дата создания')}</div>
          <div className="company-info-block__list">
            <div className="company-info-block__tag">
              {company?.info?.company?.create_date
                ? moment(company?.info?.company?.create_date).format('MM/DD/YYYY')
                : ''}
            </div>
          </div>
        </div>
        <div className="company-info-block__status">
          <div className="company-info-block__label">{t('Статус')}</div>
          { IsCompanyDelete
              ?
            <div className={classNames(`company-info-block__status-value`, `status-company-5` )}>Удалена</div>
              :
              <div
                  className={classNames(
                      'company-info-block__status-value',
                      `company-info-block__status-value-${company?.info?.company?.status?.id}`,
                  )}
              >
                {company?.info?.company?.status?.name}
              </div>
          }
        </div>
        <div className="company-info-block__owner-info">
          <div className="company-info-block__label">{t('Владелец компании')}</div>

          {company?.info?.owner && (
            <div className="company-info-block__drop-block">
              <UserLcAvatar />
              <DropdownDefault
                icon={<IconProfileCompany />}
                title={company?.info?.owner?.email}
                list={company?.info?.owner?.otherEmails ? company?.info?.owner?.otherEmails : []}
              />
              <DropdownDefault
                icon={<IconProfileConfirmPhone />}
                title={company?.info?.owner?.phone}
                list={company?.info?.owner?.otherPhones ? company?.info?.owner?.otherPhones : []}
              />
              <DropdownDefault icon={<IconProfileLocale />} title={'Киев, Украина'} list={[]} />
              <SocialList socials={company?.info?.owner?.socials} />
            </div>
          )}
        </div>
      </div>
      <div className="company-info-block__footer">
        { !IsCompanyDelete && <RemoveLcCompanyModal component={RemoveBtn} action={actionRemoveCompany} /> }
      </div>
    </div>
  );
};

export default CompanyInfo;
