import React, { FC, useState } from 'react';
import { Table } from 'antd';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// configs
import { columns } from '@configs/tables/lcCompany';
// types
import { stateType } from '@types';
// style
import './style.scss';

const LcVerificationCompanyTable: FC = () => {
  const { company }: any = useSelector((state: stateType) => state.lcVerificator);
  const [pageIndex, setPageIndex] = useState(1);
  const { t } = useTranslation();

  const excludeNewCompany = company?.history?.filter( item => item.status.id !== 1)

  return (
    <>
      <div className="lc-verification-companies-table">
        <Table
          rowKey={'update_date'}
          showSorterTooltip={false}
          locale={{
            emptyText: <div className="lc-verification-companies-table__empty">{t('Нет данных')}.</div>,
          }}
          pagination={{
            defaultPageSize: 5,
            pageSize: 5,
            current: pageIndex,
            total: excludeNewCompany ? excludeNewCompany.length : 0,
          }}
          className="lc-verification-companies-table__container"
          size="middle"
          columns={columns}
          dataSource={excludeNewCompany}
          sortDirections={['ascend', 'descend']}
        />
      </div>
    </>
  );
};

export default LcVerificationCompanyTable;
