/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call, select } from 'redux-saga/effects';
import { notification } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
// actions
import { setStatus, setSuccess } from '@redux/reducers/app';
import { setError, setLoading } from '@redux/reducers/auth';
// api
import { api } from '@api/auth/index';
// mapping
import NOTIFICATION from './notification';

export function* sagaLogin(action) {
  const { payload } = action;
  const { request, callback } = payload;
  const getAuth = (state) => state.auth;
  const { session } = yield select(getAuth);
  yield put(setLoading(true));
  yield put(setError(null));
  try {
    const { data } = yield call(api.login, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      notification.close('login-error');
      return NOTIFICATION.error(data.msg, false, 'login-error');
    }

    if (!_.isEmpty(data.user_data)) {
      if (!data.user_data.is_confirm_email) {
        yield put(setStatus('success'));
        yield put(
          setSuccess({
            type: 'registration',
          }),
        );
      }

      if (data.user_data.token) {
        !session && sessionStorage.setItem('token', `${data.user_data.token}`);
        session && localStorage.setItem('token', `${data.user_data.token}`);
        callback && callback();
      }
      localStorage.setItem('token',`${data.user_data.token}` )
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaLogout(action) {
  const { payload } = action;
  const { callback } = payload;
  const getUserInfo = (state) => state.user.info;
  const { token } = yield select(getUserInfo);
  yield put(setLoading(true));
  try {
    if (!token) {
      return callback();
    }

    if (token) {
      const { data } = yield call(api.logout, token);

      if (!_.isObject(data)) {
        return NOTIFICATION.error(ReactHtmlParser(data));
      }

      if (data.error) {
        notification.close('login-error');
        return NOTIFICATION.error(data.msg, false, 'login-error');
      }

      if (data.status === 'success') {
        callback && callback();
      }
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaRegistration(action) {
  const { payload } = action;
  const { callback } = payload;
  yield put(setLoading(true));
  try {
    const { data } = yield call(api.registration, payload);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      yield put(setStatus('success'));
      yield put(
        setSuccess({
          type: 'registration',
        }),
      );
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaUpdatePassword(action) {
  const { payload } = action;
  const { callback } = payload;
  yield put(setLoading(true));
  try {
    const { data } = yield call(api.updateVerifierPassword, payload);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    yield NOTIFICATION.success('Пароль успешно обновлён');

  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaReset(action) {
  const { payload } = action;
  const { request, callback } = payload;
  yield put(setLoading(true));
  try {
    const { data } = yield call(api.reset, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      yield put(setStatus('success'));
      yield put(
        setSuccess({
          type: 'reset',
        }),
      );
      callback && callback();
      localStorage.removeItem('recovery-token');
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaResetPasswordInside(action) {
  const { payload } = action;
  const { request, callback } = payload;
  yield put(setLoading(true));
  try {
    const { data } = yield call(api.reset, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      callback && callback();
      localStorage.removeItem('recovery-token');
    }
    yield NOTIFICATION.success('Пароль успешно обновлён');
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaResetByPhone(action) {
  const { payload } = action;
  const { request, callback } = payload;
  yield put(setLoading(true));
  try {
    const { data } = yield call(api.resetByPhone, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      localStorage.setItem('recovery-token', data.user_data.reset_password_token);
      callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaForgot(action) {
  const { payload } = action;
  const { request, callback } = payload;
  yield put(setLoading(true));
  try {
    const { data } = yield call(api.forgot, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}
