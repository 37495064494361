import React, { FC } from 'react';
import moment from 'moment';
// components
import InfoBlock from '@molecules/InfoBlock';
// styles
import './style.scss';

interface InfoBlocksType {
  step?: string;
}

const InfoBlocks: FC<InfoBlocksType> = ({ step }: InfoBlocksType) => {
  const handleOnClick = () => {
    console.log('ping');
  };

  return (
    <div className="info-blocks">
      <InfoBlock
        title="Добавить свой первый отдел"
        subtitle="17 Июня 2022"
        date={moment(Date.now()).format('DD.MM.YYYY')}
        onClick={handleOnClick}
        isWarning
        type={'birth'}
      />
      <InfoBlock
        title="Добавить свою первую вакансию"
        subtitle="17 Июня 2022 · 11:00 - 12:00"
        date={moment(Date.now()).format('DD.MM.YYYY')}
        onClick={handleOnClick}
        isWarning
        type={'validation'}
      />
      <InfoBlock
        title="Добавить свою первую вакансию"
        subtitle="17 Июня 2022 · 11:00 - 12:00"
        date={moment(Date.now()).format('DD.MM.YYYY')}
        onClick={handleOnClick}
        isWarning
        type={'doc'}
      />
      <InfoBlock
        title="Добавить свою первую вакансию"
        subtitle="17 Июня 2022 · 11:00 - 12:00"
        date={moment(Date.now()).format('DD.MM.YYYY')}
        onClick={handleOnClick}
        isWarning
        counter
        type={'social'}
      />
    </div>
  );
};

export default InfoBlocks;
