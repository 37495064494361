import React, { FC, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
// components
import InputDefault, { InputSelectDefault } from '@atoms/inputs';
import ButtonDefault from '@atoms/buttons/button';
// actions
import { profileUpdate } from '@redux/reducers/user';
// icons
import { IconPlus } from '@assets/icons/modals/addUsers';
import IconDelete from '@assets/icons/btn/delete';
// types
import { stateType } from '@types';
// styles
import './style.scss';

interface FormData {
  socials: any;
}
const AddSocialForm: FC<any> = ({ setVisible }: any) => {
  const [loading, setLoading] = useState<any>(false);
  const { info } = useSelector((state: stateType) => state.user);
  const { options } = useSelector((state: stateType) => state.app);
  const { socials } = info;

  //const schema = useMemo(() => editUserSchema(), [i18n.language]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      socials: socials ? socials : [],
    },
  });

  const socialsGroup = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'socials', // unique name for your Field Array
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = async (data: any) => {
    dispatch(
      profileUpdate({
        request: data,
        callback: {
          success: () => {
            setLoading(false);
            setVisible(false);
            message.success({ content: t('Данные успешно обновленны'), duration: 4 });
          },
          error: () => {
            setLoading(false);
          },
        },
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form add-social-form">
      <div className="add-social-form__body">
        <div className="add-social-form__container">
          <div className="add-social-form__container-label">
            {t('Социальная сеть')} <IconPlus onClick={() => socialsGroup.append({ type: '', name: '' })} />
          </div>
          {socialsGroup.fields.map((field, index) => (
            <div className="add-social-form__row" key={field.id}>
              <InputSelectDefault
                classes={'add-social-form__select'}
                name={`socials[${index}].type`}
                optionKey={'social_media'}
                label={t('Социальные сети')}
                placeholder={t('Социальные сети')}
                register={register}
                control={control}
                options={options?.social_media ? _.filter(options?.social_media) : []}
                requered
              />
              <div className="add-social-form__link">
                <InputDefault
                  name={`socials[${index}].name`}
                  label={t('Ссылка')}
                  placeholder={t('Ссылка')}
                  register={register}
                  control={control}
                  requered
                />
                <div className="add-social-form__remove" onClick={() => socialsGroup.remove(index)}>
                  <IconDelete />
                  <span>{t('Удалить')}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="add-social-form__footer">
        <span className="add-social-form__btn-text" onClick={() => setVisible(false)}>
          {t('Отмена')}
        </span>
        <ButtonDefault name={t('Сохранить')} type={'submit'} loading={loading} disabled={loading} />
      </div>
    </form>
  );
};

export default AddSocialForm;
