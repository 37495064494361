import React, { useEffect } from 'react';
// components
// import HomeTemplate from '@templates/homeTemplate';

const HrAnalytics: React.FC = () => {
  useEffect(() => {
    console.log('HrAnalytics PAGE');
  }, []);

  return <>HrAnalytics</>;
};

export default HrAnalytics;
