import React, { FC, useEffect, Fragment } from 'react';
import { Layout } from 'antd';
import { useDispatch } from 'react-redux';
// components
import AppSider from '@atoms/siders/AppSider';
import AppTitle from '@atoms/text/AppTitle';
import AppText from '@atoms/text/AppText';
import DynamicSteps from '@organisms/dynamicSteps';
// tools
import useAddVacancySteps from '@tools/hooks/steps/useAddVacancySteps';
import { appPage } from '@redux/reducers/app';

const AddVacancy: FC = () => {
  const { currentVacancyStep, progressStep, vacancySteps, setCurrentProfileStep } = useAddVacancySteps();

  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.getItem('company_step') && setCurrentProfileStep(Number(localStorage.getItem('company_step')));
    dispatch(appPage('add-vacancy'));
  }, []);

  return (
    <Layout>
      <AppSider>
        <AppTitle level={3} title="Новая вакансия" style={{ marginBottom: 56 }} />
        {vacancySteps
          .filter((vacancy: any) => vacancy.id === currentVacancyStep)
          .map((vacancy: any) => (
            <AppText
              key={vacancy.id}
              text={vacancy.text}
              style={{ fontWeight: 500, color: '#EDF2FF', margin: 0, fontSize: 16 }}
            />
          ))}
        {
          <ul className="vacancy-layout">
            {vacancySteps.map((v) => (
              <li
                key={v.id}
                className={`vacancy-layout__item ${
                  v.id === currentVacancyStep ? 'progress' : v.id < currentVacancyStep ? 'done' : ''
                } `}
              >
                <span className="vacancy-layout__step">{v.id}</span>
                <span className="vacancy-layout__name">{v.name}</span>
              </li>
            ))}
          </ul>
        }
      </AppSider>

      <Layout style={{ backgroundColor: '#fff', padding: '56px 24px', overflow: 'auto', height: '100vh' }}>
        <div style={{ maxWidth: 576 }}>
          {vacancySteps
            .filter((vacancy: any) => vacancy.id === currentVacancyStep)
            .map((vacancy: any) => (
              <Fragment key={vacancy.id}>
                <DynamicSteps steps={vacancy.steps} current={progressStep} />
              </Fragment>
            ))}
        </div>
      </Layout>
    </Layout>
  );
};

export default AddVacancy;
