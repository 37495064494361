// types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/*
 *
 * User reducer
 *
 */
const initialState = {
  loading: false,
  info: {} as any,
  list: [],
  choice: {} as any,
};

export type userInitStateType = typeof initialState;

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserInfo: () => {
      return;
    },
    profileCreate: () => {
      return;
    },
    profileUpdate: () => {
      return;
    },
    profileConfirmPhone: () => {
      return;
    },
    changeStep: () => {
      return;
    },
    sendSmsOnPhone: () => {
      return;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.info = action.payload;
    },
    setChoice: (state, action: PayloadAction<any>) => {
      state.choice = action.payload;
    },
    updateChoiceUser: (state, action: PayloadAction<any>) => {
      state.choice = { ...state.choice, ...action.payload };
    },
    updateUser: (state, action: PayloadAction<any>) => {
      state.info = { ...state.info, ...action.payload };
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
  },
});

export default user.reducer;
export const {
  getUserInfo,
  profileConfirmPhone,
  profileCreate,
  profileUpdate,
  changeStep,
  sendSmsOnPhone,
  setUser,
  setChoice,
  updateChoiceUser,
  updateUser,
  setLoading,
}: any = user.actions;
