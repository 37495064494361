import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
// components
import ButtonDefault from '@atoms/buttons/button';
// icons
import IconWrong from '@assets/icons/modals/wrong';
// style
import './style.scss';

interface OutFromCreateCompanyModalType {
  component: any;
  action?: any;
  color?: any;
}

const OutFromCreateCompanyModal: FC<OutFromCreateCompanyModalType> = ({
  component: Component,
  action,
  color,
}: OutFromCreateCompanyModalType) => {
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);

  const { t } = useTranslation();

  const onCancel = () => {
    setVisible(false);
  };

  const call = () => {
    setLoader(true);
    action(onCancel);
  };

  return (
    <>
      <>
        <Component
          color={color}
          onClick={() => {
            setVisible(true);
          }}
        />
        <Modal
          className="modal-confirm"
          open={visible}
          width={504}
          footer={null}
          closable={true}
          bodyStyle={{ padding: '64px' }}
          centered
          onCancel={onCancel}
        >
          <div className="modal-confirm__header">
            <IconWrong />
          </div>
          <div className="modal-confirm__body">
            <div className="modal-confirm__title">{t('Прервать регистрацию компании')}</div>
            <div className="modal-confirm__desc">{t('Вы действительно хотите прервать регистрацию компании?')}</div>
          </div>
          <div className="modal-confirm__btns">
            <ButtonDefault name={t('Завершить')} type={'submit'} loading={loader} disabled={loader} action={call} />
            <button className="btn-default btn-gray" onClick={onCancel}>
              {t('Отменить')}
            </button>
          </div>
        </Modal>
      </>
    </>
  );
};

export default OutFromCreateCompanyModal;
