import {CLEAR_KPI, GET_KPI_FAILED, GET_KPI_SUCCESS, INIT_KPI,} from "@redux/types";
import {kpiApi} from "@api/routers";
import {Http} from "@api/http";
import {createPositions, getData} from "@tools/helpers";
import store from "@redux/store";

export const initPositionKPI = () => async (dispatch) => {
   const {name, list} = store.getState().departments;
   const token = localStorage.getItem('token');
   const {idItem, type} = list.find(el => el.name === name)
   
   const url = `${kpiApi.getKpis}?token=${token}&idItem=${idItem}&type=${type}`;
   dispatch({type: INIT_KPI})
   try {
      const json = await Http.get(url);
      const global = getData(json.data.global);
      const local = json.data.local ? getData(json.data.local) : [];
      
      const positions = createPositions([...global, ...local]);
      const category = [{id: idItem, type, name, positions}]
      dispatch({type: GET_KPI_SUCCESS, payload: category})
   } catch (err) {
      console.error(err)
      dispatch({type: GET_KPI_FAILED, payload: err.message})
      throw new Error(err.message)
   }
};

export const resetPositionKPI = () => ({type: CLEAR_KPI});
