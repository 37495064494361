import React, { useEffect } from 'react';
// components
// import HomeTemplate from '@templates/homeTemplate';

const StaffAdaptation: React.FC = () => {
  useEffect(() => {
    console.log('StaffAdaptation PAGE');
  }, []);

  return <>StaffAdaptation</>;
};

export default StaffAdaptation;
