import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// components
import TabsCompany from '../tabsCompany';
import BtnGoBack from '@atoms/buttons/goBackButton';
import CompanyInfo from '@organisms/lcVerification/companyInfo';
// actions
import { getCompany } from '@redux/reducers/lcVerificator';
// types
import { stateType } from '@types';
// styles
import './style.scss';
import IconWrong from '@assets/icons/modals/wrong'
import ReactHtmlParser from 'react-html-parser'
import ButtonDefault from '@atoms/buttons/button'
import {Modal} from 'antd'

const LcVerificationCompanyContainer: FC = () => {
  const { company }: any = useSelector((state: stateType) => state.lcVerificator);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    dispatch(
      getCompany({
        request: {
          company_id: Number(id),
        },
      }),
    );
  }, []);
  return (
      <Modal
          className="modal-lc-company"
          open={true}
          footer={null}
          closable={true}
          centered
          style={{ width: "100%", resize: "none", overflow: 'clip' }}
          onCancel={() => navigate('/lc-companies')}
          maskClosable = {false}
          maskStyle={{ backgroundColor: 'rgba(220, 222, 252, 0.6)' }}
      >
          <div className="lc-verification-company-container">
              <div className="lc-verification-company-container__list">
                  <div className="lc-verification-company-container__body">
                      <div className="lc-verification-company-container__title">{company?.info?.company?.name}</div>
                      <TabsCompany />
                  </div>
                  <div className="lc-verification-company-container__footer">
                      <BtnGoBack onClick={() => navigate('/lc-companies')} />
                  </div>
              </div>

              <div className="lc-verification-company-container__info">
                  <CompanyInfo />
              </div>
          </div>
      </Modal>

  );
};

export default LcVerificationCompanyContainer;
