/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
import { put, call, select } from 'redux-saga/effects';
import { api } from '@api/app';
import { updateUser } from '@redux/reducers/user';
import { updateOptions } from '../reducers/app';
import NOTIFICATION from './notification';

export function* sagaGetOptions(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const { data } = yield call(api.getOptions, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      yield put(updateOptions(data.list_data));
      callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  }
}

export function* sagaSetFile(action) {
  const { payload } = action;
  const { request, callback } = payload;
  const { success, error } = callback;

  try {
    const response = yield call(api.setFile, request);

    const { data } = response;

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      error && error();
      return NOTIFICATION.error(data.msg);
    }

    if (data.status) {
      yield put(updateUser(data.user_data));
      success && success();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    //yield put(setLoading(false));
  }
}
