import React, { FC } from 'react';
// components
import AppTitle from '@atoms/text/AppTitle';
import AppText from '@atoms/text/AppText';
// hooks
import useAsideHook from '@tools/hooks/useAsideHook';
// styles
import './style.scss';

interface AuthenticationAsideType {
  page?: string;
  children?: React.ReactNode;
}

const AsideBlock: FC<AuthenticationAsideType> = ({ page, children }: AuthenticationAsideType) => {
  const titles = useAsideHook(page);

  const { title, subTitle } = titles;

  return (
    <div className="sider">
      <AppTitle title={title} level={1} style={{ paddingBottom: 24, marginBottom: 0, letterSpacing: '0.02em' }} />
      <AppText text={subTitle} style={{ fontSize: 18, paddingBottom: 72 }} />
      {children}
    </div>
  );
};

export default AsideBlock;
