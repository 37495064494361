/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as yup from 'yup';
import i18n from '@src/i18n';

const validatePhone = (watchPhone) => {
  if (watchPhone.replace(/[^\d]/g, '').slice(watchPhone[0] === '+' ? 2 : 0).length === 10) {
    return true;
  }
  return false;
};

export const loginSchema = () => {
  return yup.object().shape({
    email: yup
      .string()
      .required(i18n.t('Обязательное поле'))
      .matches(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$/i,
        i18n.t('Неверный формат поля'),
      )
      .max(100, i18n.t('Длина поля превышена')),
    password: yup
      .string()
      .required(i18n.t('Обязательное поле'))
      .min(8, i18n.t('Длина пароля должна быть не менее 8 символов'))
      .max(50, i18n.t('Длина поля превышена')),
  });
};

export const regSchema = () => {
  return yup.object().shape({
    email: yup
      .string()
      .required(i18n.t('Обязательное поле'))
      .matches(/^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}\s*$/i, i18n.t('Неверный формат поля'))
      .max(100, i18n.t('Длина поля превышена')),
    password: yup
      .string()
      .required(i18n.t('Обязательное поле'))
      .min(8, i18n.t('Длина пароля должна быть не менее 8 символов'))
      .max(50, i18n.t('Длина поля превышена')),
    password_repeat: yup
      .string()
      .oneOf([yup.ref('password'), null], i18n.t('Пароли не совпадают'))
      .required(i18n.t('Обязательное поле')),
  });
};

export const forgotSchema = () => {
  return yup.object().shape({
    email: yup
      .string()
      .required(i18n.t('Обязательное поле'))
      .matches(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$/i,
        i18n.t('Неверный формат поля'),
      )
      .max(100, i18n.t('Длина поля превышена')),
  });
};

export const resetSchema = () => {
  return yup.object().shape({
    password: yup
      .string()
      .required(i18n.t('Обязательное поле'))
      .min(8, i18n.t('Длина пароля должна быть не менее 8 символов'))
      .max(50, i18n.t('Длина поля превышена')),
    password_repeat: yup
      .string()
      .oneOf([yup.ref('password'), null], i18n.t('Пароли не совпадают'))
      .required(i18n.t('Обязательное поле')),
  });
};

export const updatePasswordSchema = () => {
  return yup.object().shape({
    currentPassword: yup
        .string()
        .required(i18n.t('Обязательное поле'))
        .min(8, i18n.t('Длина пароля должна быть не менее 8 символов'))
        .max(50, i18n.t('Длина поля превышена')),
    password: yup
        .string()
        .required(i18n.t('Обязательное поле'))
        .min(8, i18n.t('Длина пароля должна быть не менее 8 символов'))
        .max(50, i18n.t('Длина поля превышена')),
    password_repeat: yup
        .string()
        .oneOf([yup.ref('password'), null], i18n.t('Пароли не совпадают'))
        .required(i18n.t('Обязательное поле')),
  });
};
export const advanceSearch = () => {
  return yup.object().shape({
    companyName: yup.string(),
    product:yup.string(),
    site:yup.string(),
    edrCode: yup.string(),
    dateFrom: yup.string()
        .nullable()
        .transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
    dateTo: yup.string()
        .nullable()
        .transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
    employees: yup.number().nullable(true).integer(),
    activity:yup.number().nullable(true).integer(),

  });
};
export const regUserSchema = () => {
  return yup.object().shape({
    birth_date: yup.string().required(i18n.t('Обязательное поле')),
    lname: yup.string().required(i18n.t('Обязательное поле')),
    name: yup.string().required(i18n.t('Обязательное поле')),
    sname: yup.string(),
    gender_id: yup.number().required(i18n.t('Обязательное поле')),
    phone: yup
      .string()
      .required(i18n.t('Обязательное поле'))
      .test('phone', i18n.t('Некоректный телефон'), (value) => validatePhone(value)),
  });
};

export const editUserSchema = () => {
  return yup.object().shape({
    birth_date: yup.string().required(i18n.t('Обязательное поле')),
    lname: yup.string().required(i18n.t('Обязательное поле')),
    name: yup.string().required(i18n.t('Обязательное поле')),
    sname: yup.string(),
    gender_id: yup.number().required(i18n.t('Обязательное поле')),
    phone: yup
      .string()
      .required(i18n.t('Обязательное поле'))
      .test('phone', i18n.t('Некоректный телефон'), (value) => validatePhone(value)),
    socials: yup.array(),
  });
};

export const codeSchema = () => {
  return yup.object().shape({
    code: yup.number().required(i18n.t('Обязательное поле')),
  });
};

export const regCompanySchema = () => {
  return yup.object().shape({
    name: yup.string().required(i18n.t('Обязательное поле')),
    employees: yup.number().nullable(true).integer(),
    code: yup
      .string()
      .typeError(i18n.t('Обязательное поле'))
      .required(i18n.t('Обязательное поле'))
      .min(8, i18n.t('Длина поля минимум 8 цифр'))
      .max(8, i18n.t('Длина поля превышена'))
      .matches(/[0-9]|\./, i18n.t('Неверный формат поля')),
    activity: yup.number().nullable(true).integer(),
    services: yup.string(),
    website: yup.string(),
  });
};

export const addUserToCompanySchema = () => {
  return yup.object().shape({
    email: yup
      .string()
      .required(i18n.t('Обязательное поле'))
      .matches(/^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i, i18n.t('Неверный формат поля'))
      .max(100, i18n.t('Длина поля превышена')),
    lname: yup.string().required(i18n.t('Обязательное поле')),
    name: yup.string().required(i18n.t('Обязательное поле')),
    sname: yup.string(),
  });
};

/* example array object
yup.array().of(
    yup.object().shape({
      firsName: yup.string().required("First Name is required"),
      lastName: yup.string().required("Last Name is required")
    })
*/
