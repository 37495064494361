import React, {FC, useState} from 'react'
import {Table} from 'antd'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {stateType} from '@types'
import './style.scss'
import {Titlte} from '@molecules/tables/users'
import {getFileSize} from '@tools/helpers'
import Moment from 'react-moment'
import {Link, useParams} from 'react-router-dom'
import {IconAppFile, IconAppPrint} from '@assets/icons/app'

const LcVerificationFilesTable: FC = () => {
	const { company }: any = useSelector((state: stateType) => state.lcVerificator)
	const [pageIndex, setPageIndex] = useState(1)
	const [pageSize, setPageSize] = useState(9)
	const { t } = useTranslation()

	const { id } = useParams()

	const columns = [
		{
			title: <Titlte name={'№'} classes={'lc-verification-companies-table__title'} />,
			dataIndex: 'id',
			className:
				'lc-verification-companies-table__column lc-verification-companies-table__column-id',
			render: (text, record, index) => (
				<div className='lc-verification-companies-table__column-container'>{index + 1}</div>
			),
		},
		{
			title: () => (
				<Titlte name={'Название'} classes={'lc-verification-companies-table__title'} />
			),
			dataIndex: 'name',
			className:
				'lc-verification-companies-table__column lc-verification-companies-table__column-name w-307',
			render: (text, record) => (
				<div>
					<img src={record.url} alt="file" style={{ height:25, width: 25, borderRadius:4, marginRight: 8 }} />
					<Link to={`/lc-companies/company/${id}/files/view`}>{text}</Link>
				</div>
			),
		},
		{
			title: () => (
				<Titlte name={'Размер'} classes={'lc-verification-companies-table__title'} />
			),
			dataIndex: 'size',
			className:
				'lc-verification-companies-table__column lc-verification-companies-table__column-activity w-153',
			render: (text, record) => <div>{getFileSize(record)}</div>,
		},
		{
			title: () => (
				<Titlte
					name={'Дата'}
					classes={'lc-verification-companies-table__title'}
				/>
			),
			dataIndex: 'date_upload',
			className:
				'lc-verification-companies-table__column lc-verification-companies-table__column-date w-153',
			render: (text, record) => (
				<div className='lc-verification-companies-table__column-date-block'>
					<span className='table-date'>
						<Moment format='MM/DD/YYYY'>{record.date_upload}</Moment>
					</span>
					<span className='table-time'>
						<Moment format='HH:mm:ss'>{record.date_upload}</Moment>
					</span>
				</div>
			),
		},
		{
			title: () => <Titlte name={'Cкачать'} classes={'lc-verification-companies-table__title'} />,
			className: 'lc-verification-companies-table__column lc-verification-companies-table__column-link w-151',
			render: (text, record) => (
				<div className="lc-verification-companies-table__column-container" onClick={()=> window.open(record.url)}>
					<IconAppFile />
				</div>
			),
		},
		{
			title: () => <Titlte name={'Печать'} classes={'lc-verification-companies-table__title'} />,
			className: 'lc-verification-companies-table__column lc-verification-companies-table__column-link w-151',
			render: (text, record) => (
				<div className="lc-verification-companies-table__column-container">
					<a href={`https://docs.google.com/viewer?url=${encodeURIComponent(record.url)}&embedded=true}`} target="_blank">
					<IconAppPrint />
					</a>
				</div>
			),
		},
	]

	return (
		<>
			<div className='lc-verification-companies-table'>
				<Table
					rowKey={'date_upload'}
					showSorterTooltip={false}
					locale={{
						emptyText: (
							<div className='lc-verification-companies-table__empty'>
								{t('Нет данных')}.
							</div>
						),
					}}
					pagination={{
						defaultPageSize: 5,
						pageSize: 5,
						current: pageIndex,
						total: company?.files?.edr?.length,
					}}
					className='lc-verification-companies-table__container'
					size='middle'
					columns={columns}
					dataSource={company?.files?.edr}
					sortDirections={['ascend', 'descend']}
				/>
			</div>
		</>
	)
}

export default LcVerificationFilesTable
