import React, { FC } from 'react';
import ReactHtmlParser from 'react-html-parser';
// tools
import useFormHeader from '@tools/hooks/forms/useFormHeader';
// style
import './style.scss';
import { IconClose } from '@assets/icons/nav/usersTable';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setStatus } from '@redux/reducers/app';
import OutFromCreateCompanyModal from '@organisms/modals/confirms/outFromCreateCompany';
import { logoutFromCompany } from '@redux/reducers/company';
import { setUser } from '@redux/reducers/user';
import { stateType } from '@types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

interface FormHeaderType {
  type: string;
  data?: any;
}

interface FormHeaderCompaniesType {
  title?: any;
  desc?: any;
  iconClosed?: any;
  classes?: any;
}

const FormHeader: FC<FormHeaderType> = ({ type, data }: FormHeaderType) => {
  const { titles } = useFormHeader(type, data);

  return (
    <div className="form-header">
      <div className="form-header__title">{titles.title}</div>
      <div className="form-header__desc">{ReactHtmlParser(titles.desc)}</div>
    </div>
  );
};

export default FormHeader;

export const FormHeaderCompanies: FC<FormHeaderCompaniesType> = ({
  title,
  desc,
  iconClosed,
  classes,
}: FormHeaderCompaniesType) => {
  const { info: userInfo } = useSelector((state: stateType) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goBack = () => {
    if (userInfo?.company?.id) {
      dispatch(
        logoutFromCompany({
          request: { company_id: userInfo.company.id },
          callback: () => {
            dispatch(setUser({ ...userInfo, company: false }));
            dispatch(setStatus('companies-profile'));
            navigate('/companies');
          },
        }),
      );
      return;
    }

    dispatch(setStatus('companies-profile'));
    navigate('/companies');
  };

  return (
    <div className={classNames('simple-header', classes)}>
      <div className="simple-header__text">
        {title && <div className="simple-header__title">{title}</div>}
        {desc && <div className="simple-header__desc">{desc}</div>}
      </div>

      {iconClosed && (
        <div className="simple-header__close">
          <OutFromCreateCompanyModal component={IconClose} action={goBack} />
        </div>
      )}
    </div>
  );
};
