import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// components
import Countdown from 'react-countdown';
// icons
import IconTime from '@assets/icons/inputs/time';
// styles
import './style.scss';

const TimeCountdown: FC = () => {
  const { t } = useTranslation();
  const renderer = ({ hours, minutes, seconds, completed, api }: any) => {
    // api.start();

    if (completed) {
      return (
        <Link to="#" onClick={() => console.log('dispatch')}>
          {t('Оправить код повторно')}
        </Link>
      );
    } else {
      return <Beggin api={api} minutes={minutes} seconds={seconds} />;
    }
  };
  return <Countdown date={Date.now() + 90000} renderer={renderer} autoStart={false} />;
};

export default TimeCountdown;

export const Beggin: React.FC<any> = ({ api, minutes, seconds, time }: any) => {
  const { t } = useTranslation();
  useEffect(() => {
    api.start();
  }, []);

  return (
    <div className="timeout-block">
      <IconTime />
      {minutes > 0 && <div className="timeout-block__time">{`${minutes} ${t('мин')}`}</div>}
      {seconds > 0 && <div className="timeout-block__time">{`${seconds} ${t('сек')}`}</div>}
    </div>
  );
};
