import React, { FC, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// components
import FormHeader from '@atoms/text/formHeader';
import FormCode from '@organisms/forms/code/confirmPhone';
// import AsideTemplate from '@templates/asideTemplate';
// actions
import { appPage } from '@redux/reducers/app';
// types
import { stateType } from '@types';
import FormTemplate from '@templates/formTemplate';

const PageConfirmPhone: FC = () => {
  const { page } = useSelector((state: stateType) => state.app);
  //const { info } = useSelector((state: stateType) => state.user);
  //const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('confirm-phone')) {
      return navigate('/');
    }
  });

  return (
    <>
      <Helmet title={page} />
      <FormTemplate center page={page}>
        <FormHeader type={'confirmPhone'} data={{ phone: localStorage.getItem('confirm-phone') }} />
        <FormCode />
      </FormTemplate>
    </>
  );
};

export default PageConfirmPhone;
