import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// components
// import HomeTemplate from '@templates/homeTemplate';

const Сulture: React.FC = () => {
  useEffect(() => {
    console.log('Сulture PAGE');
  }, []);

  return <>Сulture</>;
};

export default Сulture;
