import React, { FC } from 'react';
// images
import collapsedArrow from '@image/icons/collapsedArrow.svg';
// styles
import './style.scss';

interface CollapsedButtonType {
  onClick: () => void;
  isCollapsed: boolean;
}

const CollapsedButton: FC<CollapsedButtonType> = ({ onClick, isCollapsed }: CollapsedButtonType) => {
  const collapsed = isCollapsed ? 'collapsed is_collapsed' : 'collapsed';
  return (
    <li className={collapsed} onClick={onClick}>
      <img className="collapsed__arrow" src={collapsedArrow} alt="collapsedArrow" />
    </li>
  );
};

export default CollapsedButton;
