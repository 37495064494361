import React, { FC, useState } from 'react';
import { Modal } from 'antd';
// components
import ButtonDefault from '@atoms/buttons/button';
// icons
import IconSeccess from '@assets/icons/modals/success';
// style
import './style.scss';

interface EmptySuccessCompanyModalType {
  component?: any;
  title?: string;
  desc?: string;
  setSuccess?: any;
}

const EmptySuccessCompanyModal: FC<EmptySuccessCompanyModalType> = ({
  component: Component,
  title,
  desc,
  setSuccess,
}: EmptySuccessCompanyModalType) => {
  const [visible, setVisible] = useState(true);

  const onCancel = () => {
    setVisible(false);
    setSuccess(false);
  };

  return (
    <Modal
      className="modal-confirm"
      open={visible}
      width={504}
      footer={null}
      closable={true}
      bodyStyle={{ padding: '64px' }}
      centered
      onCancel={onCancel}
    >
      <div className="modal-confirm__header">
        <IconSeccess />
      </div>
      <div className="modal-confirm__body">
        <div className="modal-confirm__title">{title}</div>
        <div className="modal-confirm__desc">{desc}</div>
        <div className="modal-confirm__btns">
          <ButtonDefault name="Продолжить" action={onCancel} />
        </div>
      </div>
    </Modal>
  );
};

export default EmptySuccessCompanyModal;
