import React, { FC } from 'react';
import classNames from 'classnames';
// components
import AsideFormBlock from '@containers/app/aside/form';
import CompaniesNavigationList from '@molecules/navigations/companies/sortList';
import BtnLogout from '@atoms/buttons/logoutButton';
import LogoutFromSystemModal from '@organisms/modals/confirms/logoutFromSystem';
// tools
import useLogout from '@tools/hooks/useLogout';
// styles
import './style.scss';

interface AsideTemplateType {
  children: React.ReactNode;
  center?: boolean;
  page?: string;
  classes?: string;
}

const CompaniesTemplate: FC<AsideTemplateType> = ({ children, center, page, classes }: AsideTemplateType) => {
  const { logout } = useLogout();

  return (
    <div className={classNames('form-template', classes)}>
      <AsideFormBlock page={page}>
        <CompaniesNavigationList />
        <div className="form-template__box">
          <LogoutFromSystemModal
            component={BtnLogout}
            color={'#A09EF2'}
            action={() => {
              logout();
            }}
          />
        </div>
      </AsideFormBlock>
      <div className={classNames('form-template__body', { 'form-template__body-center': center })}>{children}</div>
    </div>
  );
};

export default CompaniesTemplate;
