import React, {FC, useEffect, useState} from 'react'
import {Pagination, Table} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
// components
// actions
import {getOptions} from '@redux/reducers/app'
// configs
import {columns} from '@configs/tables/lcCompanies'
// types
import {stateType} from '@types'
// style
import './style.scss'
import ButtonDefault from '@atoms/buttons/button'
import useCalculatePageSize from '@tools/hooks/useCalculatePageSize'

interface IProps  {
    setActiveTab: (value: string) => void
}
const CompaniesAdvanceSearchTable: FC<IProps> = ({setActiveTab}) => {

    const { options } = useSelector((state: stateType) => state.app);
    const { companiesAdvanceSearch, loading }: any = useSelector((state: stateType) => state.lcVerificator);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const calculatePageSize = useCalculatePageSize();

    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(calculatePageSize);


    useEffect(() => {
        !options?.filter_companies &&
        dispatch(
            getOptions({
                request: {
                    source: ['filter_companies'],
                },
            }),
        );
    }, []);

    return (
        <>
            { companiesAdvanceSearch.length > 0 &&
                <div className="lc-verification-companies-table auto">
                    <Table
                        loading={loading}
                        showSorterTooltip={false}
                        locale={{
                            emptyText: <div className="lc-verification-companies-table__empty">{t('Нет данных')}.</div>,
                        }}
                        className="lc-verification-companies-table__container"
                        pagination={{
                            defaultPageSize: calculatePageSize,
                            pageSize: calculatePageSize,
                            current: pageIndex,
                            total: companiesAdvanceSearch.length,
                        }}
                        size="middle"
                        columns={columns}
                        rowKey={'id'}
                        dataSource={companiesAdvanceSearch ? companiesAdvanceSearch : []}
                        sortDirections={['ascend', 'descend']}
                    />


                    <div className='form__container center'>

                            <ButtonDefault
                                name={t('Расширенный поиск')}
                                action={()=>setActiveTab('Filters')}
                                loading={loading}
                                disabled={loading}
                            />



                        {companiesAdvanceSearch.length > calculatePageSize && (
                            <Pagination
                                className="table-default__pagination right"
                                defaultPageSize={calculatePageSize}
                                pageSize={calculatePageSize}
                                current={pageIndex}
                                total={companiesAdvanceSearch.length}
                                onChange={(page, size) => {
                                    setPageIndex(page);
                                    setPageSize(size);
                                }}
                            />
                        )}

                    </div>
                </div>
            }
        </>

    );
};

export default CompaniesAdvanceSearchTable;
