import React, { FC, useState } from 'react';

import bgAside from '@image/bg-aside.svg';
import { Form, Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { companyAddDeputyText } from '@tools/constants';

import { messages } from '@tools/messages';
import girl from '@image/girl-ilustaration.svg';

import __ from 'lodash';
import { checkFormValues } from '@tools/helpers';
import StaticFormModal from '@organisms/modals/staticForm';
import AuthenticationAside from '@containers/app/aside';
import FormAddDeputy from '@organisms/forms/addDeputy/FormAddDeputy';
import DeputyForm from '@organisms/forms/addDeputy/DeputyForm';

const AddDeputy: FC = () => {
  const [form] = Form.useForm();
  const [isSuccess, setIsSuccess] = useState(false);
  const [visibleDeputy, setVisibleDeputy] = useState(false);
  const [otherDeputy, setOtherDeputy] = useState<any>([]);
  const [deputySelectedId, setDeputySelectedId] = useState(0);

  const onRemove = (id: any) => {
    setOtherDeputy((prev: any) => prev.filter((item: any) => item.id !== id));
  };
  const onOpen = (id: any) => {
    setDeputySelectedId(id);
    const deputy: any = otherDeputy.find((item: any) => item.id === id);
    const formValues = form.getFieldsValue();

    for (const deputyElement in deputy) {
      if (Object.hasOwn(formValues, deputyElement)) {
        form.setFields([{ name: deputyElement, value: deputy[deputyElement] }]);
      }
    }
    setVisibleDeputy(true);
  };

  const onFinish = (values: any) => {
    const formValues: any = checkFormValues(values);
    const email: string = otherDeputy.find((item: any) => item.email === formValues.email)?.email;
    if (email && !deputySelectedId) {
      form.setFields([{ name: 'email', errors: ['Заместитель с таким адресом уже добавлен!'] }]);
      return;
    }

    if (deputySelectedId) {
      setOtherDeputy((prev: any) =>
        prev.map((item: any) => {
          if (item.id.toString() === deputySelectedId.toString()) {
            return { ...formValues, id: item.id };
          }
          return item;
        }),
      );
      setDeputySelectedId(0);
    } else {
      setOtherDeputy((prev: any) => [{ id: __.uniqueId(), ...formValues }, ...prev]);
    }
    setVisibleDeputy(!visibleDeputy);
    form.resetFields();
  };

  return (
    <>
      <StaticFormModal
        visible={visibleDeputy}
        changeVisible={setVisibleDeputy}
        title={deputySelectedId ? companyAddDeputyText.titleReplace : 'Новый заместитель'}
        text="Заполните информацию о новом заместителе"
        img={girl}
        component={<DeputyForm form={form} isSubmit onSubmit={onFinish} />}
      />

      <Layout className="new-company">
        <AuthenticationAside page="add-deputy" />

        <Layout className="new-company__layout layout">
          <Content className="new-company__container" style={{ paddingTop: 120 }}>
            <FormAddDeputy
              isSuccess={isSuccess}
              changeSuccess={setIsSuccess}
              changeVisible={() => setVisibleDeputy(!visibleDeputy)}
              otherDeputy={otherDeputy}
              onRemoveDeputy={onRemove}
              onOpenDeputy={onOpen}
            />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AddDeputy;
