import React, { FC } from 'react';
// components
import HomeBanner from '../../molecules/baners/HomeBanner';
import VerifyBanner from '../../molecules/baners/VerifyBanner';
// tools
import { messages } from '@tools/messages';

interface BannersContainerType {
  step: string;
}

const BannersContainer: FC<BannersContainerType> = ({ step }: BannersContainerType) => {
  return (
    <div>
      {step === '4' && <HomeBanner text={messages.bannersText.verifyWait} />}
      {step === '5' && <VerifyBanner text={messages.bannersText.getAccess} />}
    </div>
  );
};

export default BannersContainer;
