import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { createPhoneMask } from '@tools/helpers';
import ReactHtmlParser from 'react-html-parser';
// components
import FormCode from '@organisms/forms/code/confirmRecoveryCompany';
// style
import './style.scss';

interface RecoveryCompanyModalType {
  component: any;
  action?: any;
  phone: string;
}

const RecoveryCompanyModal: FC<RecoveryCompanyModalType> = ({
  component: Component,
  action,
  phone,
}: RecoveryCompanyModalType) => {
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);

  const { t } = useTranslation();

  const onCancel = () => {
    setVisible(false);
  };

  const call = () => {
    setLoader(true);
    action(onCancel);
  };

  return (
    <>
      <>
        <Component
          onClick={() => {
            setVisible(true);
          }}
        />
        <Modal
          className="modal-confirm"
          open={visible}
          width={464}
          footer={null}
          closable={true}
          bodyStyle={{ padding: '64px' }}
          centered
          onCancel={onCancel}
        >
          <div className="modal-confirm__body">
            <div className="modal-confirm__title">{t('Восстановление Компании')}</div>
            <div className="modal-confirm__desc">
              {ReactHtmlParser(t('Мы отправили SMS с 6-значным кодом', { phone: phone ? createPhoneMask(phone) : '' }))}
            </div>
            <FormCode action={call} loading={loader} />
          </div>
        </Modal>
      </>
    </>
  );
};

export default RecoveryCompanyModal;
