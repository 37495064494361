import React, { useEffect } from 'react';
// components
// import HomeTemplate from '@templates/homeTemplate';

const StaffTraning: React.FC = () => {
  useEffect(() => {
    console.log('StaffTraning PAGE');
  }, []);

  return <>StaffTraning</>;
};

export default StaffTraning;
