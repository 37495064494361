import React from 'react';
// icons
import online from '@image/icons/counters/online.svg';

const CounterOnline = () => {
  return (
    <div className="counter-block">
      <img src={online} alt="birth" className="counter-block__img" />
      <div className="counter-block__title">Дни рождения</div>
      <div className="counter-block__desc">В этом месяце</div>
      <div className="counter-block__list">
        <div className="counter-block__item">TA</div>
        <div className="counter-block__item">SA</div>
      </div>
    </div>
  );
};

export default CounterOnline;
