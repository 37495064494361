import React, { FC } from 'react';

export const IconUsersAdd: FC<any> = ({ onClick }: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        d="M5 14C5.28333 14 5.52067 13.904 5.712 13.712C5.904 13.5207 6 13.2833 6 13V11H8C8.28333 11 8.52067 10.904 8.712 10.712C8.904 10.5207 9 10.2833 9 10C9 9.71667 8.904 9.479 8.712 9.287C8.52067 9.09567 8.28333 9 8 9H6V7C6 6.71667 5.904 6.479 5.712 6.287C5.52067 6.09567 5.28333 6 5 6C4.71667 6 4.47933 6.09567 4.288 6.287C4.096 6.479 4 6.71667 4 7V9H2C1.71667 9 1.47933 9.09567 1.288 9.287C1.096 9.479 1 9.71667 1 10C1 10.2833 1.096 10.5207 1.288 10.712C1.47933 10.904 1.71667 11 2 11H4V13C4 13.2833 4.096 13.5207 4.288 13.712C4.47933 13.904 4.71667 14 5 14ZM15 12C16.1 12 17.0417 11.6083 17.825 10.825C18.6083 10.0417 19 9.1 19 8C19 6.9 18.6083 5.95833 17.825 5.175C17.0417 4.39167 16.1 4 15 4C13.9 4 12.9583 4.39167 12.175 5.175C11.3917 5.95833 11 6.9 11 8C11 9.1 11.3917 10.0417 12.175 10.825C12.9583 11.6083 13.9 12 15 12ZM22 20C22.2833 20 22.5207 19.904 22.712 19.712C22.904 19.5207 23 19.2833 23 19V17.2C23 16.6333 22.854 16.1123 22.562 15.637C22.2707 15.1623 21.8833 14.8 21.4 14.55C20.3667 14.0333 19.3167 13.6457 18.25 13.387C17.1833 13.129 16.1 13 15 13C13.9 13 12.8167 13.129 11.75 13.387C10.6833 13.6457 9.63333 14.0333 8.6 14.55C8.11667 14.8 7.72933 15.1623 7.438 15.637C7.146 16.1123 7 16.6333 7 17.2V19C7 19.2833 7.096 19.5207 7.288 19.712C7.47933 19.904 7.71667 20 8 20H22Z"
        fill="#4E5AF2"
      />
    </svg>
  );
};

export const IconListCheck = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="20" height="20" rx="4" fill="white" />
      <path d="M6 13L9.5 16.5L18 7.5" stroke="#71D475" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const IconPlus: FC<any> = ({ onClick }: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        d="M15.8419 10.8427H13.1558V8.15809C13.1558 7.5192 12.6381 7 11.9992 7C11.3603 7 10.8411 7.5192 10.8411 8.15809V10.8427H8.15654C7.51764 10.8427 7 11.3619 7 12.0008C7 12.6397 7.51764 13.1589 8.15654 13.1589H10.8411V15.8419C10.8411 16.4808 11.3603 17 11.9992 17C12.6381 17 13.1558 16.4808 13.1558 15.8419V13.1589H15.8419C16.4808 13.1589 17 12.6397 17 12.0008C17 11.3619 16.4808 10.8427 15.8419 10.8427Z"
        fill="#8D8BED"
      />
    </svg>
  );
};

export const IconRemoveInput: FC<any> = ({ onClick }: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5247 3.49499C13.7841 3.49499 14 3.71032 14 3.98431V4.23765C14 4.50498 13.7841 4.72697 13.5247 4.72697H2.4759C2.21591 4.72697 2 4.50498 2 4.23765V3.98431C2 3.71032 2.21591 3.49499 2.4759 3.49499H4.41971C4.81457 3.49499 5.1582 3.21432 5.24703 2.81833L5.34882 2.36366C5.50702 1.74434 6.02766 1.33301 6.62351 1.33301H9.37649C9.96585 1.33301 10.4923 1.74434 10.6447 2.331L10.7536 2.81766C10.8418 3.21432 11.1854 3.49499 11.5809 3.49499H13.5247ZM12.5372 12.7557C12.7402 10.8644 13.0955 6.37111 13.0955 6.32577C13.1084 6.18844 13.0637 6.05844 12.9749 5.95378C12.8796 5.85578 12.759 5.79778 12.626 5.79778H3.37901C3.24545 5.79778 3.11837 5.85578 3.03019 5.95378C2.94071 6.05844 2.89662 6.18844 2.90311 6.32577C2.9043 6.3341 2.91705 6.49238 2.93836 6.75699C3.03305 7.93249 3.29678 11.2065 3.46719 12.7557C3.58779 13.897 4.33665 14.6144 5.42137 14.6404C6.25841 14.6597 7.12074 14.6664 8.00252 14.6664C8.83308 14.6664 9.67661 14.6597 10.5396 14.6404C11.6619 14.621 12.4101 13.9164 12.5372 12.7557Z"
        fill="#D2D8E8"
      />
    </svg>
  );
};

export const IconUsersAddModalAside: FC = () => {
  return (
    <svg width="95" height="219" viewBox="0 0 95 219" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M89.6586 47.6558C89.6586 47.6558 73.7544 72.0067 69.4085 68.9512C69.4085 68.9512 62.4735 65.4329 64.9701 51.0816C64.9701 49.6928 64.9701 48.7669 64.9701 48.4891C64.9701 48.4891 63.8605 34.3231 61.5488 31.4528C61.2714 31.1751 60.5317 30.5269 60.6242 30.5269C67.7441 28.7677 70.5181 43.8597 72.4599 52.1001C72.5524 52.4704 85.96 42.0079 85.96 42.0079C85.96 42.0079 93.0799 32.6565 93.5422 33.212C94.282 33.9527 91.508 38.2118 91.508 38.2118C91.508 38.2118 91.6929 38.6747 92.2477 38.9525C92.8025 39.2303 93.0799 39.508 93.2648 40.2487C93.4498 40.9895 94.1895 41.3598 94.282 41.8227C94.282 42.1931 91.1381 46.5448 89.6586 47.6558Z"
        fill="#FEB38C"
      />
      <path
        d="M86.1455 42.0081C86.1455 42.0081 87.5325 37.9342 88.1798 38.0268C88.7346 38.1194 91.4161 39.323 91.3237 40.3415C91.2312 41.36 89.012 40.5267 89.012 40.5267C89.012 40.5267 89.4743 42.8414 88.2722 43.1192C87.1626 43.3969 86.1455 42.0081 86.1455 42.0081Z"
        fill="#FEB38C"
      />
      <path
        d="M89.659 40.3415C89.659 40.3415 89.5665 41.36 90.3987 41.5452C90.3987 41.5452 90.5836 42.6562 91.6932 42.3784C91.6932 42.3784 91.4158 42.8414 92.0631 42.934C92.0631 42.934 89.2891 45.9894 89.659 40.3415Z"
        fill="#F79A88"
      />
      <path
        d="M89.104 38.3046C89.6588 38.4898 90.2136 38.675 90.7684 38.9527C91.0458 39.0453 91.3232 39.1379 91.6006 39.4157C91.6931 39.5083 91.878 39.6935 91.9705 39.8786C92.0629 40.0638 91.9705 40.3416 91.7855 40.5267C91.2307 40.9897 90.491 40.9897 89.9362 40.9897C89.7513 40.9897 89.5663 40.9897 89.5663 40.9897C89.5663 40.8971 89.5663 41.1749 89.5663 41.2675L89.6588 42.1933L89.4739 41.2675C89.4739 41.0823 89.3814 40.9897 89.4739 40.8045C89.6588 40.7119 89.7513 40.7119 89.9362 40.7119C90.491 40.7119 91.1383 40.6193 91.6006 40.249C91.6931 40.1564 91.7855 39.9712 91.6931 39.8786C91.6931 39.786 91.5081 39.6009 91.4157 39.5083C91.1383 39.3231 90.8609 39.2305 90.6759 39.0453L89.104 38.3046Z"
        fill="#EC7A65"
      />
      <path
        d="M91.5082 38.0267C91.5082 38.1193 91.5082 38.3045 91.4157 38.3971C91.3233 38.4897 91.3233 38.5823 91.2308 38.6749C91.1383 38.7674 91.0459 38.86 90.9534 38.86C90.8609 38.9526 90.7684 38.9526 90.5835 38.9526C90.8609 38.6749 91.2308 38.3045 91.5082 38.0267Z"
        fill="#EC7A65"
      />
      <path
        d="M92.7098 39.6008C92.5249 39.8785 92.4324 40.1563 92.155 40.4341C91.9701 40.7118 91.6927 40.897 91.4153 41.0822C91.1379 41.2674 90.8604 41.4525 90.583 41.5451C90.3981 41.6377 90.2132 41.6377 90.0283 41.6377C89.8433 41.6377 89.6584 41.4525 89.5659 41.3599C89.7509 41.6377 90.1207 41.5451 90.3981 41.4525C90.6755 41.2674 90.9529 41.0822 91.1378 40.897C91.8776 40.4341 92.3399 39.9711 92.7098 39.6008Z"
        fill="#EC7A65"
      />
      <path
        d="M90.3984 41.5451C90.5834 41.9155 90.6758 42.1932 90.9532 42.2858C91.1382 42.3784 91.508 42.2858 91.693 42.1006C91.9704 41.9155 92.2478 41.6377 92.4327 41.4525C92.7101 41.1747 92.9875 40.9896 93.2649 40.7118C93.08 40.9896 92.8026 41.2673 92.5252 41.5451C92.2478 41.8229 92.0628 42.1006 91.7854 42.2858C91.508 42.471 91.1382 42.6562 90.7683 42.471C90.4909 42.2858 90.4909 41.8229 90.3984 41.5451Z"
        fill="#EC7A65"
      />
      <path
        d="M91.693 42.1934C91.7855 42.3786 91.8779 42.5638 91.9704 42.7489C92.0629 42.8415 92.0629 42.9341 92.1554 42.9341C92.1554 42.9341 92.2478 42.9341 92.3403 42.9341C92.5252 42.9341 92.7101 42.9341 92.8951 42.8415C93.1725 42.8415 93.265 42.8415 93.5424 42.7489C93.4499 42.9341 93.1725 43.0267 92.9876 43.1193C92.8026 43.2119 92.5252 43.3045 92.3403 43.2119C92.2478 43.2119 92.0629 43.2119 92.0629 43.1193C91.9704 43.0267 91.9704 42.9341 91.878 42.8415C91.693 42.5638 91.6005 42.3786 91.693 42.1934Z"
        fill="#EC7A65"
      />
      <path
        d="M44.9047 40.7117C44.9047 40.7117 35.4731 39.6006 36.5827 39.0451C37.6923 38.4895 45.6445 35.5267 47.1239 34.6008C48.6959 33.6749 49.713 33.8601 49.713 33.8601L60.9015 7.93526C61.0864 6.45384 58.3124 2.75028 57.0179 2.00957C53.3192 -0.119966 48.8808 -0.397729 44.7198 0.435569C31.2197 3.21323 9.85986 12.8425 2.64746 19.7866C2.64746 19.7866 34.9183 50.5261 45.4595 49.0446"
        fill="#18004C"
      />
      <path
        d="M0.613299 17.5646C7.5483 14.1388 14.7607 10.9908 21.9731 8.21311C27.891 5.8984 33.9013 3.76885 40.0041 2.28743C42.2233 1.7319 44.4425 1.26896 46.6617 1.17637C47.2165 1.17637 47.2165 0.343079 46.6617 0.343079C42.0384 0.343079 37.3226 2.00966 32.9766 3.39849C26.0416 5.62062 19.1991 8.3057 12.449 11.1759C8.28803 12.9351 4.2195 14.8795 0.150966 16.8238C-0.218901 17.1016 0.150966 17.7497 0.613299 17.5646Z"
        fill="#18004C"
      />
      <path
        d="M46.754 13.7683C46.754 13.9535 46.5691 14.1387 46.3842 14.1387C45.552 14.1387 43.6102 13.9535 43.3328 16.1756C43.0554 18.3977 45.1821 19.694 46.4767 19.7866C46.8465 19.7866 47.6787 19.8792 47.9561 20.1569C48.6958 20.9902 49.0657 22.842 49.713 25.1567C51.0075 30.2491 47.0314 32.5638 47.0314 32.5638C47.0314 32.5638 60.3467 39.1376 60.1617 32.5638C60.1617 32.5638 59.1446 20.5273 59.1446 20.4347C60.1617 19.4162 64.2303 18.9533 61.6412 10.3425C59.3295 2.37989 53.5041 2.93542 52.0247 3.21319C50.8226 3.39837 46.4766 3.67614 46.754 13.7683Z"
        fill="#FEB38C"
      />
      <path
        d="M56.1856 2.38002C56.1856 2.38002 48.0485 13.1203 46.6615 14.0462C46.569 14.0462 41.021 0.8986 56.1856 2.38002Z"
        fill="#18004C"
      />
      <path d="M44.2578 16.8239C44.2578 16.8239 45.5523 14.0463 47.3092 17.7498L44.2578 16.8239Z" fill="#EC7A65" />
      <path
        d="M54.1869 17.7418C55.31 17.7164 56.2 16.7841 56.1747 15.6595C56.1494 14.5349 55.2183 13.6437 54.0952 13.6691C52.972 13.6944 52.082 14.6267 52.1074 15.7513C52.1327 16.876 53.0637 17.7671 54.1869 17.7418Z"
        fill="#F79A88"
      />
      <path
        d="M62.4735 14.5092C61.5488 14.324 60.9015 13.5833 60.8091 12.5648C60.8091 11.8241 61.1789 11.176 61.7337 10.8056C61.7337 10.8056 62.6584 13.0277 62.4735 14.5092Z"
        fill="#F79A88"
      />
      <path
        d="M60.7164 13.4907L57.665 14.9721C57.665 14.9721 59.1445 16.0831 59.9767 15.435C60.8089 14.8795 60.7164 13.4907 60.7164 13.4907Z"
        fill="white"
      />
      <path
        d="M58.4048 11.2686L58.8671 13.3981L59.8842 12.9352C60.0692 12.8426 60.0691 12.5649 59.9767 12.4723L58.4048 11.2686Z"
        fill="#EC7A65"
      />
      <path
        d="M54.5213 10.4353C54.2439 10.8057 54.4288 9.50945 54.7987 9.23168C55.261 8.95391 55.8158 8.95391 56.0007 9.41686C56.0932 9.32427 55.1685 9.50945 54.5213 10.4353Z"
        fill="#1E274D"
      />
      <path
        d="M60.2548 8.30571C60.7171 8.3983 59.7925 7.565 59.2377 7.565C58.6829 7.565 58.313 8.02794 58.313 8.49088C58.4055 8.39829 59.2377 7.93536 60.2548 8.30571Z"
        fill="#1E274D"
      />
      <path
        d="M56.9316 12.5928C57.121 12.5162 57.1506 12.1467 56.9976 11.7673C56.8446 11.388 56.5671 11.1425 56.3777 11.2191C56.1882 11.2957 56.1587 11.6653 56.3116 12.0446C56.4646 12.424 56.7421 12.6694 56.9316 12.5928Z"
        fill="#1E274D"
      />
      <path
        d="M59.6756 11.2255C59.8651 11.1489 59.9101 10.8178 59.7763 10.4859C59.6425 10.1539 59.3805 9.94692 59.1911 10.0235C59.0017 10.1 58.9566 10.4312 59.0904 10.7631C59.2242 11.095 59.4862 11.3021 59.6756 11.2255Z"
        fill="#1E274D"
      />
      <path
        d="M59.1444 20.3424C59.1444 20.3424 55.3533 21.0831 53.4115 20.8979C51.5622 20.8054 61.0862 31.8234 59.1444 20.3424Z"
        fill="#1E274D"
      />
      <path
        d="M54.7987 3.39842C54.9836 5.9909 53.4117 8.67599 51.7473 10.5278C50.1754 12.2869 47.7712 13.5832 45.3671 13.398C44.8123 13.3054 44.9047 14.1387 45.3671 14.2313C47.9561 14.5091 50.6377 13.0277 52.3021 11.1759C54.1514 9.13893 55.8158 6.36126 55.6309 3.49101C55.6309 2.84289 54.7987 2.84288 54.7987 3.39842Z"
        fill="#18004C"
      />
      <path
        d="M48.0489 24.2308C49.0192 24.2308 49.8057 23.4432 49.8057 22.4716C49.8057 21.5001 49.0192 20.7125 48.0489 20.7125C47.0786 20.7125 46.292 21.5001 46.292 22.4716C46.292 23.4432 47.0786 24.2308 48.0489 24.2308Z"
        fill="white"
      />
      <path
        d="M48.0489 27.7491C49.0192 27.7491 49.8057 26.9615 49.8057 25.9899C49.8057 25.0183 49.0192 24.2307 48.0489 24.2307C47.0786 24.2307 46.292 25.0183 46.292 25.9899C46.292 26.9615 47.0786 27.7491 48.0489 27.7491Z"
        fill="white"
      />
      <path d="M50.3606 18.7681L47.9565 22.1939L45.7373 18.5829L50.3606 18.7681Z" fill="#4F6AFF" />
      <path
        d="M49.1587 195.613L50.6382 206.075C50.6382 206.075 52.9498 213.019 55.169 210.334C57.3882 207.649 59.5149 206.26 59.5149 206.26L60.2547 195.427L49.1587 195.613Z"
        fill="#F5836B"
      />
      <path
        d="M54.8921 195.983L55.2619 210.149C55.2619 210.149 48.3269 211.167 50.916 206.538L49.4365 196.724L54.8921 195.983Z"
        fill="#FEB38C"
      />
      <path
        d="M25.303 195.89L22.0667 206.26C22.0667 206.26 19.5701 208.39 18.7379 208.39C17.0735 208.297 16.2413 205.519 16.2413 205.519L15.9639 195.89H25.303Z"
        fill="#F5836B"
      />
      <path
        d="M20.5862 193.113L18.2745 210.334C18.2745 210.334 13.6511 211.538 16.2402 206.908L15.6854 193.668L20.5862 193.113Z"
        fill="#FEB38C"
      />
      <path
        d="M60.1619 206.075L68.8538 213.112C69.3161 213.482 68.9463 214.038 68.299 214.038L55.4462 214.13C55.1688 214.13 54.8914 214.038 54.7989 213.76L53.8742 211.723C53.5968 211.167 53.042 211.075 53.042 211.723L52.5797 213.667C52.5797 213.945 49.5283 213.852 49.5283 213.575C49.3434 211.908 49.7132 206.26 49.9906 204.686C49.9906 204.501 50.268 204.316 50.5454 204.223C50.8228 204.223 51.1002 204.223 51.2852 204.408C52.1174 205.242 54.8914 207.742 56.3708 207.279C57.388 206.908 58.6825 206.353 59.0523 205.982C59.4222 205.89 59.8845 205.89 60.1619 206.075Z"
        fill="#FF3040"
      />
      <path
        d="M16.0558 205.149L11.0627 213.482C10.5079 214.316 10.7853 215.241 11.8024 215.797C13.3743 216.723 14.5764 218.575 15.5935 218.575C17.3504 218.667 20.1244 216.723 21.6963 216.075C22.8059 215.612 23.6381 214.964 23.4532 214.038L22.2511 205.705C22.2511 205.612 22.0662 205.52 21.9737 205.612L20.0319 207.371C19.3846 207.927 17.7203 207.834 17.3504 207.186C17.073 206.723 16.3332 205.149 16.3332 205.149C16.3332 205.057 16.1483 205.057 16.0558 205.149Z"
        fill="#FF3040"
      />
      <path
        d="M40.4665 66.1737C36.6754 72.8401 34.7336 77.6547 32.6068 86.1728C30.1102 95.9872 6.9011 178.947 5.7915 196.816C8.8429 197.557 32.7918 197.464 34.7336 197.372C40.9288 179.595 49.2508 121.727 51.9324 110.524C52.3022 108.764 54.9838 109.135 54.7988 110.894C53.3194 123.671 43.9802 177.65 40.0042 197.372C41.6686 198.205 66.2647 198.02 68.114 197.742C70.1483 175.799 80.9669 92.5615 64.6928 65.6181C57.1105 64.5997 47.9563 65.9885 40.4665 66.1737Z"
        fill="#4F6AFF"
      />
      <path
        d="M62.9359 66.7294C62.9359 66.7294 70.1483 84.9693 70.1483 108.209C70.1483 131.449 62.0112 197.002 62.0112 197.002"
        stroke="white"
        strokeWidth="0.314"
        strokeMiterlimit="10"
      />
      <path d="M10.5073 196.631L44.0727 67.7477" stroke="white" strokeWidth="0.314" strokeMiterlimit="10" />
      <path
        d="M48.7886 68.3959C48.7886 68.3959 48.6036 89.6913 39.0796 87.9321"
        stroke="white"
        strokeWidth="0.314"
        strokeMiterlimit="10"
      />
      <path
        d="M68.4845 88.9507C58.9604 90.6173 60.2549 67.9331 60.2549 67.9331"
        stroke="white"
        strokeWidth="0.314"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.21"
        d="M64.6927 66.9144L33.8089 92.0985C33.8089 92.0985 33.6239 75.9881 39.0795 65.7108L64.6927 66.9144Z"
        fill="#1E274D"
      />
      <path
        d="M62.8437 31.5456C62.6587 32.1011 68.7615 47.2857 68.4841 52.4706C68.2067 57.6556 68.2992 65.1553 68.5766 65.896C68.5766 65.896 60.9019 70.4328 38.6174 66.9145C31.7749 60.8962 37.7852 40.5267 40.0969 34.1381C40.0969 34.1381 44.4428 31.916 46.3846 31.8234C46.3846 31.7308 54.5217 34.9714 62.8437 31.5456Z"
        fill="#18004C"
      />
      <path
        d="M46.3843 31.6377L49.5281 27.4712C49.5281 27.4712 53.3193 30.1563 58.9597 32.1006L58.8673 39.4151C58.8673 39.6003 58.5899 39.7855 58.4049 39.6003C56.6481 38.767 48.6959 35.156 46.3843 31.6377Z"
        fill="white"
      />
      <path
        d="M59.6994 27.2866C59.6994 27.2866 60.624 30.6198 58.8672 32.0086L61.3638 39.1379C61.5487 39.6934 61.9186 39.6934 62.011 39.4157C62.3809 37.5639 63.1206 33.6752 62.6583 31.2679L59.6994 27.2866Z"
        fill="white"
      />
      <path
        d="M60.3468 40.712C60.6532 40.712 60.9016 40.2975 60.9016 39.7861C60.9016 39.2748 60.6532 38.8602 60.3468 38.8602C60.0404 38.8602 59.792 39.2748 59.792 39.7861C59.792 40.2975 60.0404 40.712 60.3468 40.712Z"
        fill="white"
      />
      <path
        d="M60.6246 44.786C60.931 44.786 61.1794 44.3714 61.1794 43.8601C61.1794 43.3487 60.931 42.9342 60.6246 42.9342C60.3182 42.9342 60.0698 43.3487 60.0698 43.8601C60.0698 44.3714 60.3182 44.786 60.6246 44.786Z"
        fill="white"
      />
      <path
        d="M60.8092 49.3225C61.1156 49.3225 61.364 48.908 61.364 48.3967C61.364 47.8853 61.1156 47.4708 60.8092 47.4708C60.5028 47.4708 60.2544 47.8853 60.2544 48.3967C60.2544 48.908 60.5028 49.3225 60.8092 49.3225Z"
        fill="white"
      />
      <path
        d="M60.8092 54.4151C61.1156 54.4151 61.364 54.0005 61.364 53.4892C61.364 52.9778 61.1156 52.5633 60.8092 52.5633C60.5028 52.5633 60.2544 52.9778 60.2544 53.4892C60.2544 54.0005 60.5028 54.4151 60.8092 54.4151Z"
        fill="white"
      />
      <path
        d="M60.8092 59.4147C61.1156 59.4147 61.364 59.0002 61.364 58.4888C61.364 57.9775 61.1156 57.5629 60.8092 57.5629C60.5028 57.5629 60.2544 57.9775 60.2544 58.4888C60.2544 59.0002 60.5028 59.4147 60.8092 59.4147Z"
        fill="white"
      />
      <path
        d="M60.8092 64.4145C61.1156 64.4145 61.364 64 61.364 63.4886C61.364 62.9773 61.1156 62.5627 60.8092 62.5627C60.5028 62.5627 60.2544 62.9773 60.2544 63.4886C60.2544 64 60.5028 64.4145 60.8092 64.4145Z"
        fill="white"
      />
      <path
        d="M43.7952 113.394L15.0381 94.9686L26.9663 76.3583L51.7474 92.2836C53.8741 93.6724 54.5214 96.5427 53.1344 98.6722L43.7952 113.394Z"
        fill="#98CCFE"
      />
      <path
        d="M35.3808 98.6719C37.5768 98.6719 39.3569 96.8895 39.3569 94.6906C39.3569 92.4918 37.5768 90.7094 35.3808 90.7094C33.1849 90.7094 31.4048 92.4918 31.4048 94.6906C31.4048 96.8895 33.1849 98.6719 35.3808 98.6719Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M33.6243 79.969C33.6243 79.969 29.001 82.0985 32.6072 95.2461C32.7922 95.9869 32.9771 96.7276 32.7921 97.4683L31.9599 103.116C31.8675 103.764 31.775 104.505 32.2373 104.875C32.8846 105.338 36.4908 108.671 36.9531 108.857C37.8778 109.134 36.8607 104.69 37.0456 102.375C37.0456 101.913 37.1381 103.857 37.2305 104.69L37.323 106.542C38.4326 106.079 38.4326 103.949 38.5251 103.209C38.71 102.283 38.5251 101.635 38.4326 101.45C37.9703 100.431 36.9531 98.3016 36.8607 96.4498C36.7682 95.3387 37.5079 91.2648 38.1552 83.7651L33.6243 79.969Z"
        fill="#1E274D"
      />
      <path
        d="M33.069 48.026C24.4696 96.0796 30.0176 105.153 30.6648 105.524C31.4046 105.987 35.3806 109.69 35.843 109.875C36.8601 110.246 35.7505 105.338 35.9354 102.746C35.9354 102.283 36.0279 104.413 36.1204 105.338L36.2128 107.375C37.4149 106.82 37.4149 104.505 37.5998 103.764C37.8772 102.746 37.6923 102.005 37.5074 101.82C36.9526 100.709 35.9354 98.3942 35.7505 96.3573C35.658 95.1536 43.7951 56.1738 45.1821 50.4333C47.1239 42.6559 44.7198 33.6748 41.9458 33.397C38.5245 32.8415 33.7162 44.0447 33.069 48.026Z"
        fill="#FEB38C"
      />
    </svg>
  );
};
