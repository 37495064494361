import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
// components
import Template404 from '@templates/404Template';
import ButtonDefault from '@atoms/buttons/button';

const Page404: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Template404>
      <span>{t('Ошибка!')}</span>
      <p>{ReactHtmlParser(t('К сожалению, запрашиваемая <br /> Вами страница не найдена..'))}</p>
      <ButtonDefault name={t('Перейти на Главную')} classes={'btn-default btn-white'} action={() => navigate('/')} />
    </Template404>
  );
};

export default Page404;
