import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// styles
import './style.scss';

interface TooltipConfirmPhoneBodyType {
  onClick?: () => void;
}

const TooltipConfirmPhoneBody: FC<TooltipConfirmPhoneBodyType> = ({ onClick }: TooltipConfirmPhoneBodyType) => {
  const { t } = useTranslation();

  return (
    <div className="tooltip-confirm-phone">
      <div className="tooltip-confirm-phone__title">{t('Подтвердите номер телефона')}:</div>
      <ul className="tooltip-confirm-phone__ul">
        <li value={'1'} className="tooltip-confirm-phone__li">
          {t('Для защиты от несанкционированного использования другими лицами')}
        </li>
        <li value={'2'} className="tooltip-confirm-phone__li">
          {t('Входа в программу')}
        </li>
      </ul>
      <div className="tooltip-confirm-phone__footer" onClick={onClick}>
        {t('Подтвердить')}
      </div>
    </div>
  );
};

export default TooltipConfirmPhoneBody;
