import React, { FC } from 'react';
import Sider from 'antd/es/layout/Sider';

interface AppSiderType {
  children?: React.ReactNode;
}

const AppSider: FC<AppSiderType> = ({ children }: AppSiderType) => {
  return (
    <Sider theme="light" trigger={null} collapsible className="sider _app-sider">
      {children}
    </Sider>
  );
};

export default AppSider;
