import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import user from './user';
import menu from './menu';
import company from './company';
import tasks from './tasks';
import candidates from './candidates';
import departments from './departments';
import addresses from './addresses';
import positions from './positions';
// old
import { dropDownListReducer } from '@redux/reducers/old/reducers/dropDownLists-reducer';
import { companyReducer } from '@redux/reducers/old/reducers/company-reducer';
import { globalReducer } from './old/reducers/global-reducer';
import { deputyReducer } from './old/reducers/deputy-reducer';
import { vacanciesReducer } from './old/reducers/vacancies-reducer';
import { addressesReducer } from './old/reducers/addresses-reducer';
import { departmentsReducer } from './old/reducers/departments-reducer';
import { positionsListReducer } from './old/reducers/positions-reducer';
import { functionalityReducer } from './old/reducers/functionality-reducer';
import { KPIReducer } from './old/reducers/kpi-reducer';
import { resultReducer } from './old/reducers/result-reducer';
import { zunReducer } from './old/reducers/zun-reducer';
import { targetReducer } from './old/reducers/target-reducer';
import { candidatesReducer } from './old/reducers/candidates-reducer';
import { tasksReducer } from './old/reducers/tasks-reducer';
import lcVerificator from './lcVerificator';

export default combineReducers({
  app,
  auth,
  user,
  menu,
  company,
  dropDownList: dropDownListReducer,
  global: globalReducer,
  deputy: deputyReducer,
  vacancies: vacanciesReducer,
  addresses: addressesReducer,
  departments: departmentsReducer,
  positions: positionsListReducer,
  functionality: functionalityReducer,
  kpi: KPIReducer,
  result: resultReducer,
  zun: zunReducer,
  target: targetReducer,
  candidates: candidatesReducer,
  tasks: tasksReducer,
  lcVerificator: lcVerificator,
});
