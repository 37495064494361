/**
 * Create the store with asynchronously loaded reducers
 */

import { compose, createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers/index';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { sagaWatcher } from './sagas/index';
const saga = createSagaMiddleware();

const composeSetup =
  process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const store = createStore(rootReducer, composeSetup(applyMiddleware(thunk, saga)));

saga.run(sagaWatcher);

export default store;
