import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
// components
import EmptySuccessCompanyModal from '@organisms/modals/confirms/emptySuccessCompany';
import SkeletonCompanies from '@atoms/skeletons/companies';
import CompanyListItem from './item';
// types
import { stateType } from '@types';
// styles
import './style.scss';

export interface CompanyType {
  id: number;
  name: string;
  role: {
    id: number;
    name: string;
  };
  status: {
    id: number;
    name: string;
  };
}

const CompaniesList: FC = () => {
  const { list, loading, loadingLogin } = useSelector((state: stateType) => state.company);
  const { info } = useSelector((state: stateType) => state.user);

  const [success, setSuccess] = useState<false | { title: string; desc: string }>(false);
  const [initID, setInitID] = useState<false | number>(false);

  return (
    <div className="companies-list">
      {success && <EmptySuccessCompanyModal title={success.title} desc={success.desc} setSuccess={setSuccess} />}
      {loading && <SkeletonCompanies />}
      {list &&
        list.map((item: CompanyType, index: number) => {
          return (
            <CompanyListItem
              key={index}
              item={item}
              loadingLogin={loadingLogin}
              initID={initID}
              setInitID={setInitID}
              index={index}
              setSuccess={setSuccess}
              info={info}
            />
          );
        })}
    </div>
  );
};

export default CompaniesList;
