import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';
// components
import EditProfile from '@organisms/forms/editProfile';
// style
import './style.scss';
import { useDispatch } from 'react-redux';
import { getOptions } from '@redux/reducers/app';
import { t } from 'i18next';

interface EditProfileModalModalType {
  component: any;
  action?: any;
}

const EditProfileModal: FC<EditProfileModalModalType> = ({
  component: Component,
  action,
}: EditProfileModalModalType) => {
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {

    return () => {
      console.log('unmount', visible);
    };
  }, []);

  useEffect(() => {
    if (visible) {
      dispatch(
        getOptions({
          request: {
            source: ['gender', 'social_media', 'messengers'],
          },
        }),
      );
    }
  }, [visible]);

  const onCancel = () => {
    setVisible(false);
  };

  const call = () => {
    setLoader(true);
    action(onCancel);
  };

  return (
    <>
      <>
        <Component
          onClick={() => {
            setVisible(true);
          }}
        />
        <Modal
          className="modal-edit-profile"
          open={visible}
          width={1200}
          footer={null}
          closable={true}
          bodyStyle={{ padding: '64px' }}
          centered
          onCancel={onCancel}
          maskClosable = {false}
          maskStyle={{ backgroundColor: 'rgba(220,222,252,0.6)' }}
        >
          <div className="modal-edit-profile__body">
            <div className="modal-edit-profile__header">
              <div className="modal-edit-profile__title">{t('Редактирование информации')}</div>
              <div className="modal-edit-profile__desc">{t('Внесите изменения в личные данные')}</div>
            </div>
            <div className="modal-edit-profile__form">
              <EditProfile setVisible={setVisible} />
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default EditProfileModal;
