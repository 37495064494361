import React from 'react';

function PersonnelEvaluationIcon() {
   return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd"
               d="M11.8436 6.46868C11.8436 8.67181 10.0408 10.4374 7.79113 10.4374C5.54226 10.4374 3.73861 8.67181 3.73861 6.46868C3.73861 4.26554 5.54226 2.5 7.79113 2.5C10.0408 2.5 11.8436 4.26554 11.8436 6.46868ZM1.66669 14.9311C1.66669 12.8917 4.48796 12.3814 7.79113 12.3814C11.1122 12.3814 13.9156 12.9093 13.9156 14.9503C13.9156 16.9897 11.0943 17.5 7.79113 17.5C4.47002 17.5 1.66669 16.9722 1.66669 14.9311ZM13.4779 6.54062C13.4779 7.66255 13.1338 8.70942 12.5304 9.57902C12.4676 9.66845 12.523 9.78903 12.6323 9.80819C12.784 9.83295 12.9397 9.84812 13.0987 9.85131C14.6806 9.89204 16.1002 8.89468 16.4924 7.39265C17.0738 5.16396 15.368 3.16286 13.1949 3.16286C12.9593 3.16286 12.7334 3.18681 12.5133 3.23073C12.4831 3.23712 12.4505 3.25149 12.4342 3.27705C12.413 3.30979 12.4285 3.35211 12.4497 3.38006C13.1028 4.2768 13.4779 5.36839 13.4779 6.54062ZM16.0977 11.4186C17.161 11.6222 17.8598 12.0366 18.1493 12.6411C18.3947 13.1362 18.3947 13.7112 18.1493 14.2062C17.7065 15.1421 16.2779 15.4432 15.7227 15.5206C15.6077 15.5366 15.5155 15.4408 15.5278 15.3274C15.8115 12.7338 13.5553 11.504 12.9715 11.2213C12.947 11.2078 12.9413 11.1886 12.9438 11.1758C12.9454 11.1678 12.956 11.1551 12.9748 11.1527C14.2378 11.1287 15.5963 11.2988 16.0977 11.4186Z"
               fill="currentColor"/>
      </svg>
   
   
   );
}

export default PersonnelEvaluationIcon;