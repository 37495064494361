import React, { FC, useEffect, useState } from 'react';
import { object } from 'prop-types';
import { Checkbox, Col, Row } from 'antd';

import __ from 'lodash';
import AppSearchInput from '@molecules/app/search';

const PositionFunctionalityModalPositionSelected: FC<any> = ({ positionData }: any) => {
  const [searchItem, setSearchItem] = useState('');
  const { checkedList, onChangeCheckListFunctionality, tabIndex } = positionData;
  const [list, setList] = useState(checkedList);

  useEffect(() => {
    setList(__.sortBy(checkedList, 'name'));
  }, [checkedList]);

  useEffect(() => {
    setList(__.sortBy(checkedList, 'name'));
    if (tabIndex === '2') {
      setList((prev: any) =>
        __.sortBy(
          prev.filter((item: any) => !item.isUserAdd),
          'name',
        ),
      );
    } else if (tabIndex === '3') {
      setList((pre: any) =>
        __.sortBy(
          pre.filter((item: any) => item.isUserAdd),
          'name',
        ),
      );
    }
  }, [tabIndex]);

  return (
    <>
      <AppSearchInput />
      <Row gutter={[5, 5]}>
        {list
          .filter((opt: any) => opt.name.toLowerCase().includes(searchItem.toLowerCase()))
          .map((opt: any) => {
            return (
              <Col key={opt.id} span={24}>
                <Checkbox
                  onChange={() => onChangeCheckListFunctionality(opt.id)}
                  className="functionality-list__item"
                  value={opt.name}
                  checked={opt.selected}
                >
                  <span className="functionality-list__name">{opt.name}</span>
                </Checkbox>
              </Col>
            );
          })}
      </Row>
    </>
  );
};

export default PositionFunctionalityModalPositionSelected;
