import React, {FC, useEffect, useState} from 'react'
import {Tabs} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
import {Link, useNavigate, useParams} from 'react-router-dom'
// components
// actions
import {appPage} from '@redux/reducers/app'
import {getCompanies} from '@redux/reducers/company'
// styles
import './style.scss'
import UpdatePassword from '@organisms/forms/updatePassword'
import LanguageAndRegion from '@organisms/profile/languageAndRegion'
import ForgetPasswordModal from '@organisms/modals/settings/forgetPassword'

const { TabPane } = Tabs;

const TabsProfileContainer: FC = () => {
  const { page } = useSelector((state: any) => state.app);
  const { info } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [modalActive, setModalActive] = useState(false)
  const { tab } = useParams();

  useEffect(() => {
    dispatch(appPage(tab));
  }, [tab]);

  const onChange = (value: string) => {
    dispatch(appPage(value));

    switch (value) {
      case 'password':
        // dispatch(getCompanies({ request: { type: 'active' } }));
        // navigate(`/settings/${value}`);
        break;

      case 'region':
        // dispatch(getCompanies({ request: { type: 'active' } }));
        // navigate(`/settings/${value}`);
        break;

      default:
        navigate(`/settings/${value}`);
        break;
    }
  };


  return (
    <Tabs
      onChange={onChange}
      activeKey={page ? page : 'password'}
      centered
      className='settings-tabs'
    >
      <TabPane tab={t('Пароль')} key="password">
        <div className="tab_pane_title">{t('Смена пароля')}</div>
        <UpdatePassword setModalActive={setModalActive}/>
        <ForgetPasswordModal modalActive={modalActive} setModalActive={setModalActive}/>

      </TabPane>
      <TabPane tab={t('Язык и регион')} key="region" className="padding-0">
        <div className="tab_pane_title">{t('Язык и регион')}</div>
        <LanguageAndRegion/>
      </TabPane>
    </Tabs>
  );
};

export default TabsProfileContainer;
