import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
// components
import { UserProfile } from '@molecules/app/avatar';
import ButtonDefault from '@atoms/buttons/button';
// actions
import { editMessage, removeMessage } from '@redux/reducers/lcVerificator';
// icons
import { IconLcEdit, IconLcRemove } from '@assets/icons/lcVerificator';
// types
import { stateType } from '@types';
// styles
import './style.scss';

const LcMessagesList: FC = () => {
  const { company }: any = useSelector((state: stateType) => state.lcVerificator);
  const [edit, setEdit] = useState<any>(null);
  const [editValue, setEditValue] = useState('');

  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const getChange = (value: any) => {
    setEditValue(value);
  };

  const editAction = (messageId: any) => {
    dispatch(
      editMessage({
        request: {
          company_id: id,
          message_id: messageId,
          text: editValue,
        },
        callback: {
          success: () => {
            setEdit(null);
            //NOTIFICATION.success(t('Сообщение отредактировано'));
          },
        },
      }),
    );
  };

  const removeAction = (messageId: any) => {
    dispatch(
      removeMessage({
        request: {
          company_id: id,
          message_id: messageId,
        },
        callback: {
          success: () => {
            setEdit(null);
            //NOTIFICATION.success(t('Сообщение удаленно'));
          },
        },
      }),
    );
  };

  return (
    <div className="lc-messages-list">
      {company?.messages &&
        company?.messages?.map((item: any) => {
          return (
            <div className="lc-messages-list__wrapper" key={item?.id}>
              <div
                className={classNames('lc-messages-list__item', {
                  ['lc-messages-list__item-focus']: edit === item?.id ? true : false,
                })}
              >
                <div className="lc-messages-list__item-body">
                  <div className="lc-messages-list__item-header">
                    <div className="lc-messages-list__item-profile">
                      <UserProfile name={item?.author?.name ? item?.author?.name : 'Admin'} role={t('Верификатор')} />
                    </div>
                    <div className="lc-messages-list__item-date">
                      {moment(item?.update_date).format('MM/DD/YYYY, hh:mm')}
                    </div>
                  </div>
                  {edit !== item?.id && <div className="lc-messages-list__item-text">{item?.text}</div>}
                  {edit === item?.id && <EditText item={item} setChange={getChange} />}
                </div>
                <div className="lc-messages-list__item-nav">
                  {edit !== item?.id && (
                    <IconLcEdit
                      onClick={() => {
                        setEdit(item?.id);
                      }}
                    />
                  )}
                  <IconLcRemove
                    onClick={() => {
                      removeAction(item?.id);
                    }}
                  />
                </div>
              </div>
              {edit === item?.id && (
                <div className="lc-messages-list__footer">
                  <div className="send-message__btns">
                    <span className="send-message__btn-text" onClick={() => setEdit(null)}>
                      {t('Отмена')}
                    </span>
                    <ButtonDefault
                      name={t('Изменить')}
                      type={'submit'}
                      disabled={editValue ? false : true}
                      action={() => editAction(item?.id)}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default LcMessagesList;

interface EditTextType {
  item?: any;
  setChange?: any;
}

const EditText: FC<EditTextType> = ({ item, setChange }: EditTextType) => {
  const inputReference = useRef<any>(null);
  const [value, setValue] = useState('');

  useEffect(() => {
    inputReference.current.focus();
  }, []);

  useEffect(() => {
    item?.text && setValue(item?.text);
    item?.text && setChange(item?.text);
  }, [item]);

  const onchange = (e: any) => {
    setValue(e.target.value);
    setChange(e.target.value);
  };

  return <textarea ref={inputReference} className="lc-messages-list__textarea" value={value} onChange={onchange} />;
};
