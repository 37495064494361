/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
// icons
import { LoadingOutlined } from '@ant-design/icons';
import { IconDelete, IconFile, IconUploaderMini } from '@assets/icons/uploaders';
// styles
import './style.scss';

interface AttachmentType {
  file: any;
  isEditable?: boolean;
  isDownload?: boolean;
  showProgress?: boolean;
  onRemove?: () => void;
}

const Attachment: FC<AttachmentType> = ({ file, isEditable, onRemove, isDownload, showProgress }: AttachmentType) => {
  return (
    <div className="attachment" key={file.id}>
      <div className="attachment__body">
        {file.status === 'loading' ? <LoadingOutlined /> : file.status === 'done' && <IconFile />}
        <span className="attachment__name">{file.name}</span>
        <span className="attachment__size">({file.size})</span>
      </div>
      {isEditable && file.status === 'done' && (
        <div className="attachment__nav">
          {isEditable && isDownload && (
            <Link
              to={'#'}
              download={file.name}
              onClick={() => {
                window.open(file.href);
              }}
            >
              <IconUploaderMini />
            </Link>
          )}

          <IconDelete onClick={onRemove} />
        </div>
      )}
    </div>
  );
};

export default Attachment;
