import React, { useState } from 'react';
// components
// import FormSetDepartment from '@organisms/forms/setDepartment';
import FormSetDepartment from '@organisms/forms/setDepartment/FormSetDepartment';

function useDepartmentSteps() {
  const [currentDepartmentSteps, setCurrentDepartmentSteps] = useState(1);
  const departmentSteps = [
    {
      id: 1,
      content: <FormSetDepartment changeStep={setCurrentDepartmentSteps} step={currentDepartmentSteps} isGoBack />,
    },
  ];

  return {
    currentDepartmentSteps,
    setCurrentDepartmentSteps,
    departmentSteps,
  };
}

export default useDepartmentSteps;
