// types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { exampleValue } from '../../types/app-types';

/*
 *
 * App reducer
 *
 */
const initialState = {
  companies: {},
  companiesAdvanceSearch: [],
  company: {},
  loading: false,
  filter: 4,
};

export type lcVerificatorInitStateType = typeof initialState;

const lcVerificator = createSlice({
  name: 'lcVerificator',
  initialState,
  reducers: {
    getCompanies: () => {
      return;
    },
    getCompaniesAdvanceSearch: () => {
      return
    },
    setCompaniesAdvanceSearch: (state, action: PayloadAction<any>) => {
      state.companiesAdvanceSearch = action.payload
    },
    getCompany: () => {
      return;
    },
    setVerification: () => {
      return;
    },
    removeCompany: () => {
      return;
    },
    searchCompanies: () => {
      return;
    },
    sendMessage: () => {
      return;
    },
    editMessage: () => {
      return;
    },
    removeMessage: () => {
      return;
    },
    setCompany: (state, action: PayloadAction<any>) => {
      state.company = action.payload;
    },
    setCompanies: (state, action: PayloadAction<any>) => {
      state.companies = action.payload;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
    setFilter: (state, action: PayloadAction<any>) => {
      state.filter = action.payload;
    },
  },
});

export default lcVerificator.reducer;
export const {
  getCompanies,
  getCompaniesAdvanceSearch,
  getCompany,
  setVerification,
  searchCompanies,
  removeCompany,
  setCompanies,
  setCompaniesAdvanceSearch,
  setCompany,
  setLoading,
  setFilter,
  sendMessage,
  editMessage,
  removeMessage,

}: any = lcVerificator.actions;
