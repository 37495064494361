import React, { FC } from 'react';
// icons
import birth from '@image/icons/counters/birth.svg';
// styles
import './style.scss';

const CounterBirth: FC = () => {
  return (
    <div className="counter-block">
      <img src={birth} alt="birth" className="counter-block__img" />
      <div className="counter-block__title">Дни рождения</div>
      <div className="counter-block__desc">В этом месяце</div>
      <div className="counter-block__list">
        <div className="counter-block__item">TA</div>
        <div className="counter-block__item">SA</div>
      </div>
    </div>
  );
};

export default CounterBirth;
