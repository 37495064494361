import React, { useState } from 'react';
// components
// import FormSetPositionCar from '@organisms/forms/setPosition/FormSetPositionCar';
// import FormSetPositionCriteria from '@organisms/forms/setPosition/FormSetPositionCriteria';
// import FormSetPositionEducation from '@organisms/forms/setPosition/FormSetPositionEducation';
// import FormSetPositionFamilyStatus from '@organisms/forms/setPosition/FormSetPositionFamilyStatus';
// import FormSetPositionMain from '@organisms/forms/setPosition/FormSetPositionMain';
// import FormSetPositionPersonalInformation from '@organisms/forms/setPosition/FormSetPositionPersonalInformation';
// import FormSetPositionRequirements from '@organisms/forms/setPosition/FormSetPositionRequirements';
// import FormSetPositionSpecificity from '@organisms/forms/setPosition/FormSetPositionSpecificity';
// import FormSetPositionUniversities from '@organisms/forms/setPosition/FormSetPositionUniversities';
// import FormSetPositionWorkExperience from '@organisms/forms/setPosition/FormSetPositionWorkExperience';
/* TODO old components need GLOBAL rework step`s */
import FormSetPositionCar from '@organisms/forms/setPosition/old/FormSetPositionCar';
import FormSetPositionCriteria from '@organisms/forms/setPosition/old/FormSetPositionCriteria';
import FormSetPositionEducation from '@organisms/forms/setPosition/old/FormSetPositionEducation';
import FormSetPositionFamilyStatus from '@organisms/forms/setPosition/old/FormSetPositionFamilyStatus';
import FormSetPositionMain from '@organisms/forms/setPosition/old/FormSetPositionMain';
import FormSetPositionPersonalInformation from '@organisms/forms/setPosition/old/FormSetPositionPersonalInformation';
import FormSetPositionRequirements from '@organisms/forms/setPosition/old/FormSetPositionRequirements';
import FormSetPositionSpecificity from '@organisms/forms/setPosition/old/FormSetPositionSpecificity';
import FormSetPositionUniversities from '@organisms/forms/setPosition/old/FormSetPositionUniversities';
import FormSetPositionWorkExperience from '@organisms/forms/setPosition/old/FormSetPositionWorkExperience';

function usePositionSteps() {
  const [currentPositionSteps, setCurrentPositionSteps] = useState(1);

  const positionSteps = [
    {
      id: 1,
      content: <FormSetPositionMain changeStep={setCurrentPositionSteps} step={currentPositionSteps} />,
    },
    {
      id: 2,
      content: <FormSetPositionRequirements changeStep={setCurrentPositionSteps} step={currentPositionSteps} />,
    },
    {
      id: 3,
      content: <FormSetPositionEducation changeStep={setCurrentPositionSteps} step={currentPositionSteps} />,
    },
    {
      id: 4,
      content: <FormSetPositionCar changeStep={setCurrentPositionSteps} step={currentPositionSteps} />,
    },
    {
      id: 5,
      content: <FormSetPositionSpecificity changeStep={setCurrentPositionSteps} step={currentPositionSteps} />,
    },
    {
      id: 6,
      content: <FormSetPositionFamilyStatus changeStep={setCurrentPositionSteps} step={currentPositionSteps} />,
    },
    {
      id: 7,
      content: <FormSetPositionUniversities changeStep={setCurrentPositionSteps} step={currentPositionSteps} />,
    },
    {
      id: 8,
      content: <FormSetPositionWorkExperience changeStep={setCurrentPositionSteps} step={currentPositionSteps} />,
    },
    {
      id: 9,
      content: <FormSetPositionPersonalInformation changeStep={setCurrentPositionSteps} step={currentPositionSteps} />,
    },
    {
      id: 10,
      content: <FormSetPositionCriteria changeStep={setCurrentPositionSteps} step={currentPositionSteps} />,
    },
  ];
  return { currentPositionSteps, setCurrentPositionSteps, positionSteps };
}

export default usePositionSteps;
