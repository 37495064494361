import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
// components
import BannersContainer from '@containers/banner';
import InfoBlocks from '@containers/info';
import SmallCalendar from '@molecules/calendar';
import HomeDataTable from '@organisms/tables/HomeDataTable';
import NavigationContainer from '@containers/app/navigation';
import CountersBlock from '@containers/counters';
// types
import { stateType } from '@types';

const Home: React.FC = () => {
  const { info } = useSelector((state: stateType) => state.user);

  useEffect(() => {
    // console.log('HOME PAGE');
  }, []);

  return (
    <section className="home-page">
      <div className="home-page__body">
        <BannersContainer step={'4'} />
        <NavigationContainer />
        <HomeDataTable />
      </div>
      <div className="home-page__side">
        <SmallCalendar />
        <InfoBlocks step="7" />
        <CountersBlock />
      </div>
    </section>
  );
};

export default Home;
