import React, { FC } from 'react';
import { Steps } from 'antd';
// icons
import StepIconCurrent from '@assets/icons/steps/StepIconCurrent.jsx';
import StepIconWait from '@assets/icons/steps/StepIconWait.jsx';

interface DynamicStepsType {
  labelPlacement?: string | any;
  steps: Array<{ id: number; content: React.ReactNode; description?: string }>;
  current: number;
}

const DynamicSteps: FC<DynamicStepsType> = ({ labelPlacement, steps, current }: DynamicStepsType) => {
  const Component = steps[current - 1]?.content;
  return (
    <div className="app-steps">
      <div className="app-steps__header">
        <Steps initial={1} current={current} size="small" className="app-steps__steps" labelPlacement={labelPlacement}>
          {steps.map((item: any) => {
            const icon = item.id < current + 1 ? <StepIconCurrent /> : <StepIconWait />;
            return <Steps.Step key={item.id} icon={icon} {...item} />;
          })}
        </Steps>
      </div>
      <div className="app-steps__content">{Component && Component}</div>
    </div>
  );
};

export default DynamicSteps;
