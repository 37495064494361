import React from 'react';

function HrAnalyticsIcon() {
   return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd"
               d="M10.272 1.84139C10.4051 1.72194 10.5811 1.65932 10.7613 1.66736C14.5703 1.78138 17.7537 4.5465 18.3306 8.24219C18.3343 8.26426 18.3343 8.28676 18.3306 8.30883C18.343 8.48369 18.2839 8.6562 18.1664 8.78821C18.0488 8.92021 17.8825 9.00082 17.7042 9.01222L11.3047 9.43425C11.093 9.45302 10.8831 9.38337 10.7266 9.24243C10.57 9.1015 10.4812 8.90232 10.4821 8.69384L10.0519 2.40776V2.30411C10.0597 2.12731 10.1389 1.96084 10.272 1.84139ZM9.83306 11.078L15.3799 10.7226L15.4176 10.7374C15.6558 10.7413 15.8826 10.8379 16.0482 11.0059C16.2138 11.1739 16.3046 11.3996 16.3006 11.6333C16.082 14.8183 13.7468 17.4796 10.569 18.1652C7.39117 18.8508 4.1335 17.3962 2.57317 14.5949C2.11437 13.7881 1.82444 12.8995 1.7204 11.9813C1.68005 11.7092 1.66238 11.4343 1.66757 11.1594C1.67809 7.77255 4.08942 4.84922 7.46341 4.13292C7.87186 4.05647 8.28137 4.2604 8.45957 4.62899C8.50393 4.69588 8.53943 4.76803 8.56522 4.84371C8.62847 5.82 8.69407 6.78705 8.7594 7.75001C8.811 8.51056 8.86242 9.26857 8.91237 10.0266C8.90979 10.2051 8.93785 10.3828 8.99538 10.5523C9.13081 10.8856 9.46782 11.0971 9.83306 11.078Z"
               fill="currentColor"/>
      </svg>
   
   );
}

export default HrAnalyticsIcon;