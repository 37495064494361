import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Tooltip } from 'antd';
// actions
import { setFile } from '@redux/reducers/app';
// components
import EditProfileModal from '@organisms/modals/settings/editProfile';
import DropdownDefault from '@molecules/app/dropdwons/default';
import TooltipConfirmPhoneBody from '@molecules/tooltips/confirmPhone';
import TooltipJobTitleBody from '@molecules/tooltips/jobTitle';
import AddStatusModal from '@organisms/modals/settings/addStatus';
import PhoneProfileModal from '@organisms/modals/confirms/phoneProfile';
import SocialList from '@molecules/lists/social';
// icons
import {
  IconEditProfile,
  IconEmptyAvatar,
  IconProfileCompany,
  IconProfileConfirmPhone,
  IconProfileLocale,
  IconProfileMessage,
  IconProfileNotConfirmPhone,
  IconTooltipInfo,
} from '@assets/icons/nav/profileHeader';
// helps
import { createPhoneMask, getAge } from '@tools/helpers';
import { getAvatarUrl } from '@tools/helpFunctions';
// types
import { stateType } from '@types';
// styles
import './style.scss';

const HeaderProfile: FC = () => {
  const [modalPhone, setModalPhone] = useState(false);
  const { info } = useSelector((state: stateType) => state.user);
  const {
    name,
    birth_date,
    lname,
    email,
    phone,
    company,
    office_presence_status,
    otherPhones,
    otherEmails,
    is_confirm_phone,
    files,
    socials,
    is_verifier,
  } = info;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVerifier = is_verifier === 1
  const StatusButton = ({ onClick }: any) => {
    return (
      <div className="header-profile__status" onClick={onClick}>
        <Tooltip
          trigger={'hover'}
          title={t('Статус присутствия в офисе')}
          placement="bottom"
          className="default-tooltip"
          overlayClassName={'default-tooltip-overlay'}
        >
          <span
            className={classNames('header-profile__status-text', {
              [`header-profile__status-text-${office_presence_status?.id}`]: office_presence_status,
            })}
          >
            {office_presence_status ? office_presence_status?.name : t('Выбрать статус')}
          </span>
        </Tooltip>
      </div>
    );
  };

  const uploadAvatar = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', 'avatar');

      dispatch(
        setFile({
          type: 'user',
          request: formData,
          callback: {
            success: () => console.log('success'),
            error: () => console.log('error'),
          },
        }),
      );
    }
  };

  return (
    <>
      {modalPhone && <PhoneProfileModal phone={phone} open={true} setModalPhone={setModalPhone} />}
      <div className="header-profile">
        <div className="header-profile__avatar">
          <input
            type="file"
            id="file"
            name="file"
            onChange={uploadAvatar}
            className="header-profile__avatar-uploader"
          />
          {files?.avatar && (
            <img className="header-profile__avatar" src={getAvatarUrl(files?.avatar, '110')} alt={'avatar'} />
          )}
          <IconEmptyAvatar />
        </div>
        <div className="header-profile__body">
          <div className="header-profile__row">
            <div className="header-profile__column">
              <div className="header-profile__name">
                <span className="header-profile__name-title">
                  {name} {lname}
                </span>
                <span className="header-profile__name-desc">{birth_date && `(${getAge(birth_date)} ${t('лет')})`}</span>

                <EditProfileModal
                  component={IconEditProfile}
                  action={(callback: any) => {
                    //dispatch(addCompanyToArchive({ request: { company_id: id }, callback: () => callback(false) }));
                  }}
                />
              </div>
              <div className="header-profile__role">
                { isVerifier
                    ?
                    'Верефикатор'
                    :
                    <>
                      <span>{t('Должность не назначена')} </span>
                      <div className="header-profile__role-icon">
                        <Tooltip
                            trigger={'hover'}
                            placement="bottom"
                            title={<TooltipJobTitleBody />}
                            className="default-tooltip"
                            overlayClassName={'default-tooltip-overlay default-tooltip-overlay-size-294'}
                        >
                          <></>
                          <IconTooltipInfo />
                        </Tooltip>
                      </div>
                    </>
                }
              </div>
            </div>
            {
              !isVerifier &&
                <div className="header-profile__column">
                  <AddStatusModal
                      component={StatusButton}
                      action={(callback: any) => {
                        //dispatch(addCompanyToArchive({ request: { company_id: id }, callback: () => callback(false) }));
                      }}
                  />
                </div>
            }

          </div>
          <div className="header-profile__row">
            <div>
              <DropdownDefault icon={<IconProfileCompany />} title={ isVerifier ? 'ТОВ «ProfWorld»' :company?.name } list={[]} />
            </div>
            <div>
              <DropdownDefault icon={<IconProfileMessage />} title={email} list={otherEmails ? otherEmails : []} />
            </div>
            <div>
              {phone && (
                <DropdownDefault
                  icon={
                    is_confirm_phone ? (
                      <IconProfileConfirmPhone />
                    ) : (
                      <Tooltip
                        trigger={'hover'}
                        title={<TooltipConfirmPhoneBody onClick={() => setModalPhone(true)} />}
                        placement="bottom"
                        className="default-tooltip"
                        overlayClassName={'default-tooltip-overlay'}
                      >
                        <IconProfileNotConfirmPhone />
                        <></>
                      </Tooltip>
                    )
                  }
                  title={phone && `+38 ${createPhoneMask(phone)}`}
                  list={otherPhones ? otherPhones : []}
                />
              )}
            </div>
            <div>
              <DropdownDefault icon={<IconProfileLocale />} title={'Киев, Украина'} list={[]} />
            </div>
            <div>
              <SocialList socials={socials} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderProfile;
