import React, { FC, Fragment } from 'react';

import { Checkbox, Col, Row, Typography } from 'antd';
import PositionFunctionalityModalPositionNames from './PositionFunctionalityModalPositionNames';
import PositionFunctionalityModalPositionItems from './PositionFunctionalityModalPositionItems';
import PositionFunctionalityModalPositionSelected from './PositionFunctionalityModalPositionSelected';

const PositionFunctionalityModalBody: FC<any> = ({
  title,
  positionsId,
  setPositionId,
  positionData,
  addItem,
  removeItem,
}: any) => {
  const { indeterminateSelected, checkAllSelected, onChangeAllCheckListFunctionality } = positionData;
  return (
    <div className="functionality-modal__body">
      <Row className="functionality-modal__row" wrap={false}>
        <Fragment>
          <Col className="functionality-modal__column functionality-modal__column_left">
            <Typography.Text className="functionality-modal__text">Должности</Typography.Text>
            <PositionFunctionalityModalPositionNames
              positionsId={positionsId}
              setPositionId={setPositionId}
              positionData={positionData}
            />
          </Col>

          <Col className="functionality-modal__column functionality-modal__column_center">
            <Typography.Text className="functionality-modal__text">{title}</Typography.Text>

            <PositionFunctionalityModalPositionItems
              positionData={positionData}
              addItem={addItem}
              removeItem={removeItem}
            />
          </Col>

          <Col className="functionality-modal__column functionality-modal__column_right">
            <Checkbox
              className="functionality-list__item"
              checked={checkAllSelected}
              indeterminate={indeterminateSelected}
              onChange={onChangeAllCheckListFunctionality}
            >
              <Typography.Text className="functionality-modal__text">Выбранные</Typography.Text>
            </Checkbox>
            <PositionFunctionalityModalPositionSelected positionData={positionData} />
          </Col>
        </Fragment>
      </Row>
    </div>
  );
};

export default PositionFunctionalityModalBody;
