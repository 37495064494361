// types
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

/*
 *
 * App reducer
 *
 */
const initialState = {
  loading: false,
  session: false,
  isReg: false,
  token: null,
  error: null,
  isResetPass: false,
};

export type authInitStateType = typeof initialState;

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: () => {
      return;
    },
    updatePassword: () => {
      return;
    },
    setLogout: () => {
      return;
    },
    setSessionAuth: (state, action: PayloadAction<any>) => {
      state.session = action.payload;
    },
    registration: () => {
      return;
    },
    reset: () => {
      return;
    },
    forgot: () => {
      return;
    },
    forgotByPhone: () => {
      return;
    },
    resetPasswordInside: () => {
      return;
    },
    setToken: (state, action: PayloadAction<any>) => {
      state.token = action.payload;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
    setReset: (state, action: PayloadAction<any>) => {
      state.isResetPass = action.payload;
    },
    setReg: (state, action: PayloadAction<any>) => {
      state.isReg = action.payload;
    },
  },
});

export default auth.reducer;
export const {
  login,
  setLogout,
  setSessionAuth,
  registration,
  updatePassword,
  reset,
  resetPasswordInside,
  forgot,
  forgotByPhone,
  setToken,
  setError,
  setLoading,
  setReset,
  setReg,
}: any = auth.actions;
