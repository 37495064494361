import React, {FC, useState} from 'react'
import {Modal} from 'antd'
// components
// style
import './style.scss'
import {useDispatch} from 'react-redux'
import FormHeader from '@atoms/text/formHeader'
import FormForgotPasswordInside from '@organisms/forms/forgot/forgotInside'
import FormCodeInside from '@organisms/forms/code/confirmPhoneResetPasswordInside'
import FormResetPassInside from '@organisms/forms/resetInside'

interface EditProfileModalModalType {
	modalActive: any
	setModalActive: (value: boolean) => void
}

const ForgetPasswordModal: FC<EditProfileModalModalType> = ({
	modalActive,
	setModalActive,
}: EditProfileModalModalType) => {

	const [activeTab, setActiveTab] = useState('forgot')

	const onCancel = () => {
		setModalActive(false)
		setActiveTab('forgot')
	}

	return (
		<Modal
			className='modal-reset-password'
			open={modalActive}
			width={504}
			footer={null}
			closable={true}
			bodyStyle={{ padding: '64px' }}
			centered
			onCancel={onCancel}
			maskClosable = {false}
			destroyOnClose={true}
		>
			<div className='modal-reset-password__body'>
				{
					activeTab === 'forgot'
					&&
					<div className='modal-reset-password__forgot'>
						<FormHeader type={'forgot'} />
						<div className='modal-edit-profile__form'>
							<FormForgotPasswordInside setActiveTab={setActiveTab}/>
						</div>
					</div>
				}
				{
					activeTab === 'code'
					&&
					<div className='modal-reset-password__code'>
						<FormHeader type={'resetPasswordByPhone'} data={{ phone: localStorage.getItem('forgot-phone')}} />
						<FormCodeInside setActiveTab={setActiveTab} setModalActive={setModalActive}/>
					</div>
				}
				{
					activeTab === 'reset'
					&&
					<div className='modal-reset-password__pass'>
						<FormHeader type={'resetPasswordByPhone'} data={{ phone: localStorage.getItem('forgot-phone')}} />
						<FormResetPassInside setActiveTab={setActiveTab} setModalActive={setModalActive}/>
					</div>
				}
			</div>

		</Modal>
	)
}

export default ForgetPasswordModal
