import React, {FC} from 'react'

export const IconUsersSetting: FC<any> = ({ onClick }: any) => {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.4023 13.58C21.76 13.77 22.036 14.07 22.2301 14.37C22.6083 14.99 22.5776 15.75 22.2097 16.42L21.4943 17.62C21.1162 18.26 20.411 18.66 19.6855 18.66C19.3278 18.66 18.9292 18.56 18.6022 18.36C18.3365 18.19 18.0299 18.13 17.7029 18.13C16.6911 18.13 15.8429 18.96 15.8122 19.95C15.8122 21.1 14.872 22 13.6968 22H12.3069C11.1215 22 10.1813 21.1 10.1813 19.95C10.1608 18.96 9.31259 18.13 8.30085 18.13C7.96361 18.13 7.65702 18.19 7.40153 18.36C7.0745 18.56 6.66572 18.66 6.31825 18.66C5.58245 18.66 4.87729 18.26 4.49917 17.62L3.79402 16.42C3.4159 15.77 3.39546 14.99 3.77358 14.37C3.93709 14.07 4.24368 13.77 4.59115 13.58C4.87729 13.44 5.06125 13.21 5.23498 12.94C5.74596 12.08 5.43937 10.95 4.57071 10.44C3.55897 9.87 3.23194 8.6 3.81446 7.61L4.49917 6.43C5.09191 5.44 6.35913 5.09 7.38109 5.67C8.27019 6.15 9.425 5.83 9.9462 4.98C10.1097 4.7 10.2017 4.4 10.1813 4.1C10.1608 3.71 10.2732 3.34 10.4674 3.04C10.8455 2.42 11.5302 2.02 12.2763 2H13.7172C14.4735 2 15.1582 2.42 15.5363 3.04C15.7203 3.34 15.8429 3.71 15.8122 4.1C15.7918 4.4 15.8838 4.7 16.0473 4.98C16.5685 5.83 17.7233 6.15 18.6226 5.67C19.6344 5.09 20.9118 5.44 21.4943 6.43L22.179 7.61C22.7718 8.6 22.4447 9.87 21.4228 10.44C20.5541 10.95 20.2475 12.08 20.7687 12.94C20.9322 13.21 21.1162 13.44 21.4023 13.58ZM10.1097 12.01C10.1097 13.58 11.4076 14.83 13.0121 14.83C14.6165 14.83 15.8838 13.58 15.8838 12.01C15.8838 10.44 14.6165 9.18 13.0121 9.18C11.4076 9.18 10.1097 10.44 10.1097 12.01Z'
			/>
		</svg>
	)
}

export const IconUsersSettingAdd: FC<any> = ({ onClick }: any) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M5 14C5.28333 14 5.52067 13.904 5.712 13.712C5.904 13.5207 6 13.2833 6 13V11H8C8.28333 11 8.52067 10.904 8.712 10.712C8.904 10.5207 9 10.2833 9 10C9 9.71667 8.904 9.479 8.712 9.287C8.52067 9.09567 8.28333 9 8 9H6V7C6 6.71667 5.904 6.479 5.712 6.287C5.52067 6.09567 5.28333 6 5 6C4.71667 6 4.47933 6.09567 4.288 6.287C4.096 6.479 4 6.71667 4 7V9H2C1.71667 9 1.47933 9.09567 1.288 9.287C1.096 9.479 1 9.71667 1 10C1 10.2833 1.096 10.5207 1.288 10.712C1.47933 10.904 1.71667 11 2 11H4V13C4 13.2833 4.096 13.5207 4.288 13.712C4.47933 13.904 4.71667 14 5 14ZM15 12C16.1 12 17.0417 11.6083 17.825 10.825C18.6083 10.0417 19 9.1 19 8C19 6.9 18.6083 5.95833 17.825 5.175C17.0417 4.39167 16.1 4 15 4C13.9 4 12.9583 4.39167 12.175 5.175C11.3917 5.95833 11 6.9 11 8C11 9.1 11.3917 10.0417 12.175 10.825C12.9583 11.6083 13.9 12 15 12ZM22 20C22.2833 20 22.5207 19.904 22.712 19.712C22.904 19.5207 23 19.2833 23 19V17.2C23 16.6333 22.854 16.1123 22.562 15.637C22.2707 15.1623 21.8833 14.8 21.4 14.55C20.3667 14.0333 19.3167 13.6457 18.25 13.387C17.1833 13.129 16.1 13 15 13C13.9 13 12.8167 13.129 11.75 13.387C10.6833 13.6457 9.63333 14.0333 8.6 14.55C8.11667 14.8 7.72933 15.1623 7.438 15.637C7.146 16.1123 7 16.6333 7 17.2V19C7 19.2833 7.096 19.5207 7.288 19.712C7.47933 19.904 7.71667 20 8 20H22Z' />
		</svg>
	)
}

export const IconUsersTableSettingTransfer: FC<any> = ({ onClick }: any) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.76277 3.92525L10.7628 1.25857C11.5385 0.913812 12.424 0.913811 13.1997 1.25857L19.1997 3.92523C20.283 4.40673 20.9891 5.48202 20.9526 6.66703C20.68 15.5203 18.44 19.3407 13.2408 22.0953C12.4548 22.5118 11.5104 22.5048 10.7248 22.0874C4.71955 18.8967 3.18273 15.1578 3.00067 6.66754C2.97525 5.48227 3.67942 4.40675 4.76277 3.92525ZM14.1062 6.59205C14.6896 7.17538 14.9812 7.88371 14.9812 8.71705C14.9812 9.39205 14.7917 9.98171 14.4127 10.486C14.0334 10.99 13.5562 11.342 12.9812 11.542V13.717H14.9812V15.717H12.9812V17.717H10.9812V11.542C10.4062 11.342 9.92907 10.99 9.54974 10.486C9.17074 9.98171 8.98124 9.39205 8.98124 8.71705C8.98124 7.88371 9.27291 7.17538 9.85624 6.59205C10.4396 6.00871 11.1479 5.71705 11.9812 5.71705C12.8146 5.71705 13.5229 6.00871 14.1062 6.59205ZM12.6872 9.42305C12.8832 9.22738 12.9812 8.99205 12.9812 8.71705C12.9812 8.44205 12.8832 8.20671 12.6872 8.01105C12.4916 7.81505 12.2562 7.71705 11.9812 7.71705C11.7062 7.71705 11.4707 7.81505 11.2747 8.01105C11.0791 8.20671 10.9812 8.44205 10.9812 8.71705C10.9812 8.99205 11.0791 9.22738 11.2747 9.42305C11.4707 9.61905 11.7062 9.71705 11.9812 9.71705C12.2562 9.71705 12.4916 9.61905 12.6872 9.42305Z'
			/>
		</svg>
	)
}

export const IconClose: FC<any> = ({ onClick }: any) => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			onClick={onClick}
		>
			<path
				d='M12.6668 4.2735L11.7268 3.3335L8.00016 7.06016L4.2735 3.3335L3.3335 4.2735L7.06016 8.00016L3.3335 11.7268L4.2735 12.6668L8.00016 8.94016L11.7268 12.6668L12.6668 11.7268L8.94016 8.00016L12.6668 4.2735Z'
				fill='#6D7895'
			/>
		</svg>
	)
}

export const IconRemoveUser: FC<any> = ({ onClick }: any) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			onClick={onClick}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M20.0368 5.18892C20.3977 5.18892 20.6981 5.50653 20.6981 5.91067V6.28434C20.6981 6.67865 20.3977 7.0061 20.0368 7.0061H4.66222C4.30044 7.0061 4 6.67865 4 6.28434V5.91067C4 5.50653 4.30044 5.18892 4.66222 5.18892H7.36705C7.91649 5.18892 8.39467 4.77494 8.51827 4.19084L8.65991 3.52022C8.88005 2.60671 9.60453 2 10.4337 2H14.2644C15.0846 2 15.8171 2.60671 16.0292 3.47203L16.1807 4.18986C16.3034 4.77494 16.7816 5.18892 17.332 5.18892H20.0368ZM18.6624 18.8487C18.9448 16.059 19.4392 9.43141 19.4392 9.36454C19.4573 9.16198 19.395 8.97023 19.2714 8.81585C19.1388 8.6713 18.971 8.58575 18.786 8.58575H5.91871C5.73286 8.58575 5.55602 8.6713 5.43332 8.81585C5.30882 8.97023 5.24747 9.16198 5.25649 9.36454C5.25815 9.37683 5.27589 9.61028 5.30555 10.0006C5.43731 11.7344 5.80428 16.5636 6.04141 18.8487C6.20922 20.5321 7.25127 21.5902 8.76067 21.6285C9.92542 21.6571 11.1254 21.6669 12.3524 21.6669C13.5081 21.6669 14.6819 21.6571 15.8827 21.6285C17.4444 21.6 18.4856 20.5607 18.6624 18.8487Z'
			/>
		</svg>
	)
}
