import React from 'react';
// icons
import HomeIcon from '@assets/icons/menu/HomeIcon.jsx';
import MyCompanyIcon from '@assets/icons/menu/MyCompanyIcon.jsx';
import StaffAdaptationIcon from '@assets/icons/menu/StaffAdaptationIcon.jsx';
import PersonnelSelectionIcon from '@assets/icons/menu/PersonnelSelectionIcon.jsx';
import PersonnelEvaluationIcon from '@assets/icons/menu/PersonnelEvaluationIcon.jsx';
import PerformersEvaluationIcon from '@assets/icons/menu/PerformersEvaluationIcon.jsx';
import HrAdministrationIcon from '@assets/icons/menu/HrAdministrationIcon.jsx';
import StaffTrainingIcon from '@assets/icons/menu/StaffTrainingIcon.jsx';
import HrAnalyticsIcon from '@assets/icons/menu/HrAnalyticsIcon.jsx';
import SurveysIcon from '@assets/icons/menu/SurveysIcon.jsx';
import TasksIcon from '@assets/icons/menu/TasksIcon.jsx';
import SettingsIcon from '@assets/icons/menu/SettingsIcon.jsx';
import TestIcon from '@assets/icons/menu/TestIcon';
import GameIcon from '@assets/icons/menu/GameIcon';
import CalendarIcon from '@assets/icons/menu/CalendarIcon';
// pages
import Home from '@pages/home';
import HrAdministration from '@pages/hrAdministration';
import MyCompany from '@pages/myCompany';
import Personne from '@pages/personne';
import StaffAdaptation from '@pages/staffAdaptation';
import StaffAssessment from '@pages/staffAssessment';
import Performens from '@pages/performens';
import StaffTraning from '@pages/staffTraning';
import HrAnalytics from '@pages/hrAnalytics';
import Surveys from '@pages/surveys';
import Tasks from '@pages/tasks';
import Settings from '@pages/settings';
import MyCalendar from '@pages/myCalendar';
import Testing from '@pages/testing';
import Culture from '@pages/culture';
import LcVerificationCompanies from '@pages/lcVerification/companies';
import LcVerificationSettings from '@pages/lcVerification/settings';

// lazy
// const Home = React.lazy(() => import('@pages/home'));

export type menuItemType = {
  key: number;
  label: string;
  disabled: boolean;
  icon: React.ReactNode;
  link: string;
  component: React.ReactNode;
};

export const menuLists: Array<menuItemType> = [
  {
    key: 1,
    label: 'Главная',
    disabled: false,
    icon: <HomeIcon />,
    link: '/',
    component: <Home />,
  },
  {
    key: 2,
    label: 'Тестирование',
    disabled: false,
    icon: <TestIcon />,
    link: '/testing',
    component: <Testing />,
  },
  {
    key: 3,
    label: 'Отбор персонала',
    disabled: true,
    icon: <PersonnelSelectionIcon />,
    link: '/personne',
    component: <Personne />,
  },
  {
    key: 4,
    label: 'Адаптация персонала',
    disabled: true,
    icon: <StaffAdaptationIcon />,
    link: '/staff-adaptation',
    component: <StaffAdaptation />,
  },
  {
    key: 5,
    label: 'Оценка персонала',
    disabled: true,
    icon: <PersonnelEvaluationIcon />,
    link: '/staff-assessment',
    component: <StaffAssessment />,
  },

  {
    key: 6,
    label: 'Оценка исполнителя',
    disabled: true,
    icon: <PerformersEvaluationIcon />,
    link: '/performers',
    component: <Performens />,
  },

  {
    key: 7,
    label: 'Корпоративная культура',
    disabled: true,
    icon: <GameIcon />,
    link: '/culture',
    component: <Culture />,
  },
  {
    key: 8,
    label: 'HR-администрирование',
    disabled: true,
    icon: <HrAdministrationIcon />,
    link: '/hr-administration',
    component: <HrAdministration />,
  },

  {
    key: 9,
    label: 'Обучение персонала',
    disabled: true,
    icon: <StaffTrainingIcon />,
    link: '/staff-traning',
    component: <StaffTraning />,
  },
  {
    key: 10,
    label: 'HR-аналитика',
    disabled: true,
    icon: <HrAnalyticsIcon />,
    link: '/hr-analyrics',
    component: <HrAnalytics />,
  },
  {
    key: 11,
    label: 'Опросы',
    disabled: true,
    icon: <SurveysIcon />,
    link: '/surveys',
    component: <Surveys />,
  },
  {
    key: 12,
    label: 'Моя компания',
    disabled: false,
    icon: <MyCompanyIcon />,
    link: '/my-company',
    component: <MyCompany />,
  },
  {
    key: 13,
    label: 'Мой календарь',
    disabled: false,
    icon: <CalendarIcon />,
    link: '/my-calendar',
    component: <MyCalendar />,
  },
  {
    key: 14,
    label: 'Мои задачи',
    disabled: true,
    icon: <TasksIcon />,
    link: '/tasks',
    component: <Tasks />,
  },
  {
    key: 15,
    label: 'Настройки',
    disabled: false,
    icon: <SettingsIcon />,
    link: '/settings/myCompanies',
    component: <Settings />,
  },
];

export const menuLcVerificationLists: Array<menuItemType> = [
  {
    key: 1,
    label: 'Компании',
    disabled: false,
    icon: <MyCompanyIcon />,
    link: '/lc-companies',
    component: <LcVerificationCompanies />,
  },

  {
    key: 2,
    label: 'Настройки',
    disabled: false,
    icon: <SettingsIcon />,
    link: '/lc-settings',
    component: <LcVerificationSettings />,
  },
];
