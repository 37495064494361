import React, { FC } from 'react';
import { Button, Row } from 'antd';

const PositionFunctionalityModalFooter: FC<any> = ({ onClick }: any) => {
  return (
    <div className="functionality-modal__footer">
      <Row justify="end">
        <Button type="primary" shape="round" onClick={onClick}>
          Применить
        </Button>
      </Row>
    </div>
  );
};

export default PositionFunctionalityModalFooter;
