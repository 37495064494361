import React, { FC } from 'react';
// images
import formAdd from '@image/icons/form-add-button-plus.svg';
// styles
import './style.scss';
import classNames from 'classnames';

interface FormAddButtonType {
  onClick?: any;
  text?: string;
  type?: string;
  extraClasses?: string;
  containerStyle?: React.CSSProperties;
  icon?: React.ReactNode;
  disabled?: boolean;
}

const FormAddButton: FC<FormAddButtonType> = ({
  onClick,
  text,
  extraClasses,
  containerStyle,
  icon,
}: FormAddButtonType) => {
  return (
    <div style={{ ...containerStyle }} className={`btn-nav ${extraClasses ? extraClasses : ''}`} onClick={onClick}>
      <div className="btn-nav__plus">{icon || <img src={formAdd} alt="form-add" className="btn-nav__icon" />}</div>
      <p className="btn-nav__text">{text}</p>
    </div>
  );
};

export default FormAddButton;

export const MainNavButton: FC<FormAddButtonType> = ({ onClick, text, type, icon, disabled }: FormAddButtonType) => {
  return (
    <div
      className={classNames(`btn-nav`, { [type ? type : '']: type }, { ['btn-nav-disabled']: disabled })}
      onClick={() => !disabled && onClick()}
    >
      <div className="btn-nav__plus">{icon || <img src={formAdd} alt="form-add" className="btn-nav__icon" />}</div>
      <p className="btn-nav__text">{text}</p>
    </div>
  );
};
