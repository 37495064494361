import React, { FC, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import md5 from 'md5'
import { useTranslation } from 'react-i18next'
import i18n from '@src/i18n'
// components
import InputDefault from '@atoms/inputs'
import GeneratePassword from '@molecules/generatePassword'
import ButtonDefault from '@atoms/buttons/button'
// actions
import { updatePassword } from '@redux/reducers/auth'
// helpers
import usePasswordHook from '@tools/hooks/forms/usePasswordHook'
// types
import { stateType } from '@types'
// icons
import IconEye from '@assets/icons/inputs/eye'
import IconEyeClose from '@assets/icons/inputs/eyeClose'

// style
import './style.scss'
import { updatePasswordSchema } from '@tools/shemas'

interface FormData {
	currentPassword: string
	password: string
	password_repeat: string
}
interface IProps {
	setModalActive: (value: boolean) => void
}
const UpdatePassword: FC<IProps> = ({ setModalActive }) => {
	const { loading } = useSelector((state: stateType) => state.auth)
	const schema = useMemo(() => updatePasswordSchema(), [i18n.language])
	const {
		register,
		control,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
		reset,
	} = useForm<FormData>({
		defaultValues: {
			currentPassword: '',
			password: '',
			password_repeat: '',
		},
		resolver: yupResolver(schema),
	})

	const { passwordType: passwordType, changePasswordType: changePasswordType } = usePasswordHook()
	const { passwordType: passwordRepeatType, changePasswordType: changePasswordRepeatType } =
		usePasswordHook()
	const { passwordType: currentPasswordType, changePasswordType: changeCurrentPasswordType } =
		usePasswordHook()

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const onSubmit = async (data: FormData) => {
		const { currentPassword, password } = data

		const request = {
			currentPassword: md5(currentPassword),
			newPassword: md5(password),
		}

		dispatch(updatePassword({ ...request }))

		reset()
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='update-pass-form'>
			<div className='form__container'>
				<InputDefault
					name={'currentPassword'}
					label={t('Текущий пароль')}
					placeholder={t('Укажите ваш пароль')}
					error={errors.currentPassword?.message}
					register={register}
					control={control}
					type={currentPasswordType}
				/>
				<div className='form__container-icons'>
					<div className='form__container-icon' onClick={changeCurrentPasswordType}>
						{currentPasswordType === 'input' ? <IconEye /> : <IconEyeClose />}
					</div>
				</div>
			</div>
			<div className='form__container'>
				<InputDefault
					name={'password'}
					label={t('Новый пароль')}
					placeholder={t('Укажите новый пароль')}
					error={errors.password?.message}
					register={register}
					control={control}
					type={passwordType}
				/>
				<div className='form__container-icons'>
					<GeneratePassword setValue={setValue} />
					<div className='form__container-icon' onClick={changePasswordType}>
						{passwordType === 'input' ? <IconEye /> : <IconEyeClose />}
					</div>
				</div>
			</div>

			<div className='form__container'>
				<InputDefault
					name={'password_repeat'}
					label={t('Повторить новый пароль')}
					placeholder={t('Укажите новый пароль')}
					error={errors.password_repeat?.message}
					register={register}
					control={control}
					type={passwordRepeatType}
				/>
				<div className='form__container-icons'>
					<div className='form__container-icon' onClick={changePasswordRepeatType}>
						{passwordRepeatType === 'input' ? <IconEye /> : <IconEyeClose />}
					</div>
				</div>
				<div className='forget' onClick={() => setModalActive(true)}>
					{t('Забыли пароль?')}
				</div>
			</div>

			<div className='form__container'>
				<ButtonDefault
					name={t('Сохранить')}
					type={'submit'}
					loading={loading}
					disabled={loading}
				/>
			</div>
		</form>
	)
}

export default UpdatePassword
