import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import { hideBanner } from '@redux/actions/global-actions';
// images
import close from '@image/icons/close.svg';
import person from '@image/person.svg';
// types
import { stateType } from '@types';
// styles
import './style.scss';

interface VerifyBannerType {
  text: string;
}

const VerifyBanner: FC<VerifyBannerType> = ({ text }: VerifyBannerType) => {
  // const dispatch = useDispatch();
  const { info } = useSelector((state: stateType) => state.user);

  const { t } = useTranslation();
  const handleOnCloseBanner = () => {
    // dispatch(hideBanner());
  };
  return (
    <div className="app-banner">
      <h3 className="app-banner__title">{t('Привет user', { name: info.name })}</h3>
      <p className="app-banner__text">{t(text)}</p>
      <span className="app-banner__close" onClick={handleOnCloseBanner}>
        <img src={close} alt="close" />
        <span className="app-banner__link">{t('Закрыть')}</span>
      </span>
      <img className="app-banner__person" src={person} alt="person" />
    </div>
  );
};

export default VerifyBanner;
