import React, { FC, useEffect } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// components
import Login from '@organisms/forms/login/index';
import Registration from '@organisms/forms/registration/index';
import FormForgotPassword from '@organisms/forms/forgot/index';
import LoginOptions from '@organisms/socialBlock';
// actions
import { appPage } from '@redux/reducers/app';
// styles
import './style.scss';

const { TabPane } = Tabs;

const TabsAuthContent: FC = () => {
  const { page } = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.getItem('page') && dispatch(appPage(localStorage.getItem('page')));
  }, []);

  const onChange = (value: string) => {
    dispatch(appPage(value));
  };

  const controller = () => {
    switch (page) {
      case 'forgot':
        return <FormForgotPassword />;

      default:
        return (
          <Tabs
            onChange={onChange}
            defaultActiveKey={page ? page : localStorage.getItem('page') ? localStorage.getItem('page') : 'login'}
            centered
            className="auth-tabs"
          >
            <TabPane tab={t('Вход')} key="login">
              <Login />
              <LoginOptions />
            </TabPane>
            <TabPane tab={t('Регистрация')} key="registration">
              <Registration />
              <LoginOptions />
            </TabPane>
          </Tabs>
        );
    }
  };
  return controller();
};

export default TabsAuthContent;
