import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Dropdown } from 'antd';
import _ from 'lodash';
// icons
import { IconArrowDropdown } from '@assets/icons/btn/arrow';
// types
import { stateType } from '@types';
// styles
import './style.scss';

const DropdownDefault: FC<any> = ({ icon, title, list }: any) => {
  const [open, setOpen] = useState<any>(null);
  const [name, setName] = useState<string>('');
  const { info } = useSelector((state: stateType) => state.user);

  useEffect(() => {
    info.company && setName(info.company.name);
  }, [info?.company?.name]);

  return (
    <Dropdown
      className={'dropdown'}
      overlay={<DropdownOverlay setOpen={setOpen} list={list} />}
      trigger={['click']}
      open={open}
      onOpenChange={(data: any) => {
        setOpen(data);
      }}
    >
      <div
        className="dropdown__triger"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        <div className="dropdown__prefix-icon">{icon}</div>
        <span className="dropdown__name">{title}</span>
        <div
          className={classNames(
            'dropdown__icon',
            { ['animation-dropdown-up']: open },
            { ['animation-dropdown-back']: !open },
          )}
        >
          {list.length > 0 && (
            <div className="dropdown__icon-arrow">
              <IconArrowDropdown />
            </div>
          )}
        </div>
      </div>
    </Dropdown>
  );
};

export default DropdownDefault;

const DropdownOverlay = ({ setOpen, list }: any) => {
  return (
    <div className={classNames('dropdown-overlay', { ['dropdown-overlay__hide']: list.length === 0 })}>
      <div className="dropdown-overlay__list">
        {list &&
          list.map((item: any, index: any) => {
            return (
              <div key={index} className="dropdown-overlay__item">
                {item}
              </div>
            );
          })}
      </div>
    </div>
  );
};
