import React, { FC } from 'react';
// components
import BtnGoBack from '@atoms/buttons/goBackButton';
// style
import './style.scss';

const FormFooter: FC = () => {
  return (
    <div className="form-footer">
      <BtnGoBack />
    </div>
  );
};

export default FormFooter;
