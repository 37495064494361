import React, { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import i18n from '@src/i18n';
// components
import InputDefault, { InputSelectDefault } from '@atoms/inputs';
import BtnGoBack from '@atoms/buttons/goBackButton';
import ButtonDefault from '@atoms/buttons/button';
import OutFromCreateCompanyModal from '@organisms/modals/confirms/outFromCreateCompany';
// actions
import { createCompany, logoutFromCompany, updateCompany } from '@redux/reducers/company';
import { setStatus, setSuccess } from '@redux/reducers/app';
import { setUser } from '@redux/reducers/user';
// helps
import { regCompanySchema } from '@tools/shemas';
// types
import { stateType } from '@types';
// styles
import './style.scss';

interface FormData {
  name: string;
  employees: number;
  code: number;
  activity: number;
  services: string;
  website: string;
}

const FormCompanyRegistration: FC = () => {
  const { info: userInfo } = useSelector((state: stateType) => state.user);
  const { options } = useSelector((state: stateType) => state.app);
  const schema = useMemo(() => regCompanySchema(), [i18n.language]);
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      employees: undefined,
      code: undefined,
      activity: undefined,
      services: '',
      website: '',
    },
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!_.isEmpty(userInfo.company)) {
      const { name, employees, code, activity, services, website } = userInfo.company;

      name && setValue('name', name);
      employees && setValue('employees', employees.id);
      code && setValue('code', code);
      activity && setValue('activity', activity.id);
      services && setValue('services', services);
      website && setValue('website', website);
    }
  }, [userInfo]);

  const onSubmit = (data: FormData) => {
    const request = {
      ...data,
      code: Number(data.code),
      employees: data.employees ? data.employees : 0,
      activity: data.activity ? data.activity : 0,
    };

    if (userInfo.company) {
      dispatch(
        updateCompany({
          request: {
            company_id: userInfo.company.id,
            fields: request,
          },
          callback: () => {
            navigate('/company-create/verification');
          },
        }),
      );
      return;
    }

    dispatch(
      createCompany({
        request: { ...request },
        callback: () => {
          dispatch(setStatus('success'));
          dispatch(setSuccess({ type: 'company-create' }));
          navigate('/company-create/verification');
        },
      }),
    );
  };

  const goBack = () => {
    if (userInfo?.company?.id) {
      dispatch(
        logoutFromCompany({
          request: { company_id: userInfo.company.id },
          callback: () => {
            dispatch(setUser({ ...userInfo, company: false }));
            dispatch(setStatus('companies-profile'));
            navigate('/companies');
          },
        }),
      );
      return;
    }

    dispatch(setStatus('companies-profile'));
    navigate('/companies');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form form-company-registration">
      <div className="form__body">
        <div className="form__container">
          <InputDefault
            name={'name'}
            label={t('Название Компании')}
            placeholder={t('Введите название Компании')}
            error={errors.name?.message}
            register={register}
            control={control}
            requered
          />
        </div>
        <div className="form__container form__container-mini">
          <InputSelectDefault
            name={'employees'}
            optionKey={'employees'}
            label={t('Количество сотрудников в компании')}
            placeholder={t('Выберите из списка')}
            error={errors.employees?.message}
            register={register}
            control={control}
            options={options?.employees ? options?.employees : []}
          />
        </div>
        <div className="form__container">
          <InputDefault
            name={'code'}
            type={'input'}
            label={t('Код ЕГРПОУ (ЕДРПОУ)  / ИНН ФЛП')}
            placeholder={t('Введите код ЕГРПОУ')}
            error={errors.code?.message}
            register={register}
            control={control}
            requered
            maxLength={8}
          />
        </div>
        <div className="form__container">
          <InputSelectDefault
            name={'activity'}
            optionKey={'activities'}
            label={t('Сфера деятельности')}
            placeholder={t('Выберите из списка')}
            error={errors.activity?.message}
            register={register}
            control={control}
            options={options?.activities ? options?.activities : []}
          />
        </div>
        <div className="form__container">
          <InputDefault
            name={'services'}
            label={t('Услуги / Продукт')}
            placeholder={t('Введите название основного продукта Компании')}
            error={errors.services?.message}
            register={register}
            control={control}
          />
        </div>

        <div className="form__container">
          <InputDefault
            name={'website'}
            label={t('Сайт')}
            placeholder={t('Добавьте ссылку на сайт')}
            error={errors.website?.message}
            register={register}
            control={control}
          />
        </div>
      </div>
      <div className="form-footer form-footer_submit">
        <OutFromCreateCompanyModal component={BtnGoBack} action={goBack} />
        <ButtonDefault name={t('Далее')} type={'submit'} />
      </div>
    </form>
  );
};

export default FormCompanyRegistration;
