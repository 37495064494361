import React from 'react';

function StaffAdaptationIcon() {
   return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd"
               d="M13.2082 2.08392C13.734 2.08392 14.259 2.15809 14.7582 2.32559C17.834 3.32559 18.9423 6.70059 18.0165 9.65059C17.4915 11.1581 16.6332 12.5339 15.509 13.6581C13.8999 15.2164 12.134 16.5998 10.2332 17.7914L10.0249 17.9173L9.80818 17.7831C7.90068 16.5998 6.12485 15.2164 4.50068 13.6498C3.38402 12.5256 2.52485 11.1581 1.99152 9.65059C1.04985 6.70059 2.15818 3.32559 5.26735 2.30809C5.50902 2.22475 5.75818 2.16642 6.00818 2.13392H6.10818C6.34235 2.09975 6.57485 2.08392 6.80818 2.08392H6.89985C7.42485 2.09975 7.93318 2.19142 8.42568 2.35892H8.47485C8.50818 2.37475 8.53318 2.39225 8.54985 2.40809C8.73402 2.46725 8.90818 2.53392 9.07485 2.62559L9.39152 2.76725C9.46804 2.80806 9.55392 2.87042 9.62815 2.92431C9.67518 2.95846 9.71753 2.9892 9.74985 3.00892C9.76345 3.01694 9.77727 3.02501 9.7912 3.03314C9.86265 3.07485 9.93709 3.1183 9.99985 3.16642C10.9257 2.45892 12.0499 2.07559 13.2082 2.08392ZM15.4248 8.08392C15.7665 8.07475 16.0582 7.80059 16.0832 7.44975V7.35059C16.1082 6.18309 15.4007 5.12559 14.3248 4.71725C13.9832 4.59975 13.6082 4.78392 13.4832 5.13392C13.3665 5.48392 13.5498 5.86725 13.8998 5.99142C14.434 6.19142 14.7915 6.71725 14.7915 7.29975V7.32559C14.7757 7.51642 14.8332 7.70059 14.9498 7.84225C15.0665 7.98392 15.2415 8.06642 15.4248 8.08392Z"
               fill="currentColor"/>
      </svg>
   );
}

export default StaffAdaptationIcon;