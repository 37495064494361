import React, { FC, useEffect, useState } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
// components
// style
import './style.scss'
import CompaniesAdvanceSearchTable from '@organisms/tables/lcVerificationCompanies/companiesAdvanceSearchTable'
import AdvancedSearch from '@organisms/forms/advancedSearch'
import {useForm} from 'react-hook-form'

interface SearchModalType {
	component?: any
	action?: any
	active?: boolean
	open: boolean
	setOpen: any
	defaultValue?: string
	defaultResult?: any
}

const SearchModal: FC<SearchModalType> = ({
	component: Component,
	open,
	setOpen,
	defaultValue,
}: SearchModalType) => {
	const [visible, setVisible] = useState(false)

	const [activeTab, setActiveTab] = useState('Filters')
	const { t } = useTranslation()



	useEffect(() => {
		setVisible(open)
	}, [open])

	const onCancel = () => {
		setVisible(false)
		setOpen(false)
		setActiveTab('Filters')
	}

	return (
		<>
			{Component && (
				<Component
					onClick={() => {
						!open && setVisible(true)
					}}
				/>
			)}

			<Modal
				className='modal-search'
				open={visible}
				footer={null}
				closable={true}
				bodyStyle={{ padding: '0' }}
				onCancel={onCancel}
				destroyOnClose={true}
			>
				<div className='modal-search__body'>
					<div className={activeTab === 'Filters' ? '' : 'none'}>
						<div className='modal-search__title'>{t('Расширенный поиск')}</div>
						<AdvancedSearch setActiveTab={setActiveTab} defaultValue={defaultValue}/>
					</div>

					<div className={activeTab === 'Result' ? '' : 'none'}>
						<div className='modal-search__title'>{t('Результаты поиска')}</div>
						<CompaniesAdvanceSearchTable setActiveTab={setActiveTab} />
					</div>
				</div>
			</Modal>
		</>
	)
}

export default SearchModal
