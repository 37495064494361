import React, {FC, useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import i18n from '@src/i18n'
// components
import InputDefault, {InputDatePicker, InputSelectDefault} from '@atoms/inputs'
// actions
// helpers
// types
import {stateType} from '@types'
import {isEmpty} from 'lodash'

// icons
// style
import './style.scss'
import {advanceSearch} from '@tools/shemas'
import {getOptions, setStatus} from '@redux/reducers/app'
import {Menu} from 'antd'
import {getCompaniesAdvanceSearch} from '@redux/reducers/lcVerificator'
import ButtonDefault from '@atoms/buttons/button'
import moment from 'moment'
import {login} from '@redux/reducers/auth'

interface FormData {
	companyName: string
	product: string
	site: string
	edrCode: string
	dateFrom: any
	dateTo: any
	employees: number
	activity: number
}

interface IProps {
	setActiveTab?: (value: string) => void,
	defaultValue?: string
}

const AdvancedSearch: FC<IProps> = ({setActiveTab, defaultValue}) => {
	const { loading } = useSelector((state: stateType) => state.auth)
	const { options } = useSelector((state: stateType) => state.app)
	const { companiesAdvanceSearch }: any = useSelector((state: stateType) => state.lcVerificator);

	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		if ( !options?.employees || !options?.activities ){
			dispatch(
				getOptions({
					request: {
						source: ['employees', 'activities'],
					},
				})
			)
		}
	}, [])

	const schema = useMemo(() => advanceSearch(), [i18n.language])
	const {
		register,
		control,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
		reset,
	} = useForm<FormData>({
		defaultValues: {
			companyName: defaultValue,
			product: '',
			site: '',
			edrCode: '',
			dateFrom: '',
			dateTo: '',
		},
		resolver: yupResolver(schema),
	})


	const onSubmit = async (data: FormData) => {

		const { dateFrom, dateTo } = data

		const processedData = Object.fromEntries(
			Object.entries(data).filter(([key, value]) => value !== '' && value !== null)
		);

		const formatDate =  (date) => {
			if(!date)return
			return moment(date).format("YYYY-MM-DD");
		}

		if (!isEmpty(processedData)){
			const request  =  {
				...processedData,
				dateFrom: formatDate(dateFrom),
				dateTo: formatDate(dateTo)
			}
			dispatch(
				getCompaniesAdvanceSearch({
					request,
					callback: () => {
						setActiveTab('Result')
					},
				}),
			);

		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='advance-search-form'>
			<div className='forms'>
				<div className='column-form'>
					<div className='form__container'>
						<InputDefault
							name={'companyName'}
							label={t('Название Компании')}
							placeholder={t('Введите название Компании')}
							error={errors.companyName?.message}
							register={register}
							control={control}
						/>
					</div>

					<div className='form__container'>
						<InputSelectDefault
							allowClear={true}
							name={'employees'}
							optionKey={'employees'}
							label={t('Количество сотрудников в компании')}
							placeholder={t('Выберите из списка')}
							error={errors.employees?.message}
							register={register}
							control={control}
							options={options?.employees ? options?.employees : []}
						/>
					</div>

					<div className='form__container'>
						<InputDefault
							name={'edrCode'}
							label={t('Код ЕГРПОУ (ЕДРПОУ)  / ИНН ФЛП')}
							placeholder={t('Введите код ЕГРПОУ')}
							error={errors.edrCode?.message}
							register={register}
							control={control}
						/>
					</div>

					<div className='form__container'>
						<InputSelectDefault
							allowClear={true}
							name={'activity'}
							optionKey={'activities'}
							label={t('Сфера деятельности')}
							placeholder={t('Выберите из списка')}
							error={errors.activity?.message}
							register={register}
							control={control}
							options={options?.activities ? options?.activities : []}
						/>
					</div>
				</div>

				<div className='column-form'>
					<div className='form__container'>
						<InputDefault
							name={'product'}
							label={t('Услуги / Продукт')}
							placeholder={t('Введите название основного продукта Компании')}
							error={errors.product?.message}
							register={register}
							control={control}
						/>
					</div>

					<div className='form__container'>
						<InputDefault
							name={'site'}
							label={t('Сайт')}
							placeholder={t('Добавьте ссылку на сайт')}
							error={errors.site?.message}
							register={register}
							control={control}
						/>
					</div>

					<div className='date-range'>
						<InputDatePicker
							classes={'date-input'}
							name={'dateFrom'}
							label={t('Диапазон дат')}
							placeholder={'10/08/1983'}
							error={errors.dateFrom?.message}
							register={register}
							control={control}
						/>
						<InputDatePicker
							classes={'date-input'}
							name={'dateTo'}
							placeholder={'10/08/1983'}
							error={errors.dateTo?.message}
							register={register}
							control={control}
						/>
					</div>
				</div>
			</div>

			<div className='form__container'>
				<ButtonDefault
					name={t('Расширенный поиск')}
					type={'submit'}
					loading={loading}
					disabled={loading}
				/>
			</div>
		</form>
	)
}

export default AdvancedSearch
