import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// components
import AppMenu from '@molecules/app/menu';
import UserAvatar from '@molecules/app/avatar';
import CollapsedButton from '@atoms/buttons/CollapsedButton';
// actions
import { setMenuList } from '@redux/reducers/menu';
// tools
import { menuLcVerificationLists, menuLists } from '@tools/menu';

interface MenuType {
  set: 'default' | 'lc-verification';
}

const Menu: FC<MenuType> = ({ set }: MenuType) => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    set === 'default' && dispatch(setMenuList(menuLists));
    set === 'lc-verification' && dispatch(setMenuList(menuLcVerificationLists));
  }, []);

  return (
    <nav className="main-menu">
      <UserAvatar isCollapsed={collapsed} />
      <AppMenu isCollapsed={collapsed} />
      <div className="main-menu__footer">
        <CollapsedButton onClick={() => setCollapsed(!collapsed)} isCollapsed={collapsed} />
      </div>
    </nav>
  );
};

export default Menu;
