import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
// components
import LcVerificationTemplate from '@templates/lcVerificationTemplate';
import UserRegistration from '@pages/user/registration';
import Success from '@pages/success';
import Loading from './Loading';
// actions
import { setStatus } from '@redux/reducers/app';
import { getUserInfo } from '@redux/reducers/user';
// tools
import { getStoreToken } from '@tools/helpFunctions';
import usePageStatusController from '@tools/hooks/usePageStatusController';
// types
import { stateType } from '@types';

interface LcVerificationWrapperType {
  children: React.ReactNode;
  showTemplate?: boolean;
  showHeader?: boolean;
}

const LcVerificationWrapper: FC<LcVerificationWrapperType> = ({
  children,
  showTemplate,
  showHeader,
}: LcVerificationWrapperType) => {
  const { status, success } = useSelector((state: stateType) => state.app);
  const token = getStoreToken();
  const { navigatePageController } = usePageStatusController();
  const dispatch = useDispatch();

  useEffect(() => {
    token && dispatch(getUserInfo());
    !token && dispatch(setStatus('auth'));
  }, []);

  useEffect(() => {
    navigatePageController();
  }, [status]);

  const controller = () => {
    switch (status) {
      case 'success':
        return <Success type={success.type} action={success.action} />;

      case 'loading':
        return <Loading />;

      case 'profile-create':
        return <UserRegistration />;

      case 'verifier':
        return showTemplate ? (
          <LcVerificationTemplate showHeader={showHeader}>{children}</LcVerificationTemplate>
        ) : (
          <>{children}</>
        );
    }
  };
  return <>{controller()}</>;
};

export default LcVerificationWrapper;
