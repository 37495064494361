import ButtonDefault from '@atoms/buttons/button';
import { sendMessage } from '@redux/reducers/lcVerificator';
import NOTIFICATION from '@redux/sagas/notification';
import { stateType } from '@types';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// styles
import './style.scss';

const LcSendMessage: FC = () => {
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { id } = useParams();

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const submit = () => {
    dispatch(
      sendMessage({
        request: {
          company_id: id,
          text: value,
        },
        callback: {
          success: () => {
            setValue('');
            //NOTIFICATION.success(t('Сообщение сохранено успешно'));
          },
        },
      }),
    );
  };
  return (
    <div className="send-message">
      <textarea
        className="send-message__textarea"
        placeholder={`${t('Добавить новый комментарий')}`}
        value={value}
        onChange={onChange}
      />

      <div className="send-message__btns">
        <span className="send-message__btn-text" onClick={() => setValue('')}>
          {t('Отмена')}
        </span>
        <ButtonDefault name={t('Сохранить')} type={'submit'} disabled={value ? false : true} action={submit} />
      </div>
    </div>
  );
};

export default LcSendMessage;
