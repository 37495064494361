/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { menuLists } from './menu';
import _ from 'lodash';

export const findMenuKey = (path: string) => {
  const result = _.find(menuLists, { link: path });
  return result?.key;
};

export const deleteProp = (obj: any, prop: any) => {
  if (obj[prop] && !obj[prop].length) delete obj[prop];
};

const isLeapYear = (year: any) => {
  return !((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0);
};

export const datePickerformat = (val: any) => {
  if (!val) return '';
  const len = val.length;
  if (len === 1 && !['0', '1', '2', '3'].includes(val[0])) {
    return '';
  } else if ((len === 2 && val[0] === '3' && !['0', '1'].includes(val[1])) || val.substr(0, 2) === '00') {
    return val.substr(0, 1);
  } else if (len === 3) {
    if (val[2] === '/') {
      return val.substr(0, 2);
    } else if (['0', '1'].includes(val[2])) {
      return val.substr(0, 2) + '/' + val.substr(2);
    } else {
      return val.substr(0, 2);
    }
  } else if (
    len === 5 &&
    (val.substr(3, 5) === '00' ||
      (val[3] === '1' && !['0', '1', '2'].includes(val[4])) ||
      (['30', '31'].includes(val.substr(0, 2)) && val.substr(3, 5) === '02') ||
      (val.substr(0, 2) === '31' &&
        !['01', '02', '03', '04', '05', '6', '07', '08', '09', '10', '11', '12'].includes(val.substr(3, 5))))
  ) {
    return val.substr(0, 4);
  } else if (len === 6) {
    if (val[5] === '/') {
      return val.substr(0, 5);
    } else {
      return val.substr(0, 5) + '/' + val.substr(5);
    }
  } else if (len === 8 && val.substr(0, 6) === '29/02/') {
    let year = +val.substr(6);
    year = year > 1970 ? 1900 + year : 2000 + year;
    if (isLeapYear(year)) {
      return val.substr(0, 7);
    }
  } else if (len > 8) {
    return val.substr(0, 8);
  }
  return val;
};

export const getStoreToken = () => {
  try {
    let token = localStorage.getItem('token');

    if (!token) {
      token = sessionStorage.getItem('token') || '';
    }

    return token;
  } catch (error) {
    console.error('Error retrieving token:', error);
    return '';
  }
};

export const getStoreLang = () => {
  try {
    return localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ru'
  } catch (error) {
    console.error('Error retrieving language:', error);
    return '';
  }
}

export const initLangFormMomment = (lang: any) => {
  if (!lang) {
    return 'en';
  }

  if (lang === 'ua') {
    return 'uk';
  }

  return lang;
};

export const printPdf = (pdf: any) => {
  const iframe: any = document.createElement('iframe');
  document.body.appendChild(iframe);
  iframe.style.display = 'none';
  iframe.src = pdf;
  document.body.appendChild(iframe);
  iframe.contentWindow.focus();
  iframe.contentWindow.print();
};

export const getAvatarUrl = (data: any, size: any) => {
  const result = _.find(data, { type: size });
  if (!result) {
    return '';
  }
  return result.url;
};
