import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
// components
import AsideBlock from '@containers/app/aside';
import AppBenefits from '@atoms/text/AppBenefits';
import DropdownLang from '@molecules/app/dropdwons/lang';
// styles
import './style.scss';

interface AsideTemplateType {
  children: React.ReactNode;
  center?: boolean;
  showBenefits?: boolean;
  page?: string;
}

const AsideTemplate: FC<AsideTemplateType> = ({ children, center, showBenefits, page }: AsideTemplateType) => {
  useEffect(() => {
    console.log('AsideTemplateType');
  }, []);
  return (
    <div className="aside-template">
      <DropdownLang />
      <AsideBlock page={page}>{showBenefits && <AppBenefits />}</AsideBlock>
      <div className={classNames('aside-template__body', { 'aside-template__body-center': center })}>{children}</div>
    </div>
  );
};

export default AsideTemplate;
