import React, { FC } from 'react';
// components
import StatsInfo from '@organisms/lcVerification/statsInfo';
import TabsCompanies from '../tabsCompanies';
// styles
import './style.scss';

const LcVerificationCompaniesContainer: FC = () => {
  return (
    <div className="lc-verification-container">
      <StatsInfo />
      <TabsCompanies />
    </div>
  );
};

export default LcVerificationCompaniesContainer;
