import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// styles
import './style.scss';

const TooltipJobTitleBody: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="tooltip-job-title">
      <div className="tooltip-job-title__title">
        {t('После прохождения Верификации и заполнения детального профиля Компании будут доступны функции')}:
      </div>
      <li value={'1'} className="tooltip-job-title__li">
        {t('Подразделение / Отдел')}
      </li>
      <li value={'2'} className="tooltip-job-title__li">
        {t('Должность')}
      </li>
      <li value={'3'} className="tooltip-job-title__li">
        {t('Район / Город')}
      </li>
    </div>
  );
};

export default TooltipJobTitleBody;
