import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserRegistration from '@pages/user/registration';
// components
import Success from '@pages/success';
import Loading from './Loading';
// actions
import { setStatus } from '@redux/reducers/app';
// tools
import { getStoreToken } from '@tools/helpFunctions';
import usePageStatusController from '@tools/hooks/usePageStatusController';
// types
import { stateType } from '@types';

interface AuthWrapperType {
  children: React.ReactNode;
}

const AuthWrapper: FC<AuthWrapperType> = ({ children }: AuthWrapperType) => {
  const { status, success } = useSelector((state: stateType) => state.app);
  const token = getStoreToken();
  const { navigateAuthController } = usePageStatusController();
  const dispatch = useDispatch();

  useEffect(() => {
    token && dispatch(setStatus('main'));
    !token && dispatch(setStatus('auth'));
  }, []);

  useEffect(() => {
    navigateAuthController();
  }, [status]);

  const controller = () => {
    switch (status) {
      case 'success':
        return <Success type={success.type} />;

      case 'login':
        return <Loading />;

      case 'profile-create':
        return <UserRegistration />;

      default:
        return <>{children}</>;
    }
  };
  return <>{controller()}</>;
};

export default AuthWrapper;
