import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
// components
import { TemplateERROR } from '@templates/404Template';
// tools
import useLogout from '@tools/hooks/useLogout';
import ButtonDefault from '@atoms/buttons/button';

const ErrorFallbackPage: FC<any> = ({ error, resetErrorBoundary }: any) => {
  /* TODO ReactHtmlParser for error */
  const { t } = useTranslation();
  const { logout } = useLogout();
  return (
    <TemplateERROR>
      <span>{error?.message ? error?.message : 'ERROR'}</span>
      <p>{error?.stack ? error?.stack : 'stack'}</p>
      <ButtonDefault name={t('Выход')} classes={'btn-default btn-white'} action={() => logout()} />
    </TemplateERROR>
  );
};

export default ErrorFallbackPage;
