import React from 'react';

const V1 = () => {
  return (
    <svg width="233" height="228" viewBox="0 0 233 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9 187.1L15.1001 183.9C16.4001 182.6 18.6 182.6 19.9 183.9C21.3 185.3 21.3 187.5 19.9 188.8L16.8 191.9C15.5 193.3 13.3 193.3 11.9 191.9C10.6 190.6 10.6 188.4 11.9 187.1Z"
        fill="#FFDD90"
      />
      <path
        d="M9.6999 218.5L6.5999 221.6C5.1999 223 2.9999 223 1.6999 221.6C0.299902 220.3 0.299902 218.1 1.6999 216.8L4.79988 213.6C6.19988 212.3 8.2999 212.3 9.6999 213.6C10.9999 215 10.9999 217.2 9.6999 218.5Z"
        fill="#FFDD90"
      />
      <path
        d="M40.3 217.1L33.5 223.9C32.1 225.3 29.9 225.3 28.6 223.9C27.3 222.6 27.3 220.4 28.6 219.1L35.4 212.3C36.7 210.9 38.9 210.9 40.3 212.3C41.6 213.6 41.6 215.8 40.3 217.1Z"
        fill="#FFDD90"
      />
      <path
        d="M92.9 174.2L67.0001 200.1C65.7001 201.5 63.5001 201.5 62.1001 200.1C60.8001 198.8 60.8001 196.6 62.1001 195.2L69.0001 188.4C70.3001 187 70.3001 184.9 69.0001 183.5C67.7001 182.2 65.5001 182.2 64.1001 183.5L44.9 202.7C43.6 204.1 41.4001 204.1 40.0001 202.7C38.7001 201.4 38.7001 199.2 40.0001 197.9L46.1001 191.8C47.5001 190.5 47.5001 188.3 46.1001 186.9C44.8001 185.6 42.6 185.6 41.3 186.9L17.8 210.4C16.4 211.8 14.2 211.8 12.9 210.4C11.6 209.1 11.6 206.9 12.9 205.5L44.6001 173.8C46.0001 172.5 46.0001 170.3 44.6001 168.9C43.3001 167.6 41.1 167.6 39.8 168.9L28.9 179.8C27.6 181.1 25.4001 181.1 24.0001 179.8C22.7001 178.5 22.7001 176.3 24.0001 174.9L58.9 140.1L92.9 174.2Z"
        fill="#FFDD90"
      />
      <path
        d="M89.4999 187.8C86.6999 191.3 74.1999 184 61.5999 171.4C48.9999 158.8 41.6999 146.3 45.1999 143.5C45.3332 143.434 45.4666 143.334 45.5999 143.2L77.8999 123.7L109.3 155.1L89.7999 187.4C89.7332 187.534 89.6332 187.667 89.4999 187.8Z"
        fill="#757575"
      />
      <path
        d="M79.9001 153.1C71.7001 144.9 65.3 137.2 62.8 132.8L77.9001 123.7L109.3 155.1L100.2 170.2C95.8001 167.7 88.1001 161.3 79.9001 153.1Z"
        fill="#616161"
      />
      <path d="M101.1 97.5999L75.7 109.6L50.1 105.9L6 124.5L48.3 82.0999L91.8 79.8999L101.1 97.5999Z" fill="#FF5E55" />
      <path d="M153.1 141.3L150.9 184.8L108.6 227.1L127.2 182.9L123.5 157.4L135.5 131.9L153.1 141.3Z" fill="#FF5E55" />
      <path
        d="M174.5 125.2C159.9 139.8 143.2 150.7 127 159C126.933 159 126.867 159.033 126.8 159.1C124.4 159.7 111.7 149.4 97.5999 135.4C83.5999 121.3 73.2999 108.6 73.8999 106.2C73.8999 106.133 73.9333 106.067 73.9999 106C82.1999 89.7001 93.1999 73.1001 107.8 58.5001C129.4 36.9001 155.4 23.2001 178.1 14.6001L218.3 54.9001C209.8 77.5001 196.1 103.5 174.5 125.2Z"
        fill="white"
      />
      <path
        d="M231.3 0.899902C231.7 0.899902 232.1 1.19991 232 1.69991C231.5 6.09991 228.5 28.0999 218.3 54.8999L178.1 14.5999C204.8 4.49991 226.9 1.4999 231.3 0.899902Z"
        fill="#FF5E55"
      />
      <path
        d="M127 159C120 162.5 113.1 165.6 106.5 168.2V168.1C104.9 169.7 94.3 161.7 82.8 150.2C71.3 138.7 63.2 128.1 64.8 126.5C67.4 119.8 70.5 112.9 74 106C72.4 107.6 83 120.7 97.6 135.4C112.3 150 125.4 160.6 127 159Z"
        fill="#BDBDBD"
      />
      <path
        d="M164.7 108.5C153.5 119.6 135.5 119.6 124.4 108.5C113.3 97.4001 113.3 79.4001 124.4 68.3001C135.5 57.2001 153.5 57.2001 164.7 68.3001C175.8 79.4001 175.8 97.4001 164.7 108.5Z"
        fill="#616161"
      />
      <path
        d="M163.8 88.4002C163.8 99.1002 155.2 107.7 144.5 107.7C133.9 107.7 125.3 99.1002 125.3 88.4002C125.3 77.8002 133.9 69.2002 144.5 69.2002C155.2 69.2002 163.8 77.8002 163.8 88.4002Z"
        fill="#26C6DA"
      />
      <path
        d="M162.6 72.8001C162 73.5001 160.9 73.5001 160.2 72.8001C159.5 72.1001 159.5 71.0001 160.2 70.3001C160.9 69.7001 162 69.7001 162.6 70.3001C163.3 71.0001 163.3 72.1001 162.6 72.8001Z"
        fill="#E0E0E0"
      />
      <path
        d="M168.4 90.1002C167.4 90.1002 166.7 89.4002 166.7 88.4002C166.7 87.5002 167.4 86.7002 168.4 86.7002C169.3 86.7002 170.1 87.5002 170.1 88.4002C170.1 89.4002 169.3 90.1002 168.4 90.1002Z"
        fill="#E0E0E0"
      />
      <path
        d="M160.2 106.5C159.5 105.8 159.5 104.8 160.2 104.1C160.9 103.4 162 103.4 162.6 104.1C163.3 104.8 163.3 105.8 162.6 106.5C162 107.2 160.9 107.2 160.2 106.5Z"
        fill="#E0E0E0"
      />
      <path
        d="M142.8 112.3C142.8 111.3 143.6 110.6 144.5 110.6C145.5 110.6 146.3 111.3 146.3 112.3C146.3 113.2 145.5 114 144.5 114C143.6 114 142.8 113.2 142.8 112.3Z"
        fill="#E0E0E0"
      />
      <path
        d="M126.5 104.1C127.1 103.4 128.2 103.4 128.9 104.1C129.6 104.8 129.6 105.8 128.9 106.5C128.2 107.2 127.1 107.2 126.5 106.5C125.8 105.8 125.8 104.8 126.5 104.1Z"
        fill="#E0E0E0"
      />
      <path
        d="M120.7 86.7002C121.6 86.7002 122.4 87.5002 122.4 88.4002C122.4 89.4002 121.6 90.1002 120.7 90.1002C119.7 90.1002 119 89.4002 119 88.4002C119 87.5002 119.7 86.7002 120.7 86.7002Z"
        fill="#E0E0E0"
      />
      <path
        d="M128.9 70.3001C129.6 71.0001 129.6 72.1001 128.9 72.8001C128.2 73.5001 127.1 73.5001 126.5 72.8001C125.8 72.1001 125.8 71.0001 126.5 70.3001C127.1 69.7001 128.2 69.7001 128.9 70.3001Z"
        fill="#E0E0E0"
      />
      <path
        d="M146.3 64.5999C146.3 65.4999 145.5 66.2999 144.5 66.2999C143.6 66.2999 142.8 65.4999 142.8 64.5999C142.8 63.5999 143.6 62.8999 144.5 62.8999C145.5 62.8999 146.3 63.5999 146.3 64.5999Z"
        fill="#E0E0E0"
      />
      <path
        d="M152.4 70.9001L127 96.3001C126.4 95.0334 125.967 93.7334 125.7 92.4001L148.5 69.6001C149.833 69.8668 151.133 70.3001 152.4 70.9001Z"
        fill="#80DEEA"
      />
      <path
        d="M161.6 79.4002L135.6 105.4C132.9 104 130.6 102 128.8 99.5002L155.6 72.7002C158.1 74.4002 160.1 76.8002 161.6 79.4002Z"
        fill="#80DEEA"
      />
      <path d="M41.3999 109.5V112.3L23.3999 122.8V117.1L41.3999 109.5Z" fill="#BDBDBD" />
    </svg>
  );
};

export default V1;
