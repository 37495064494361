/* eslint-disable react/no-find-dom-node */
import React, {FC, useEffect, useState} from 'react'
import classNames from 'classnames';
import _, {isObject} from 'lodash'
import { Controller } from 'react-hook-form';
import { Select, DatePicker } from 'antd';
import ReactInputMask from 'react-input-mask';
import moment from 'moment';
import IMask from 'imask';
// icons
import {IconPlus, IconRemoveInput} from '@assets/icons/modals/addUsers'
import IconXError from '@assets/icons/inputs/error';
import IconDropDownArrow from '@assets/icons/select/dropDownArrow';
// styles
import './styles/style.scss';
import CalendarIcon from '@assets/icons/menu/CalendarIcon'

interface InputDefaultType {
  reference?: any;
  name?: string;
  label?: any;
  classes?: string;
  error?: any;
  type?: string;
  placeholder?: any;
  register?: any;
  control?: any;
  rules?: any;
  requered?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  maxLength?: number;
  actionPlus?: any;
  plus?: any;
  onChange?: any;
  value?: any;
  allowClear?: any;
  field?: any;
  index?: any;
  options?: any;
  dropdownComponent?: any;
}

const InputDefault: FC<InputDefaultType> = ({
  reference,
  name,
  label,
  classes,
  error,
  type,
  placeholder,
  register,
  rules,
  control,
  defaultValue,
  disabled,
  requered,
  children,
  maxLength,
  actionPlus,
  plus,
  onChange,
  value,
}: InputDefaultType) => {
  const switchController = () => {
    const onChangeFnc = (e: any, onChange: any) => {
      if (maxLength) {
        maxLength >= e.target.value.length &&
          onChange(parseInt(e.target.value.toString().replace('+', '').replace('-', '')));
        return;
      }
      onChange(e.target.value);
    };
    if (control) {
      return (
        <Controller
          control={control}
          name={name ? name : ''}
          rules={rules}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty },
            formState,
          }) => (
            <div className={classNames('input-default', classes)}>
              {label && (
                <label>
                  {label}
                  {requered && <div className="input-default__requered">*</div>}
                  {plus && (
                    <div className="input-default__plus" onClick={() => actionPlus()}>
                      <IconPlus />
                    </div>
                  )}
                </label>
              )}
              <div className="input-default__input">
                <input
                  ref={reference}
                  className={classNames('', { 'input-default__error': error })}
                  onChange={(e: any) => onChangeFnc(e, onChange)}
                  name={name}
                  type={type}
                  placeholder={placeholder}
                  value={value || ''}
                  disabled={disabled ? disabled : false}
                />
                <div className="input-default__icons">{children}</div>

                {error && (
                  <div className={classNames('input-default__message', { ['animation-opacity']: error })}>
                    <div className="input-default__message-icon">
                      <IconXError />
                    </div>
                    <span>{error}</span>
                  </div>
                )}
              </div>
            </div>
          )}
        />
      );
    }

    return (
      <div className={classNames('input-default', classes)}>
        <label>{label}</label>
        <div className="input-default__input">
          <input
            ref={reference}
            className={classNames('', { 'input-default__error': error })}
            name={name}
            type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            defaultValue={defaultValue}
            disabled={disabled ? disabled : false}
          />
          <div className="input-default__icons">{children}</div>

          {error && (
            <div className={classNames('input-default__message', { ['animation-opacity']: error })}>
              <div className="input-default__message-icon">
                <IconXError />
              </div>
              <span>{error}</span>
            </div>
          )}
        </div>
      </div>
    );
  };
  return <>{switchController()}</>;
};

export default InputDefault;

export const InputDatePicker: FC<any> = ({
  name,
  label,
  classes,
  error,
  type,
  placeholder,
  register,
  rules,
  control,
  defaultValue,
  disabled,
  requered,
  children,
  allowClear,
}: any) => {
  const DATE_FORMAT = 'DD/MM/YYYY';
  const MASKED = IMask.createMask({
    blocks: {
      DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
      MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
      YYYY: { from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE },
    },
    format: (date: Date) => moment(date).format(DATE_FORMAT),
    mask: Date,
    parse: (date: string) => moment(date, DATE_FORMAT),
    pattern: DATE_FORMAT,
  });

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty },
        formState,
      }) => (
        <div className={classNames('input-default', classes)}>
          <label>
            {label}
            {requered && <div className="input-default__requered">*</div>}
          </label>
          <div className="input-default__input">
            <DatePicker
              allowClear={allowClear}
              className={classNames('', { 'input-default__error': error })}
              placeholder={placeholder}
              format={'DD/MM/YYYY'}
              value={value}
              onChange={onChange}
              suffixIcon={ <CalendarIcon/> }
              onKeyDown={(event: any) => {
                // event.target.value = datePickerformat(event.target.value);

                const input = event.target as HTMLInputElement;
                input.value = MASKED.resolve(input.value);
              }}
            />
            <div className="input-default__icons">{children}</div>

            {error && (
              <div className={classNames('input-default__message', { ['animation-opacity']: error })}>
                <div className="input-default__message-icon">
                  <IconXError />
                </div>
                <span>{error}</span>
              </div>
            )}
          </div>
        </div>
      )}
    />
  );
};

export const InputMaskDefault: FC<any> = ({
  name,
  label,
  classes,
  error,
  type,
  placeholder,
  register,
  rules,
  control,
  defaultValue,
  disabled,
  requered,
  children,
  plus,
  actionPlus,
}: any) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty },
        formState,
      }) => (
        <div className={classNames('input-default', classes)}>
          <label>
            {label}
            {requered && <div className="input-default__requered">*</div>}
            {plus && (
              <div className="input-default__plus" onClick={() => actionPlus()}>
                <IconPlus />
              </div>
            )}
          </label>
          <div className="input-default__input">
            <ReactInputMask
              mask="+38 (099) 999 99 99"
              className={classNames('', { 'input-default__error': error })}
              placeholder="+38 (097) 777 77 77"
              maskChar={' '}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
            <div className="input-default__icons">{children}</div>

            {error && (
              <div className={classNames('input-default__message', { ['animation-opacity']: error })}>
                <div className="input-default__message-icon">
                  <IconXError />
                </div>
                <span>{error}</span>
              </div>
            )}
          </div>
        </div>
      )}
    />
  );
};

export const InputSelectDefault: FC<any> = ({
  name,
  label,
  classes,
  error,
  type,
  placeholder,
  register,
  rules,
  control,
  defaultValue,
  disabled,
  children,
  options,
  requered,
  allowClear,
}: any) => {
  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty },
          formState,
        }) => (
          <div className={classNames('input-default', classes)}>
            <label>
              {label}
              {requered && <div className="input-default__requered">*</div>}
            </label>
            <div className="input-default__input">
              <Select
                className={classNames('form-select', { 'form-select__error': error })}
                popupClassName="form-dropdown"
                placeholder={placeholder}
                bordered={false}
                style={{ width: '100%', height: '40px' }}
                onChange={(value: any) => {
                  onChange(value);
                }}
                options={options.map(({ id, name }: any) => {
                  return { label: name, value: id };
                })}
                allowClear={allowClear}
                suffixIcon={<IconDropDownArrow />}
                value={value}
              />
              <div className="input-default__icons">{children}</div>

              {error && (
                <div className={classNames('input-default__message', { ['animation-opacity']: error })}>
                  <div className="input-default__message-icon">
                    <IconXError />
                  </div>
                  <span>{error}</span>
                </div>
              )}
            </div>
          </div>
        )}
      />
    );
  }

  return (
    <div className={classNames('input-default', classes)}>
      <label>{label}</label>
      <div className="input-default__input">
        <Select
          className={classNames('form-select', { 'form-select__error': error })}
          popupClassName="form-dropdown"
          placeholder={placeholder}
          bordered={false}
          style={{ width: '100%', height: '40px' }}
          options={options.map(({ id, name }: any) => {
            return { label: name, value: id };
          })}
          allowClear
          suffixIcon={<IconDropDownArrow />}
        />
        <div className="input-default__icons">{children}</div>

        {error && (
          <div className={classNames('input-default__message', { ['animation-opacity']: error })}>
            <div className="input-default__message-icon">
              <IconXError />
            </div>
            <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export const InputDropdownPrefix: FC<InputDefaultType> = ({
  reference,
  name,
  label,
  classes,
  error,
  type,
  placeholder,
  register,
  rules,
  control,
  defaultValue,
  disabled,
  requered,
  children,
  maxLength,
  actionPlus,
  plus,
  onChange,
  value,
  field,
  index,
  options,
  dropdownComponent: DropdownComponent,
}: InputDefaultType) => {

    const isEmpty = isObject(children)


  return (
    <div className={classNames('input-default', classes)}>
      {label && (
        <label>
          {label}
          {requered && <div className="input-default__requered">*</div>}
          {plus && (
            <div className="input-default__plus" onClick={() => actionPlus()}>
              <IconPlus />
            </div>
          )}
        </label>
      )}

      <div className={`${classes}__body`}>
        <Controller
          control={control}
          name={`socials[${index}].type`}
          rules={rules}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty },
            formState,
          }) => (
            <DropdownComponent value={value} name={`socials[${index}].type`} register={register} control={control} />
          )}
        />
        <Controller
          control={control}
          name={`socials[${index}].name`}
          rules={rules}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty },
            formState,
          }) => (
            <div className="input-default__input">
              <input
                ref={reference}
                className={classNames('', { 'input-default__error': error, 'isIcon': isEmpty,   })}
                onChange={onChange}
                name={`socials[${index}].name`}
                type={type}
                placeholder={placeholder}
                value={value || ''}
                disabled={disabled ? disabled : false}
              />
              <div className="input-default__icons">{children}</div>

              {error && (
                <div className={classNames('input-default__message', { ['animation-opacity']: error })}>
                  <div className="input-default__message-icon">
                    <IconXError />
                  </div>
                  <span>{error}</span>
                </div>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};
