/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {setCompanies, setCompaniesAdvanceSearch, setCompany, setLoading} from '@redux/reducers/lcVerificator'
import {call, put} from 'redux-saga/effects'
import ReactHtmlParser from 'react-html-parser'
import _ from 'lodash'
// api
import {api} from '@api/lcVerification'
// mapping
import NOTIFICATION from './notification'

export function* sagaGetVerificationCompanyInfo(action) {
  const { payload } = action;
  const { request, callback } = payload;
  try {
    const { data } = yield call(api.getVerificationCompanyInfo, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }
    if (data.status) {
      yield put(setCompany(data.company_info));
      callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaGetVerificationCompaniesList(action) {
  const { payload } = action;
  const { request, callback } = payload;
  try {
    const { data } = yield call(api.getVerificationCompaniesList, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }
    if (data.status) {
      yield put(setCompanies(data));
      callback && callback();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaFindListVerificationCompanies(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const { data } = yield call(api.getVerificationCompanyAdvanceSearch, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }

    yield put(setCompaniesAdvanceSearch(data.find_result));

    if (data.find_result.length === 0) {
      return NOTIFICATION.error('По вашему поисковому запросу нет результатов. Повторите попытку.');
    }

    if(data.find_result.length > 0){
      callback && callback()
    }

  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaSetVerificationCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;
  try {
    const { data } = yield call(api.setVerificationCompany, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }
    if (data.status) {
      yield put(setCompany(data.company_info));
      callback && callback.success();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaRemoveVerificationCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;
  try {
    const { data } = yield call(api.removeVerificationCompany, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }
    if (data.status) {
      callback && callback.success();
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaSearchVerificationCompany(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const { data } = yield call(api.searchVerificationCompany, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }
    if (data.status) {
      callback && callback.success(data.search_result);
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaSendVerificationCompanyMessage(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const { data } = yield call(api.sendVerificationCompanyMessage, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }
    if (data.status) {
      callback && callback.success();
      yield put(setCompany(data.company_info));
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaEditVerificationCompanyMessage(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const { data } = yield call(api.editVerificationCompanyMessage, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }
    if (data.status) {
      callback && callback.success();
      yield put(setCompany(data.company_info));
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}

export function* sagaRemoveVerificationCompanyMessage(action) {
  const { payload } = action;
  const { request, callback } = payload;

  try {
    const { data } = yield call(api.removeVerificationCompanyMessage, request);

    if (!_.isObject(data)) {
      return NOTIFICATION.error(ReactHtmlParser(data));
    }

    if (data.error) {
      return NOTIFICATION.error(data.msg);
    }
    if (data.status) {
      callback && callback.success();
      yield put(setCompany(data.company_info));
    }
  } catch (e) {
    yield NOTIFICATION.error(ReactHtmlParser(e));
  } finally {
    yield put(setLoading(false));
  }
}
