import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
// components
import ButtonDefault from '@atoms/buttons/button';
// icons
import IconWrong from '@assets/icons/modals/wrong';
// style
import './style.scss';
import {useParams} from 'react-router-dom'
import {setVerification} from '@redux/reducers/lcVerificator'
import {setStatus, setSuccess} from '@redux/reducers/app'
import {useDispatch} from 'react-redux'

interface RemoveLcCompanyModalType {
    component: any;
}

const DeclineVerificationCompany: FC<RemoveLcCompanyModalType> = ({
                                                                component: Component,
                                                            }: RemoveLcCompanyModalType) => {
    const [visible, setVisible] = useState(false);
    const [loader, setLoader] = useState(false);
    const [comment, setComment] = useState('');
    const { id } = useParams();

    const dispatch = useDispatch()
    const { t } = useTranslation();

    const onCancel = () => {
        setVisible(false);
    };

    const onChange = (e: any) => {
        setComment(e.target.value);
    };

    const actionVerification = () => {
        dispatch(
            setVerification({
                request: {
                    company_id: id,
                    verification: 0,
                    comment,
                },
                callback: {
                    success: () => {
                        dispatch(setStatus('success'));
                        dispatch(setSuccess({ type: 'lc-not-verification' }));
                        setLoader(true);
                        onCancel();
                    },
                },
            }),
        );
    };


    return (
        <>
            <>
                <Component
                    onClick={() => {
                        setVisible(true);
                    }}
                />
                <Modal
                    className="modal-confirm"
                    open={visible}
                    width={504}
                    footer={null}
                    closable={true}
                    bodyStyle={{ padding: '32px 64px' }}
                    centered
                    onCancel={onCancel}
                >
                    <div className="modal-confirm__header">
                        <IconWrong />
                    </div>
                    <div className="modal-confirm__body">
                        <div className="modal-confirm__title decline">{t('Отклонить верификацию')}</div>
                        <div className="modal-confirm__desc decline-desc">{ReactHtmlParser(t('Укажите причину отказа'))}</div>
                        <div>
                            <textarea
                                className="send-message__textarea verification"
                                placeholder={`${t('Неккоректные данные для проведения верификации. Загрузитие актуальный подтверждающий документ.')}`}
                                value={comment}
                                onChange={onChange}
                            />
                        </div>
                        <div className="modal-confirm__btns-decline">
                             <span className="edit-profile-form__btn-text" onClick={onCancel}>
                                 {t('Отмена')}
                             </span>
                            <ButtonDefault action={actionVerification} name={t('Отклонить')} />
                        </div>
                    </div>
                </Modal>
            </>
        </>
    );
};

export default DeclineVerificationCompany;
