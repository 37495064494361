import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '@src/i18n';
// components
import InputDefault from '@atoms/inputs';
import ButtonDefault from '@atoms/buttons/button';
// actions
import { forgot } from '@redux/reducers/auth';
import { appPage, setStatus, setSuccess } from '@redux/reducers/app';
// helps
import { forgotSchema } from '@tools/shemas';
// tools
import { consts } from '@tools/consts';
// types
import { stateType } from '@types';
// styles
import './style.scss';

interface FormData {
  email: string;
}

const FormForgotPassword: FC = () => {
  const { loading } = useSelector((state: stateType) => state.auth);
  const schema = useMemo(() => forgotSchema(), [i18n.language]);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = (data: any) => {
    const { email } = data;

    let key = 'email';
    let request;

    Number(email) ? (key = 'phone') : (key = 'email');

    if (key === 'email') {
      request = {
        [key]: email,
        ref: consts.domain,
      };
    }

    if (key === 'phone') {
      request = {
        [key]: email,
      };
    }

    dispatch(
      forgot({
        request,
        callback: () => {
          if (key === 'email') {
            dispatch(setStatus('success'));
            dispatch(
              setSuccess({
                type: 'forgot',
              }),
            );
          }

          if (key === 'phone') {
            dispatch(setStatus('forgot-code'));
            localStorage.setItem('forgot-phone', email);
            navigate('/forgot-by-phone');
          }
        },
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form forgot-form">
      <div className="form__container">
        <InputDefault
          name={'email'}
          label={t('E-mail или телефон')}
          placeholder={t('Адрес эл. почты или телефон')}
          error={errors.email?.message}
          register={register}
          control={control}
        />
      </div>
      <div className="form__container">
        <ButtonDefault name={t('Восстановить пароль')} type={'submit'} loading={loading} disabled={loading} />
        <div className="form__container-links">
          <Link to="/auth" onClick={() => dispatch(appPage('login'))}>
            {t('Вход')}
          </Link>
          <Link to="/auth" onClick={() => dispatch(appPage('registration'))}>
            {t('Регистрация')}
          </Link>
        </div>
      </div>
    </form>
  );
};

export default FormForgotPassword;
