import React from 'react';

function TasksIcon() {
   return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd"
               d="M6.39175 1.66658H13.6167C16.4417 1.66658 18.3334 3.64991 18.3334 6.59991V13.4091C18.3334 16.3499 16.4417 18.3332 13.6167 18.3332H6.39175C3.56675 18.3332 1.66675 16.3499 1.66675 13.4091V6.59991C1.66675 3.64991 3.56675 1.66658 6.39175 1.66658ZM9.52508 12.4916L13.4834 8.53325C13.7667 8.24991 13.7667 7.79158 13.4834 7.49991C13.2001 7.21658 12.7334 7.21658 12.4501 7.49991L9.00841 10.9416L7.55008 9.48325C7.26675 9.19991 6.80008 9.19991 6.51675 9.48325C6.23341 9.76658 6.23341 10.2249 6.51675 10.5166L8.50008 12.4916C8.64175 12.6332 8.82508 12.6999 9.00841 12.6999C9.20008 12.6999 9.38341 12.6332 9.52508 12.4916Z"
               fill="currentColor"/>
      </svg>
   
   );
}

export default TasksIcon;