// types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/*
 *
 * Departments reducer
 *
 */
const initialState = {
  loading: false,
  name: null,
  error: null,
  list: [] as any,
  info: null as any,
};

export type deparmentsInitStateType = typeof initialState;

const deparments = createSlice({
  name: 'deparments',
  initialState,
  reducers: {
    createDepartment: () => {
      return;
    },
    getDepartments: () => {
      return;
    },
    getDepartmentInfo: () => {
      return;
    },
    setDepList: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setDePInfo: (state, action: PayloadAction<any>) => {
      state.info = action.payload;
    },
    setDepLoading: (state, action: PayloadAction<any>) => {
      state.info = action.payload;
    },
    setDepName: (state, action: PayloadAction<any>) => {
      state.name = action.payload;
    },
  },
});

export default deparments.reducer;
export const {
  createDepartment,
  getDepartments,
  getDepartmentInfo,
  setDepList,
  setDePInfo,
  setDepLoading,
  setDepName,
}: any = deparments.actions;
