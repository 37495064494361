import React from 'react';

function SettingsIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0019 11.3167C17.2999 11.4751 17.5299 11.7251 17.6917 11.9751C18.0068 12.4917 17.9812 13.1251 17.6747 13.6834L17.0785 14.6834C16.7634 15.2167 16.1758 15.5501 15.5711 15.5501C15.2731 15.5501 14.9409 15.4667 14.6684 15.3001C14.447 15.1584 14.1915 15.1084 13.919 15.1084C13.0759 15.1084 12.369 15.8001 12.3435 16.6251C12.3435 17.5834 11.5599 18.3334 10.5806 18.3334H9.42236C8.43446 18.3334 7.65096 17.5834 7.65096 16.6251C7.63393 15.8001 6.92708 15.1084 6.08396 15.1084C5.80292 15.1084 5.54743 15.1584 5.33453 15.3001C5.062 15.4667 4.72135 15.5501 4.4318 15.5501C3.81862 15.5501 3.231 15.2167 2.91589 14.6834L2.32827 13.6834C2.01316 13.1417 1.99613 12.4917 2.31124 11.9751C2.4475 11.7251 2.70299 11.4751 2.99254 11.3167C3.231 11.2001 3.38429 11.0084 3.52907 10.7834C3.95488 10.0667 3.69939 9.12508 2.97551 8.70008C2.13239 8.22508 1.85987 7.16675 2.3453 6.34175L2.91589 5.35841C3.40984 4.53341 4.46586 4.24175 5.31749 4.72508C6.05841 5.12508 7.02076 4.85841 7.45509 4.15008C7.59135 3.91675 7.668 3.66675 7.65096 3.41675C7.63393 3.09175 7.72761 2.78341 7.88942 2.53341C8.20452 2.01675 8.77512 1.68341 9.39681 1.66675H10.5976C11.2278 1.66675 11.7984 2.01675 12.1135 2.53341C12.2668 2.78341 12.369 3.09175 12.3435 3.41675C12.3264 3.66675 12.4031 3.91675 12.5393 4.15008C12.9737 4.85841 13.936 5.12508 14.6854 4.72508C15.5286 4.24175 16.5931 4.53341 17.0785 5.35841L17.6491 6.34175C18.1431 7.16675 17.8705 8.22508 17.0189 8.70008C16.295 9.12508 16.0395 10.0667 16.4739 10.7834C16.6101 11.0084 16.7634 11.2001 17.0019 11.3167ZM7.59134 10.0084C7.59134 11.3167 8.67292 12.3584 10.01 12.3584C11.347 12.3584 12.4031 11.3167 12.4031 10.0084C12.4031 8.70008 11.347 7.65008 10.01 7.65008C8.67292 7.65008 7.59134 8.70008 7.59134 10.0084Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SettingsIcon;
