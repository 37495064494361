import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
// components
import TabsAuthContent from '@containers/auth/tabsAuthConteiner';
import AsideTemplate from '@templates/asideTemplate';
// types
import { stateType } from '@types';

const Authentication: FC = () => {
  const { page } = useSelector((state: stateType) => state.app);
  return (
    <>
      <Helmet title={page} />
      <AsideTemplate showBenefits center page={page}>
        <TabsAuthContent />
      </AsideTemplate>
    </>
  );
};

export default Authentication;
