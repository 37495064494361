import React, { FC } from 'react';
import { Layout } from 'antd';

import { Content } from 'antd/es/layout/layout';

import { useSelector } from 'react-redux';

import { stateType } from '@types';
import AuthenticationAside from '@containers/app/aside';
import FormVerifyCompany from '@organisms/forms/verifyCompany/index';

const VerifyCompany: FC = () => {
  //const { user, loading, error } = useSelector((state) => state.user);

  const { page } = useSelector((state: stateType) => state.app);
  //  const resetApp = useAppReset();

  // if (loading && !user) return <Loading text="Проверка пользователя..." />;

  return (
    <Layout className="new-company">
      <AuthenticationAside page={page} />

      <Layout className="new-company__layout layout">
        <Content className="new-company__container">
          <FormVerifyCompany />
        </Content>
      </Layout>
    </Layout>
  );
};

export default VerifyCompany;
