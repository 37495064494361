import React, { useEffect, useState } from 'react';
import DatePickerCalendarIcon from '@assets/icons/picker/DatePickerCalendarIcon.jsx';
import { DatePicker } from 'antd';

function AppDatePicker({ placeholder, isHideOkButton, ...rest }: any) {
  const [isOpen, setIsOpen] = useState(false);
  const hideOkButton = () => {
    //const popupContainer: Array<NodeListOf<Element>> = [...document.querySelectorAll('.ant-picker-footer')];
    //isHideOkButton && popupContainer.forEach((el: any) => el.classList.add('hide-ok-button'));
  };

  useEffect(() => {
    if (isOpen) {
      hideOkButton();
    }
  }, [isOpen]);
  return (
    <DatePicker
      suffixIcon={<DatePickerCalendarIcon />}
      format="DD.MM.YYYY"
      style={{ width: '100%' }}
      placeholder={placeholder}
      showToday={false}
      showNow={false}
      onOpenChange={setIsOpen}
      {...rest}
    />
  );
}

export default AppDatePicker;
