import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// styles
import './style.scss';

interface TitlteType {
  name: any;
  name2?: any;
  classes: string;
}

interface StatusType {
  status: 1 | null;
  classes: string;
}

export const Titlte: FC<TitlteType> = ({ name, name2, classes }: TitlteType) => {
  const { t } = useTranslation();
  return (
    <div className={`${classes}`}>
      <span>{t(name)}</span>
      {name2 && <span>{t(name2)}</span>}
    </div>
  );
};

export const Status: FC<StatusType> = ({ status, classes }: StatusType) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(`${classes}`, { [`${classes}-active`]: status })}>
      {status ? t('Подтвержден') : t('Ожидает подтверждения')}
    </div>
  );
};

export const StatusCompany: FC<any> = ({ status, isDel, classes }: any) => {
  const { t } = useTranslation();

  return (
      <>
        { isDel === 1
            ?
            <div className={classNames(`${classes}`, { [`status-company-5`]: 5 })}>Удалена</div>
            :
            <div className={classNames(`${classes}`, { [`status-company-${status?.id}`]: status })}>{status?.name}</div>}
      </>
  )
};
