import React, { FC } from 'react';
import moment from 'moment';
import classNames from 'classnames';
// styles
import './style.scss';

interface InfoBlockType {
  isWarning: boolean;
  classes?: string;
  onClick?: () => void;
  title: string;
  subtitle: string;
  date?: string;
  counter?: boolean;
  type?: string;
}

const InfoBlock: FC<InfoBlockType> = ({
  isWarning,
  onClick,
  title,
  subtitle,
  date,
  counter,
  type,
  classes,
}: InfoBlockType) => {
  const day = moment(date, 'DD.MM.YYYY').format('DD');
  const month = moment(date, 'DD.MM.YYYY').format('MMM');

  return (
    <div className={classNames('info-block', classes)}>
      <div className={`info-block__item ${isWarning && '_warning'}`} onClick={() => onClick && onClick()}>
        <div className={classNames('info-block__type', { [`info-block__type-${type}`]: type ? type : false })} />

        <div className="info-block__message">
          <span className="info-block__title">{title}</span>
          <span className="info-block__subtitle">{subtitle}</span>
        </div>
      </div>
      {counter && <div className="info-block__counter">+1</div>}
    </div>
  );
};

export default InfoBlock;
