import React, {useEffect, useState} from 'react'
import './style.scss'

import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import {Carousel} from 'react-responsive-carousel'
import IconArrowLeft, {IconArrowLeftCircle, IconArrowRightCircle} from '@assets/icons/btn/arrow'
import {IconFile} from '@assets/icons/uploaders'
import {IconAppFile, IconAppPrint} from '@assets/icons/app'
import {getCompany} from '@redux/reducers/lcVerificator'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import moment from 'moment'
import 'moment-timezone'
import ScaleMinus from '@assets/icons/btn/scaleMinus'
import ScalePlus from '@assets/icons/btn/scalePlus'

const ViewFilesModal = () => {

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { id } = useParams()

	const { company } = useSelector((state) => state.lcVerificator)


	const [activeImage, setActiveImage] = useState('')

	useEffect(() => {
		setActiveImage(company?.files?.edr[0].url)
	}, [company])



	const slide_img = [
		'https://swiperjs.com/demos/images/nature-1.jpg',
		'https://swiperjs.com/demos/images/nature-2.jpg',
		'https://swiperjs.com/demos/images/nature-3.jpg',
		'https://swiperjs.com/demos/images/nature-4.jpg',
	]


	useEffect(() => {
		dispatch(
			getCompany({
				request: {
					company_id: Number(id),
				},
			})
		)
	}, [])

	const downloadImage = () => {
		window.open(activeImage)
	}

	const printImage = () => {
		window.print()
	}

	const [scale, setScale] = useState(1.0);

	const handleScaleUp = () => {
		setScale(scale + 0.1);
	};

	const handleScaleDown = () => {
		setScale(scale - 0.1);
	};

	const scalePercent = Math.round(scale * 100);

	const originalTime = moment('2023-06-22T12:30:00Z');
	const convertedTime = originalTime.tz('America/New_York');
	const formattedTime = convertedTime.format('YYYY-MM-DD HH:mm:ss');

	// @ts-ignore
	return (
		<div className='view-file-container'>
			<div className='headerModal'>
				<div
					onClick={() => {
						navigate(`/lc-companies/company/${id}/files`)
					}}
					className='headerModal_start'
				>
					<IconArrowLeft onClick={() => {}} />
					<IconFile />
					<span className='title'>Свидетельство о регистрации налогоплательщика.pdf</span>
				</div>

				<div className='headerModal_end'>
					<div onClick={printImage}>
						<IconAppPrint />
					</div>
					<div onClick={downloadImage}>
						<IconAppFile />
					</div>
				</div>
			</div>

			<Carousel
				axis='horizontal'
				showStatus={false}
				className='carusel'
				showThumbs={false}
				infiniteLoop={false}
				showIndicators={false}
				emulateTouch
				fade={false}
				useKeyboardArrows
				transitionTime={1000}
				onChange={(index) => {
					setActiveImage(company?.files?.edr[index])
				}}
				renderArrowNext={(clickHandler, hasNext) => {
					return (
						hasNext && (
							<div onClick={clickHandler}>
								<IconArrowRightCircle />
							</div>
						)
					)
				}}
				renderArrowPrev={(clickHandler, hasPrev) => {
					return (
						hasPrev && (
							<div onClick={clickHandler}>
								<IconArrowLeftCircle />
							</div>
						)
					)
				}}
			>
				{company?.files?.edr.map((item) => {

					const fileName = item.name;
					const fileExtension = fileName.split('.').pop().toLowerCase();

					const isImage = fileExtension === 'jpeg' || fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'gif'

					if (isImage){
						return (
							<>
								<img
									key={item}
									src={item.url}
									style={{ width: '50%', transform: `scale(${scale})`}}
									alt={'image'}
									loading={'lazy'}
								/>
								<div style={{position:'relative', marginTop:35}}>


									<button onClick={handleScaleDown} className='btn-zoom'>-</button>
									<span className='percent'>{scalePercent} %</span>
									<button onClick={handleScaleUp} className='btn-zoom'>+</button>

									{/*<div onClick={handleScaleDown}>*/}
									{/*	<ScaleMinus/>*/}
									{/*</div>*/}
									{/*{scalePercent}*/}
									{/*<div onClick={handleScaleUp}>*/}
									{/*	<ScalePlus/>*/}
									{/*</div>*/}
								</div>
							</>

						)
					} else {
						return (
							<div style={{ width: '100%', height: '100vh' }}>
							<iframe
								src={`https://docs.google.com/viewer?url=${encodeURIComponent(item.url)}&embedded=true`}
								width="100%"
								height="100%"
								frameBorder="0"
								title="PDF Viewer"
								className='iframeClass'
								style={{ background: '#dcdefc', }}
							/>
							</div>
						)
					}

				})}

			</Carousel>
		</div>
	)
}

export default ViewFilesModal
