import React, { useEffect, useState } from 'react';

const usePasswordHook: any = (page: string) => {
  const [passwordType, setPasswordType] = useState<string>('password');

  const changePasswordType = () => {
    passwordType === 'password' && setPasswordType('input');
    passwordType === 'input' && setPasswordType('password');
  };

  return { passwordType, changePasswordType };
};

export default usePasswordHook;
